import * as Jotai from 'jotai';
import { toastItemListAtom } from './store/toastItemListAtom';
import Toast from './Toast';
import { queueAtom } from './store/queueAtom';
import { useEffect } from 'react';

export interface Props {
  maxLength?: number;
}
function ToastContainer({ maxLength = 5 }: Props) {
  const [queue, setQueue] = Jotai.useAtom(queueAtom);
  const [toastItemList, setToastItemList] = Jotai.useAtom(toastItemListAtom);

  useEffect(() => {
    if (toastItemList.length < maxLength && queue.length > 0 && queue[0]) {
      const newToast = queue[0];
      setQueue((prevQueue) => prevQueue.slice(1));
      setToastItemList((prevDisplayQueue) => [...prevDisplayQueue, newToast]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxLength, queue, toastItemList]);

  const handleOnOpenChange = (id: string, open: boolean) => {
    setToastItemList((prevList) => {
      const newQueue = prevList.map((toast) =>
        toast.id === id ? { ...toast, open } : toast,
      );
      return newQueue.filter((toast) => toast.open);
    });
  };

  return toastItemList.map((item) => (
    <Toast
      key={item.id}
      type={item.type}
      description={item.contents}
      open={item.open}
      onOpenChange={(open) => handleOnOpenChange(item.id, open)}
    />
  ));
}

export default ToastContainer;
