import { Button } from '@daouoffice/ui';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table/index';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table/index';
import { SortChevron } from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  CheckIcon,
  NoSymbolIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import * as deleteMobileAccessRequestMutation from '../../api/deleteMobileAccessRequestMutation';
import * as mobileAccessListQuery from '../../api/mobileAccessListQuery';
import * as mobileAccessRequestMutation from '../../api/mobileAccessRequestMutation';
import {
  AccessType,
  Direction,
  MobileAccessType,
  MobileInfo,
  SearchType,
} from '../../types';
import { useValuesContext } from '../Context';
import { Tag } from './Tag';

export interface Props {
  type: AccessType;
}

export function DeviceList({ type }: Props) {
  // TODO: sort 및 searchBar select 기능 추가 필요
  const { accessEnabled } = useValuesContext();
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();
  const { info } = useToastMessage();
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageOffet, setCurrentPageOffset] = useState(20);
  const [keyword, setKeyword] = useState('');
  const [orderType, setOrderType] = useState<string>('name');
  const [seletedDevice, setSeletedDevice] = useState<MobileInfo[]>([]);
  const [searchType, setSearchType] = useState<SearchType>('name');
  const [direction, setDirection] = useState<Direction>('asc');
  const searchOptions = new Map<SearchType, string>([
    [
      'name',
      t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.table.field.name',
      ),
    ],
    [
      'loginId',
      t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.table.field.loginId',
      ),
    ],
    [
      'DEVICE_MODEL',
      t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.table.field.deviceModel',
      ),
    ],
  ]);
  const getQueryString = () => {
    const query = new URLSearchParams();
    query.append('page', currentPage.toString());
    query.append('size', pageOffet.toString());
    query.append('orderType', orderType);
    query.append('searchType', searchType);
    query.append('direction', direction);
    query.append('keyword', keyword);
    return query.toString();
  };

  const confirmBlock = (title: string, contents: string) => (
    <div className="w-[480px] !p-6 flex flex-col gap-4">
      <h2 className="text-[18px] text-black font-medium">{title}</h2>
      <span className="text-[14px] text-black font-normal whitespace-normal">
        {contents}
      </span>
    </div>
  );

  const {
    data: deviceData,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [mobileAccessListQuery.QUERY_KEY, currentPage, pageOffet],
    queryFn: async () => {
      if (accessEnabled) {
        return await mobileAccessListQuery.getMobileDeviceAccessStatus(
          type,
          getQueryString(),
        );
      }
    },
  });

  const deleteMobileDeviceAccess = useMutation({
    mutationKey: [deleteMobileAccessRequestMutation.MUTATION_KEY],
    mutationFn: () =>
      deleteMobileAccessRequestMutation.deleteMobileAccess(
        type,
        seletedDevice.map((device) => device.id),
      ),
    onSuccess: async () => {
      info(
        t(
          'globalconfig.secureManagement.mobileDeviceAccessConfig.toast.delete',
        ),
      );
      accessEnabled &&
        (await queryClient.invalidateQueries({
          queryKey: [mobileAccessListQuery.QUERY_KEY],
        }));
    },
  });

  const updateMobileDeviceAccess = useMutation({
    mutationKey: [mobileAccessRequestMutation.MUTATION_KEY],
    mutationFn: async (type: MobileAccessType) => {
      await mobileAccessRequestMutation.requestMobileAccess(
        type,
        seletedDevice.map((device) => device.id),
      );
      return type;
    },
    onSuccess: async (type: MobileAccessType) => {
      info(
        t(
          type === 'REJECTED'
            ? 'globalconfig.secureManagement.mobileDeviceAccessConfig.toast.allow'
            : 'globalconfig.secureManagement.mobileDeviceAccessConfig.toast.disallow',
        ),
      );
      accessEnabled &&
        (await queryClient.invalidateQueries({
          queryKey: [mobileAccessListQuery.QUERY_KEY],
        }));
    },
  });

  useEffect(() => {
    refetch().catch(console.error);
  }, [
    type,
    currentPage,
    pageOffet,
    keyword,
    orderType,
    searchType,
    direction,
    refetch,
  ]);

  useEffect(() => {
    setCurrentPage(0);
  }, [keyword, pageOffet]);

  if (error) {
    return <></>;
  }

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
  };

  const onOffsetChange = (offset: number) => {
    setCurrentPageOffset(offset);
  };

  const onSortChangeHandler = (direction: Direction, value: string) => {
    setDirection(direction);
    setOrderType(value);
  };

  const columnDefs: ColumnDef<MobileInfo>[] = [
    columnDefGenerator<MobileInfo>(
      'userName',
      t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.table.field.name',
      ),
      'name',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    columnDefGenerator<MobileInfo>(
      'email',
      t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.table.field.address',
      ),
      'loginId',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    columnDefGenerator<MobileInfo>(
      'osType',
      t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.table.field.ostype',
      ),
      'osType',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    columnDefGenerator<MobileInfo>(
      'deviceModel',
      t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.table.field.modelname',
      ),
    ),
    columnDefGenerator<MobileInfo>(
      'deviceId',
      t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.table.field.deviceId',
      ),
    ),
    {
      accessorKey: 'status',
      id: 'status',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t(
            'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.table.field.status',
          )}
          {'status' && (
            <SortChevron
              filterValue={'status'}
              selectedValue={orderType}
              direction={direction ?? 'asc'}
              onChange={onSortChangeHandler}
            />
          )}
        </div>
      ),
      cell: (value: CellContext<MobileInfo, unknown>) => (
        <div className="py-3 text-center">
          <Tag type={value.row.original.status} />
        </div>
      ),
    },
  ];

  const deleteHandler = () => {
    const handleConfirm = async (deviceCount: number) => {
      const requestConfirm = confirmBlock(
        t(
          'globalconfig.secureManagement.mobileDeviceAccessConfig.confirm.delete.title',
        ),
        t(
          'globalconfig.secureManagement.mobileDeviceAccessConfig.confirm.delete.contents',
        ).replace('{deviceCount}', deviceCount.toString()),
      );

      return deviceCount > 0 && (await confirm(requestConfirm));
    };
    handleConfirm(seletedDevice.length)
      .then((res) => res && deleteMobileDeviceAccess.mutate())
      .catch(console.error);
  };

  const requestAllowHandler = () => {
    const handleConfirm = async (deviceCount: number) => {
      const requestConfirm = confirmBlock(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.confirm.allow.title',
        t(
          'globalconfig.secureManagement.mobileDeviceAccessConfig.confirm.allow.contents',
        ).replace('{deviceCount}', deviceCount.toString()),
      );

      return deviceCount > 0 && (await confirm(requestConfirm));
    };
    handleConfirm(seletedDevice.length)
      .then((res) => res && updateMobileDeviceAccess.mutate('APPROVED'))
      .catch(console.error);
  };

  const requestDisallowHandler = () => {
    const handleConfirm = async (deviceCount: number) => {
      const requestConfirm = confirmBlock(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.confirm.disallow.title',
        t(
          'globalconfig.secureManagement.mobileDeviceAccessConfig.confirm.disallow.contents',
        ).replace('{deviceCount}', deviceCount.toString()),
      );

      return deviceCount > 0 && (await confirm(requestConfirm));
    };
    handleConfirm(seletedDevice.length)
      .then((res) => res && updateMobileDeviceAccess.mutate('APPROVED'))
      .catch(console.error);
  };

  return (
    <TablePrimitives.Root<MobileInfo>
      contents={deviceData?.elements || []}
      columnDefs={columnDefs}
      onSelectContents={(selectedRows) => setSeletedDevice(selectedRows)}
      selectable
    >
      <div className="flex flex-wrap w-full justify-between">
        <div className="flex flex-col">
          <TablePrimitives.SearchBar
            className="inline-block w-[240px]"
            onKeywordChange={onKeywordChange}
          >
            <select
              className="w-[50px] border-none mb-[2px] shirink-0 text-ellipsis"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value as SearchType)}
            >
              {Array.from(searchOptions).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </TablePrimitives.SearchBar>
          {keyword && (
            <div className="pt-3 text-[#81A9FC]">{`${deviceData?.elements ? deviceData.elements.length : 0}${t('globalconfig.common.table.search.result')}`}</div>
          )}
        </div>
        <div className="flex justify-between items-end gap-1">
          {type === 'REQUEST' && (
            <>
              <Button
                title={t(
                  'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.requestallowbutton.title',
                )}
                className="flex items-center justify-center p-1"
                onClick={requestAllowHandler}
              >
                <CheckIcon className="size-6" />
              </Button>
              <Button
                title={t(
                  'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.requestdisallowbutton.title',
                )}
                className="flex items-center justify-center p-1"
                onClick={requestDisallowHandler}
              >
                <NoSymbolIcon className="size-6" />
              </Button>
            </>
          )}
          <Button
            title={t(
              'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.deletebutton.title',
            )}
            className="flex items-center justify-center p-1 me-4"
            onClick={deleteHandler}
          >
            <TrashIcon className="size-6" />
          </Button>
          <TablePrimitives.SizeSelector
            className="w-[52px]"
            onChangeTableSize={onOffsetChange}
          />
        </div>
      </div>
      <TablePrimitives.Contents
        className="mt-[16px]"
        emptyNotice={
          <div className="flex items-center py-15 justify-center py-[60px] w-full border-solid">
            <span className="text-[#AAA] text-ellipsis -tracking-[0.56px] font-normal text-[14px] leading-normal ">
              {t(
                'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.table.noData',
              )}
            </span>
          </div>
        }
      />
      {deviceData?.elements && deviceData?.elements?.length === 0 && (
        <TablePrimitives.Pagination
          className="mt-[16px]"
          currentPage={currentPage}
          totalPage={deviceData?.page?.totalPage ?? 0}
          onNextPage={setCurrentPage}
          onPreviousPage={setCurrentPage}
          onClickPage={setCurrentPage}
          onFirstPage={() => setCurrentPage(0)}
          onLastPage={() =>
            setCurrentPage(
              deviceData?.page.totalPage ? deviceData.page.totalPage - 1 : 0,
            )
          }
        />
      )}
    </TablePrimitives.Root>
  );
}

export default DeviceList;
