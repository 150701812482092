import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24';

export interface Props {
  title: string;
}

export function Header({ title }: Props) {
  return (
    <div className="wrap_menu_header">
      <h3>{title}</h3>
      <Dialog.Close>
        <IconButton title="닫기" size="medium">
          <CloseIcon className="btn_icon ic_medium" />
        </IconButton>
      </Dialog.Close>
    </div>
  );
}

export default Header;
