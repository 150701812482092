import { PropsWithChildren } from 'react';
import { SubTabType } from '../types';
import { Provider } from './Context';

export interface Props {
  selectedTab?: SubTabType;
}

export default function Root({
  selectedTab,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className="wrap_tabs line_type !gap-2 !mx-0">
      <div className="dop_tabs">
        <Provider selectedTab={selectedTab}>{children}</Provider>
      </div>
    </div>
  );
}
