import { Primitives as ToastPrimivites } from '@daouoffice/ui/lib/foundation/Toast';

export interface Props {
  title?: string;
  description: string;
}

function Basic({ title, description }: Props) {
  return (
    <>
      <div className="flex-1 flex flex-col overflow-hidden text-ellipsis whitespace-nowrap">
        {title && (
          <ToastPrimivites.Title className="overflow-hidden text-ellipsis whitespace-nowrap text-[13px]">
            {title}
          </ToastPrimivites.Title>
        )}
        <ToastPrimivites.Description className="font-bold overflow-hidden text-ellipsis whitespace-nowrap text-[14px]">
          {description}
        </ToastPrimivites.Description>
      </div>
    </>
  );
}

export default Basic;
