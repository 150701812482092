import * as fetchClient from '../../../shared/lib/fetch/client';

const API_SCREEN_LOCK_SIMPLE_CODE_URL =
  '/api/portal/common/simple-code/screen-lock';

export interface ResponseBody {
  data: {
    isScreenLockTarget: boolean;
    remainMillisToCheckScreenLock: number;
  };
}

export async function checkScreenLockTarget(appCode: string) {
  try {
    const res = (await (
      await fetchClient.fetch(`${API_SCREEN_LOCK_SIMPLE_CODE_URL}/${appCode}`)
    ).json()) as ResponseBody;
    return res;
  } catch (e) {
    console.error(e);
  }
}
