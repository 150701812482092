import * as React from 'react';
import type { IUserMenuInfo } from '../../../types';

export interface IContextValues {
  readonly userApps: IUserMenuInfo[];
  readonly userAppsInitialValue: IUserMenuInfo[];
}

export interface IContextActions {
  setUserApps: React.Dispatch<React.SetStateAction<IUserMenuInfo[]>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  userApps: undefined,
  userAppsInitialValue: undefined,
});

export const ActionsContext = React.createContext<IContextActions>({
  setUserApps: () => undefined,
});

export interface Props {
  data: IUserMenuInfo[];
}

export function Provider({ data, children }: React.PropsWithChildren<Props>) {
  const [userApps, setUserApps] = React.useState(data);
  const [userAppsInitialValue] = React.useState(data);

  const contextValues = {
    get userApps() {
      return userApps;
    },
    get userAppsInitialValue() {
      return userAppsInitialValue;
    },
  };

  const contextActions = {
    setUserApps,
  };

  return (
    <ActionsContext.Provider value={contextActions}>
      <ValuesContext.Provider value={contextValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
