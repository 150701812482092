import * as Jotai from 'jotai';
import { usePathname } from 'next/navigation';
import { PropsWithChildren, useEffect } from 'react';
import { appInfoAtom, loadStateAtom, useLockState } from '../model/atoms';
import { SimpleCodeFormClient } from './simple-code-form-client';

/**
 * @component AppScreenLock
 * 간편비밀번호 전체 로직이 포함되어 있는 ScreenLock.
 */
export function AppScreenLock({ children }: PropsWithChildren) {
  const [isLocked, setLockState] = useLockState();
  const [appInfo] = Jotai.useAtom(appInfoAtom);
  const [isLoaded] = Jotai.useAtom(loadStateAtom);
  const pathname = usePathname();

  useEffect(() => {
    appInfo.appUrl !== pathname && setLockState(false);
  }, [pathname, appInfo.appUrl, setLockState]);

  // 로딩 중일 경우, 로드 화면을 노출함.
  if (!isLoaded) {
    return (
      <div className="h-full flex justify-start">DaouOffice App Loading...</div>
    );
    // 잠금 상태일 경우, 잠금 화면을 노출함.
  } else if (isLocked) {
    return (
      <div className="flex size-full justify-center main">
        <SimpleCodeFormClient
          title={appInfo.appName}
          appCode={appInfo.appCode}
          type="web"
          onSuccess={() => setLockState(false)}
        />
      </div>
    );
    // 잠금 상태와 로딩 중이 아닐 경우, 앱을 노출함.
  } else {
    return <>{children}</>;
  }
}

export default AppScreenLock;
