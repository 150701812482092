/**
 * @param bytes
 * @param decimals
 * @returns
 */
export const formatBytes = (bytes: number, decimal = 0) => {
  const k = 1024.0;
  const dim = decimal > 0 ? decimal : 0;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  /**
   * size = byte(1024)^n
   *  -> log(size) = n*log(byte)
   *  -> n = log(size) / log(byte)
   */
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const unit = k ** i;

  return bytes === 0
    ? '0Bytes'
    : `${new Intl.NumberFormat().format(Number((bytes / unit).toFixed(dim)))}${sizes[i]}`;
};

// GB를 Byte로 변환하는 함수
export const gbToBytes = (gb: number): number => gb * 1073741824;

// MB를 Byte로 변환하는 함수
export const mbToBytes = (mb: number): number => mb * 1048576;

// 숫자에 콤마를 추가하는 함수
export const formatNumberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
