import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Button } from '@dop-ui/react/shared/ui/button';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import OrgChart from '../../../../../OrgChart';
import { NodeProps } from '../../../../../OrgChart/interface';
import {
  AccessDeviceType,
  AccessLimitTargetType,
  ClassRoleInfo,
  RoleType,
} from '../../types';
import { ClassList } from './class-list';
import { OrganizationCode } from './types';
import * as subSelectionApi from './apis/sub-selections';

interface Props {
  className?: string;
  title?: string | JSX.Element;
  classRoleInfo: ClassRoleInfo[];
  accessDeviceType: AccessDeviceType;
  accessLimitTargetType: AccessLimitTargetType;
  narrowMode?: boolean;
  onClassInfoChange: (classRoleInfo: ClassRoleInfo[]) => void;
}

export function ClassListManager({
  className,
  classRoleInfo,
  accessDeviceType,
  accessLimitTargetType,
  narrowMode = false,
  onClassInfoChange,
}: Props) {
  const { t } = useTranslation('component');
  const [currentRoleType, setCurrentRoleType] = useState<RoleType>('USER');
  const [orgChartOpenState, setOrgChartOpenState] = useState(false);
  const [subCodeList, setSubCodeList] = useState<OrganizationCode[]>([]);
  const [currentSubCode, setCurrentSubCode] = useState<
    OrganizationCode | undefined
  >();

  const { data: organizationCodeList } = useQuery({
    queryKey: [subSelectionApi.QUERY_KEY, currentRoleType],
    queryFn: async () => subSelectionApi.getSubSelectionList(currentRoleType),
  });

  const userList = classRoleInfo.filter((info) => info.roleType === 'USER');
  const departmentList = classRoleInfo.filter(
    (info) => info.roleType === 'DEPARTMENT',
  );
  const positionList = classRoleInfo.filter(
    (info) => info.roleType === 'POSITION',
  );
  const dutyList = classRoleInfo.filter((info) => info.roleType === 'DUTY');
  const gradeList = classRoleInfo.filter((info) => info.roleType === 'GRADE');
  const userGroupList = classRoleInfo.filter(
    (info) => info.roleType === 'USER_GROUP',
  );

  const toaster = useToastMessage();

  useEffect(() => {
    setSubCodeList(organizationCodeList || []);
    setCurrentSubCode(organizationCodeList?.[0]);
  }, [organizationCodeList]);

  const onClickAdd = () => {
    if (currentRoleType === 'USER' || currentRoleType === 'DEPARTMENT') {
      setOrgChartOpenState(true);
    } else if (currentSubCode) {
      const ids = new Set<number>();
      onClassInfoChange(
        [
          ...classRoleInfo,
          {
            roleId: currentSubCode.id,
            roleName: currentSubCode.name,
            roleType: currentRoleType,
            isIncludeChildDepartment: false,
            accessDeviceType: accessDeviceType,
            accessLimitTargetType: accessLimitTargetType,
          },
        ].filter((info) => {
          if (ids.has(info.roleId)) {
            return false;
          }
          ids.add(info.roleId);
          return true;
        }),
      );
    } else {
      toaster.warning(t('globalconfig.common.classList.noSelectedData'));
    }
  };

  const onConfirmOnOrgChart = (nodes: NodeProps[]) => {
    const ids = new Set<number>();

    const newRoleInfo = nodes.map(
      (node): ClassRoleInfo => ({
        roleId: currentRoleType === 'USER' ? node.userId ?? -1 : node.id,
        roleName: node.name,
        roleType: currentRoleType,
        isIncludeChildDepartment: false,
        accessDeviceType: accessDeviceType,
        accessLimitTargetType: accessLimitTargetType,
      }),
    );
    onClassInfoChange(
      [...classRoleInfo, ...newRoleInfo].filter((info) => {
        if (ids.has(info.roleId)) {
          return false;
        }
        ids.add(info.roleId);
        return true;
      }),
    );
    setOrgChartOpenState(false);
  };

  const onChangeHandler = (list: ClassRoleInfo[], roleType: RoleType) => {
    onClassInfoChange([
      ...(roleType === 'USER' ? list : userList),
      ...(roleType === 'DEPARTMENT' ? list : departmentList),
      ...(roleType === 'POSITION' ? list : positionList),
      ...(roleType === 'DUTY' ? list : dutyList),
      ...(roleType === 'GRADE' ? list : gradeList),
      ...(roleType === 'USER_GROUP' ? list : userGroupList),
    ]);
  };

  const onSubCodeChange = (id?: number) => {
    if (!id) return;
    setCurrentSubCode(subCodeList.find((subCode) => subCode.id === id));
  };

  return (
    <div
      className={clsx(className, 'flex flex-col', { 'flex-grow': !narrowMode })}
    >
      <div className="min-h-[32px] flex flex-wrap gap-[4px] items-center">
        <select
          className={clsx(
            'h-[32px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]',
            {
              'w-[120px]': narrowMode,
              'w-[200px]': !narrowMode,
            },
          )}
          value={currentRoleType}
          onChange={(e) => setCurrentRoleType(e.target.value as RoleType)}
        >
          <option value={'USER'}>
            {t('globalconfig.secureManagement.classManagement.category.user')}
          </option>
          <option value={'DEPARTMENT'}>
            {t(
              'globalconfig.secureManagement.classManagement.category.department',
            )}
          </option>
          <option value={'POSITION'}>
            {t(
              'globalconfig.secureManagement.classManagement.category.position',
            )}
          </option>
          <option value={'DUTY'}>
            {t('globalconfig.secureManagement.classManagement.category.duty')}
          </option>
          <option value={'GRADE'}>
            {t('globalconfig.secureManagement.classManagement.category.grade')}
          </option>
          <option value={'USER_GROUP'}>
            {t(
              'globalconfig.secureManagement.classManagement.category.userGroup',
            )}
          </option>
        </select>
        {currentRoleType !== 'USER' && currentRoleType !== 'DEPARTMENT' && (
          <select
            className={clsx(
              'h-[32px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]',
              {
                'w-[120px]': narrowMode,
                'w-[200px]': !narrowMode,
              },
            )}
            onChange={(e) => onSubCodeChange(Number(e.target.value))}
          >
            {subCodeList &&
              subCodeList.map((subCode) => (
                <option key={subCode.id} value={subCode.id}>
                  {subCode.name}
                </option>
              ))}
          </select>
        )}
        <Button
          className="flex items-center h-[32px] w-[72px] px-[12px] rounded-[4px] border border-solid border-[#C5C5C5]"
          onClick={onClickAdd}
        >
          <PlusIcon className="inline size-[20px]" />
          <span className="ml-[4px] text-[14px] text-[#363636] font-[400]">
            {t('globalconfig.secureManagement.loginSecureConfig.ip.add')}
          </span>
        </Button>
      </div>
      {!narrowMode && (
        <div className="divider-h w-full h-[0px] my-[12px] border border-dashed stroke-[2px] border-[#E2E2E2]" />
      )}
      <div
        className={clsx(
          'flex justify-center flex-col mt-[8px] px-[12px] py-[8px] min-h-[72px] gap-[6px]',
          {
            'border border-dashed border-[#D8D8D8] rounded-[8px]': narrowMode,
            'border border-solid border-[#D8D8D8] rounded-[4px]': !narrowMode,
          },
        )}
      >
        {userList && userList.length > 0 && (
          <ClassList
            title={t(
              'globalconfig.secureManagement.classManagement.category.user',
            )}
            roleInfos={userList}
            roleType="USER"
            onChangeList={(list) => {
              onChangeHandler(list, 'USER');
            }}
          />
        )}
        {departmentList && departmentList.length > 0 && (
          <ClassList
            title={t(
              'globalconfig.secureManagement.classManagement.category.department',
            )}
            roleInfos={departmentList}
            roleType="DEPARTMENT"
            onChangeList={(list) => {
              onChangeHandler(list, 'DEPARTMENT');
            }}
          />
        )}
        {positionList && positionList.length > 0 && (
          <ClassList
            title={t(
              'globalconfig.secureManagement.classManagement.category.position',
            )}
            roleInfos={positionList}
            roleType="POSITION"
            onChangeList={(list) => {
              onChangeHandler(list, 'POSITION');
            }}
          />
        )}
        {dutyList && dutyList.length > 0 && (
          <ClassList
            title={t(
              'globalconfig.secureManagement.classManagement.category.duty',
            )}
            roleInfos={dutyList}
            roleType="DUTY"
            onChangeList={(list) => {
              onChangeHandler(list, 'DUTY');
            }}
          />
        )}
        {gradeList && gradeList.length > 0 && (
          <ClassList
            title={t(
              'globalconfig.secureManagement.classManagement.category.grade',
            )}
            roleInfos={gradeList}
            roleType="GRADE"
            onChangeList={(list) => {
              onChangeHandler(list, 'GRADE');
            }}
          />
        )}
        {userGroupList && userGroupList.length > 0 && (
          <ClassList
            title={t(
              'globalconfig.secureManagement.classManagement.category.userGroup',
            )}
            roleInfos={userGroupList}
            roleType="USER_GROUP"
            onChangeList={(list) => {
              onChangeHandler(list, 'USER_GROUP');
            }}
          />
        )}
        {classRoleInfo.length === 0 && (
          <p className="text-center text-[14px] text-[#999999] text-[--color-text-level3] font-[400]">
            {t('globalconfig.common.classList.empty')}
          </p>
        )}
      </div>
      <Dialog.Root open={orgChartOpenState} onOpenChange={setOrgChartOpenState}>
        <Dialog.Content size="resizeable">
          <OrgChart
            type={
              currentRoleType === 'DEPARTMENT'
                ? 'DEPARTMENT'
                : currentRoleType === 'USER'
                  ? 'MEMBER'
                  : 'NORMAL'
            }
            style={{
              width: '80px',
              height: '800px',
              translate: '120% 0',
            }}
            onClose={() => setOrgChartOpenState(false)}
            onConfirm={onConfirmOnOrgChart}
            useResize
          />
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
}
