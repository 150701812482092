import { fetch } from '../../../../../../lib/fetch/client';
import {
  DATA_REQUEST_DETAIL_API,
  DATA_REQUEST_REJECT_API,
} from '../../constants';
import { DetailRequestInfo } from '../types';

export async function rejectRequest(
  infoId: string,
  comment: string,
  info?: DetailRequestInfo,
) {
  await fetch(DATA_REQUEST_REJECT_API + '/' + infoId, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...info, adminComment: comment }),
  });
}

export async function approveRequest(
  infoId: string,
  comment: string,
  volume: number,
  info?: DetailRequestInfo,
) {
  await fetch(DATA_REQUEST_DETAIL_API + '/' + infoId, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...info,
      adminComment: comment,
      volumeAlloc: volume,
    }),
  });
}
