import { fetch } from '../../../../../../lib/fetch/client';
import { ATTACH_FILE_LIST_API } from '../../constants';
import { BaseResponseWithPage, PageAndResponseData } from '../../types';
import { FileInfo } from '../types';

interface FileResponse {
  attachmentId: number;
  fileName: string;
  fileSize: string;
  fileType: string;
  sender: {
    platformUserId: number;
    senderName: string;
  };
  createdAt: string;
  updatedAt: string;
}

export const QUERY_KEY =
  'QueryKeys.GlobalConfig.DataManagement.UploadDataManagement';

export async function getFileList(): Promise<PageAndResponseData<FileInfo[]>> {
  return Promise.resolve({
    page: {
      pageNumber: 1,
      pageSize: 20,
      totalPage: 1,
      totalElements: 4,
    },
    data: [
      {
        id: 1,
        fileName:
          'test, test, test, test, test, test, test, test, test, test, test',
        fileSize: 1,
        createdDate: '2021-01-01',
        senderName: '조용문',
      },
      {
        id: 2,
        fileName: 'test2',
        fileSize: 2,
        createdDate: '2021-01-02',
        senderName: '민대인',
      },
      {
        id: 3,
        fileName: 'test3',
        fileSize: 3,
        createdDate: '2021-01-03',
        senderName: '이학주',
      },
      {
        id: 4,
        fileName: 'test4',
        fileSize: 4,
        createdDate: '2021-01-04',
        senderName: '이동현',
      },
    ],
  });

  const response = await fetch(ATTACH_FILE_LIST_API, {
    headers: { 'Content-Type': 'application/json' },
  });

  const responseData =
    (await response.json()) as BaseResponseWithPage<FileResponse>;

  return {
    page: responseData.data.page,
    data: responseData.data.hasElements
      ? responseData.data.elements.map(adaptor)
      : [],
  };
}

function adaptor(source: FileResponse): FileInfo {
  return {
    id: source.attachmentId,
    fileName: source.fileName,
    fileSize: Number(source.fileSize),
    createdDate: source.createdAt,
    senderName: source.sender.senderName,
  };
}
