import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';

export function SaveConfirmDialog() {
  const { t } = useTranslation('component');

  return (
    <div className="size-full px-[24px]">
      <p className="text-[#1A1A1A] text-[20px] font-[500]">
        {t('globalconfig.common.dialog.saveConfirm.title')}
      </p>
      <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
        {t('globalconfig.common.dialog.saveConfirm.description')}
      </p>
    </div>
  );
}
