import { fetch } from '../../../../../../lib/fetch/client';
import { OVERSEAS_LOGIN_DETAIL_BLOCK_CONFIG_API } from '../../constants';
import { BaseResponse } from '../../types';
import { OverseasDetailBlockConfig } from '../types';

export const QUERY_KEY_OVERSEAS_BLOCK_CONFIG =
  'GlobalConfig.SecureManagement.LoginSecureConfig.OverseasBlockConfig';
/**
 *
 * @param isUsingBlock
 * @description 해외 로그인 차단 설정 조회
 */
export async function getDetailBlockConfig() {
  const response = await fetch(OVERSEAS_LOGIN_DETAIL_BLOCK_CONFIG_API, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<OverseasDetailBlockConfig>;

  return responseData.data;
}

export async function updateDetailBlockConfig(
  value: OverseasDetailBlockConfig,
) {
  const response = await fetch(OVERSEAS_LOGIN_DETAIL_BLOCK_CONFIG_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(value),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as BaseResponse<string>;
    throw new Error(responseData.message);
  }
}
