import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * ArrowRotateLeft 아이콘
 * @param props
 * @returns
 */
export function ArrowLeftIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M3.17815 14.9999C4.4379 18.8326 8.04583 21.5999 12.3001 21.5999C17.602 21.5999 21.9001 17.3018 21.9001 11.9999C21.9001 6.69797 17.602 2.3999 12.3001 2.3999C8.74674 2.3999 5.64429 4.33046 3.98441 7.1999M6.9001 8.3999H2.1001V3.5999"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default ArrowLeftIcon;
