import { Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import { flushSync } from 'react-dom';
import { useDndContext } from './provider';
import { Edge } from '../../../lib/dnd/types';

export interface DropParams {
  startIndex: number;
  targetIndex: number;
  edge: Edge | null;
}

export interface Props<T> {
  list: T[];
  setList: Dispatch<SetStateAction<T[]>>;
  onDrop?: (data: DropParams) => void;
}

export function Container<T>({
  list,
  setList,
  onDrop,
  children,
}: PropsWithChildren<Props<T>>) {
  const { dataValidation, name, idKey } = useDndContext();

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return dataValidation(source.data, name);
      },
      onDrop({ location, source }) {
        const target = location.current.dropTargets[0];

        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;

        if (
          !dataValidation(sourceData, name) ||
          !dataValidation(targetData, name)
        ) {
          return;
        }

        const indexOfSource = list.findIndex(
          (item: T) => item[idKey as keyof T] === sourceData[idKey],
        );
        const indexOfTarget = list.findIndex(
          (item: T) => item[idKey as keyof T] === targetData[idKey],
        );

        if (indexOfTarget < 0 || indexOfSource < 0) {
          return;
        }

        const closestEdgeOfTarget = extractClosestEdge(targetData);

        if (onDrop) {
          onDrop({
            startIndex: indexOfSource,
            targetIndex: indexOfTarget,
            edge: closestEdgeOfTarget,
          });
        } else {
          flushSync(() => {
            setList(
              reorderWithEdge({
                list: list,
                startIndex: indexOfSource,
                indexOfTarget,
                closestEdgeOfTarget,
                axis: 'vertical',
              }),
            );
          });
        }

        const element = document.querySelector(
          `[data-id="${name}${sourceData.id}"]`,
        );
        if (element instanceof HTMLElement) {
          triggerPostMoveFlash(element);
        }
      },
    });
  }, [list, setList, dataValidation, name, idKey]);

  return <>{children}</>;
}

export default Container;
