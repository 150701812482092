import { Contents } from './components/Contents';
import { DetailConfig } from './components/DetailConfig';
import { DeviceConfig } from './components/DeviceConfig';
import { Root } from './components/Root';

export function MobileDeviceAccessConfig() {
  return (
    <Root>
      <Contents>
        <DeviceConfig />
        <DetailConfig />
      </Contents>
    </Root>
  );
}

export default MobileDeviceAccessConfig;
