import { IPInfo } from '../../types';

export async function parseIpFromText(text: string): Promise<IPInfo[]> {
  const ipPattern =
    /^(\[.*?\])?\s*ip\s*:\s*\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
  const rangePattern =
    /^(\[.*?\])?\s*range\s*:\s*\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\s*-\s*\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
  const subnetPattern =
    /^(\[.*?\])?\s*sub\s*:\s*\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\s*\/\s*\d{1,2}$/;

  const delimiterPattern = /[\n,]/;
  const parts = text.split(delimiterPattern);

  const ipList: IPInfo[] = parts
    .map((part) => {
      const ipInfoCandidate = part.trim().toLowerCase();

      if (ipPattern.test(ipInfoCandidate)) {
        return parseSingleIpInfo(ipInfoCandidate);
      }

      if (rangePattern.test(ipInfoCandidate)) {
        return parseRangeIpInfo(ipInfoCandidate);
      }

      if (subnetPattern.test(ipInfoCandidate)) {
        return parseSubnetIpInfo(ipInfoCandidate);
      }

      return undefined;
    })
    .filter((ipInfo) => ipInfo !== undefined);

  return Promise.resolve(ipList);
}

function parseSingleIpInfo(text: string): IPInfo | undefined {
  const ipGroupName = text.split('[')[1]?.split(']')[0];
  const ip = text.split(':')[1];

  if (!ip) {
    return undefined;
  }
  return {
    ipType: 'SINGLE_IP',
    ipGroupName: ipGroupName,
    ipAddress: ip,
  };
}

function parseRangeIpInfo(text: string): IPInfo | undefined {
  const ipGroupName = text.split('[')[1]?.split(']')[0];
  const range = text.split(':')[1];
  if (!range) return undefined;

  const [start, end] = range.split('-');

  if (!start || !end) {
    return undefined;
  }
  return {
    ipType: 'IP_RANGE',
    ipGroupName: ipGroupName,
    ipAddress: `${start}-${end}`,
  };
}

function parseSubnetIpInfo(text: string): IPInfo | undefined {
  const ipGroupName = text.split('[')[1]?.split(']')[0];
  const subnet = text.split(':')[1];
  if (!subnet) return undefined;

  const [ip, mask] = subnet.split('/');

  if (!ip || !mask) {
    return undefined;
  }
  return {
    ipType: 'SUBNET_MASK',
    ipGroupName: ipGroupName,
    ipAddress: `${ip}/${mask}`,
  };
}
