import { useAtom } from 'jotai';
import { clsx } from 'clsx';
import { PropsWithChildren, useEffect } from 'react';
import { activatedFiltersAtom } from '../stores/activatedFilterAtom';
import { useTableActionsContext } from '../Context';

export interface Props {
  className?: string;
}

export function FilterContainer({
  className,
  children,
}: PropsWithChildren<Props>) {
  const [activeFilters] = useAtom(activatedFiltersAtom);
  const { onChangeFilter } = useTableActionsContext();

  useEffect(() => {
    onChangeFilter && onChangeFilter(activeFilters);
  }, [activeFilters, onChangeFilter]);

  return <div className={clsx('flex gap-[8px]', className)}>{children}</div>;
}

export default FilterContainer;
