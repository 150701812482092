import { useEffect, useState } from 'react';
import { useSetAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { byteToProperUnit } from '../../../utils/byteConvertUtils';
import * as usageApi from '../CurrentDataUsage/apis/getCompanyDataStatus';
import { DataRequestInfo, SortDirection } from './types';
import * as requestListApis from './apis/getRequestList';
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid';
import { COLOR_COMPLETE, COLOR_EXPECTED, COLOR_REJECT } from './colors';
import { selectedRequestInfoAtom } from './store/selectedRequestInfoAtom';
import { requestInfoDialogOpenAtom } from './store/requestInfoDialogOpenAtom';

export function RequestList() {
  const { t } = useTranslation('component');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageOffet, setCurrentPageOffset] = useState(20);
  const [sortStandard, setSortStandard] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [keyword, setKeyword] = useState('');
  const setSelectedId = useSetAtom(selectedRequestInfoAtom);
  const setDialogOpen = useSetAtom(requestInfoDialogOpenAtom);

  const { data: requestData, error } = useQuery({
    queryKey: [
      requestListApis.QUERY_KEY,
      currentPage,
      pageOffet,
      sortStandard,
      sortDirection,
    ],
    queryFn: async () =>
      requestListApis.getRequestList(
        currentPage,
        pageOffet,
        sortStandard,
        sortDirection,
      ),
  });

  // Todo : 추후 keyword 추가
  useEffect(() => {
    setCurrentPage(0);
  }, [pageOffet, keyword]);

  if (error) {
    console.error(
      `Error: GlobalConfig > DataRequestManagement : ${error.message}`,
    );
  }

  const { data: usageData, error: usageError } = useQuery({
    queryKey: [usageApi.COMPANY_DATA_USAGE_KEY],
    queryFn: usageApi.getCompanyDataStatus,
  });

  if (usageError) {
    console.error(
      `Error: GlobalConfig > PresentCondition : ${usageError.message}`,
    );
  }

  const onClickRow = (index: number) => {
    if (requestData) {
      setSelectedId(requestData.data[index]?.id ?? 0);
      setDialogOpen(true);
    }
  };

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
    console.log('keyword', keyword);
  };

  const onOffsetChange = (offset: number) => {
    setCurrentPageOffset(offset);
  };

  const onClickSortStandard = (standard: string) => {
    if (standard === sortStandard) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortStandard(standard);
      setSortDirection('desc');
    }
  };

  const dateTitles = {
    today: t('globalconfig.dataManagement.requestManagement.date.today'),
    days7: t('globalconfig.dataManagement.requestManagement.date.7daysAgo'),
    days30: t('globalconfig.dataManagement.requestManagement.date.30daysAgo'),
    days90: t('globalconfig.dataManagement.requestManagement.date.90daysAgo'),
    selfInput: t(
      'globalconfig.dataManagement.requestManagement.date.selfInput',
    ),
  };

  const columnDefs: ColumnDef<DataRequestInfo>[] = [
    {
      id: 'detailApplyState',
      accessorFn: (data: DataRequestInfo): string => data.detailApplyState,
      header: () => (
        <div className="flex items-center w-full text-start h-[48px]">
          {t('globalconfig.dataManagement.requestManagement.list.state')}
        </div>
      ),
      cell: (info) => {
        const value = info.getValue() as string;
        let color = COLOR_REJECT;
        if (value === '예정') color = COLOR_EXPECTED;
        else if (value === '완료') color = COLOR_COMPLETE;

        return (
          <div className="flex items-center h-[48px]">
            <span
              className="px-[6px] py-[2px] text-center text-white text-[13px] font-[500] rounded-[8px]"
              style={{ backgroundColor: color }}
            >
              {value}
            </span>
          </div>
        );
      },
    },
    {
      id: 'updatedAt',
      accessorKey: 'updatedAt',
      header: () => {
        const arrow = () => {
          if (sortStandard === 'createdAt') {
            return sortDirection === 'asc' ? (
              <ChevronUpIcon className="size-[16px]" />
            ) : (
              <ChevronDownIcon className="size-[16px]" />
            );
          }
          return <ChevronUpDownIcon className="size-[16px]" color="#AAAAAA" />;
        };

        return (
          <button
            className="flex items-center w-full text-start"
            onClick={() => onClickSortStandard('createdAt')}
          >
            <span className="mr-[4px]">
              {t(
                'globalconfig.dataManagement.requestManagement.dialog.requestDate2',
              )}
            </span>
            {arrow()}
          </button>
        );
      },
    },
    {
      id: 'volumeType',
      accessorKey: 'volumeType',
      header: () => (
        <div className="w-full text-start">
          {t(
            'globalconfig.dataManagement.requestManagement.dialog.requestType',
          )}
        </div>
      ),
    },
    {
      id: 'requestor',
      accessorKey: 'requester',
      header: () => (
        <div className="w-full text-start">
          {t('globalconfig.dataManagement.requestManagement.dialog.requestor')}
        </div>
      ),
    },
    {
      id: 'userUsage',
      accessorKey: 'userUsage',
      header: () => (
        <div className="w-full text-start">
          {t(
            'globalconfig.dataManagement.requestManagement.dialog.userUsageAndTotal',
          )}
        </div>
      ),
    },
    {
      id: 'applyDate',
      accessorKey: 'applyDate',
      header: () => {
        const arrow = () => {
          if (sortStandard === 'updatedAt') {
            return sortDirection === 'asc' ? (
              <ChevronUpIcon className="size-[16px]" />
            ) : (
              <ChevronDownIcon className="size-[16px]" />
            );
          }
          return <ChevronUpDownIcon className="size-[16px]" color="#AAAAAA" />;
        };

        return (
          <button
            className="flex items-center w-full text-start"
            onClick={() => onClickSortStandard('updatedAt')}
          >
            <span className="mr-[4px]">
              {t(
                'globalconfig.dataManagement.requestManagement.list.applyDate',
              )}
            </span>
            {arrow()}
          </button>
        );
      },
    },
    {
      id: 'manager',
      accessorKey: 'manager',
      header: () => (
        <div className="w-full text-start">
          {t('globalconfig.dataManagement.requestManagement.list.manager')}
        </div>
      ),
    },
  ];

  const TitleAndVolume = ({
    title,
    volume,
  }: {
    title: string;
    volume: number;
  }) => (
    <>
      {title + '   '}
      <span className="text-[#888888]">{byteToProperUnit(volume ?? 0)}</span>
    </>
  );

  return (
    <div className="mt-[24px] p-[40px] border border-solid border-[#DDDDDD] rounded-[8px] overflow-x-scroll">
      <p className="text-[22px] text-[#333333] font-[500]">
        {t('globalconfig.dataManagement.requestManagement.list.title')}
      </p>
      <p className="mt-[10px] text-[#888888] text-[14px] font-[400]">
        <TitleAndVolume
          title={t('globalconfig.dataManagement.currentUsage.totalUsage')}
          volume={usageData?.companyQuota ?? 0}
        />
        {'  |  '}
        <TitleAndVolume
          title={t('globalconfig.dataManagement.currentUsage.inUse')}
          volume={usageData?.companyUsedQuota ?? 0}
        />
        {'  |  '}
        <TitleAndVolume
          title={t('globalconfig.dataManagement.currentUsage.available')}
          volume={usageData?.companyAvailableQuota ?? 0}
        />
      </p>
      <TablePrimitives.Root<DataRequestInfo>
        className="mt-[32px]"
        contents={requestData?.data ?? []}
        columnDefs={columnDefs}
        onClickRow={onClickRow}
      >
        <div className="flex">
          <div className="flex flex-col">
            <div className="flex items-center">
              <TablePrimitives.SearchBar
                className="inline-block w-[240px] h-[40px]"
                onKeywordChange={onKeywordChange}
              />
              <div className="mx-[8px] w-[1px] h-[12px] bg-[#C5CBD3]" />
              <TablePrimitives.Filter.Container className="inline-block">
                {/** TODO: 현재 필터 동작하지 않음. 일단 목데이터만 뿌려주는 상황. 추후 메일쪽 개발 완료 시 다시 작업 해야함. */}
                <TablePrimitives.Filter.Option
                  title={t(
                    'globalconfig.dataManagement.requestManagement.dialog.requestType',
                  )}
                  filterKey="requestType"
                  filterType="checkbox"
                  options={[
                    {
                      key: 'personalStorage',
                      title: t(
                        'globalconfig.dataManagement.requestManagement.type.webstorage',
                      ),
                      value: 'dataRequest',
                    },
                    {
                      key: 'mailStorage',
                      title: t(
                        'globalconfig.dataManagement.requestManagement.type.mail',
                      ),
                      value: 'mailRequest',
                    },
                  ]}
                />
                <TablePrimitives.Filter.DateOption
                  title={t(
                    'globalconfig.dataManagement.requestManagement.dialog.requestDate2',
                  )}
                  filterKey="requestDate"
                  dateOptionTitles={dateTitles}
                />
                <TablePrimitives.Filter.DateOption
                  title={t(
                    'globalconfig.dataManagement.requestManagement.list.applyDate',
                  )}
                  filterKey="applyDate"
                  dateOptionTitles={dateTitles}
                />
                <TablePrimitives.Filter.Option
                  title={t(
                    'globalconfig.dataManagement.requestManagement.list.applyState',
                  )}
                  filterKey="applyState"
                  filterType="checkbox"
                  options={[
                    {
                      key: 'personalStorage',
                      title: t(
                        'globalconfig.dataManagement.requestManagement.type.webstorage',
                      ),
                      value: 'dataRequest',
                    },
                    {
                      key: 'mailStorage',
                      title: t(
                        'globalconfig.dataManagement.requestManagement.type.mail',
                      ),
                      value: 'mailRequest',
                    },
                  ]}
                />
              </TablePrimitives.Filter.Container>
            </div>
            {keyword && (
              <div className="pt-3 text-[#81A9FC]">{`${requestData?.data.length}${t('globalconfig.common.table.search.result')}`}</div>
            )}
          </div>
          <div className="spacer flex-grow" />
          <div className="flex justify-between items-end">
            <TablePrimitives.SizeSelector onChangeTableSize={onOffsetChange} />
          </div>
        </div>
        <TablePrimitives.Contents className="pb-[32px] mt-[16px] mb-[32px]" />
        <TablePrimitives.Pagination
          currentPage={currentPage}
          totalPage={requestData?.page.totalPage ?? 0}
          onNextPage={setCurrentPage}
          onPreviousPage={setCurrentPage}
          onClickPage={setCurrentPage}
          onFirstPage={() => setCurrentPage(0)}
          onLastPage={() =>
            setCurrentPage(
              requestData?.page.totalPage ? requestData.page.totalPage - 1 : 0,
            )
          }
        />
      </TablePrimitives.Root>
    </div>
  );
}
