import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { formatBytes } from '../../../../utils/byteFormatter';

export interface Props {
  availableStorage: number;
  allocatedStorage: number;
}

export function OverView({ availableStorage, allocatedStorage }: Props) {
  const { t } = useTranslation('component');

  const textBlock = (title: string, value: number) => {
    return (
      <div className="gap-2 flex items-center">
        <span className="text-[#333] text-[14px] font-[400] -tracking-[0.28px] leading-[140%]">
          {title}
        </span>
        <span className="text-[#333] text-[22px] font-[600] -tracking-[0.44px] leading-[140%]">
          {formatBytes(value)}
        </span>
      </div>
    );
  };

  return (
    <div className="flex mt-8 items-center gap-[10px] py-7 bg-[#F9FAFB] rounded-3xl justify-center">
      {availableStorage < allocatedStorage ? (
        <span className="text-[#f54242] text-[18px] font-[600] -tracking-[0.44px] leading-[140%]">
          {t('globalconfig.dataManagement.memberManagement.no.enough.storage')}
        </span>
      ) : (
        <>
          {textBlock(
            t(
              'globalconfig.dataManagement.memberManagement.dialog.availablestorage',
            ),
            availableStorage,
          )}
          <span className="font-[700] text-[20px] leading-[140%] -tracking-[0.4px] text-[#555]">
            -
          </span>
          {textBlock(
            t(
              'globalconfig.dataManagement.memberManagement.dialog.allocatedstorage',
            ),
            allocatedStorage,
          )}
          <span className="font-[700] text-[20px] leading-[140%] -tracking-[0.4px] text-[#555]">
            =
          </span>
          {textBlock(
            t(
              'globalconfig.dataManagement.memberManagement.dialog.expectedremainingstorage',
            ),
            availableStorage - allocatedStorage,
          )}
        </>
      )}
    </div>
  );
}

export default OverView;
