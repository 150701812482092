import { PropsWithChildren, Suspense } from 'react';

export function ContentsWrapper({ children }: PropsWithChildren) {
  return (
    <div className="flex-col w-full h-full border rounded-xl gap-1 p-10">
      <Suspense fallback={<div className="h-full">Loading...</div>}>
        {children}
      </Suspense>
    </div>
  );
}

export default ContentsWrapper;
