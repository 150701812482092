import { BaseResponse, OrgConfigInfo } from '../type';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.BasicInfo.OrgConfig';

const BASIC_INFO_ORG_CONFIG_URL = '/api/portal/setting/company-group/config';

export async function getOrgConfig(
  companyGroupId: string | number,
): Promise<OrgConfigInfo> {
  try {
    const response = await fetch(
      BASIC_INFO_ORG_CONFIG_URL + '/' + companyGroupId,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getOrgConfig 오류 발생.');
    }

    const resData = (await response.json()) as BaseResponse<OrgConfigInfo>;

    return resData.data;
  } catch (error) {
    console.log('error', error);
    return {
      companyGroupId: 0,
      isOrganizationChartShare: 'false',
      shareTarget: 'ALL',
      targetList: [],
      shareRange: 'CHART_AND_SEARCH',
      organizationChartOrder: [],
    };
  }
}
