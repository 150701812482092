import { Button } from '@daouoffice/ui';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useSetAtom } from 'jotai';
import { USAGE_CURRENT_PATH } from '../../../../../menus/dataManagement/paths';
import { setMenuByPathAtom } from '../../../../../store/setMenuByPathAtom';

export function Header() {
  const { t } = useTranslation('component');
  const setMenuByPath = useSetAtom(setMenuByPathAtom);
  // TODO: 고객포털 계약자, 주문 담당자 등록된 데이터 관리자인지 확인하는 API 존재하는지.
  const isCustomerPortalManager = true;

  // 용량 사용 현황 버튼 클릭 시, 용량관리 > 용량 사용 현황으로 화면 전환
  const onClickStorageUsageDetailHandler = () =>
    setMenuByPath(USAGE_CURRENT_PATH);

  // 고객포털 계약자, 주문 담당자 등록된 데이터 관리자인지 확인 용량 구매하기 버튼 클릭 시, 고객포털 주문 페이지로 연결.
  const onClickStorageBuyStorage = () => {
    alert('고객포털 주문 페이지로 연결');
  };

  return (
    <div className="flex justify-between">
      <h2 className="font-[600] -tracking-[0.88px] text-[22px] text-[#333333]">
        {t('globalconfig.dataManagement.memberManagement.info')}
      </h2>
      <div className="flex gap-2">
        <Button
          title=""
          withTitle={false}
          styleType="line"
          className="flex items-center px-3 py-2 !border-solid !border !rounded-lg gap-1 !border-[#E6E6E6]"
          onClick={onClickStorageUsageDetailHandler}
        >
          <span>
            {t(
              'globalconfig.dataManagement.memberManagement.storageusage.detail',
            )}
          </span>
          <ChevronRightIcon className="size-4 stroke-[#B3B3B3]" />
        </Button>
        {isCustomerPortalManager && (
          <Button
            title=""
            withTitle={false}
            styleType="line"
            className="flex items-center px-3 py-2 !border-solid !border !rounded-lg gap-1 !border-[#E6E6E6]"
            onClick={onClickStorageBuyStorage}
          >
            <span>
              {t(
                'globalconfig.dataManagement.memberManagement.storageusage.buy',
              )}
            </span>
            <ChevronRightIcon className="size-4 stroke-[#B3B3B3]" />
          </Button>
        )}
      </div>
    </div>
  );
}

export default Header;
