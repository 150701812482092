import { Switch } from '@daouoffice/ui';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import * as mobileDeviceAccessConfigMutation from '../api/mobileDeviceAccessConfigMutation';
import * as mobileDeviceAccessConfigQuery from '../api/mobileDeviceAccessConfigQuery';
import { useContext } from './Context';

export interface Props {
  withDivider?: boolean;
}

export function DeviceConfig({ withDivider = false }: Props) {
  const { accessEnabled, setAccessEnabled } = useContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation('component');
  const { data: defaultAccessState, error } = useSuspenseQuery({
    queryKey: [mobileDeviceAccessConfigQuery.QUERY_KEY],
    queryFn: () => mobileDeviceAccessConfigQuery.getMobileDeviceAccessStatus(),
  });

  const updateLockState = useMutation({
    mutationKey: [mobileDeviceAccessConfigMutation.MUTATION_KEY],
    mutationFn: (newState: boolean) =>
      mobileDeviceAccessConfigMutation.updateMobileDeviceAccessStatus(newState),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [mobileDeviceAccessConfigQuery.QUERY_KEY],
      }),
  });

  const onChangeHandler = (newState: boolean) => {
    updateLockState.mutate(newState);
  };

  useEffect(() => {
    setAccessEnabled(defaultAccessState ?? false);
  }, [defaultAccessState, setAccessEnabled]);

  if (error) {
    return <></>;
  }

  return (
    <div
      className={clsx('w-full flex items-center justify-between py-8', {
        'border-b border-[#E5E5E5]': withDivider,
      })}
    >
      <h2 className="text-[#111] text-ellipsis font-medium text-base leading-6 -tracking-[0.96px] overflow-hidden">
        {t('globalconfig.secureManagement.mobileDeviceAccessConfig.title')}
      </h2>
      <span className="font-normal leading-normal -tracking-[0.84px] text-[14px] text-[#AAA]">
        {t(
          'globalconfig.secureManagement.mobileDeviceAccessConfig.description',
        )}
      </span>
      <Switch
        id="SWITCH"
        checked={accessEnabled ?? false}
        onChange={onChangeHandler}
      />
    </div>
  );
}

export default DeviceConfig;
