import { SetRetentionPeriod } from './SetRetentionPeriod';
import { FileList } from './FileList';

export function UploadDataManagement() {
  return (
    <div className="px-[72px] mt-[40px]">
      <SetRetentionPeriod />
      <FileList />
    </div>
  );
}
