import { AppType } from '../types';
import AppItem from './app-item';
import ScrollList from './scroll/scroll-list';
import styles from '../shortcut.module.css';

interface Props {
  type: string;
  appList: AppType[];
  scrollable?: boolean;
  offset?: number;
}

export function AppList({
  type,
  appList,
  scrollable = false,
  offset = 5,
}: Props) {
  return (
    <ul id={type} className={styles.AppList}>
      {scrollable ? (
        <ScrollList type={type} appList={appList} offset={offset} />
      ) : (
        appList
          .slice(0, offset)
          .map((appItem) => (
            <AppItem key={appItem.id} type={type} appItem={appItem} />
          ))
      )}
    </ul>
  );
}

export default AppList;
