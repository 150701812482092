import type { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { TooltipWith } from '@daouoffice/ui/lib/foundation/Tooltip';
import { ChevronDownIcon } from '@daouoffice/ui/lib/icons/dop/24/ChevronDown';
export interface Props {
  title: string;
  hasChildren?: boolean;
  href?: string;
  target?: string;
}

export function Item({
  children,
  title,
  hasChildren = false,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <li>
      <TooltipWith
        text={title}
        role="button"
        className={clsx(
          {
            btn_icon_select: hasChildren,
            btn_icon: !hasChildren,
          },
          'medium',
        )}
        {...props}
      >
        {children}
        {hasChildren && <ChevronDownIcon size={14} />}
      </TooltipWith>
    </li>
  );
}

export default Item;
