import { useState } from 'react';
import { AccessType } from '../../types';
import { useValuesContext } from '../Context';
import { DeviceList } from './DeviceList';

export function DetailConfig() {
  const { accessEnabled } = useValuesContext();
  const [accessType] = useState<AccessType>('APPROVED');

  return (
    <>
      {accessEnabled && (
        <div className="flex flex-col gap-[34px] border rounded-lg border-dashed p-6">
          <DeviceList type={accessType} />
        </div>
      )}
    </>
  );
}

export default DetailConfig;
