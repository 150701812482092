export const isContinuousNumbers = (simpleCode: string): boolean => {
  const digits = simpleCode.split('').map((digit) => parseInt(digit));
  const isIncreasing = digits.every(
    (digit, index) => index === 0 || digit === Number(digits[index - 1]) + 1,
  );
  const isDecreasing = digits.every(
    (digit, index) => index === 0 || digit === Number(digits[index - 1]) - 1,
  );

  return isIncreasing || isDecreasing;
};

export const isSameNumbers = (simpleCode: string) =>
  new Set(simpleCode.split('')).size === 1;

export const getOnlyNumber = (text: string) => text.replace(/[^0-9]/g, '');
