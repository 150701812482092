import { clsx } from 'clsx';
import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { FilterOption, FilterType } from '../../types';
import { useFilterValuesContext } from './Context';
import { CheckboxOption } from './Options/CheckboxOption';
import { SelectOption } from './Options/SelectOption';
import { DateOption } from './Options/DateOption';

export interface Props {
  className?: string;
  title: string;
  filterKey: string;
  filterType: FilterType;
  options: FilterOption[];
}

export function Content({
  className,
  title,
  filterKey,
  filterType,
  options,
}: Props) {
  const [openState, setOpenState] = useState(false);
  const { selectedOptions } = useFilterValuesContext();

  return (
    <div
      className={clsx(
        className,
        'relative flex items-center px-[16px] h-[35px]',
        {
          'bg-[#2c88f2]': selectedOptions.size > 0,
          'bg-[#fafafa]': selectedOptions.size === 0,
        },
      )}
    >
      <button
        id={'table_filter_' + filterKey}
        title={title}
        className="flex items-center w-full"
        onClick={() => setOpenState(!openState)}
      >
        {title}
        {openState ? (
          <ChevronUpIcon className="inline-block size-[24px] ml-[10px]" />
        ) : (
          <ChevronDownIcon className="inline-block size-[24px] ml-[10px]" />
        )}
      </button>
      {openState && (
        <div className="absolute left-0 min-w-full top-[40px] bg-gray-200 z-50">
          {options.map((option) => {
            if (filterType === 'checkbox') {
              return <CheckboxOption key={option.value} option={option} />;
            } else if (filterType === 'select') {
              return <SelectOption key={option.value} option={option} />;
            } else {
              return <DateOption key={option.value} option={option} />;
            }
          })}
        </div>
      )}
    </div>
  );
}

export default Content;
