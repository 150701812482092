import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import * as secureConfigPages from '../../pages/secureManagement';
import { MenuInfo } from '../../types';
import { codes, paths } from '../main';
import * as secureManagementPaths from './paths';

const i18nNamespace = 'component';

export const menuKeyPath = paths.SECURE_MANAGEMENT;

export const menuInfo: MenuInfo[] = [
  {
    menuCode: codes.SECURE_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.secureManagement.login"
      />
    ),
    menuPath: secureManagementPaths.LOGIN_PATH,
    sortOrder: 0,
    children: [
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.login.config"
          />
        ),
        menuPath: secureManagementPaths.LOGIN_CONFIG_PATH,
        sortOrder: 0,
        page: <secureConfigPages.LoginSecureConfigPage />,
      },
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.login.password"
          />
        ),
        menuPath: secureManagementPaths.PASSWORD_CONFIG_PATH,
        sortOrder: 1,
        page: <secureConfigPages.PasswordPolicyConfigPage />,
      },
    ],
  },
  {
    menuCode: codes.SECURE_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.secureManagement.admin"
      />
    ),
    menuPath: secureManagementPaths.ADMIN_PATH,
    sortOrder: 1,
    children: [
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.admin.config"
          />
        ),
        menuPath: secureManagementPaths.ADMIN_CONFIG_PATH,
        sortOrder: 0,
        page: <secureConfigPages.AdminManagementPage />,
      },
    ],
  },
  {
    menuCode: codes.SECURE_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.secureManagement.tfa"
      />
    ),
    menuPath: secureManagementPaths.TFA_PATH,
    sortOrder: 2,
    children: [
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.tfa.config"
          />
        ),
        menuPath: secureManagementPaths.TFA_CONFIG_PATH,
        sortOrder: 0,
        page: <secureConfigPages.TwoFactorAuthConfigPage />,
      },
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.tfa.exception"
          />
        ),
        menuPath: secureManagementPaths.TFA_EXCEPTION_PATH,
        sortOrder: 1,
        page: <secureConfigPages.TfaExceptionConfigPage />,
      },
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.tfa.member"
          />
        ),
        menuPath: secureManagementPaths.TFA_MEMBER_PATH,
        sortOrder: 2,
        page: <secureConfigPages.TfaRegistrationStatusPage />,
      },
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.tfa.errorlist"
          />
        ),
        menuPath: secureManagementPaths.TFA_ERROR_LIST_PATH,
        sortOrder: 3,
        page: <secureConfigPages.TfaErrorLogPage />,
      },
    ],
  },
  {
    menuCode: codes.SECURE_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.secureManagement.media"
      />
    ),
    menuPath: secureManagementPaths.MEDIA_PATH,
    sortOrder: 3,
    children: [
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.media.config"
          />
        ),
        menuPath: secureManagementPaths.MEDIA_CONFIG_PATH,
        sortOrder: 0,
        page: <secureConfigPages.MediaSecureConfigPage />,
      },
    ],
  },
  {
    menuCode: codes.SECURE_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.secureManagement.appSecurity"
      />
    ),
    menuPath: secureManagementPaths.APP_SECURITY_PATH,
    sortOrder: 4,
    children: [
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.appSecurity.config"
          />
        ),
        menuPath: secureManagementPaths.APP_SECURITY_CONFIG_PATH,
        sortOrder: 0,
        page: <secureConfigPages.AppManagementPage />,
      },
    ],
  },
  {
    menuCode: codes.SECURE_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.secureManagement.mobile"
      />
    ),
    menuPath: secureManagementPaths.MOBILE_PATH,
    sortOrder: 5,
    children: [
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.mobile.deviceAccess"
          />
        ),
        menuPath: secureManagementPaths.MOBILE_DEVICE_ACCESS_PATH,
        sortOrder: 0,
        page: <secureConfigPages.MobileDeviceAccessConfigPage />,
      },
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.mobile.accessBlock"
          />
        ),
        menuPath: secureManagementPaths.MOBILE_ACCESS_BLOCK_PATH,
        sortOrder: 1,
        page: <secureConfigPages.MobileBlockConfigPage />,
      },
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.mobile.securityConfig"
          />
        ),
        menuPath: secureManagementPaths.MOBILE_SECURITY_CONFIG_PATH,
        sortOrder: 2,
        page: <secureConfigPages.MobileSecureConfigPage />,
      },
    ],
  },
  {
    menuCode: codes.SECURE_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.secureManagement.log"
      />
    ),
    menuPath: secureManagementPaths.LOG_PATH,
    sortOrder: 6,
    children: [
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.log.member"
          />
        ),
        menuPath: secureManagementPaths.LOG_MEMBER_ACCESS_PATH,
        sortOrder: 0,
        page: <secureConfigPages.MemberLogConfigPage />,
      },
      {
        menuCode: codes.SECURE_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.secureManagement.log.admin"
          />
        ),
        menuPath: secureManagementPaths.LOG_ADMIN_PATH,
        sortOrder: 1,
        page: <secureConfigPages.AdminLogConfigPage />,
      },
    ],
  },
];
