import { fetch } from '../../../../../../../../lib/fetch/client';

export async function deleteTableDataInfo(
  companyAppId: string,
  managers: number[],
) {
  try {
    const pathUrl = `/api/portal/admin/app-manager/app/${companyAppId}`;
    const resp = await fetch(pathUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: managers,
      }),
    });

    if (!resp.ok) {
      alert(
        '최고관리자는 조직관리, 권한관리 앱 관리자에서 해임할 수 없습니다.',
      );
      throw new Error('관리자 삭제 API 오류 발생.');
    }

    alert('삭제되었습니다.');
  } catch (e) {
    console.error(e);
  }
}

export async function deleteMasterDataInfo(managers: number[]) {
  try {
    const pathUrl = `/api/portal/admin/master`;
    const resp = await fetch(pathUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: managers,
      }),
    });

    if (!resp.ok) {
      throw new Error('최고관리자 삭제 API 오류 발생.');
    }
  } catch (e) {
    console.error(e);
  }
}
