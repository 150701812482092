import { SvgContainer } from './svg-container';
import type { SvgCommonProps } from './types';

/**
 * info triangle 아이콘
 * @param props
 * @returns
 */
export function InfoTriangle(props: SvgCommonProps) {
  return (
    <SvgContainer {...props}>
      <g id="info-triangle">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6615 4.33745C18.3449 3.95296 19.1158 3.75098 19.9 3.75098C20.6841 3.75098 21.455 3.95296 22.1384 4.33745C22.8218 4.72195 23.3946 5.27599 23.8017 5.94619L23.8056 5.95269L37.8544 29.4036C38.2527 30.0937 38.4632 30.8762 38.4654 31.673C38.4675 32.4698 38.2611 33.2533 37.8665 33.9456C37.472 34.6379 36.9031 35.2148 36.2165 35.6191C35.5298 36.0234 34.7493 36.2409 33.9526 36.25L33.9383 36.2502L5.84899 36.2501C5.05223 36.241 4.27172 36.0234 3.58508 35.6191C2.89843 35.2148 2.32957 34.6379 1.93504 33.9456C1.5405 33.2533 1.33405 32.4698 1.3362 31.673C1.33835 30.8762 1.54903 30.0938 1.94729 29.4037L1.95747 29.386L15.9943 5.95276L15.9982 5.94619C16.4053 5.27599 16.9781 4.72195 17.6615 4.33745ZM19.9 6.25098C19.5452 6.25098 19.1965 6.34234 18.8874 6.51627C18.5792 6.68965 18.3208 6.93923 18.1368 7.2411C18.1362 7.24206 18.1356 7.24303 18.135 7.24399L4.10814 30.661C3.93088 30.9713 3.83715 31.3223 3.83619 31.6797C3.83522 32.0402 3.92861 32.3946 4.10708 32.7078C4.28555 33.0209 4.54288 33.2819 4.85348 33.4648C5.16226 33.6466 5.513 33.7449 5.87121 33.7501H33.9304C34.2886 33.7449 34.6393 33.6466 34.9481 33.4648C35.2587 33.2819 35.516 33.0209 35.6945 32.7078C35.873 32.3946 35.9664 32.0402 35.9654 31.6797C35.9644 31.3223 35.8707 30.9713 35.6934 30.661L21.6649 7.24399C21.6643 7.24303 21.6637 7.24207 21.6632 7.2411C21.4791 6.93923 21.2207 6.68965 20.9125 6.51627C20.6034 6.34234 20.2547 6.25098 19.9 6.25098ZM18.75 16.6668C18.75 15.9764 19.3096 15.4168 20 15.4168H20.0166C20.707 15.4168 21.2666 15.9764 21.2666 16.6668C21.2666 17.3571 20.707 17.9168 20.0166 17.9168H20C19.3096 17.9168 18.75 17.3571 18.75 16.6668ZM17.0833 21.6668C17.0833 20.9764 17.6429 20.4168 18.3333 20.4168H20C20.6903 20.4168 21.25 20.9764 21.25 21.6668V27.0834H21.6666C22.357 27.0834 22.9166 27.6431 22.9166 28.3334C22.9166 29.0238 22.357 29.5834 21.6666 29.5834H20C19.3096 29.5834 18.75 29.0238 18.75 28.3334V22.9168H18.3333C17.6429 22.9168 17.0833 22.3571 17.0833 21.6668Z"
          fill="currentColor"
        />
      </g>
    </SvgContainer>
  );
}

export default InfoTriangle;
