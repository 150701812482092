import { clsx } from 'clsx';
import MemoMainContent from './memo-main-content';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { Suspense } from 'react';
import styles from '../../../organizer-panel.module.css';
import { useOrganizerAtom } from '../../../Provider';
import PanelHeader from '../../panel-header';
function OrganizerMemoMain() {
  const { t } = useTranslation();

  const [, setOrganizerAtom] = useOrganizerAtom();

  const handleClickCloseBtn = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'default',
      panelState: 'none',
    }));
  };

  return (
    <div className={styles.Wrapper}>
      <PanelHeader
        title={t('organizer.memo.title')}
        onClickClose={() => void handleClickCloseBtn()}
      />
      <div
        className={clsx(
          styles.Content,
          'relative overflow-auto gap-4 pb-[24px]',
        )}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <MemoMainContent />
        </Suspense>
      </div>
    </div>
  );
}

export default OrganizerMemoMain;
