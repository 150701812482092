import { gql } from 'graphql-request';
import { fetchGraphQL } from '../../../../../../lib/fetch/client';
import { AuthConfig, MediaConfig } from '../types';

const mediaScreenLockQuery = (media: MediaConfig) => gql`
  query {
    authConfig {
      ${media}
    }
  }
`;

interface ResponseBody {
  data: {
    authConfig: AuthConfig;
  };
}

export const QUERY_KEY = 'mediaScreenLockQuery';

export async function getMediaScreenLock(media: MediaConfig) {
  try {
    const { data } = (await (
      await fetchGraphQL({ query: mediaScreenLockQuery(media) })
    ).json()) as ResponseBody;

    if (media === 'MESSENGER_MEDIA_SCREEN_LOCK')
      return data.authConfig.MESSENGER_MEDIA_SCREEN_LOCK;
    else return data.authConfig.MOBILE_MEDIA_SCREEN_LOCK;
  } catch (error) {
    console.error(error);
  }
}
