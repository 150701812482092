import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import { MenuInfo } from '../../../types';
import { paths } from '../../main';
import * as mailPaths from './paths';

const i18nNamespace = 'component';

export const menuKeyPath = paths.APPS_MANAGEMENT + '/mail';
export const menuKeyCode = 'dop-employee-mail';

export const menuInfo: MenuInfo[] = [
  {
    menuCode: menuKeyCode,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.appsManagement.mail"
      />
    ),
    menuPath: menuKeyPath,
    sortOrder: 0,
    children: [
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.config"
          />
        ),
        menuPath: mailPaths.CONFIG_PATH,
        sortOrder: 0,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.group"
          />
        ),
        menuPath: mailPaths.GROUP_PATH,
        sortOrder: 1,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.memberGroup"
          />
        ),
        menuPath: mailPaths.MEMBER_GROUP_PATH,
        sortOrder: 2,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.banner"
          />
        ),
        menuPath: mailPaths.BANNER_PATH,
        sortOrder: 3,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.alias"
          />
        ),
        menuPath: mailPaths.ALIAS_PATH,
        sortOrder: 4,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.bulkMail"
          />
        ),
        menuPath: mailPaths.BULK_MAIL_PATH,
        sortOrder: 5,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.domainMailbox"
          />
        ),
        menuPath: mailPaths.DOMAIN_MAILBOX_PATH,
        sortOrder: 6,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.stationery"
          />
        ),
        menuPath: mailPaths.STATIONERY_PATH,
        sortOrder: 7,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.template"
          />
        ),
        menuPath: mailPaths.TEMPLATE_PATH,
        sortOrder: 8,
      },
      {
        menuCode: menuKeyCode,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.appsManagement.mail.statistics"
          />
        ),
        menuPath: mailPaths.STATISTICS_PATH,
        sortOrder: 9,
      },
    ],
  },
];
