import { Suspense } from 'react';
import AppsDockClient from './AppsDockClient';
import { Fallback } from './Fallback';

export function AppsDock() {
  return (
    <Suspense fallback={<Fallback />}>
      <AppsDockClient />
    </Suspense>
  );
}

export default AppsDock;
