import { Editor } from '@daouoffice/ui/lib/foundation/Editor';
import {
  BoldIcon,
  ItalicIcon,
  NumberedListIcon,
  StrikeIcon,
  ListBulletIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import { clsx } from 'clsx';

import { XMarkCircleIcon } from '@daouoffice/ui/lib/icons/dop/16';
import { Dispatch, SetStateAction } from 'react';
import { MemoToolbarType } from '../../types';
import { Button } from '@dop-ui/react/shared/ui/button';
import memoStyles from '../../memo.module.css';

interface Props {
  editor: Editor | null;
  setToolbarShow: Dispatch<SetStateAction<MemoToolbarType>>;
}
function FormattingToolbar({ editor, setToolbarShow }: Props) {
  if (!editor) return null;
  return (
    <>
      <Button
        className={memoStyles.MemoIconBtn}
        onClick={() => setToolbarShow('default')}
      >
        <XMarkCircleIcon className="w-[20px] h-[20px] p-[3px]" />
      </Button>
      <Button
        className={clsx(
          {
            '!bg-[#E3E3E4]': editor.isActive('bold'),
          },
          memoStyles.MemoIconBtn,
        )}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <BoldIcon size={20} />
      </Button>
      <Button
        className={clsx(
          {
            '!bg-[#E3E3E4]': editor.isActive('italic'),
          },
          memoStyles.MemoIconBtn,
        )}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <ItalicIcon size={20} />
      </Button>
      <Button
        className={clsx(
          {
            '!bg-[#E3E3E4]': editor.isActive('strike'),
          },
          memoStyles.MemoIconBtn,
        )}
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <StrikeIcon size={20} />
      </Button>
      <Button
        className={clsx(
          {
            '!bg-[#E3E3E4]': editor.isActive('orderedList'),
          },
          memoStyles.MemoIconBtn,
        )}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <NumberedListIcon size={20} />
      </Button>
      <Button
        className={clsx(
          {
            '!bg-[#E3E3E4]': editor.isActive('bulletList'),
          },
          memoStyles.MemoIconBtn,
        )}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <ListBulletIcon size={20} />
      </Button>
    </>
  );
}

export default FormattingToolbar;
