export interface Response {
  data: MemoRecentlySearchListInfo;
}

export interface MemoRecentlySearchListInfo {
  count: number;
  memoSearchRecordList: MemoRecentlySearchType[];
}

export interface MemoRecentlySearchType {
  id: number;
  keyword: string;
  createdAt: string;
}

export const QUERY_KEY = 'organizer-memo-recently-search';

export async function getMemoRecentlySearchList(): Promise<{
  data: MemoRecentlySearchType[];
}> {
  try {
    const url = `/api/portal/common/memo/recent/searches`;

    const resp = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!resp.ok) {
      throw new Error('getMemoRecentlySearchList 오류 발생.');
    }

    const resData = (await resp.json()) as Response;
    return {
      data: resData.data.memoSearchRecordList,
    };
  } catch (e) {
    console.log(e);
    return {
      data: [],
    };
  }
}
