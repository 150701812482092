import { MemberList } from './member-list';

export function DetailConfig() {
  return (
    <div className="flex flex-col gap-[34px] border rounded-lg border-dashed p-6">
      <MemberList />
    </div>
  );
}

export default DetailConfig;
