import { dropTargetForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { ElementDragType } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import type { DragTargets, DropTargetArgs, ExternalDragType } from '../types';

export type DropTargetForElementsArgs = DropTargetArgs<ElementDragType>;
export type DropTargetForExternalArgs = DropTargetArgs<ExternalDragType>;

type DroppableElementParams = DropTargetForElementsArgs & DragTargets;
type DroppableExternalParams = DropTargetForExternalArgs & DragTargets;

export type DroppableParams = DroppableElementParams | DroppableExternalParams;

/**
 * Element가 드롭 대상이 되도록 설정
 * Argument에 대한 명세는 ../types.ts
 * https://atlassian.design/components/pragmatic-drag-and-drop/core-package/drop-targets
 */
export function droppable({ type, ...props }: DroppableParams) {
  if (type === 'element') {
    return dropTargetForElements(props as DroppableElementParams);
  } else if (type === 'external') {
    return dropTargetForExternal(props as DroppableExternalParams);
  } else {
    throw new Error('Invalid type provided');
  }
}

export { dropTargetForElements, dropTargetForExternal };

export default droppable;
