const DATA_MANAGEMENT_API_PREFIX = '/api/portal/setting/storage';

/**
 * @description 회사 용량 조회 API
 */
export const COMPANY_DATA_USAGE_API = `${DATA_MANAGEMENT_API_PREFIX}/company`;

/**
 * @description 포털별 용량 사용 조회 API
 */
export const PER_PORTAL_DATA_USAGE_API = `${COMPANY_DATA_USAGE_API}/usage`;

/**
 * @description 앱별 용량 사용 조회 API
 */
export const DETAIL_DATA_USAGE_API = `${PER_PORTAL_DATA_USAGE_API}/list`;

/**
 * @description 알림 설정 조회 API
 */
export const NOTIFICATION_CONFIG_API = `${DATA_MANAGEMENT_API_PREFIX}/config/notification`;

/**
 * @description 업로드 정책 관리 API
 */
export const UPLOAD_FILE_POLICY_API = `${DATA_MANAGEMENT_API_PREFIX}/config/upload`;

const DATA_MANAGMENT_MAIL_API_TEMP_PREFIX = '/gw/ad/api';
/**
 * @description 용량 요청 내역 조회 API
 */
export const DATA_REQUEST_LIST_API = `${DATA_MANAGMENT_MAIL_API_TEMP_PREFIX}/volume/list`;

/**
 * @description 용량 요청 상세 조회 API, 용량 요청 추가 API
 */
export const DATA_REQUEST_DETAIL_API = `${DATA_MANAGMENT_MAIL_API_TEMP_PREFIX}/volume`;

/**
 * @description 용량 요청 거절 API
 */
export const DATA_REQUEST_REJECT_API = `${DATA_REQUEST_DETAIL_API}/reject`;

/**
 * @description 용량 요청 승인 설정 API
 */
export const DATA_REQUEST_APPROVE_API = `${DATA_REQUEST_DETAIL_API}/config`;

const CHAT_API_PREFIX = '/api/portal/chat';
/**
 * @description 첨부 파일 목록 조회 API - (미개발)
 */
export const ATTACH_FILE_LIST_API = `${CHAT_API_PREFIX}/attachement`;
