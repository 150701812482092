import { HeaderSort } from './types';

export function getTableSortTypeQueryString(
  page: number,
  tableSize: number,
  tableSortType?: HeaderSort,
  keyword?: string,
): string {
  const data = {
    page: page,
    size: tableSize,
    sort: getSortString(tableSortType),
  };

  let params = `?${keyword ? `keyword=${keyword}&` : ''}page=${data.page}&size=${data.size ?? 10}`;

  for (const item of data.sort) {
    params += `&sort=${item}`;
  }

  return params.toString();
}

const getSortString = (tableSortType?: HeaderSort) => {
  if (tableSortType?.name === 'name') {
    return [
      `username,${tableSortType.sortType === 'ascend' ? 'ASC' : 'DESC'}`,
      'createdAt,ASC',
      'position,ASC',
      'loginId,ASC',
    ];
  } else if (tableSortType?.name === 'account') {
    return [
      `loginId,${tableSortType.sortType === 'ascend' ? 'ASC' : 'DESC'}`,
      'createdAt,ASC',
      'username,ASC',
      'position,ASC',
    ];
  } else if (tableSortType?.name === 'position') {
    return [
      `position,${tableSortType.sortType === 'ascend' ? 'ASC' : 'DESC'}`,
      'createdAt,ASC',
      'username,ASC',
      'loginId,ASC',
    ];
  } else if (tableSortType?.name === 'date') {
    return [
      `createdAt,${tableSortType.sortType === 'ascend' ? 'ASC' : 'DESC'}`,
      'loginId,ASC',
      'username,ASC',
      'position,ASC',
    ];
  } else {
    return ['createdAt,DESC', 'username,ASC', 'position,ASC', 'loginId,ASC'];
  }
};
