'use client';

import * as React from 'react';
import dynamic from 'next/dynamic';
import * as ServiceMapPrimitives from '@daouoffice/ui/lib/service/ServiceMap';

const LazyBody = dynamic(() => import('./LazyBody'), { ssr: false });

export interface Props {
  serviceType: 'ep' | 'mp';
  asChild?: boolean;
  t?: ServiceMapPrimitives.TTransFunc;
}

/**
 * 서비스맵 기본 컴포넌트
 */
export function ServiceMapClient({
  children,
  asChild = false,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <ServiceMapPrimitives.Root {...props}>
      <ServiceMapPrimitives.Trigger asChild={asChild}>
        {children}
      </ServiceMapPrimitives.Trigger>
      <React.Suspense>
        <LazyBody />
      </React.Suspense>
    </ServiceMapPrimitives.Root>
  );
}

export default ServiceMapClient;
