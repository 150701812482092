import { ReactElement } from 'react';
import { clsx } from 'clsx';
import { getToastStyles } from './utils';
import { Primitives as ToastPrimitives } from '@daouoffice/ui/lib/foundation/Toast';

import {
  InfoIcon,
  ExclamationCircleIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import type { ToastTypes } from './types';

export interface Props {
  type: ToastTypes;
  description: string | ReactElement;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}
function Toast({ type, open, onOpenChange, description }: Props) {
  const iconMap = {
    message: null,
    info: <InfoIcon className="mr-3" />,
    warning: <ExclamationCircleIcon className="mr-3" />,
    alert: <ExclamationCircleIcon className="mr-3" />,
  };

  return (
    <ToastPrimitives.Root
      className={clsx(
        'max-w-[500px] min-w-[500px] text-white p-4 rounded shadow-lg flex items-center',
        getToastStyles(type),
      )}
      open={open}
      onOpenChange={onOpenChange}
    >
      {iconMap[type]}
      {description}
    </ToastPrimitives.Root>
  );
}

export default Toast;
