import { PropsWithChildren } from 'react';
import { Provider } from './context';

export function Root({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-col px-[75px] pb-[75px] pt-10 gap-8">
      <Provider>{children}</Provider>
    </div>
  );
}

export default Root;
