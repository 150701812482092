import { createContext, Dispatch, PropsWithChildren, useContext } from 'react';
import { NodeProps } from './interface';
import { SetStateAction } from 'jotai';
import { NORMAL } from './constants';

export interface OrgChartState {
  uuid: string;
  type: string;
  selectableNodeType: string;
  useMultiSelect: boolean;
  usePresence: boolean;
}

export interface OrgChartActions {
  onNodeClick?: (node: NodeProps) => void;
  setLastSelectedNode?:
    | Dispatch<SetStateAction<NodeProps | undefined>>
    | undefined;
}

export const OrgChartStateContext = createContext<OrgChartState>({
  uuid: '',
  type: NORMAL,
  selectableNodeType: NORMAL,
  useMultiSelect: true,
  usePresence: false,
});
export const OrgChartActionsContext = createContext<OrgChartActions>({
  onNodeClick: () => undefined,
  setLastSelectedNode: () => undefined,
});

export interface Props {
  uuid: string;
  type: string;
  selectableNodeType?: string;
  onNodeClick?: (node: NodeProps) => void;
  setLastSelectedNode:
    | Dispatch<SetStateAction<NodeProps | undefined>>
    | undefined;
  useMultiSelect: boolean;
  usePresence?: boolean;
}

export function Provider({
  uuid,
  type,
  selectableNodeType = NORMAL,
  onNodeClick,
  setLastSelectedNode,
  useMultiSelect,
  usePresence = false,
  children,
}: PropsWithChildren<Props>) {
  const state = {
    type,
    selectableNodeType,
    useMultiSelect,
    uuid,
    usePresence,
  };
  const actions = { onNodeClick, setLastSelectedNode };

  return (
    <OrgChartStateContext.Provider value={state}>
      <OrgChartActionsContext.Provider value={actions}>
        {children}
      </OrgChartActionsContext.Provider>
    </OrgChartStateContext.Provider>
  );
}

export const useOrgChartStateContext = () => useContext(OrgChartStateContext);
export const useOrgChartActionsContext = () =>
  useContext(OrgChartActionsContext);
export const useOrgChartContext = () => {
  const state = useOrgChartStateContext();
  const actions = useOrgChartActionsContext();

  return {
    state,
    actions,
  };
};
