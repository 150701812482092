import { useState } from 'react';
import { clsx } from 'clsx';
import { ClearIcon, SearchIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';

export function SearchBar({ className }: { className?: string }) {
  const { t } = useTranslation('component');

  const [text, setText] = useState('');
  const [isActive, setIsActive] = useState(false);

  const clearButtonOnClickHandler = () => {
    setText('');
    setIsActive(false);
  };

  const handleOnChange = (value: string) => {
    if (value.length > 0) setIsActive(true);

    setText(value);
  };

  return (
    <div
      className={clsx(
        className,
        'relative flex items-center mx-[8px] border-solid border border-[#E5E5E5] rounded-[8px] h-[32px]',
      )}
    >
      <SearchIcon className="absolute left-[12px] size-[18px] self-center" />
      <input
        className="text-[13px] pl-[38px] h-full w-full bg-transparent"
        id="globalConfig_search"
        type="search"
        title={t('globalconfig.common.search.placeholder')}
        placeholder={t('globalconfig.common.search.placeholder')}
        value={text}
        onChange={(e) => handleOnChange(e.target.value)}
        onFocus={() => {
          setIsActive(true);
        }}
        onBlur={() => {
          if (text === '') {
            setIsActive(false);
          } else {
            setIsActive(true);
          }
        }}
      />
      {isActive && (
        <Button
          className="absolute right-[8px] bg-transparent"
          size="none"
          styleType="ghost"
          shape="rect"
          colorset="minor"
          onClick={clearButtonOnClickHandler}
        >
          <ClearIcon />
          <span className="blind">{t('globalconfig.common.search.clear')}</span>
        </Button>
      )}
    </div>
  );
}

export default SearchBar;
