import { clsx } from 'clsx';
import MemoList from '../memo-list';
import { PlusIcon } from '@daouoffice/ui/lib/icons/heroicons/24/solid';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { OrganizerMemoPages } from '..';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import * as getMemoListQuery from '../apis/getMemoList';
import NoMemoTemplate from './no-memo-template';
import { useEffect } from 'react';
import { useMemoContext } from '../Context';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { SearchIcon } from '@daouoffice/ui/lib/icons/dop/24';
import * as getMemoRecentlySearchList from '../apis/getMemoRecentlySearchList';
import styles from '../../../organizer-panel.module.css';
import memoStyles from '../../Memo/memo.module.css';
import { Button } from '@dop-ui/react/shared/ui/button';

export function MemoMainContent() {
  const { push } = useStackMethod<OrganizerMemoPages>();

  const { t } = useTranslation();

  const { setMemoContext } = useMemoContext();

  const queryClient = useQueryClient();

  const {
    data: { data: memoListInfo },
    error,
  } = useSuspenseQuery({
    queryKey: [getMemoListQuery.QUERY_KEY],
    queryFn: async () => await getMemoListQuery.getMemoList(),
  });

  const pushCreateMemo = () => {
    setMemoContext((prev) => ({
      ...prev,
      requiredSave: true,
    }));
    push('memo');
  };
  useEffect(() => {
    if (memoListInfo) {
      setMemoContext((prev) => ({
        ...prev,
        favoriteCount: memoListInfo.memoList.reduce(
          (acc, memo) => acc + Number(memo.isFavorite),
          0,
        ),
      }));
    }
  }, [memoListInfo]);

  const handleClickSearchBtn = () => {
    push('search');

    void queryClient.invalidateQueries({
      queryKey: [getMemoRecentlySearchList.QUERY_KEY],
    });
  };

  if (error) {
    console.error(error);
    return;
    // throw error
  }

  return (
    <>
      <div className={clsx(styles.ContentHeader, 'pt-[16px] pb-[4px]')}>
        <div className="flex items-center gap-1 h-[32px]">
          <span className="text-[#5B5B5B] font-medium">
            {t('organizer.memo.title.all')}
          </span>
          <span className="font-[#262626] font-medium">
            {memoListInfo.count}
          </span>
        </div>
        <div className="flex justify-end items-center gap-1 h-[32px] px-2 rounded hover:bg-gray-100">
          <Button
            onClick={handleClickSearchBtn}
            size="none"
            className="flex justify-end items-center gap-1 bg-transparent"
          >
            <SearchIcon size={16} />
            <span className="text-[12px]">
              {t('organizer.memo.button.search')}
            </span>
          </Button>
        </div>
      </div>
      {memoListInfo.memoList.length > 0 ? (
        <MemoList memoList={memoListInfo.memoList} />
      ) : (
        <NoMemoTemplate />
      )}
      <Button
        className={clsx(
          memoStyles.MemoIconBtn,
          'fixed bottom-6 right-5 flex justify-center items-center !w-[40px] !h-[40px] !bg-black !rounded-xl',
        )}
        onClick={pushCreateMemo}
      >
        <PlusIcon
          width={20}
          height={20}
          color="#FFFFFF"
          className={memoStyles.MemoPlusIcon}
        />
      </Button>
    </>
  );
}

export default MemoMainContent;
