import { fetch } from '../../../../../../lib/fetch/client';
import { SECURITY_SETTINGS_URL } from '../../constants';
import { MobileConfigType } from '../types';

export const MUTATION_KEY = 'mobileConfigMutation';

export async function updateMobileConfig(
  isActivated: boolean,
  media: MobileConfigType,
) {
  try {
    return await fetch(SECURITY_SETTINGS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        configType: 'MOBILE',
        configName: media,
        configValue: isActivated,
      }),
    });
  } catch (error) {
    console.error(error);
  }
}
