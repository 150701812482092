import { clsx } from 'clsx';
import { useMemo } from 'react';
import { useMemoContext } from '../Context';
import {
  useStack,
  useStackMethod,
} from '@daouoffice/ui/lib/foundation/Stacker';
import { PreviousIndicatorIcon } from '@daouoffice/ui/lib/icons/dop/16';

import MemoEditor from './Editor';

import { createMemo } from '../apis/createMemo';
import { updateMemo } from '../apis/updateMemo';
import * as getMemoQuery from '../apis/getMemo';
import * as getMemoListQuery from '../apis/getMemoList';
import * as getMemoSearchQuery from '../apis/getMemoSearch';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import type { OrganizerMemoPages } from '..';
import styles from '../../../organizer-panel.module.css';
import { useOrganizerAtom } from '../../../Provider';
import memoStyles from '../memo.module.css';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import PanelHeader from '../../panel-header';
import {
  useToastMessage,
  Templates,
} from '@dop-ui/react/features/toast-message';

export function OrganizerMemoEdit() {
  const { stack } = useStack<OrganizerMemoPages>();
  const { pop, navigate } = useStackMethod<OrganizerMemoPages>();
  const { confirm } = useMessageDialog();
  const canPop = stack.length > 1;

  const { info } = useToastMessage();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [, setOrganizerAtom] = useOrganizerAtom();

  const {
    memoContext: {
      memoId,
      content,
      prevContent,
      textContent,
      prevTextContent,
      requiredSave,
      color,
      prevColor,
    },
    setMemoContext,
  } = useMemoContext();

  const updateMemoMutation = useMutation({
    mutationFn: async () =>
      await updateMemo({ id: memoId!, content, color, textContent }),
    onSuccess: (data) => {
      info(<Templates.Basic description={t('organizer.memo.toast.saved')} />);
      setMemoContext((prev) => ({
        ...prev,
        updatedAt: data.data.updatedAt,
        requiredSave: false,
        prevContent: content,
        prevTextContent: textContent,
        prevColor: color,
      }));
      void queryClient.invalidateQueries({
        queryKey: [getMemoQuery.QUERY_KEY, memoId],
      });
      handlePop();
    },
    onError: (e) => {
      info(<Templates.Basic description={e.message} />);
      handlePop();
    },
  });

  const createMemoMutation = useMutation({
    mutationFn: async () => await createMemo({ content, textContent, color }),
    onSuccess: (data) => {
      setMemoContext((prev) => ({
        ...prev,
        memoId: data.data.id,
        updatedAt: data.data.updatedAt,
        requiredSave: false,
      }));
      info(<Templates.Basic description={t('organizer.memo.toast.saved')} />);

      navigate('memoDetail');
    },
    onError: (e) => {
      <Templates.Basic description={e.message} />;
    },
  });

  const handleOnClickSave = () => {
    if (!content) {
      info(<Templates.Basic description={t('organizer.memo.empty')} />);
      return;
    }
    if (!memoId) {
      createMemoMutation.mutate();
    } else {
      updateMemoMutation.mutate();
    }
  };

  const handlePop = () => {
    void queryClient.invalidateQueries({
      queryKey: [getMemoQuery.QUERY_KEY, memoId],
    });
    void queryClient.invalidateQueries({
      queryKey: [getMemoSearchQuery.QUERY_KEY],
    });
    void queryClient.invalidateQueries({
      queryKey: [getMemoListQuery.QUERY_KEY],
    });
    if (!memoId) {
      setMemoContext((prev) => ({
        ...prev,
        memoId: null,
        content: '',
        updatedAt: '',
        requiredSave: false,
        isFavorite: false,
        color: 'yellow',
      }));
    }

    pop();
  };

  const handleOnClickPrev = async () => {
    if (requiredSave) {
      if (await confirm(<div>{t('organizer.setting.alert.cancel')}</div>)) {
        setMemoContext((prev) => ({
          ...prev,
          content: prevContent,
          textContent: prevTextContent,
          color: prevColor,
          requiredSave: false,
        }));
        handlePop();
      }
      return;
    } else {
      handlePop();
    }
  };
  const handleClickCloseBtn = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'default',
      panelState: 'none',
    }));
  };

  const prev = useMemo(() => {
    return (
      canPop && (
        <Button
          className={memoStyles.MemoIconBtn}
          onClick={() => void handleOnClickPrev()}
        >
          <PreviousIndicatorIcon />
        </Button>
      )
    );
  }, [canPop, requiredSave]);

  return (
    <div className={styles.Wrapper}>
      <PanelHeader
        title={t('organizer.memo.title')}
        onClickClose={() => void handleClickCloseBtn()}
      />
      <div className={clsx(styles.Content, 'gap-2')}>
        <div
          className={clsx(styles.ContentHeader, 'w-[350px] pt-[16px] pb-[4px]')}
        >
          {prev}

          <span className="text-[16px] text-[#363636] font-medium flex-1 ">
            {memoId ? t('organizer.memo.update') : t('organizer.memo.write')}
          </span>

          <Button
            shape="rect"
            size="small"
            className="align-bottom"
            onClick={handleOnClickSave}
          >
            {t('organizer.memo.save')}
          </Button>
        </div>
        <div className="gap-4 flex flex-col w-full h-[calc(100%-88px)] max-h-[calc(100%-88px)]">
          {<MemoEditor />}
        </div>
      </div>
    </div>
  );
}

export default OrganizerMemoEdit;
