import { PropsWithChildren } from 'react';
import { Provider } from './Context';
import type { IUserMenuInfo } from '../../../types';

export interface Props {
  userApps: IUserMenuInfo[];
}

export function Root({ userApps, children }: PropsWithChildren<Props>) {
  return (
    <div className="menu_setting gnb_setting open" id="gnb_setting">
      <div className="wrap_menu_setting">
        <Provider data={userApps}>{children}</Provider>
      </div>
    </div>
  );
}

export default Root;
