import { clsx } from 'clsx';
import Link from 'next/link';

export interface Props {
  message?: string;
  className?: string;
  linkUrl?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export function InfoMessage({ message, className, linkUrl, onClick }: Props) {
  const messageClassName = clsx(
    'whitespace-pre-line text-gray-600 text-center',
    className,
  );
  const messageBlock = <span className={messageClassName}>{message}</span>;

  return linkUrl ? (
    <Link
      href={linkUrl ?? ''}
      className={clsx(
        'hover:underline hover:decoration-solid',
        messageClassName,
      )}
      onClick={onClick}
    >
      {messageBlock}
    </Link>
  ) : (
    messageBlock
  );
}

export default InfoMessage;
