import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { FilterOption } from '../../../types';
import {
  activatedFiltersAtom,
  deleteActivatedFiltersAtom,
} from '../../../stores/activatedFilterAtom';
import { useFilterContext } from '../Context';

export interface SelectOptionProps {
  option: FilterOption;
}

export function SelectOption({ option }: SelectOptionProps) {
  const [, setActivateFilter] = useAtom(activatedFiltersAtom);
  const [, deleteActiveFilter] = useAtom(deleteActivatedFiltersAtom);
  const { selectedOptions, setSelectedOptions } = useFilterContext();

  const onClickSelect = () => {
    const newSelectedOptions = new Set<string>();
    if (selectedOptions.has(option.key)) {
      deleteActiveFilter(option.key);
    } else {
      selectedOptions.forEach((value) => deleteActiveFilter(value));
      newSelectedOptions.add(option.key);
      setActivateFilter(option.key, option.key);
    }
    setSelectedOptions(newSelectedOptions);
  };
  return (
    <button
      className={clsx('flex items-center w-full p-[8px]', {
        'bg-gray-400': selectedOptions.has(option.key),
        'bg-gray-300': !selectedOptions.has(option.key),
      })}
      onClick={() => onClickSelect()}
    >
      {option.title}
    </button>
  );
}
