import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
interface Props {
  className?: string;
}

export function Root({ className, children }: PropsWithChildren<Props>) {
  return (
    <div
      className={clsx(
        'flex flex-col items-start px-[24px] h-full transition-all overflow-y-scroll',
        className,
      )}
    >
      {children}
    </div>
  );
}
