import { ActionButton } from './ui/primitives/action-button';
import { Contents } from './ui/primitives/contents';
import { ErrorMessage } from './ui/primitives/error-message';
import { InfoMessage } from './ui/primitives/info-message';
import { PinField } from './ui/primitives/pin-field';
import { Provider } from './ui/primitives/provider';
import { Root } from './ui/primitives/root';
import { TextField } from './ui/primitives/text-field';
import { Title } from './ui/primitives/title';

export { SimpleCodeFormClient as default } from './ui/simple-code-form-client';

export const SimpleCodeFormPrimitives = {
  Root,
  Provider,
  Contents,
  TextField,
  PinField,
  ActionButton,
  Title,
  InfoMessage,
  ErrorMessage,
};

export type * from './types';

export { useScreenLock } from './api/use-screen-lock';
export * from './model';
export * from './types';
export * from './ui';
