/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useAtom } from 'jotai';
import { NodeProps } from './interface';
import { getNodeId } from './Util';
import { useTranslation } from 'react-i18next';
import { useOrgChartContext } from './Provider';
import {
  getDisplaySelectedNodes,
  treesAtom,
  searchTreesAtom,
  updateTrees,
  selectedNodesAtom,
  updateSelectedNodes,
} from './Store';
import { useEffect, useRef, useState } from 'react';

export interface Props {
  isSearch: boolean;
}

const LEFT_PADDING = 26;
const NODE_WIDTH = 86;

export default function SelectedNodes() {
  const { t } = useTranslation();
  const context = useOrgChartContext();
  const { type } = context.state;
  const [trees, setTrees] = useAtom(treesAtom);
  const [, setSearchTrees] = useAtom(searchTreesAtom);
  const [, setSelectedNodes] = useAtom(selectedNodesAtom);
  const selectedNodes = getDisplaySelectedNodes(trees, type);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(4);
  const containerRef = useRef<HTMLDivElement>(null);
  const visibleNodes = selectedNodes.slice(startIndex, endIndex);
  const totalWidth = selectedNodes.length * NODE_WIDTH;

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const rect = container?.getBoundingClientRect();
        const width = rect.width;
        const scrollLeft = container.scrollLeft;
        const newStartIndex = Math.floor(scrollLeft / NODE_WIDTH);
        const newEndIndex = Math.min(
          newStartIndex + Math.ceil(width / NODE_WIDTH) + 1,
        );
        setScrollLeft(scrollLeft);
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
      }
    };

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      const container = containerRef.current;
      if (container) container.scrollLeft += e.deltaY / 2;
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);
    container?.addEventListener('wheel', handleWheel);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
      container?.removeEventListener('wheel', handleWheel);
    };
  });

  const removeSelectedNode = (node: NodeProps) => {
    setTrees((nodes) =>
      updateTrees(nodes, { ...node, selected: !node.selected }),
    );
    setSearchTrees((nodes) =>
      updateTrees(nodes, { ...node, selected: !node.selected }),
    );
    setSelectedNodes((nodes) =>
      updateSelectedNodes(nodes, { ...node, selected: !node.selected }),
    );
  };

  return (
    <div className="wrap_selected_list" ref={containerRef}>
      {!selectedNodes.length && <div>{t('아래 목록에서 선택하세요.')}</div>}
      <ul className="selected_member_list" style={{ width: totalWidth }}>
        {visibleNodes.map((item: NodeProps, index) => (
          <li
            key={getNodeId(item)}
            className="selected_member"
            style={{
              position: 'absolute',
              left:
                LEFT_PADDING + (startIndex + index) * NODE_WIDTH - scrollLeft,
            }}
          >
            <div className="member">
              <div className="avatar_medium">
                <svg viewBox="0 0 88 88">
                  <path
                    id="userPhoto"
                    d="M44,0 C76.0948147,0 88,11.9051853 88,44 C88,76.0948147 76.0948147,88 44,88 C11.9051853,88 0,76.0948147 0,44 C0,11.9051853 11.9051853,0 44,0 Z"
                    fill="#ffffff"
                  ></path>
                  <clipPath id="photoMask">
                    <use xlinkHref="#userPhoto"></use>
                  </clipPath>
                  <rect
                    className="group_bg"
                    data-color="1"
                    clipPath="url(#photoMask)"
                    x="0"
                    y="0"
                    rx="15"
                    width="88"
                    height="88"
                  ></rect>
                  <text
                    className="group_txt"
                    x="50%"
                    y="50%"
                    dy="10"
                    textAnchor="middle"
                  >
                    {item.name}
                  </text>
                </svg>
              </div>
              <i
                className="icon ic_xs delete"
                onClick={() => {
                  removeSelectedNode(item);
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.5" cy="7.5" r="6.5" fill="white"></circle>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.781 11.2497L8.00012 8.46887L5.21899 11.25L4.51189 10.5429L7.29302 7.76177L4.41699 4.88574L5.1241 4.17864L8.00012 7.05466L10.8758 4.17893L11.583 4.88604L8.70723 7.76177L11.4881 10.5426L10.781 11.2497Z"
                    fill="#3B3B3B"
                  ></path>
                </svg>
              </i>
              <div className="info">
                <div className="main">
                  <span className="name_member">{`${item.name} ${item.position ?? ''}`}</span>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
