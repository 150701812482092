import { clsx } from 'clsx';
import { ServiceLogo } from '@daouoffice/ui/lib/service/ServiceLogo';
import { ToolbarClient } from '@daouoffice/next/components/Toolbar';

import '@daouoffice/design/dist/pages/employee/layout/root/header.css';

export function Header({ className }: { className?: string }) {
  return (
    <header className={clsx('header', className)}>
      <div className="wrap_header">
        <div className="group wrap_logo">
          <h1 className="logo">
            <ServiceLogo color="primary" />
          </h1>
        </div>

        <div className="group utils">
          {/* placeholder: 검색 */}

          {/* SNB 영역 (+ 아바타) */}
          <ToolbarClient service="employee" />
        </div>
      </div>
    </header>
  );
}

export default Header;
