import { IconContainer } from '../IconContainer';
import { IconCommonProps } from '../types';

/**
 * 메뉴 접기 아이콘
 * @param props
 * @returns
 */

export function FlipIcon(props: IconCommonProps) {
  return (
    <IconContainer viewBox="0 0 14 15" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25338 4.46627C2.4584 4.26124 2.79082 4.26124 2.99584 4.46627L6.99961 8.47004L11.0034 4.46627C11.2084 4.26124 11.5408 4.26124 11.7458 4.46627C11.9509 4.67129 11.9509 5.00371 11.7458 5.20873L7.37084 9.58373C7.16582 9.78876 6.8334 9.78876 6.62838 9.58373L2.25338 5.20873C2.04835 5.00371 2.04835 4.67129 2.25338 4.46627Z"
        fill="#AAAAAA"
      />
    </IconContainer>
  );
}

export default FlipIcon;
