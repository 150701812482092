import { PropsWithChildren } from 'react';
import LinkItem from '../link-item';
import LinkButton from '../link-button';
import type { ShortcutItemInfo } from '../types';

export interface Props extends ShortcutItemInfo {
  className?: string;
}

function ShortcutListItem({
  uid,
  name,
  icon,
  url,
  itemType,
  appType,
  className,
  onClick,
}: PropsWithChildren<Props>) {
  return (
    <LinkButton
      id={uid}
      className={className}
      name={name}
      url={url}
      itemType={itemType}
      appType={appType}
      onClick={onClick}
    >
      <LinkItem id={uid} name={name} size={32} faviconUrl={icon} />
    </LinkButton>
  );
}

export default ShortcutListItem;
