import { clsx } from 'clsx';
import { ArrowUpSquareIcon } from '../../../../icons/dop/24/ArrowUpSquare';
import { useContext as useServiceMapContext } from '../../Context';
import { AppStatus, IconTypes } from '../../types';

/**
 * AppListItem 컴포넌트 속성
 */
export interface Props {
  /** @property App 아이디*/
  uuid: string;
  /** @property App 링크*/
  link: string;
  /** @property 이름*/
  name: string;
  /** @property 설명 */
  description: string;
  /** @property 앱 상태 (사용가능, 미이용, 권한없음)*/
  appStatus: AppStatus;
  /** @property 아이콘 */
  icon: IconTypes;
  /** @property 새창으로 오픈할지 여부 */
  targetBlank?: boolean;
}

/**
 * ServiceMap.AppList.AppListItem 컴포넌트
 * @returns
 */
export default function AppListItem({
  link,
  name,
  description,
  appStatus,
  icon,
  targetBlank = false,
}: Props) {
  const { t } = useServiceMapContext();

  const iconPropReturned = typeof icon === 'function' ? icon() : icon;
  const iconPart =
    typeof iconPropReturned === 'string' ? (
      <span
        className="app_icon"
        dangerouslySetInnerHTML={{ __html: iconPropReturned }}
      />
    ) : (
      <span className="app_icon">{iconPropReturned}</span>
    );

  return (
    <li
      key={name}
      className={clsx('wrap_app_list', {
        ['inactive']: appStatus !== 'authorized',
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={`app_list`} href={appStatus === 'authorized' ? link : '#'}>
        <div className="app_icon">{iconPart}</div>
        <div className="wrap_app_info">
          <div className="app_name">
            <p className="title">{name}</p>
            {appStatus === 'unauthorized' && (
              <div className="badge permission">
                <span>{t('serviceMap.app.item.label.notAuthorized')}</span>
              </div>
            )}
            {appStatus === 'authorized' && targetBlank && (
              <ArrowUpSquareIcon className="target_blank" size={16} />
            )}
          </div>
          <p className="description">{description}</p>
        </div>
      </a>
    </li>
  );
}
