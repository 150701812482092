import { fetch } from '../../../../../../lib/fetch/client';
import { DATA_REQUEST_APPROVE_API } from '../../constants';
import { BaseResponse } from '../../types';

export const QUERY_KEY = 'QueryKeys.GlobalConfig.DataManagement.RequestConfig';

export async function getRequestConfig() {
  const response = await fetch(DATA_REQUEST_APPROVE_API, {
    headers: { 'Content-Type': 'application/json' },
  });
  const responseData = (await response.json()) as BaseResponse<{
    data: boolean;
  }>;
  return responseData.data;
}

export async function updateRequestConfig(setup: boolean) {
  await fetch(DATA_REQUEST_APPROVE_API, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ setup }),
  });
}
