import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export function Root({ className, children }: PropsWithChildren<Props>) {
  return <div className={clsx(className, 'grid gap-[8px]')}>{children}</div>;
}
