import { Suspense } from 'react';
import { useMemoContext } from '../Context';
import RecentlySearchList from './recently-search-list';
import SearchResultList from './search-result-list';

export function MemoSearchList() {
  const { memoContext } = useMemoContext();

  const isShowResult = memoContext.keyword !== '';

  return (
    <>
      {isShowResult ? (
        <Suspense fallback={<div>Loading...</div>}>
          <SearchResultList />
        </Suspense>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <RecentlySearchList />
        </Suspense>
      )}
    </>
  );
}

export default MemoSearchList;
