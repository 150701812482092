import * as React from 'react';

export interface IValuesContext {
  selectedAppCode: null | string;
}

export interface IActionsContext {
  setSelectedAppCode: React.Dispatch<React.SetStateAction<null | string>>;
}

export const ValuesContext = React.createContext<IValuesContext>({
  selectedAppCode: null,
});

export const ActionsContext = React.createContext<IActionsContext>({
  setSelectedAppCode: () => {},
});

export function Provider({ children }: React.PropsWithChildren) {
  const [selectedAppCode, setSelectedAppCode] = React.useState<null | string>(
    null,
  );

  return (
    <ValuesContext.Provider value={{ selectedAppCode }}>
      <ActionsContext.Provider value={{ setSelectedAppCode }}>
        {children}
      </ActionsContext.Provider>
    </ValuesContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return { ...values, ...actions };
};
