import * as React from 'react';
import { MemberDataInfo } from '../../types';

export interface IValuesContext {
  selectedRows: MemberDataInfo[];
}

export interface IActionsContext {
  setSelectedRows: React.Dispatch<React.SetStateAction<MemberDataInfo[]>>;
}

export const ValuesContext = React.createContext<IValuesContext>({
  selectedRows: [],
});

export const ActionsContext = React.createContext<IActionsContext>({
  setSelectedRows: () => undefined,
});

export interface Props {
  defaultSelectedRows: MemberDataInfo[];
}

export function Provider({
  defaultSelectedRows,
  children,
}: React.PropsWithChildren<Props>) {
  const [selectedRows, setSelectedRows] = React.useState<MemberDataInfo[]>([]);

  React.useEffect(() => {
    setSelectedRows(defaultSelectedRows);
  }, [defaultSelectedRows]);

  return (
    <ValuesContext.Provider value={{ selectedRows }}>
      <ActionsContext.Provider value={{ setSelectedRows }}>
        {children}
      </ActionsContext.Provider>
    </ValuesContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return { ...values, ...actions };
};
