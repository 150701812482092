import { fetch } from '../../../../../../lib/fetch/client';
import { ACCESS_ROLE_INFO_URL } from '../../constants';
import { AppAccessRoleRequestInfo, AppLockInfo } from '../types';

interface ResponseBody {
  data: AppAccessRoleRequestInfo;
}

export const MUTATION_KEY = 'deviceAccessMutation';

export async function updateDevice(
  selectedAppId: number,
  appLockInfo: AppLockInfo,
) {
  try {
    const { data } = (await (
      await fetch(`${ACCESS_ROLE_INFO_URL}/${selectedAppId}/access-role-info`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          webAccessRoleInfo: appLockInfo?.webAccessRoleInfo,
          mobileAccessRoleInfo: appLockInfo?.mobileAccessRoleInfo,
          messengerAccessRoleInfo: appLockInfo?.messengerAccessRoleInfo,
        } as AppAccessRoleRequestInfo),
      })
    ).json()) as ResponseBody;
    return data;
  } catch (error) {
    console.error(error);
  }
}
