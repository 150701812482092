import TabList from './tab-list';
import WorksList from './works-list';
import LinkPlusList from './link-plus-list';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { ComponentType, Suspense } from 'react';
import styles from '../shortcut.module.css';
import { useShortcutContext } from '../Context';

export function OrganizerAppAdder() {
  const { t } = useTranslation();
  const { tab } = useShortcutContext();

  const renderList = (
    Component: ComponentType<{ scrollable: boolean }>,
    scrollable: boolean,
  ) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component scrollable={scrollable} />
    </Suspense>
  );

  return (
    <div className={styles.Card}>
      <p className={styles.Title}>{t('organizer.shortcut.app')}</p>
      <TabList />

      {tab === 'All' ? (
        <>
          <p className="text-[16px] font-medium">Works</p>
          {renderList(WorksList, false)}
          <div className={styles.Divider} />
          <p className="text-[16px] font-medium">Link+</p>
          {renderList(LinkPlusList, false)}
        </>
      ) : tab === 'Works' ? (
        renderList(WorksList, true)
      ) : tab === 'LinkPlus' ? (
        renderList(LinkPlusList, true)
      ) : null}
    </div>
  );
}

export default OrganizerAppAdder;
