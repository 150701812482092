/* eslint-disable import/no-named-as-default-member */

import dayjs from 'dayjs';

export interface FormatDateProps {
  date: string | number | Date | dayjs.Dayjs | null | undefined;
  format: string;
}

export const formatDate = ({ date, format }: FormatDateProps) => {
  const parsedDate = dayjs(date);
  if (!dayjs.isDayjs(parsedDate)) return '';
  return parsedDate.format(format);
};

interface CompareDateProps {
  date: string | number | Date | dayjs.Dayjs | null | undefined;
  compareDate: string | number | Date | dayjs.Dayjs | null | undefined;
  unit: dayjs.UnitType;
}
export const isBefore = ({ date, compareDate, unit }: CompareDateProps) => {
  const parsedDate = dayjs(date);
  const parsedCompareDate = dayjs(compareDate);

  return parsedDate.isBefore(parsedCompareDate, unit);
};

export const isAfter = ({ date, compareDate, unit }: CompareDateProps) => {
  const parsedDate = dayjs(date);
  const parsedCompareDate = dayjs(compareDate);

  return parsedDate.isAfter(parsedCompareDate, unit);
};

export const isSame = ({ date, compareDate, unit }: CompareDateProps) => {
  const parsedDate = dayjs(date);
  const parsedCompareDate = dayjs(compareDate);

  return parsedDate.isSame(parsedCompareDate, unit);
};

interface OperationProps {
  date: string | number | Date | dayjs.Dayjs | null | undefined;
  value: number;
  unit: dayjs.ManipulateType;
}

export const add = ({ date, value, unit }: OperationProps) => {
  const parsedDate = dayjs(date);
  return parsedDate.add(value, unit);
};

export const subtract = ({ date, value, unit }: OperationProps) => {
  const parsedDate = dayjs(date);
  return parsedDate.subtract(value, unit);
};
