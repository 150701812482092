import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as fileListApi from './apis/fileList';
import { FileInfo } from './types';
import { useEffect, useState } from 'react';

export function FileList() {
  const { t } = useTranslation('component');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageOffet, setCurrentPageOffset] = useState(20);
  const [keyword, setKeyword] = useState('');

  const { data: fileData, error } = useQuery({
    queryKey: [fileListApi.QUERY_KEY, currentPage, pageOffet],
    queryFn: fileListApi.getFileList,
  });

  // Todo : 추후 keyword 추가
  useEffect(() => {
    setCurrentPage(0);
  }, [pageOffet]);

  if (error) {
    console.error(
      'Error: GlobalConfig > DataManagement > UploadDataManagement : ',
      error,
    );
  }

  const dateTitles = {
    today: t('globalconfig.dataManagement.requestManagement.date.today'),
    days7: t('globalconfig.dataManagement.requestManagement.date.7daysAgo'),
    days30: t('globalconfig.dataManagement.requestManagement.date.30daysAgo'),
    days90: t('globalconfig.dataManagement.requestManagement.date.90daysAgo'),
    selfInput: t(
      'globalconfig.dataManagement.requestManagement.date.selfInput',
    ),
  };

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
    console.log('keyword', keyword);
  };

  const onOffsetChange = (offset: number) => {
    setCurrentPageOffset(offset);
  };

  const columnDefs: ColumnDef<FileInfo>[] = [
    {
      id: 'fileName',
      accessorKey: 'fileName',
      header: () => (
        <div className="max-w-[480px]">
          {t(
            'globalconfig.dataManagement.uploadManagement.retentionPeriod.list.fileSize',
          )}
        </div>
      ),
      cell: (info) => {
        const fileName = info.getValue() as string;
        return (
          <p className="max-w-[510px] h-[48px] text-ellipsis">{fileName}</p>
        );
      },
      maxSize: 510,
    },
    {
      id: 'fileSize',
      accessorKey: 'fileSize',
      header: t(
        'globalconfig.dataManagement.uploadManagement.retentionPeriod.list.fileSize',
      ),
    },
    {
      id: 'createdDate',
      accessorKey: 'createdDate',
      header: t(
        'globalconfig.dataManagement.uploadManagement.retentionPeriod.list.createDate',
      ),
    },
    {
      id: 'senderName',
      accessorKey: 'senderName',
      header: t(
        'globalconfig.dataManagement.uploadManagement.retentionPeriod.list.sender',
      ),
    },
  ];

  return (
    <div className="mt-[32px] p-[40px] border border-solid border-[#DDDDDD] rounded-[8px]">
      <p className="text-[22px] text-[#333333] font-[500]">
        {t(
          'globalconfig.dataManagement.uploadManagement.retentionPeriod.list.title',
        )}
      </p>
      <p className="mt-[12px] text-[14px] text-[#888888] font-[400]">
        <span className="h-[20px] px-[8px] mr-[8px] rounded-[8px] bg-[#FFEEEE] text-[#FF5252]">
          notice
        </span>
        {t(
          'globalconfig.dataManagement.uploadManagement.retentionPeriod.list.description',
        )}
      </p>
      <TablePrimitives.Root<FileInfo>
        className="mt-[32px]"
        contents={fileData?.data || []}
        columnDefs={columnDefs}
        selectable
      >
        <div className="flex flex-wrap w-full">
          <div className="flex flex-col">
            <div className="flex items-center">
              <TablePrimitives.SearchBar
                className="inline-block w-[240px] h-[40px]"
                onKeywordChange={onKeywordChange}
              />
              <div className="mx-[8px] w-[1px] h-[12px] bg-[#C5CBD3]" />
              <TablePrimitives.Filter.Container className="inline-block">
                <TablePrimitives.Filter.DateOption
                  title={t(
                    'globalconfig.dataManagement.requestManagement.list.applyDate',
                  )}
                  filterKey="applyDate"
                  dateOptionTitles={dateTitles}
                />
              </TablePrimitives.Filter.Container>
            </div>
            {keyword && (
              <div className="pt-3 text-[#81A9FC]">{`${fileData?.data.length}${t('globalconfig.common.table.search.result')}`}</div>
            )}
          </div>
          <div className="spacer flex-grow" />
          <div className="flex justify-between items-end">
            <Button
              title={t(
                'globalconfig.dataManagement.uploadManagement.retentionPeriod.list.delete',
              )}
              size="medium"
              shape="rect"
              colorset="minor"
              styleType="line"
            ></Button>
            <TablePrimitives.SizeSelector
              className="w-[60px] h-[40px]"
              tableSize={pageOffet}
              onChangeTableSize={onOffsetChange}
            ></TablePrimitives.SizeSelector>
          </div>
        </div>
        <TablePrimitives.Contents className="pb-[32px] mt-[16px] mb-[32px]" />
        <TablePrimitives.Pagination
          className="mt-[16px]"
          currentPage={currentPage}
          totalPage={fileData?.page.totalPage ?? 0}
          onNextPage={setCurrentPage}
          onPreviousPage={setCurrentPage}
          onClickPage={setCurrentPage}
          onFirstPage={() => setCurrentPage(0)}
          onLastPage={() =>
            setCurrentPage(
              fileData?.page.totalPage ? fileData.page.totalPage - 1 : 0,
            )
          }
        />
      </TablePrimitives.Root>
    </div>
  );
}
