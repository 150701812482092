import { Contents } from './components/Contents';
import { Root } from './components/Root';
import { ScreenLockConfig } from './components/ScreenLockConfig';

export function MediaSecureConfig() {
  return (
    <Root>
      <Contents>
        <ScreenLockConfig type="MESSENGER_MEDIA_SCREEN_LOCK" withDivider />
        <ScreenLockConfig type="MOBILE_MEDIA_SCREEN_LOCK" />
      </Contents>
    </Root>
  );
}

export default MediaSecureConfig;
