// @radix/react-popover를 차용한다.

import * as RadixPrimitives from '@radix-ui/react-popover';

export const Root = RadixPrimitives.Root;
export const Trigger = RadixPrimitives.Trigger;
export const Close = RadixPrimitives.Close;

export interface PopoverContentProps
  extends RadixPrimitives.PopoverContentProps {
  isPortal?: boolean;
}

/**
 * Popover Content 컴포넌트
 */
export function Content({
  isPortal = false,
  children,
  ...restProps
}: PopoverContentProps) {
  const contentPart = (
    <RadixPrimitives.Content {...restProps}>{children}</RadixPrimitives.Content>
  );

  return isPortal ? (
    <RadixPrimitives.Portal>{contentPart}</RadixPrimitives.Portal>
  ) : (
    contentPart
  );
}

// type export
export type TriggerProps = RadixPrimitives.PopoverTriggerProps;
