import * as Jotai from 'jotai';
import { AppInfo } from '../types';

export const loadStateAtom = Jotai.atom<boolean>(false);
export const useLoadState = () => Jotai.useAtom(loadStateAtom);

export const lockStateAtom = Jotai.atom<boolean>(true);
export const useLockState = () => Jotai.useAtom(lockStateAtom);

export const appInfoAtom = Jotai.atom<AppInfo>({});
export const useAppInfoAtom = () => Jotai.useAtom(appInfoAtom);
