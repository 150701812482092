import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { SaveConfirmDialog } from '../../../../../parts/components/save-confirm-dialog';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useAtom } from 'jotai';
import { isOrgSahreNeedsSaveAtom } from '../../store/is-org-share-needs-save-atom';
import { Suspense, useEffect, useState } from 'react';
import { Switch } from '@daouoffice/ui';
import { TargetConfig, TargetInfo, getClassList } from '../../type';
import { PlusIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { useBasicInfoContext } from '../../Context';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOrgConfig } from '../../apis/update-org-config';
import * as getOrgConfig from '../../apis/get-org-config';
import AdditionalClassSelect from './additional-class-select';
import OrgChart from '../../../../../../OrgChart';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { NodeProps } from '../../../../../../OrgChart/interface';
import { useToastMessage } from '@dop-ui/react/features/toast-message';

export function OrgShareConfig() {
  const { t } = useTranslation('component');

  const { confirm } = useMessageDialog();
  const stacker = useStackMethod();
  const toaster = useToastMessage();
  const queryClient = useQueryClient();

  const {
    basicInfoContext: {
      companyList,
      orgConfig,
      orgConfig: {
        isOrganizationChartShare,
        shareTarget,
        shareRange,
        targetList,
      },
    },
  } = useBasicInfoContext();

  const [selectedSiteId, setSelectedSiteId] = useState(
    companyList[0]?.companyId ?? 0,
  );
  const [selectedClassName, setSelectedClassName] = useState('USER');
  const [selectedAdditionalClass, setSelectedAdditionalClass] = useState({
    id: 0,
    name: '',
  });

  const [isShared, setIsShared] = useState(isOrganizationChartShare === 'true');

  const [isConfigNeedsSave, setIsConfigNeedsSave] = useAtom(
    isOrgSahreNeedsSaveAtom,
  );

  const [targetConfig, setTargetConfig] = useState<TargetConfig>({
    shareTarget: shareTarget,
    shareRange: shareRange,
    targetList: targetList,
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const updateOrgConfigMutation = useMutation({
    mutationFn: async () =>
      await updateOrgConfig({
        ...orgConfig,
        isOrganizationChartShare: isShared.toString(),
        shareTarget: targetConfig.shareTarget,
        shareRange: targetConfig.shareRange,
        targetList: targetConfig.targetList.map(
          ({ roleTypeName, ...rest }) => rest,
        ),
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [getOrgConfig.QUERY_KEY],
      });
      setIsConfigNeedsSave(false);
      toaster.info(
        t('globalconfig.secureManagement.loginSecureConfig.ip.saved'),
      );
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    return () => {
      setIsConfigNeedsSave(false);
    };
  }, [setIsConfigNeedsSave]);

  useEffect(() => {
    if (
      shareTarget === targetConfig.shareTarget &&
      shareRange === targetConfig.shareRange &&
      JSON.stringify(targetList) === JSON.stringify(targetConfig.targetList)
    ) {
      setIsConfigNeedsSave(false);
    } else {
      setIsConfigNeedsSave(true);
    }

    if ((isOrganizationChartShare === 'true') != isShared) {
      setIsConfigNeedsSave(true);
    }
  }, [targetConfig, isShared]);

  const onClickBackButton = async () => {
    if (isConfigNeedsSave) {
      if (await confirm(<SaveConfirmDialog />)) {
        stacker.pop();
      }
    } else {
      stacker.pop();
    }
  };

  const onSwitchChange = (shareStatus: boolean) => {
    setIsShared(shareStatus);
    if (isOrganizationChartShare === shareStatus.toString()) {
      setIsConfigNeedsSave(false);
    } else {
      setIsConfigNeedsSave(true);
    }
  };

  const onClickCancle = () => {
    setIsShared(isOrganizationChartShare === 'true');
    setSelectedSiteId(companyList[0]?.companyId ?? 0);
    setSelectedClassName('USER');
    setTargetConfig({
      shareTarget: shareTarget,
      shareRange: shareRange,
      targetList: targetList,
    });
    setIsConfigNeedsSave(false);
  };

  const onClickSave = () => {
    updateOrgConfigMutation.mutate();
  };

  const onClickAdd = () => {
    if (selectedClassName === 'USER' || selectedClassName === 'DEPT') {
      setDialogOpen(true);
    } else {
      if (
        targetConfig.targetList.find(
          (item) =>
            item.companyId === selectedSiteId &&
            item.roleType === selectedClassName &&
            item.roleTypeId === selectedAdditionalClass.id,
        )
      ) {
        return;
      }

      if (selectedAdditionalClass.id === -1) {
        return;
      }
      setTargetConfig((prev) => ({
        ...prev,
        targetList: [
          ...prev.targetList,
          {
            companyId: selectedSiteId,
            roleType: selectedClassName,
            roleTypeId: selectedAdditionalClass.id,
            roleTypeName: selectedAdditionalClass.name,
          },
        ],
      }));
    }
  };

  const onClickCancleTarget = (
    roleType: string,
    companyId: number,
    roleTypeId: number,
  ) => {
    const newTargetList = targetConfig.targetList.filter(
      (item) =>
        !(
          item.roleType === roleType &&
          item.companyId === companyId &&
          item.roleTypeId === roleTypeId
        ),
    );
    setTargetConfig((prev) => ({
      ...prev,
      targetList: newTargetList,
    }));
  };

  const onConfirmOnOrgChart = (nodes: NodeProps[]) => {
    console.log('nodes', nodes);

    const newTargetList = nodes.map(
      (node): TargetInfo => ({
        companyId: selectedSiteId,
        roleType: selectedClassName,
        roleTypeId:
          selectedClassName === 'USER' ? node.userId ?? -1 : node.id ?? -1,
        roleTypeName: node.name,
      }),
    );
    setTargetConfig((prev) => ({
      ...prev,
      targetList: [...prev.targetList, ...newTargetList],
    }));
    setDialogOpen(false);
  };

  return (
    <div className="flex flex-col flex-wrap gap-[24px]">
      <div className="h-[38px] flex items-center">
        <Button
          className="!w-[24px] !h-[24px] mr-[8px]"
          onClick={() => void onClickBackButton()}
        >
          <ArrowLeftIcon className="" />
        </Button>
        <h2 className="text-[#333] text-[20px] font-bold -tracking-[1.2px]">
          {t(
            'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.title',
          )}
        </h2>
      </div>
      <div className="flex flex-wrap items-center h-[88px] w-full py-[24px] text-[--color-text-level1] text-[16px]">
        <span className="w-[264px] text-[--color-text-level1] text-[16px] font-[500] whitespace-nowrap text-ellipsis overflow-hidden">
          {t(
            'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.title',
          )}
        </span>
        <span className="text-[--color-text-level3] text-[14px] font-[400]">
          {t(
            'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.description',
          )}
        </span>
        <div className="spacer flex-grow" />
        <Switch
          id="twoFactorAuth.cofig.switch"
          checked={isShared}
          onChange={onSwitchChange}
        />
      </div>
      {/*  조직도 공유할 때 */}
      {isShared && (
        <div className="flex flex-col p-[24px] border border-[#D8D8D8] rounded-lg gap-4">
          <div className="flex gap-[2px]">
            <p className="flex items-start w-[240px] min-h-[32px]">
              {t(
                'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.shareTarget',
              )}
            </p>
            <div className="flex flex-col justify-center flex-grow">
              <div className="flex felx-col pb-[8px] gap-6">
                <div className="flex items-center gap-2">
                  <input
                    className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                    type="radio"
                    id={'allShare'}
                    name="shareTarget"
                    value={'ALL'}
                    checked={targetConfig.shareTarget === 'ALL'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTargetConfig({
                          ...targetConfig,
                          shareTarget: 'ALL',
                        });
                      }
                    }}
                  />
                  <label htmlFor={'allShare'}>
                    {t(
                      'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.allShare',
                    )}
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                    type="radio"
                    id={'partialShare'}
                    name="shareTarget"
                    value={'TARGET_CLASS'}
                    checked={targetConfig.shareTarget === 'TARGET_CLASS'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setTargetConfig({
                          ...targetConfig,
                          shareTarget: 'TARGET_CLASS',
                        });
                      }
                    }}
                  />
                  <label htmlFor={'partialShare'}>
                    {t(
                      'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare',
                    )}
                  </label>
                </div>
              </div>
              {/* 일부만 공유일 때 */}
              {targetConfig.shareTarget === 'TARGET_CLASS' && (
                <div className="flex flex-col gap-2">
                  <p className="text-[--color-text-level3]">
                    {t(
                      'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.description',
                    )}
                  </p>
                  <div className="flex items-center gap-2">
                    <div className="flex items-center min-w-[200px] max-w-[360px]">
                      <select
                        className="w-full h-[32px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]"
                        value={selectedSiteId}
                        onChange={(e) => {
                          setSelectedSiteId(Number(e.target.value));
                          setSelectedClassName('USER');
                        }}
                      >
                        {companyList.map((item) => (
                          <option key={item.companyId} value={item.companyId}>
                            {item.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex items-center w-[120px] h-[32px]">
                      <select
                        className="h-[32px] w-[120px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]"
                        value={selectedClassName}
                        onChange={(e) => setSelectedClassName(e.target.value)}
                      >
                        {getClassList(t).map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {selectedClassName != 'USER' &&
                      selectedClassName != 'DEPT' && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <AdditionalClassSelect
                            selectedSiteId={selectedSiteId}
                            selectedClassName={selectedClassName}
                            selectedAdditionalClass={selectedAdditionalClass}
                            setSelectedAdditionalClass={
                              setSelectedAdditionalClass
                            }
                          />
                        </Suspense>
                      )}
                    <Button
                      className="flex justify-center items-center h-[32px] px-[8px] gap-1 border border-solid border-[#464646] rounded"
                      onClick={onClickAdd}
                    >
                      <PlusIcon width={16} height={16} />
                      {t(
                        'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.add',
                      )}
                    </Button>
                  </div>
                  <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen}>
                    <Dialog.Content size="resizeable">
                      <OrgChart
                        useResize={true}
                        style={{
                          top: '160px',
                          left: '60%',
                          bottom: '16px',
                          borderRadius: '16px',
                          minWidth: '360px',
                          maxWidth: '720px',
                        }}
                        onClose={() => setDialogOpen(false)}
                        onConfirm={onConfirmOnOrgChart}
                      />
                    </Dialog.Content>
                  </Dialog.Root>
                  <ul className="w-full min-h-[72px] max-h-[140px] px-[12px] py-[8px] border border-[#D8D8D8] rounded-lg overflow-y-auto">
                    {targetConfig.targetList.length !== 0 ? (
                      targetConfig.targetList.map((item) => (
                        <li
                          key={`${item.roleType}${item.companyId}${item.roleTypeId}`}
                          className="flex items-center w-full min-h-[32px] max-h-[32px]  h-[32px] pl-[12px] pr-[8px] gap-2"
                        >
                          <div className="flex-1 flex items-center gap-1">
                            <p className="text-[--color-text-level3] pr-[4px]">
                              {
                                getClassList(t).find(
                                  (classInfo) => classInfo.id === item.roleType,
                                )?.name
                              }
                              :
                            </p>
                            <p>
                              {
                                companyList.find(
                                  (companyInfo) =>
                                    companyInfo.companyId === item.companyId,
                                )?.companyName
                              }
                            </p>
                            <p>-</p>
                            <p>
                              {/* Todo : api연동이 잘 된다면 roleTypeId 삭제 */}
                              {item.roleTypeId}
                              {item.roleTypeName}
                            </p>
                          </div>
                          <Button
                            className="flex justify-center items-center p-[3px]"
                            onClick={() =>
                              onClickCancleTarget(
                                item.roleType,
                                item.companyId,
                                item.roleTypeId,
                              )
                            }
                          >
                            <XMarkIcon width={16} height={16} />
                          </Button>
                        </li>
                      ))
                    ) : (
                      <div className="flex items-center justify-center h-full text-[--color-text-level3]">
                        {t(
                          'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.partialShare.nodata',
                        )}
                      </div>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-[2px]">
            <p className="flex items-center w-[240px] min-h-[32px]">
              {t(
                'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.shareRange',
              )}
            </p>
            <div className="flex items-center py-[8px] gap-6">
              <div className="flex items-center gap-2">
                <input
                  className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                  type="radio"
                  id={'orgAndSearch'}
                  name="sharerRange"
                  value={'CHART_AND_SEARCH'}
                  checked={targetConfig.shareRange === 'CHART_AND_SEARCH'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTargetConfig({
                        ...targetConfig,
                        shareRange: 'CHART_AND_SEARCH',
                      });
                    }
                  }}
                />
                <label htmlFor={'orgAndSearch'}>
                  {t(
                    'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.orgAndSearch',
                  )}
                </label>
              </div>
              <div className="flex items-center gap-2">
                <input
                  className="size-[16px] border border-solid border-[#C5C5C5] bg-white checked:border-[#363636] checked:accent-[#363636]"
                  type="radio"
                  id={'onlySearch'}
                  name="sharerRange"
                  value={'SEARCH'}
                  checked={targetConfig.shareRange === 'SEARCH'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setTargetConfig({
                        ...targetConfig,
                        shareRange: 'SEARCH',
                      });
                    }
                  }}
                />
                <label htmlFor={'onlySearch'}>
                  {t(
                    'globalconfig.multiCompanyManagement.basicInfo.siteOrgShareSettings.orgSharing.onlySearch',
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-[50px] flex items-center justify-center gap-[8px]">
        <Button
          size="medium"
          shape="rect"
          variant="outline"
          colorset="level2"
          onClick={onClickCancle}
        >
          {t('dialog.cancel')}
        </Button>
        <Button
          size="medium"
          shape="rect"
          variant="solid"
          colorset="level1"
          disabled={!isConfigNeedsSave}
          onClick={onClickSave}
        >
          {t('dialog.save')}
        </Button>
      </div>
    </div>
  );
}

export default OrgShareConfig;
