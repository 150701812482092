import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { clsx } from 'clsx';
import { MobileAccessType } from '../../types';

export interface Props {
  type: MobileAccessType;
}

export function Tag({ type }: Props) {
  const { t } = useTranslation('component');
  const getTitle = (type: MobileAccessType) => {
    if (type === 'APPROVED') {
      return t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.approved',
      );
    } else if (type === 'REQUEST') {
      return t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.request',
      );
    } else {
      return t(
        'globalconfig.secureManagement.mobileDeviceAccessConfig.disallow',
      );
    }
  };

  return (
    <span
      className={clsx(
        'p-[6px] text-[12px] rounded-[4px] text-white font-medium leading-[12px] -tracking-[0.48px]',
        {
          'bg-[#999999]': type === 'APPROVED',
          'bg-[#49C689]': type === 'REQUEST',
          'bg-[#FF9B52]': type === 'REJECTED',
        },
      )}
    >
      {getTitle(type)}
    </span>
  );
}

export default Tag;
