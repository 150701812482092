import { gql } from 'graphql-request';
import { fetchGraphQL } from '../../../../../../lib/fetch/client';
import { MobileConfig, MobileConfigType } from '../types';

const mobileConfigQuery = (type: MobileConfigType) => gql`
  query {
    mobileConfig {
      ${type}
    }
  }
`;

interface ResponseBody {
  data: {
    mobileConfig: MobileConfig;
  };
}

export const QUERY_KEY = 'mobileConfigTypeQuery';

export async function getMobileConfigType(type: MobileConfigType) {
  try {
    const { data } = (await (
      await fetchGraphQL({ query: mobileConfigQuery(type) })
    ).json()) as ResponseBody;

    if (type === 'ATTACHED_FILE_DOWNLOAD')
      return data.mobileConfig.ATTACHED_FILE_DOWNLOAD;
    else if (type === 'ATTACHED_FILE_PREVIEW')
      return data.mobileConfig.ATTACHED_FILE_PREVIEW;
    else return data.mobileConfig.MOBILE_CAPTURE;
  } catch (error) {
    console.error(error);
  }
}
