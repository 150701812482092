import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * XMarkCircle (16x16) 아이콘
 * @param props
 * @returns
 */
export function XMarkCircleIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.781 11.2497L8.00012 8.46887L5.21899 11.25L4.51189 10.5429L7.29302 7.76177L4.41699 4.88574L5.1241 4.17864L8.00012 7.05466L10.8758 4.17893L11.583 4.88604L8.70723 7.76177L11.4881 10.5426L10.781 11.2497Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default XMarkCircleIcon;
