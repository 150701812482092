import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useContext } from '../provider';

export function AppContents() {
  const { t } = useTranslation('component');
  const { selectedAppCode, setSelectedAppCode } = useContext();

  const getTitle = () => {
    if (selectedAppCode === 'dop-board') {
      return t('globalconfig.multiCompanyManagement.appShare.board.title');
    } else if (selectedAppCode === 'dop-asset') {
      return t('globalconfig.multiCompanyManagement.appShare.asset.title');
    } else if (selectedAppCode === 'dop-calendar') {
      return t('globalconfig.multiCompanyManagement.appShare.calendar.title');
    } else {
      return t('globalconfig.multiCompanyManagement.appShare.approval.title');
    }
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <Button onClick={() => setSelectedAppCode(null)}>
          <ArrowLeftIcon className="size-6 storke-[#363636]" />
        </Button>
        <h2 className="text-[#363636] text-[20px] font-bold leading-[--font-multi-line-height-XLarge] tracking-[--font-letter-spacing-XLarge]">
          {getTitle()}
        </h2>
      </div>
    </>
  );
}

export default AppContents;
