import { useSuspenseQuery } from '@tanstack/react-query';
import * as getLinkPlusList from '../api/getLinkPlusList';
import AppList from './app-list';
import NoLinkPlusTemplate from './no-data-template/no-link-plus-template';

interface Props {
  scrollable: boolean;
}
function LinkPlusList({ scrollable }: Props) {
  const {
    data: { linkPlusList },
    error,
  } = useSuspenseQuery({
    queryKey: [getLinkPlusList.QUERY_KEY],
    queryFn: async () => await getLinkPlusList.getLinkPlusList(),
  });

  if (error) {
    console.error(error);
    return;
  }

  if (!linkPlusList || linkPlusList.length === 0) {
    return <NoLinkPlusTemplate />;
  }

  return (
    <AppList
      type="LINK_PLUS"
      appList={linkPlusList}
      scrollable={scrollable}
      offset={scrollable ? 20 : 5}
    />
  );
}

export default LinkPlusList;
