import { Avatar } from '@daouoffice/ui';
import * as Icon from '@daouoffice/ui/lib/icons/dop/24';
import { useContext } from './Context';
import { UserProfileInfo } from './types';
// import { GlobalConfigDialog } from '../GlobalConfig/GlobalConfigDialog';

import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import { useAuthContext } from '../../lib/auth/client/Context';

export interface Props {
  profileInfo: UserProfileInfo;
}

export function Card({ profileInfo }: Props) {
  const { cardOpenState, setCardOpenState } = useContext();
  const { logout } = useAuthContext();
  const closeHandler = () => {
    setCardOpenState(false);
  };

  return cardOpenState ? (
    <div className="layer card">
      <IconButton title="small" onClick={closeHandler}>
        <i className="icon ic_medium">
          <Icon.XMarkIcon />
        </i>
      </IconButton>
      <div className="user_profile">
        <div className="avatar_large">
          <Avatar
            size={64}
            alt={profileInfo.name}
            src={profileInfo.avatarImageURL}
            backgroundColor={profileInfo.color}
          />
        </div>
        <dl className="personal_info">
          <dt>
            <span className="txt blind">사용자 명</span>
          </dt>
          <dd>
            <span className="txt user_name">{profileInfo.name}</span>
            <span className="txt user_position">{profileInfo.gradeName}</span>
          </dd>
          <dt>
            <span className="txt blind">소속</span>
          </dt>
          {/* 여러 부서가 있는 경우, 각 부서를 개행시켜서 카드에 노출 */}
          {profileInfo.departmentNames.map((name) => (
            <dd key={name}>
              <span className="txt user_team">{name}</span>
            </dd>
          ))}
          <dt>
            <span className="txt blind">계정</span>
          </dt>
          <dd>
            <span className="txt user_account">{profileInfo.email}</span>
          </dd>
        </dl>
        <div className="group_btn">
          <Button
            title="환경 설정"
            className="btn_menu"
            onClick={() => window.alert('준비중인 기능 입니다.')}
          >
            <Icon.GearSettingsIcon viewBox="0 0 20 20" size={20} />
          </Button>
          {/* {profileInfo.roleType === 'admin' ? (
            <GlobalConfigDialog asChild>
              <button className="btn_menu">
                <Icon.SettingsIcon />
                <span>통합 설정</span>
              </button>
            </GlobalConfigDialog>
          ) : (
            <></>
          )} */}
          <Button
            title="로그아웃"
            className="btn_menu"
            onClick={() => logout()}
          >
            <Icon.PowerOnOffIcon viewBox="0 0 20 20" size={20} />
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
