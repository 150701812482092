import { Suspense } from 'react';
import { OrganizerClient } from './OrganizerClient';

export function Organizer() {
  return (
    <Suspense>
      <OrganizerClient />
    </Suspense>
  );
}

export default Organizer;
