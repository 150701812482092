import { Contents } from './components/contents';
import { DetailConfig } from './components/detail-config';
import { Root } from './components/root';

export function AdminLogConfig() {
  return (
    <Root>
      <Contents>
        <DetailConfig />
      </Contents>
    </Root>
  );
}

export default AdminLogConfig;
