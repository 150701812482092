import { fetch } from '../../../../../../lib/fetch/client';

interface Params {
  sync: boolean;
  companyGroupId?: string | number;
}

export async function updatePasswordSync({
  sync,
  companyGroupId,
}: Params): Promise<boolean | null> {
  if (!companyGroupId) return null;

  const response = await fetch(
    `/api/portal/setting/integration/password/config/${companyGroupId}/${sync}`,
    {
      method: 'PUT',
    },
  );

  const { data } = (await response.json()) as { data: boolean };

  return data;
}
