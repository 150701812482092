'use client';
import { useOrganizerValuesAtom } from '@daouoffice/next/components/Organizer/Provider';
import { clsx } from 'clsx';
import { ReactNode } from 'react';

export function DOPContainer({ children }: { children: ReactNode }) {
  const { organizerState } = useOrganizerValuesAtom();

  return (
    <div
      className={clsx('dop_root dop_container', {
        open_organizer: organizerState === 'panel',
        flip_organizer: organizerState === 'flip',
      })}
    >
      {children}
    </div>
  );
}
