import * as fetchClient from '@daouoffice/next/lib/fetch/client';
import type { IUserMenuInfo } from '../../types';

export async function updateUserMenus(
  menus: IUserMenuInfo[],
): Promise<boolean> {
  try {
    const pathUrl = `/api/portal/common/menu/user/EMPLOYEE/setting`;
    const settingList = menus.map(adapter).filter(Boolean);
    const payload = { settingList };
    const resp = await fetchClient.fetch(pathUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!resp.ok) {
      throw new Error('userMenusMutation > updateUserMenus 오류 발생.');
    }

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

interface IMenuSettingData {
  menuId: string | number;
  sortOrder: number;
  displayed: boolean;
}
function adapter(
  src: IUserMenuInfo,
  index: number,
): IMenuSettingData | undefined {
  return {
    menuId: src.uid,
    // sortOrder는 배열 인덱스를 기준으로 새로 업데이트해서 전송
    sortOrder: index + 1,
    displayed: !src.disabled,
  };
}
