import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { DeletedDeptInfo } from '../../../types';
import { formatBytes } from '../../../utils/byteFormatter';
import { useActionsContext } from '../../Provider';

export interface Props {
  contents: DeletedDeptInfo[];
}

export function DeletedDeptTable({ contents }: Props) {
  const { t } = useTranslation('component');
  const { setSelectedRows, setDeptId, setDeptName } = useActionsContext();

  const columnDefs: ColumnDef<DeletedDeptInfo>[] = [
    columnDefGenerator<DeletedDeptInfo>(
      'name',
      t('globalconfig.common.table.header.dept'),
    ),
    {
      accessorKey: 'attachSize',
      id: 'attachSize',
      header: () => (
        <div className="flex items-center px-5 py-3 text-left gap-1">
          {t('globalconfig.common.table.header.storageUsage')}
        </div>
      ),
      cell: (value: CellContext<DeletedDeptInfo, unknown>) => (
        <div className="px-5 py-3 text-left">
          {formatBytes(value.renderValue() as number)}
        </div>
      ),
    },
    columnDefGenerator<DeletedDeptInfo>(
      'deletedAt',
      t('globalconfig.common.table.header.deletedDate'),
    ),
    columnDefGenerator<DeletedDeptInfo>(
      'deleteAdminName',
      t('globalconfig.common.table.header.deletedName'),
    ),
  ];

  const rowClickHandler = (selectedRowIndex: number) => {
    const selectedDeptInfo = contents.at(selectedRowIndex);
    if (selectedDeptInfo) {
      setDeptId(selectedDeptInfo.deptId);
      setDeptName(selectedDeptInfo.name);
    }
  };

  return contents.length === 0 ? (
    <div className="flex items-center justify-center border rounded-lg w-full h-[256px] text-lg">
      {t('globalconfig.common.table.noData')}
    </div>
  ) : (
    <TablePrimitives.Root
      selectable={false}
      contents={contents}
      columnDefs={columnDefs}
      onSelectContents={setSelectedRows}
      onClickRow={rowClickHandler}
    >
      <TablePrimitives.Contents />
    </TablePrimitives.Root>
  );
}

export default DeletedDeptTable;
