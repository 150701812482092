import * as Dialog from '../../foundation/Dialog';

export { Root, type Props as RootProps } from './Root';
export { Content, type Props as ContentProps } from './Content';

// re-export from dialog
export const Trigger = Dialog.Trigger;
export type TriggerProps = Dialog.TriggerProps;

// 타입 re-export
export * from './types';
