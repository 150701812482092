import {
  BaseResponse,
  OrganizationCodeInfo,
  OrganizationCodeResponse,
} from '../type';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.BasicInfo.OrgCode';

const BASIC_INFO_ORG_CODE_URL = '/api/portal/setting/organization-code/list';

export async function getOrgCodeList(
  companyId: number,
  type: string,
): Promise<OrganizationCodeInfo[]> {
  try {
    const response = await fetch(
      BASIC_INFO_ORG_CODE_URL + '/' + companyId + '/' + type,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getOrgCodeList 오류 발생.');
    }

    const resData =
      (await response.json()) as BaseResponse<OrganizationCodeResponse>;

    // console.log('resData.data', resData.data);

    return resData.data.elements;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
