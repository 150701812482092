import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Pencil (16x16) 아이콘
 * @param props
 * @returns
 */
export function PencilIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.31295 3.31311C9.76034 2.86572 10.3671 2.61438 10.9998 2.61438C11.6325 2.61438 12.2393 2.86572 12.6867 3.31311C13.1341 3.7605 13.3855 4.36729 13.3855 5C13.3855 5.6327 13.1341 6.23949 12.6867 6.68688L12.0209 7.35272C12.0206 7.353 12.0203 7.35328 12.0201 7.35355C12.0198 7.35383 12.0195 7.35411 12.0192 7.35438L5.68672 13.6869C5.59296 13.7807 5.46578 13.8333 5.33317 13.8333H2.6665C2.39036 13.8333 2.1665 13.6095 2.1665 13.3333V10.6667C2.1665 10.5341 2.21918 10.4069 2.31295 10.3131L9.31295 3.31311ZM8.99984 5.04044L3.1665 10.8738V12.8333H5.12606L10.9594 7L8.99984 5.04044ZM11.6665 6.29289L9.70694 4.33333L10.0201 4.02022C10.2799 3.76036 10.6323 3.61438 10.9998 3.61438C11.3673 3.61438 11.7198 3.76036 11.9796 4.02022C12.2395 4.28007 12.3855 4.63251 12.3855 5C12.3855 5.36749 12.2395 5.71992 11.9796 5.97978L11.6665 6.29289Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default PencilIcon;
