import { clsx } from 'clsx';

interface Props {
  total: number;
  used: number;
  className?: string;
}

export function ProgressBar({ total, used, className }: Props) {
  const percentage = Math.round((used / total) * 100);

  return (
    <div className={clsx(className, 'h-[12px] bg-[#EAECF2] rounded-full')}>
      <div
        className="bg-gradient-to-r from-[#32DCE8] from-[18.54%] to-[#6EE488] to-[69.55%] h-[100%] rounded-full"
        style={{
          minWidth: '12px',
          width: `${percentage}%`,
        }}
      />
    </div>
  );
}
