import { fetch } from '../../../../../../lib/fetch/client';
import { APP_LIST_URL } from '../../constants';
import { AppInfo, ResponseBody } from '../types';

export const QUERY_KEY = 'appListQuery';

export async function getAppList() {
  try {
    const res = (await (
      await fetch(APP_LIST_URL)
    ).json()) as ResponseBody<AppInfo>;
    return res;
  } catch (error) {
    console.error(error);
  }
}
