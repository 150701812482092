import { clsx } from 'clsx';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { useShortcutContext } from '../Context';
import Button from '@dop-ui/react/shared/ui/button';

function TabList() {
  const { t } = useTranslation();

  const { tab, setTab } = useShortcutContext();
  const tabList = ['All', 'Works', 'LinkPlus'];

  return (
    <div className="flex gap-1">
      {tabList.map((tabName) => (
        <Button
          key={tabName}
          size="medium"
          variant="outline"
          colorset={tab === tabName ? 'level1' : 'level2'}
          shape="round"
          className={clsx({
            '!border-[#d8d8d8] !text-[#5b5b5b]': tab !== tabName,
          })}
          onClick={() => {
            setTab(tabName);
          }}
        >
          {t(`organizer.tab.${tabName.toLowerCase()}`)}
        </Button>
      ))}
    </div>
  );
}

export default TabList;
