import { Button } from '@daouoffice/ui';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { clsx } from 'clsx';
import { useState } from 'react';
import { AccessType } from '../../types';
import { useValuesContext } from '../Context';
import { DeviceList } from './DeviceList';

export function DetailConfig() {
  const { accessEnabled } = useValuesContext();
  const [accessType, setAccessType] = useState<AccessType>('APPROVED');
  const { t } = useTranslation('component');

  return (
    <>
      {accessEnabled && (
        <div className="flex flex-col gap-[34px] border rounded-lg border-dashed p-6">
          <span className="text-[14px] font-normal leading-normal -tracking-[0.28px] text-[#888]">
            {t(
              'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.description',
            )}
          </span>
          <div className="flex items-ceter gap-2">
            <Button
              className={clsx('border border-solid rounded-[40px] px-4 py-2', {
                'border-[#363636] text-[#363636] font-normal':
                  accessType === 'APPROVED',
                'border-[#D8D8D8] text-[#5B5B5B] font-semibold':
                  accessType !== 'APPROVED',
              })}
              title={t(
                'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.tab.allow',
              )}
              onClick={() => setAccessType('APPROVED')}
            />
            <Button
              className={clsx('border border-solid rounded-[40px] px-4 py-2', {
                'border-[#363636] text-[#363636] font-normal':
                  accessType === 'REQUEST',
                'border-[#D8D8D8] text-[#5B5B5B] font-semibold':
                  accessType !== 'REQUEST',
              })}
              title={t(
                'globalconfig.secureManagement.mobileDeviceAccessConfig.detail.tab.request',
              )}
              onClick={() => setAccessType('REQUEST')}
            />
          </div>
          <DeviceList type={accessType} />
        </div>
      )}
    </>
  );
}

export default DetailConfig;
