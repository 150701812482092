import { useOrganizerContext } from '../Context';
import { PanelState } from '../types';
import styles from '../organizer-body.module.css';
import PanelMenu from '../panel-button';

function DefaultMenuList() {
  const { defaultItems } = useOrganizerContext();
  const availableApps = defaultItems.filter((item) => item.name !== 'shortcut');
  return (
    <ul className={styles.group_link_list}>
      {availableApps.map((app) => (
        <PanelMenu
          key={app.name}
          id={app.name as PanelState}
          name={app.title}
          hasBackground={true}
        />
      ))}
    </ul>
  );
}

export default DefaultMenuList;
