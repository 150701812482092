import { BlockConfig } from './components/BlockConfig';
import { Contents } from './components/Contents';
import { DetailConfig } from './components/DetailConfig';
import { Root } from './components/Root';

export function MobileBlockConfig() {
  return (
    <Root>
      <Contents>
        <BlockConfig />
        <DetailConfig />
      </Contents>
    </Root>
  );
}

export default MobileBlockConfig;
