import type { Edge } from '../../../lib/dnd/types';
import type { CSSProperties } from 'react';

type Orientation = 'horizontal' | 'vertical';

const edgeToOrientationMap: Record<Edge, Orientation> = {
  top: 'horizontal',
  bottom: 'horizontal',
  left: 'vertical',
  right: 'vertical',
};

const orientationStyles: Record<Orientation, CSSProperties> = {
  horizontal: {
    height: 'var(--line-thickness)',
    left: 'var(--terminal-radius)',
    right: 0,
  },
  vertical: {
    width: 'var(--line-thickness)',
    top: 'var(--terminal-radius)',
    bottom: 0,
  },
};

const edgeStyles: Record<Edge, CSSProperties> = {
  top: { top: 'var(--line-offset)' },
  right: { right: 'var(--line-offset)' },
  bottom: { bottom: 'var(--line-offset)' },
  left: { left: 'var(--line-offset)' },
};

const strokeSize = 1.5;
const terminalSize = 8;

interface DropIndicatorProps {
  edge: Edge;
  gap: string;
  color?: string;
  showTerminal?: boolean;
  terminalColor?: string;
}

export function DropIndicator({
  edge,
  gap,
  color = '#007bff',
  showTerminal = false,
  terminalColor,
}: DropIndicatorProps) {
  const lineOffset = `calc(-0.5 * (${gap} + ${strokeSize}px))`;
  const orientation = edgeToOrientationMap[edge];
  const terminalColorValue = terminalColor || color;

  return (
    <div
      style={
        {
          '--line-thickness': `${strokeSize}px`,
          '--line-offset': `${lineOffset}`,
          '--terminal-size': `${terminalSize}px`,
          '--terminal-radius': `${terminalSize / 2}px`,
          '--terminal-position': `calc(50% - var(--terminal-size) / 2)`,
          '--color': color,
          '--terminal-color': terminalColorValue,
          backgroundColor: color,
          position: 'absolute',
          zIndex: 10,
          pointerEvents: 'none',
          ...orientationStyles[orientation],
          ...edgeStyles[edge],
        } as CSSProperties
      }
      className={`absolute z-10 ${showTerminal ? 'before:content-[""] before:w-[var(--terminal-size)] before:h-[var(--terminal-size)] before:border-[var(--line-thickness)] before:border-solid before:border-[var(--terminal-color)] before:rounded-full' : ''}`}
    >
      {showTerminal && (
        <div
          style={
            {
              width: 'var(--terminal-size)',
              height: 'var(--terminal-size)',
              borderWidth: 'var(--line-thickness)',
              borderStyle: 'solid',
              borderColor: terminalColorValue,
              borderRadius: '50%',
              position: 'absolute',
              top: `calc(50% - var(--terminal-size) / 2)`,
              backgroundColor: terminalColorValue,
            } as CSSProperties
          }
        ></div>
      )}
    </div>
  );
}
