import { Header } from './Header';
import { Root } from './Root';

export interface Props {}

// TODO: ServiceMap 컴포넌트는 추후 리팩토링. -> 현재 퍼블리싱한 컨텐츠만 보여주고 있음.
export function ServiceMap() {
  return (
    <Root>
      <Header />
      <div className="wrap_contents" id="test_state_use">
        <div className="group_columns">
          <div className="column">
            <ul className="group_menu_list">
              <li className="menu_list">
                <div className="menu">
                  <div className="menu_icon">
                    <i className="icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.3149 1.33514C10.1203 1.22162 9.87968 1.22162 9.68508 1.33514L2.5 5.52643L10 9.90143L17.5 5.52643L10.3149 1.33514Z"
                          fill="#525356"
                        />
                        <path
                          d="M18.125 6.60898L10.625 10.984V18.484L17.8149 14.2899C18.0069 14.1779 18.125 13.9723 18.125 13.75V6.60898Z"
                          fill="#525356"
                        />
                        <path
                          d="M9.375 18.484V10.984L1.875 6.60898V13.75C1.875 13.9723 1.99307 14.1779 2.18508 14.2899L9.375 18.484Z"
                          fill="#525356"
                        />
                      </svg>
                    </i>
                  </div>
                  <div className="wrap_menu_info">
                    <div className="menu_name">
                      <p className="title">기본관리</p>
                    </div>
                  </div>
                </div>

                <ul className="wrap_menu_list">
                  <li className="sub_menu_list">
                    <a className="sub_menu" href="/">
                      <span className="txt body_medium regular">
                        서비스 정보
                      </span>
                    </a>
                  </li>
                  <li className="sub_menu_list">
                    <a className="sub_menu" href="/">
                      <span className="txt body_medium regular">
                        서비스 App 관리
                      </span>
                    </a>
                  </li>
                  <li className="sub_menu_list">
                    <a className="sub_menu" href="/">
                      <span className="txt body_medium regular">
                        로고 및 테마
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="group_menu_list">
              <li className="menu_list">
                <a href="/" className="menu">
                  <div className="menu_icon">
                    <i className="icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1 2.75C1 2.33579 1.33579 2 1.75 2H12.25C12.6642 2 13 2.33579 13 2.75C13 3.16421 12.6642 3.5 12.25 3.5H12V17.25C12 17.6642 11.6642 18 11.25 18H9.75C9.33579 18 9 17.6642 9 17.25V14.75C9 14.3358 8.66421 14 8.25 14H5.75C5.33579 14 5 14.3358 5 14.75V17.25C5 17.6642 4.66421 18 4.25 18H1.75C1.33579 18 1 17.6642 1 17.25C1 16.8358 1.33579 16.5 1.75 16.5H2V3.5H1.75C1.33579 3.5 1 3.16421 1 2.75ZM4 5.5C4 5.22386 4.22386 5 4.5 5H5.5C5.77614 5 6 5.22386 6 5.5V6.5C6 6.77614 5.77614 7 5.5 7H4.5C4.22386 7 4 6.77614 4 6.5V5.5ZM4.5 9C4.22386 9 4 9.22386 4 9.5V10.5C4 10.7761 4.22386 11 4.5 11H5.5C5.77614 11 6 10.7761 6 10.5V9.5C6 9.22386 5.77614 9 5.5 9H4.5ZM8 5.5C8 5.22386 8.22386 5 8.5 5H9.5C9.77614 5 10 5.22386 10 5.5V6.5C10 6.77614 9.77614 7 9.5 7H8.5C8.22386 7 8 6.77614 8 6.5V5.5ZM8.5 9C8.22386 9 8 9.22386 8 9.5V10.5C8 10.7761 8.22386 11 8.5 11H9.5C9.77614 11 10 10.7761 10 10.5V9.5C10 9.22386 9.77614 9 9.5 9H8.5Z"
                          fill="#525356"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.25 6C13.8358 6 13.5 6.33579 13.5 6.75V17C13.5 17.5523 13.9477 18 14.5 18H18.25C18.6642 18 19 17.6642 19 17.25C19 16.8358 18.6642 16.5 18.25 16.5H18V7.5H18.25C18.6642 7.5 19 7.16421 19 6.75C19 6.33579 18.6642 6 18.25 6H14.25ZM14.75 9.5C14.75 9.22386 14.9739 9 15.25 9H16.25C16.5261 9 16.75 9.22386 16.75 9.5V10.5C16.75 10.7761 16.5261 11 16.25 11H15.25C14.9739 11 14.75 10.7761 14.75 10.5V9.5ZM15.25 13C14.9739 13 14.75 13.2239 14.75 13.5V14.5C14.75 14.7761 14.9739 15 15.25 15H16.25C16.5261 15 16.75 14.7761 16.75 14.5V13.5C16.75 13.2239 16.5261 13 16.25 13H15.25Z"
                          fill="#525356"
                        />
                      </svg>
                    </i>
                  </div>
                  <div className="wrap_menu_info">
                    <div className="menu_name">
                      <p className="title">멀티컴퍼니 관리</p>
                    </div>
                  </div>
                </a>
              </li>
              <li className="menu_list">
                <a href="/" className="menu">
                  <div className="menu_icon">
                    <i className="icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <ellipse
                          cx="10"
                          cy="5.58332"
                          rx="2.25001"
                          ry="2.25001"
                          fill="#525356"
                        />
                        <circle
                          cx="4.75001"
                          cy="14.75"
                          r="2.25001"
                          fill="#525356"
                        />
                        <circle cx="10" cy="14.75" r="2.25001" fill="#525356" />
                        <circle
                          cx="15.25"
                          cy="14.75"
                          r="2.25001"
                          fill="#525356"
                        />
                        <path
                          d="M5 13.3333V10H15V13.3333"
                          stroke="#525356"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 6.74402V13.119"
                          stroke="#525356"
                          strokeWidth="1.5"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                  </div>
                  <div className="wrap_menu_info">
                    <div className="menu_name">
                      <p className="title">조직 관리</p>
                    </div>
                  </div>
                  <i className="icon ic_goto">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.3">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.39961 4.39998C3.06824 4.39998 2.79961 4.6686 2.79961 4.99998V11.8C2.79961 12.1313 3.06824 12.4 3.39961 12.4H10.1996C10.531 12.4 10.7996 12.1313 10.7996 11.8V8.59998C10.7996 8.2686 11.0682 7.99998 11.3996 7.99998C11.731 7.99998 11.9996 8.2686 11.9996 8.59998V11.8C11.9996 12.7941 11.1937 13.6 10.1996 13.6H3.39961C2.4055 13.6 1.59961 12.7941 1.59961 11.8V4.99998C1.59961 4.00586 2.4055 3.19998 3.39961 3.19998H7.39961C7.73098 3.19998 7.99961 3.4686 7.99961 3.79998C7.99961 4.13135 7.73098 4.39998 7.39961 4.39998H3.39961Z"
                          fill="#0F172A"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.95469 10.2025C5.17701 10.4482 5.55643 10.4672 5.80216 10.2449L13.1996 3.55196V5.79998C13.1996 6.13135 13.4682 6.39998 13.7996 6.39998C14.131 6.39998 14.3996 6.13135 14.3996 5.79998V2.19998C14.3996 1.86861 14.131 1.59998 13.7996 1.59998H10.1996C9.86824 1.59998 9.59961 1.86861 9.59961 2.19998C9.59961 2.53135 9.86824 2.79998 10.1996 2.79998H12.2421L4.99706 9.35505C4.75134 9.57737 4.73237 9.9568 4.95469 10.2025Z"
                          fill="#0F172A"
                        />
                      </g>
                    </svg>
                  </i>
                </a>
              </li>
              <li className="menu_list">
                <a href="/" className="menu">
                  <div className="menu_icon">
                    <i className="icon">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.39961 5.59998C6.39961 3.39084 8.19047 1.59998 10.3996 1.59998C12.6087 1.59998 14.3996 3.39084 14.3996 5.59998C14.3996 7.80911 12.6087 9.59998 10.3996 9.59998C10.0137 9.59998 9.64062 9.54533 9.28758 9.44337L7.76529 10.9657C7.61527 11.1157 7.41178 11.2 7.19961 11.2H6.39961V12C6.39961 12.4418 6.04144 12.8 5.59961 12.8H4.79961V13.6C4.79961 14.0418 4.44144 14.4 3.99961 14.4H2.39961C1.95778 14.4 1.59961 14.0418 1.59961 13.6V12C1.59961 11.7878 1.68389 11.5843 1.83392 11.4343L6.55621 6.712C6.45425 6.35896 6.39961 5.98585 6.39961 5.59998ZM10.3996 3.04998C9.9854 3.04998 9.64961 3.38576 9.64961 3.79998C9.64961 4.21419 9.9854 4.54998 10.3996 4.54998C10.9795 4.54998 11.4496 5.02008 11.4496 5.59998C11.4496 6.01419 11.7854 6.34998 12.1996 6.34998C12.6138 6.34998 12.9496 6.01419 12.9496 5.59998C12.9496 4.19165 11.8079 3.04998 10.3996 3.04998Z"
                          fill="#525356"
                        />
                      </svg>
                    </i>
                  </div>
                  <div className="wrap_menu_info">
                    <div className="menu_name">
                      <p className="title">권한 관리</p>
                    </div>
                  </div>
                  <i className="icon ic_goto">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.3">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M3.39961 4.39998C3.06824 4.39998 2.79961 4.6686 2.79961 4.99998V11.8C2.79961 12.1313 3.06824 12.4 3.39961 12.4H10.1996C10.531 12.4 10.7996 12.1313 10.7996 11.8V8.59998C10.7996 8.2686 11.0682 7.99998 11.3996 7.99998C11.731 7.99998 11.9996 8.2686 11.9996 8.59998V11.8C11.9996 12.7941 11.1937 13.6 10.1996 13.6H3.39961C2.4055 13.6 1.59961 12.7941 1.59961 11.8V4.99998C1.59961 4.00586 2.4055 3.19998 3.39961 3.19998H7.39961C7.73098 3.19998 7.99961 3.4686 7.99961 3.79998C7.99961 4.13135 7.73098 4.39998 7.39961 4.39998H3.39961Z"
                          fill="#0F172A"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.95469 10.2025C5.17701 10.4482 5.55643 10.4672 5.80216 10.2449L13.1996 3.55196V5.79998C13.1996 6.13135 13.4682 6.39998 13.7996 6.39998C14.131 6.39998 14.3996 6.13135 14.3996 5.79998V2.19998C14.3996 1.86861 14.131 1.59998 13.7996 1.59998H10.1996C9.86824 1.59998 9.59961 1.86861 9.59961 2.19998C9.59961 2.53135 9.86824 2.79998 10.1996 2.79998H12.2421L4.99706 9.35505C4.75134 9.57737 4.73237 9.9568 4.95469 10.2025Z"
                          fill="#0F172A"
                        />
                      </g>
                    </svg>
                  </i>
                </a>
              </li>
            </ul>
            <ul className="group_menu_list">
              <li className="menu_list">
                <a href="/" className="menu">
                  <div className="menu_icon">
                    <i className="icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9.3"
                          stroke="#34374F"
                          strokeWidth="1.4"
                        />
                        <path
                          d="M8.83381 11.7933V11.6548C8.83736 11.0121 8.89773 10.5007 9.01491 10.1207C9.13565 9.74077 9.30611 9.43537 9.52628 9.20455C9.74645 8.97017 10.0146 8.75533 10.3306 8.56001C10.5472 8.42507 10.7408 8.2777 10.9112 8.1179C11.0852 7.95455 11.2219 7.77344 11.3214 7.57457C11.4208 7.37216 11.4705 7.14666 11.4705 6.89808C11.4705 6.61754 11.4048 6.37429 11.2734 6.16832C11.142 5.96236 10.9645 5.80256 10.7408 5.68892C10.5206 5.57528 10.2738 5.51847 10.0004 5.51847C9.74822 5.51847 9.50852 5.57351 9.28125 5.68359C9.05753 5.79013 8.87109 5.95348 8.72195 6.17365C8.57635 6.39027 8.49467 6.66548 8.47692 6.99929H6.58061C6.59837 6.32457 6.76172 5.75994 7.07067 5.3054C7.38317 4.85085 7.7951 4.50994 8.30646 4.28267C8.82138 4.0554 9.38956 3.94176 10.011 3.94176C10.6893 3.94176 11.2859 4.06072 11.8008 4.29865C12.3192 4.53658 12.7223 4.87571 13.0099 5.31605C13.3011 5.75284 13.4467 6.27131 13.4467 6.87145C13.4467 7.27628 13.381 7.63849 13.2496 7.9581C13.1218 8.2777 12.9389 8.56179 12.701 8.81037C12.4631 9.05895 12.1808 9.28089 11.854 9.47621C11.5664 9.65376 11.3303 9.83842 11.1456 10.0302C10.9645 10.2219 10.8295 10.4474 10.7408 10.7067C10.6555 10.9624 10.6112 11.2784 10.6076 11.6548V11.7933H8.83381ZM9.76065 15.1172C9.44105 15.1172 9.16584 15.0036 8.93501 14.7763C8.70419 14.549 8.58878 14.272 8.58878 13.9453C8.58878 13.6257 8.70419 13.3523 8.93501 13.125C9.16584 12.8977 9.44105 12.7841 9.76065 12.7841C10.0767 12.7841 10.3501 12.8977 10.581 13.125C10.8153 13.3523 10.9325 13.6257 10.9325 13.9453C10.9325 14.1619 10.8775 14.359 10.7674 14.5366C10.6609 14.7141 10.5188 14.8562 10.3413 14.9627C10.1673 15.0657 9.97372 15.1172 9.76065 15.1172Z"
                          fill="#34374F"
                        />
                      </svg>
                    </i>
                  </div>
                  <div className="wrap_menu_info">
                    <div className="menu_name">
                      <p className="title">도움말</p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="column">
            <ul className="group_menu_list">
              <li className="menu_list">
                <div className="menu">
                  <div className="menu_icon">
                    <i className="icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 1C13.866 1 17 2.79086 17 5C17 7.20914 13.866 9 10 9C6.13401 9 3 7.20914 3 5C3 2.79086 6.13401 1 10 1ZM15.694 9.13079C16.1576 8.86588 16.6044 8.54736 17 8.17775V10C17 12.2091 13.866 14 10 14C6.13401 14 3 12.2091 3 10V8.17775C3.3956 8.54736 3.84244 8.86588 4.30604 9.13079C5.83803 10.0062 7.85433 10.5 10 10.5C12.1457 10.5 14.162 10.0062 15.694 9.13079ZM3 13.1777V15C3 17.2091 6.13401 19 10 19C13.866 19 17 17.2091 17 15V13.1777C16.6044 13.5474 16.1576 13.8659 15.694 14.1308C14.162 15.0062 12.1457 15.5 10 15.5C7.85433 15.5 5.83803 15.0062 4.30604 14.1308C3.84244 13.8659 3.3956 13.5474 3 13.1777Z"
                          fill="#525356"
                        />
                      </svg>
                    </i>
                  </div>
                  <div className="wrap_menu_info">
                    <div className="menu_name">
                      <p className="title">데이터 관리</p>
                    </div>
                  </div>
                </div>

                <ul className="wrap_menu_list">
                  <li className="sub_menu_list">
                    <a className="sub_menu" href="/">
                      <span className="txt body_medium regular">용량 관리</span>
                    </a>
                  </li>
                  <li className="sub_menu_list">
                    <a className="sub_menu" href="/">
                      <span className="txt body_medium regular">
                        업로드 정책 관리
                      </span>
                    </a>
                  </li>
                  <li className="sub_menu_list">
                    <a className="sub_menu" href="/">
                      <span className="txt body_medium regular">
                        업로드 데이터 테마
                      </span>
                    </a>
                  </li>
                  <li className="sub_menu_list">
                    <a className="sub_menu" href="/">
                      <span className="txt body_medium regular">
                        부서자료 테마
                      </span>
                    </a>
                  </li>
                  <li className="sub_menu_list">
                    <a className="sub_menu" href="/">
                      <span className="txt body_medium regular">
                        데이터 백업
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="column">
            <ul className="group_menu_list">
              <li className="menu_list">
                <div className="menu">
                  <div className="menu_icon">
                    <i className="icon">
                      <svg
                        width="20"
                        height="24"
                        viewBox="0 0 20 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.3389 6.23657C10.1429 6.07381 9.85709 6.07381 9.66109 6.23657C7.72231 7.84643 5.2685 8.85748 2.58337 8.98601C2.34202 8.99756 2.13593 9.17189 2.10424 9.41144C2.03548 9.93125 2 10.4616 2 11.0002C2 16.1627 5.26004 20.5638 9.83378 22.2572C9.94102 22.2969 10.0593 22.2969 10.1665 22.2572C14.7401 20.5637 18 16.1627 18 11.0003C18 10.4616 17.9645 9.93129 17.8957 9.41143C17.8641 9.17189 17.658 8.99756 17.4166 8.98601C14.7315 8.85747 12.2777 7.84642 10.3389 6.23657ZM10 9.99994C10.4142 9.99994 10.75 10.3357 10.75 10.7499V14.2499C10.75 14.6642 10.4142 14.9999 10 14.9999C9.58579 14.9999 9.25 14.6642 9.25 14.2499L9.25 10.7499C9.25 10.3357 9.58579 9.99994 10 9.99994ZM10 18.9999C10.5523 18.9999 11 18.5522 11 17.9999C11 17.4477 10.5523 16.9999 10 16.9999C9.44772 16.9999 9 17.4477 9 17.9999C9 18.5522 9.44772 18.9999 10 18.9999Z"
                          fill="#525356"
                        />
                      </svg>
                    </i>
                  </div>
                  <div className="wrap_menu_info">
                    <div className="menu_name">
                      <p className="title">보안 관리</p>
                    </div>
                  </div>
                </div>

                <ul className="wrap_menu_list ">
                  <li className="sub_menu_list">
                    <div className="category">
                      <span className="txt body_medium semibold">
                        로그인 정책
                      </span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            로그인 차단 설정
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            자동 입력 방지 문자 설정
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list">
                    <div className="category">
                      <span className="txt body_medium semibold">
                        비밀번호 정책
                      </span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            비밀번호 정책
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            간편 비밀번호 관리
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list">
                    <div className="category">
                      <span className="txt body_medium semibold">
                        2차 인증 설정 및 관리
                      </span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            2차 인증 사용 포털 설정
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            2차 인증 로그인 제외 IP설정
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            2차 인증 오입력 리스트
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list">
                    <div className="category">
                      <span className="txt body_medium semibold">
                        App/기능 보안 설정
                      </span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            App 보안 설정
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            기능 보안 설정
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list">
                    <div className="category">
                      <span className="txt body_medium semibold">
                        모바일 접속 관리
                      </span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            모바일 기기 접근 관리
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            모바일 접속 차단(MAM)
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list">
                    <div className="category">
                      <span className="txt body_medium semibold">로그</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            멤버 접근 로그
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            관리자 로그
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="column app_list">
            <ul className="group_menu_list">
              <li className="menu_list">
                <div className="menu">
                  <div className="menu_icon">
                    <i className="icon">
                      <svg
                        width="20"
                        height="24"
                        viewBox="0 0 20 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.3389 6.23657C10.1429 6.07381 9.85709 6.07381 9.66109 6.23657C7.72231 7.84643 5.2685 8.85748 2.58337 8.98601C2.34202 8.99756 2.13593 9.17189 2.10424 9.41144C2.03548 9.93125 2 10.4616 2 11.0002C2 16.1627 5.26004 20.5638 9.83378 22.2572C9.94102 22.2969 10.0593 22.2969 10.1665 22.2572C14.7401 20.5637 18 16.1627 18 11.0003C18 10.4616 17.9645 9.93129 17.8957 9.41143C17.8641 9.17189 17.658 8.99756 17.4166 8.98601C14.7315 8.85747 12.2777 7.84642 10.3389 6.23657ZM10 9.99994C10.4142 9.99994 10.75 10.3357 10.75 10.7499V14.2499C10.75 14.6642 10.4142 14.9999 10 14.9999C9.58579 14.9999 9.25 14.6642 9.25 14.2499L9.25 10.7499C9.25 10.3357 9.58579 9.99994 10 9.99994ZM10 18.9999C10.5523 18.9999 11 18.5522 11 17.9999C11 17.4477 10.5523 16.9999 10 16.9999C9.44772 16.9999 9 17.4477 9 17.9999C9 18.5522 9.44772 18.9999 10 18.9999Z"
                          fill="#525356"
                        />
                      </svg>
                    </i>
                  </div>
                  <div className="wrap_menu_info">
                    <div className="menu_name">
                      <p className="title">App 관리</p>
                    </div>
                  </div>
                </div>

                <ul className="wrap_menu_list masonry-grid">
                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">전자결재</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            결재양식
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            자동 결재선
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전자 문서함
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            부서 문서함
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            공문 발송 관리
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            결재 관리자 설정
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            서명 일괄 등록
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전자결재 문서번호
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            결재문서 관리
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            보안등급 관리
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전자결재 일자별 통계
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전자결재 부서별 통계
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">보고</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            공용 양식
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            부서 보고서
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">자료실</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            자료실 기본 설정
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">Works</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            Works 기본 설정
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전체 앱 현황
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">
                        PC 메신저
                      </span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            PC 메신저 기본 관리
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">캘린더</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전사 캘린더
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전사 기념일 / 공휴일
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">Link +</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            Link+ 기본 설정
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">예약</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            자산 목록
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">인사</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            부서장 사원 정보 알림 권한
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">설문</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            설문 기본 설정
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전체 설문
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">매출입</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            거래 유형 설정
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">커뮤니티</span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            커뮤니티 기본 설정
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            개설신청 커뮤니티
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            전체 커뮤니티
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            커뮤니티 전체 게시판 통계
                          </span>
                        </a>
                      </li>
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            커뮤니티별 게시판 통계
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li className="sub_menu_list masonry-grid-item">
                    <div className="category">
                      <span className="txt body_medium semibold">
                        시스템 현황
                      </span>
                    </div>

                    <ul className="wrap_menu_list">
                      <li className="sub_menu_list">
                        <a className="sub_menu" href="/">
                          <span className="txt body_medium regular">
                            시스템 연동
                          </span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Root>
  );
}

export default ServiceMap;
