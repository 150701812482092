import { ErrorWithCode } from './type';

export class GatewayError extends ErrorWithCode {
  constructor(message: string, code: string) {
    super(message, code);
    this.name = 'ERR_GATEWAY_ERROR';
  }
}

export const GATEWAY_ERRORS = {
  STATUS_401_ERRORS: {
    ROUTE0001: 'ROUTE0001',
    ROUTE0002: 'ROUTE0002',
    ROUTE0004: 'ROUTE0004',
    ROUTE0005: 'ROUTE0005',
    ROUTE0006: 'ROUTE0006',
    ROUTE0007: 'ROUTE0007',
  },
};
