import { Option } from '../types';

export const searchParamGenerator = (options?: Option) => {
  const params = new URLSearchParams();
  if (options) {
    options.page && params.append('page', options.page.toString());
    options.offset && params.append('offset', options.offset.toString());
    options.property && params.append('property', options.property);
    options.direction && params.append('direction', options.direction);
    options.statusFilter && params.append('statusFilter', options.statusFilter);
  }
  return params.toString();
};
