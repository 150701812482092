import Fuse, { FuseResult } from 'fuse.js';

export interface FuseOptions {
  threshold: number;
  findAllMatches: boolean;
  shouldSort: boolean;
  includeMatches: boolean;
}

const defaultOptions: FuseOptions = {
  threshold: 0.3,
  findAllMatches: false,
  shouldSort: true,
  includeMatches: true,
};

export function keywordSearchedResult<T>(
  data: T[],
  searchKeyword: string,
  targetKeys: string[],
  options: FuseOptions = defaultOptions,
): T[] {
  const fuseOption = {
    ...options,
    keys: targetKeys,
  };

  const fuse = new Fuse(data, fuseOption);

  return fuse.search(searchKeyword).map((result) => {
    return result.item;
  });
}

export function keywordSearchedFuseResult<T>(
  data: T[],
  searchKeyword: string,
  targetKeys: string[],
  options: FuseOptions = defaultOptions,
): FuseResult<T>[] {
  const fuseOption = {
    ...options,
    keys: targetKeys,
  };

  const fuse = new Fuse(data, fuseOption);

  return fuse.search(searchKeyword);
}
