import { fetch } from '../../../../../../lib/fetch/client';
import { SECURE_MANAGEMENT_MOBILE_PREFIX } from '../../constants';
import { MobileAccessType } from '../types';

export const MUTATION_KEY = 'deleteMobileAccessRequestMutation';

export async function deleteMobileAccess(
  accessType: MobileAccessType,
  userIds: number[],
) {
  try {
    return await fetch(`${SECURE_MANAGEMENT_MOBILE_PREFIX}/${accessType}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: userIds,
      }),
    });
  } catch (error) {
    console.error(error);
  }
}
