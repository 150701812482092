import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { useOrganizerAtom } from '../../Provider';
import OrganizerLinkAdder from './organizer-link-adder';
import OrganizerAppAdder from './organizer-app-adder';
import styles from '../../organizer-panel.module.css';
import PanelHeader from '../panel-header';

export function OrganizerShortcut() {
  const { t } = useTranslation();

  const [, setOrganizerAtom] = useOrganizerAtom();

  const handleOnClose = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'default',
      panelState: 'none',
    }));
  };

  return (
    <div className={styles.Wrapper}>
      <PanelHeader
        title={t('organizer.shortcut')}
        onClickClose={handleOnClose}
      />
      <div className={clsx(styles.Content, '!py-6')}>
        <OrganizerLinkAdder />
        <hr className="m-0" />
        <OrganizerAppAdder />
      </div>
    </div>
  );
}

export default OrganizerShortcut;
