import PolicyConfig from './password-policy-config';
import styles from './password-policy-config.module.css';

import { PasswordReset } from './password-reset';
import { Suspense } from 'react';
export function PasswordPolicyConfig() {
  return (
    <div className={styles.Container}>
      <Suspense>
        <PolicyConfig />
      </Suspense>

      <PasswordReset />
    </div>
  );
}

export default PasswordPolicyConfig;
