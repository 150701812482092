import { StorageResponse, Storage } from '../types';

export function adaptStoragesResponse(response: StorageResponse): Storage {
  return {
    id: response.storageInfoId,
    code: response.storageCode,
    name: response.storageName,
    usage: response.usedQuota,
    icon: response.storageIcon,
  };
}
