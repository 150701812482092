import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Switch } from '@daouoffice/ui';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Templates, useToast } from '../../../../Toast';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';
import * as notiConfigApis from './apis/getNotificationConfig';

export function SetUsageNotification() {
  const { t } = useTranslation('component');
  const { data: configData, error } = useQuery({
    queryKey: [notiConfigApis.QUERY_KEY],
    queryFn: notiConfigApis.getNotificationConfig,
  });

  if (error) {
    console.error(
      'Error: GlobalConfig > SetUsageNotification, getNotiConfig : ',
      error,
    );
  }

  const defaultNotiStandard = 80;
  const defaultNotiMethod = 'NOTI_AND_MAIL';
  const selfInputOptionKey = 'standard.selfInput';

  const [notificationStandard, setNotificationStandard] =
    useState(defaultNotiStandard);

  const [notificationMethod, setNotificationMethod] =
    useState(defaultNotiMethod);

  const [
    notificationStandardSelectOption,
    setNotificationStandardSelectOption,
  ] = useState(defaultNotiStandard.toString());

  const [isCustomInputVisible, setIsCustomInputVisible] = useState(false);
  const [isDifferenceExist, setIsDifferenceExist] = useState(false);

  const setIsContentNeesSave = useSetAtom(isContentNeedsSaveAtom);

  const queryClient = useQueryClient();
  const toaster = useToast();

  const notiConfigMutation = useMutation({
    mutationFn: () =>
      notiConfigApis.updateNotificationConfig({
        notiCriteria: notificationStandard,
        notiMethod: notificationMethod,
      }),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [notiConfigApis.QUERY_KEY],
      });
      console.log(
        'Success: GlobalConfig > SetUsageNotification, updateNotiConfig',
      );
      setIsContentNeesSave(false);
      toaster.info(<Templates.Basic description="저장 성공" />);
    },
    onError: (error) =>
      console.error(
        'Error: GlobalConfig > SetUsageNotification, updateNotiConfig : ',
        error,
      ),
  });

  const applyConfigData = useCallback(() => {
    if (configData && configData.notiCriteria) {
      setNotificationStandard(configData.notiCriteria);
      if (
        configData.notiCriteria === 70 ||
        configData.notiCriteria === 80 ||
        configData.notiCriteria === 90
      ) {
        setNotificationStandardSelectOption(configData.notiCriteria.toString());
        setIsCustomInputVisible(false);
      } else {
        setNotificationStandardSelectOption(selfInputOptionKey);
        setIsCustomInputVisible(true);
      }
    } else {
      setNotificationStandard(defaultNotiStandard);
      setNotificationStandardSelectOption(defaultNotiStandard.toString());
    }
    setNotificationMethod(configData?.notiMethod ?? defaultNotiMethod);
  }, [configData]);

  useEffect(() => {
    applyConfigData();
  }, [applyConfigData]);

  useEffect(() => {
    if (
      configData &&
      (configData.notiCriteria !== notificationStandard ||
        configData.notiMethod !== notificationMethod)
    ) {
      setIsDifferenceExist(true);
      setIsContentNeesSave(true);
    } else {
      setIsDifferenceExist(false);
      setIsContentNeesSave(false);
    }
  }, [
    configData,
    notificationStandard,
    notificationMethod,
    setIsContentNeesSave,
  ]);

  const onClickSave = () => notiConfigMutation.mutate();

  const onClickCancel = () => {
    applyConfigData();
  };

  const onSelectStandard = (value: string) => {
    setNotificationStandardSelectOption(value);
    if (value === selfInputOptionKey) {
      setIsCustomInputVisible(true);
    } else {
      setNotificationStandard(Number(value));
      setIsCustomInputVisible(false);
    }
  };

  const onChangeStandard = (value: number) => {
    if (value < 1) {
      setNotificationStandard(1);
    } else if (value > 90) {
      setNotificationStandard(90);
    } else {
      setNotificationStandard(value);
    }
  };

  return (
    <div className="px-[72px] pt-[40px]">
      <div className="p-[40px] border border-solid border-[#DDDDDD] rounded-[8px]">
        <p className="text-[22px] text-[#333333] font-[500]">
          {t('globalconfig.dataManagement.setNotification.title')}
        </p>
        <div className="flex flex-wrap items-center mt-[24px] gap-[8px]">
          <span className="text-[16px] text-[#111111] font-[500] min-w-[160px]">
            {t('globalconfig.dataManagement.setNotification.standard.title')}
          </span>
          <select
            className="ml-[16px] px-[8px] h-[40px] rounded-[8px] min-w-[90px]"
            onChange={(e) => onSelectStandard(e.target.value)}
            value={notificationStandardSelectOption}
          >
            <option value={70}>70 %</option>
            <option value={80}>80 %</option>
            <option value={90}>90 %</option>
            <option value={selfInputOptionKey}>
              {t(
                'globalconfig.dataManagement.setNotification.standard.selfInput',
              )}
            </option>
          </select>
          {isCustomInputVisible && (
            <div className="flex items-center px-[8px] w-[60px] h-[40px] border border-solid border-[#D8D8D8] rounded-[8px]">
              <input
                className="w-full"
                value={notificationStandard}
                type="number"
                onChange={(e) => onChangeStandard(Number(e.target.value))}
              />
              %
            </div>
          )}
          <span className="text-[14px] text-[#333333] font-[400]">
            {t(
              'globalconfig.dataManagement.setNotification.standard.description',
            )}
          </span>
        </div>
        <div className="flex flex-wrap items-center mt-[8px] gap-[8px]">
          <span className="text-[16px] text-[#111111] font-[500] min-w-[160px]">
            {t('globalconfig.dataManagement.setNotification.method')}
          </span>
          <select
            className="flex-grow ml-[16px] px-[8px] h-[40px] rounded-[8px] min-w-[160px] max-w-[300px]"
            onChange={(e) => setNotificationMethod(e.target.value)}
            value={notificationMethod}
          >
            {configData &&
              configData.notiMethodList.map((method, index) => (
                <option key={`${method.key}${index}`} value={method.key}>
                  {method.value}
                </option>
              ))}
          </select>
        </div>
        <div className="w-full h-[1px] my-[24px] bg-[#E5E5E5]" />
        <div className="flex flex-wrap items-center mt-[8px] gap-[8px] justify-between">
          <span className="mr-[16px] text-[16px] text-[#111111] font-[500] min-w-[160px]">
            {t(
              'globalconfig.dataManagement.setNotification.overflowNoti.title',
            )}
          </span>
          <span className="text-[14px] text-[#AAAAAA] font-[400] flex-grow">
            {t(
              'globalconfig.dataManagement.setNotification.overflowNoti.description',
            )}
          </span>
          <Switch
            id="GlobalConfig.Datamanagement.UsageAlertSwitch"
            checked
            disabled
          />
        </div>
        <div className="mt-[24px] py-[16px] flex items-center justify-center gap-[8px]">
          <Button
            title={t('dialog.cancel')}
            colorset="minor"
            shape="rect"
            styleType="line"
            size="medium"
            onClick={onClickCancel}
          />
          <Button
            title={t('dialog.save')}
            colorset="major"
            shape="rect"
            styleType="solid"
            size="medium"
            disabled={!isDifferenceExist}
            onClick={onClickSave}
          />
        </div>
      </div>
    </div>
  );
}
