import { fetch } from '../../../../../../lib/fetch/client';
import { MOBILE_APP_ACCESS_LIST_URL } from '../../constants';

export const MUTATION_KEY = 'mobileAppBlockMutation';

export async function updateMobileAppBlockStatus(
  blockYn: boolean,
  mobileDeviceId: number,
) {
  try {
    return await fetch(`${MOBILE_APP_ACCESS_LIST_URL}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mobileDeviceId,
        blockYn,
      }),
    });
  } catch (error) {
    console.error(error);
  }
}
