import { useState } from 'react';
import {
  CharCountConfig,
  EditorContent,
  Link,
  Placeholder,
  StarterKit,
  TaskItem,
  TaskList,
  useEditor,
} from '@daouoffice/ui/lib/foundation/Editor';
import './memo-editor.css';

import { clsx } from 'clsx';
import { useMemoContext } from '../Context';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import MemoToolbar from './edit-menus/memo-toolbar';
import FormattingToolbar from './edit-menus/formmating-toolbar';
import { MemoColorClassNames } from '../constants';
import memoStyles from '../../Memo/memo.module.css';
import { MemoToolbarType } from '../types';
import ColorToolbar from './edit-menus/color-toolbar';
import FavoriteButton from '../memo-list/favorite-button';

function MemoEditor() {
  const {
    memoContext: { memoId, content, textContent, isFavorite, color },
    setMemoContext,
  } = useMemoContext();

  const { t } = useTranslation();

  const [toolbarShow, setToolbarShow] = useState<MemoToolbarType>('default');

  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          heading: false,
        }),
        Placeholder.configure({
          placeholder: t('organizer.memo.empty'),
        }),
        TaskItem.configure({ HTMLAttributes: { class: 'task-list-item' } }),
        TaskList,
        Link.configure({
          autolink: true,
          openOnClick: true,
          HTMLAttributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        }),
        CharCountConfig({ limit: 2000 }),
      ],
      autofocus: true,

      editorProps: {
        attributes: {
          class: 'focus:outline-none',
        },
      },
      content,
      onCreate({ editor }) {
        if (!textContent) {
          setMemoContext((prev) => ({
            ...prev,
            textContent: editor.getText(),
          }));
        }
      },
      onUpdate({ editor }) {
        let updatedContent = editor.getHTML();
        let updatedTextContent = editor.getText();
        if (editor.isEmpty) {
          updatedContent = '';
          updatedTextContent = '';
        }
        setMemoContext((prev) => ({
          ...prev,
          content: updatedContent,
          textContent: updatedTextContent,
          requiredSave: true,
        }));
      },
      immediatelyRender: false,
    },
    [memoId],
  );

  const getCharacterCount = () => {
    return (
      editor?.storage.characterCount as { characters: () => number }
    )?.characters();
  };

  return (
    <>
      <div
        className={clsx('flex relative py-1 border-y border-[#F2F2F2] rounded')}
      >
        <div
          className={`transition-opacity duration-300 ${
            toolbarShow === 'default'
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }`}
        >
          <MemoToolbar editor={editor} setToolbarShow={setToolbarShow} />
        </div>
        <div
          className={`absolute top-1 left-0 w-full transition-opacity duration-300 ${
            toolbarShow === 'format'
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }`}
        >
          <FormattingToolbar editor={editor} setToolbarShow={setToolbarShow} />
        </div>
        <div
          className={`absolute top-1 left-0 w-full transition-opacity duration-300 ${
            toolbarShow === 'color'
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }`}
        >
          <ColorToolbar editor={editor} setToolbarShow={setToolbarShow} />
        </div>
      </div>
      <div
        className={clsx(
          memoStyles.MemoItem,
          MemoColorClassNames[color],
          'gap-2',
        )}
      >
        <EditorContent
          onClick={() => editor?.commands.focus()}
          spellCheck="false"
          className={clsx(
            'memo-editor-container is-edit',
            memoStyles.MemoScrollItem,
          )}
          editor={editor}
        />

        <div className="flex justify-between items-center h-[20px] py-1">
          <div className="text-[12px] font-[#5B5B5B]">{`${getCharacterCount()} /2,000${t('organizer.memo.word.count')}`}</div>

          {memoId && (
            <FavoriteButton
              memoId={memoId}
              isFavorite={isFavorite}
              className={clsx(
                memoStyles.FavoriteBtn,
                isFavorite && memoStyles.IsFavorite,
              )}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MemoEditor;
