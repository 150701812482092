import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { BASIC_SERVICE_INFO_API } from '../../constants';
import { BaseResponse } from '../../types';
import { BasicServiceInfo } from '../types';

export const QUERY_KEY = 'GlobalConfig.BasicManagement.ServiceInfo';

export async function getBasicServiceInfo() {
  const response = await fetch(BASIC_SERVICE_INFO_API, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });
  const responseData =
    (await response.json()) as BaseResponse<BasicServiceInfo>;
  return responseData.data;
}
