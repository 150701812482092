import { FilterOption, FilterType } from '../../types';
import { Content } from './Content';
import { Provider } from './Context';

/**
 * @description Filter 컴포넌트의 Props
 * @property title 필터 타이틀
 * @property filterKey 필터 키
 * @property filterType 필터 타입 (* date 타입일 경우 options는 사용하지 않고 자체적으로 처리함)
 * @property options 필터 옵션
 */
export interface Props {
  className?: string;
  title: string;
  filterKey: string;
  filterType: FilterType;
  options: FilterOption[];
}

/**
 * @description Filter 컴포넌트의 틀
 * @prop Props
 */
export function Root({ ...props }: Props) {
  return (
    <Provider>
      <Content {...props} />
    </Provider>
  );
}
