import { useAtomValue } from 'jotai';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from '@dop-ui/react/shared/ui/button';
import { StarIcon as FavoriteStarIcon } from '@heroicons/react/24/solid';
import { StarIcon as NotFavoriteStarIcon } from '@heroicons/react/24/outline';
import { favoriteMenuAtom } from '../../../store/favoriteMenuAtom';
import { currentSelectedMenuInfoAtom } from '../../../store/currentSelectedMenuInfoAtom';
import {
  addFavoriteMenuInfo,
  deleteFavoriteMenuInfo,
  QUERY_KEY,
} from '../../Menu/apis/favoriteMenuInfos';

export function FavoriteButton() {
  const favoriteMenuInfos = useAtomValue(favoriteMenuAtom);
  const currentMenuInfo = useAtomValue(currentSelectedMenuInfoAtom);
  const queryClient = useQueryClient();

  const currentFavoriteMenuInfo = favoriteMenuInfos?.find(
    (info) => info.menuPath === currentMenuInfo.menuPath,
  );

  const deleteFavoriteMenu = useMutation({
    mutationFn: deleteFavoriteMenuInfo,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const addFavoriteMenu = useMutation({
    mutationFn: addFavoriteMenuInfo,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  if (favoriteMenuInfos && currentMenuInfo && currentFavoriteMenuInfo)
    return (
      <Button
        onClick={() =>
          deleteFavoriteMenu.mutate(currentFavoriteMenuInfo.id ?? -1)
        }
      >
        <FavoriteStarIcon width={24} height={24} color="#D9DD45" />
      </Button>
    );
  else if (currentMenuInfo) {
    return (
      <Button onClick={() => addFavoriteMenu.mutate(currentMenuInfo)}>
        <NotFavoriteStarIcon width={24} height={24} color="#363636" />
      </Button>
    );
  } else {
    return null;
  }
}

export default FavoriteButton;
