/**
 * @param bytes
 * @param decimals
 * @returns
 */
export const formatBytes = (bytes: number, decimal = 0) => {
  const k = 1024.0;
  const dim = decimal > 0 ? decimal : 0;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  /**
   * size = byte(1024)^n
   *  -> log(size) = n*log(byte)
   *  -> n = log(size) / log(byte)
   */
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const unit = k ** i;

  return bytes === 0
    ? '0 Bytes'
    : `${new Intl.NumberFormat().format(Number((bytes / unit).toFixed(dim)))} ${sizes[i]}`;
};
