import { fetch } from '../../../../../../lib/fetch/client';
import { COMPANY_DATA_USAGE_API } from '../../constants';
import { BaseResponse } from '../../types';
import { CompanyDataUsage, StorageResponse } from '../types';
import { adaptStoragesResponse } from '../utils/stroageAdaptor';

interface CompanyDataUsageResponse {
  companyId: string;
  companyQuota: number;
  companyUsedQuota: number;
  companyAvailableQuota: number;
  highStorageUsageAppList: StorageResponse[];
}

export const COMPANY_DATA_USAGE_KEY =
  'QueryKeys.GlobalConfig.DataManagement.CompanyDataUsage';

export async function getCompanyDataStatus(): Promise<CompanyDataUsage> {
  const apiURL = `${COMPANY_DATA_USAGE_API}?usageTopCount=3`;

  const response = await fetch(apiURL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<CompanyDataUsageResponse>;

  return adaptor(responseData.data);
}

function adaptor(response: CompanyDataUsageResponse): CompanyDataUsage {
  return {
    companyId: response.companyId,
    companyQuota: response.companyQuota,
    companyUsedQuota: response.companyUsedQuota,
    companyAvailableQuota: response.companyAvailableQuota,
    highStorageUsageAppList: response.highStorageUsageAppList
      ? response.highStorageUsageAppList.map(adaptStoragesResponse)
      : [],
  };
}
