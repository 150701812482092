import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import * as passwordSyncQueries from '../apis/get-password-sync';
import { updatePasswordSync } from '../apis/update-password-sync';

import { Switch } from '@daouoffice/ui';
import { useAuthContext } from '../../../../../../lib/auth/client';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';

import {
  useToastMessage,
  Templates,
} from '@dop-ui/react/features/toast-message';
import { TRANSLATION_PREFIX } from '../constants';

function PasswordSyncSwitch() {
  const { t } = useTranslation('component');
  const { info } = useToastMessage();

  const { me } = useAuthContext();
  const queryClient = useQueryClient();
  const companyGroupId = me?.companyGroupId;
  const response = useSuspenseQuery({
    queryKey: [passwordSyncQueries.QUERY_KEY],
    queryFn: async () =>
      await passwordSyncQueries.getMultiMemberList(companyGroupId),
  });

  const updatePasswordSyncMutation = useMutation({
    mutationFn: updatePasswordSync,
    onSuccess: () => {
      info(<Templates.Basic description={t(`${TRANSLATION_PREFIX}.saved`)} />);
      void queryClient.invalidateQueries({
        queryKey: [passwordSyncQueries.QUERY_KEY],
      });
    },
    onError: () => {
      info(<Templates.Basic description={t(`${TRANSLATION_PREFIX}.error`)} />);
    },
  });
  const { data } = response;

  const handleOnCheck = (value: boolean) => {
    updatePasswordSyncMutation.mutate({ companyGroupId, sync: value });
  };

  if (data === null) {
    return null;
  }

  return (
    <Switch
      className="h-5"
      id="password-sync"
      checked={data}
      onChange={(val) => handleOnCheck(val)}
    />
  );
}

export default PasswordSyncSwitch;
