import { DATA_MANAGEMENT } from '../main/menuPaths';

const menuKeyPath = DATA_MANAGEMENT;

export const USAGE_PATH = menuKeyPath + '/usage';
export const USAGE_CURRENT_PATH = USAGE_PATH + '/current';
export const USAGE_REQUEST_PATH = USAGE_PATH + '/request';
export const USAGE_NOTIFICATION_PATH = USAGE_PATH + '/notification';

export const UPLOAD_POLICY_PATH = menuKeyPath + '/upload-policy';
export const UPLOAD_POLICY_MANAGE_PATH = UPLOAD_POLICY_PATH + '/manage';

export const UPLOAD_DATA_PATH = menuKeyPath + '/upload-data';
export const UPLOAD_DATA_MANAGE_PATH = UPLOAD_DATA_PATH + '/manage';

export const DEPTS_PATH = menuKeyPath + '/depts';
export const DEPTS_DATA_PATH = DEPTS_PATH + '/data';
export const DEPTS_DELETE_PATH = DEPTS_PATH + '/delete';

export const MEMBER_PATH = menuKeyPath + '/member';
export const MEMBER_DATA_PATH = MEMBER_PATH + '/data';

export const BACKUP_PATH = menuKeyPath + '/backup';
export const BACKUP_DATA_PATH = BACKUP_PATH + '/data';
