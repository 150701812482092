import * as React from 'react';

export interface IContext {
  searchKeyword: string;
  openNodes: Set<string>;
  inSearchCloseNodes: Set<string>;
  selectedNode: string;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
  setOpenNodes: React.Dispatch<React.SetStateAction<Set<string>>>;
  setInSearchCloseNodes: React.Dispatch<React.SetStateAction<Set<string>>>;
  setSelectedNode: React.Dispatch<React.SetStateAction<string>>;
}

const Context = React.createContext<IContext>({
  searchKeyword: '',
  openNodes: new Set(),
  inSearchCloseNodes: new Set(),
  selectedNode: '',
  setSearchKeyword: () => undefined,
  setOpenNodes: () => undefined,
  setInSearchCloseNodes: () => undefined,
  setSelectedNode: () => undefined,
});

export function Provider({ children }: { children: React.ReactNode }) {
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [openNodes, setOpenNodes] = React.useState<Set<string>>(new Set());
  const [inSearchCloseNodes, setInSearchCloseNodes] = React.useState<
    Set<string>
  >(new Set());
  const [selectedNode, setSelectedNode] = React.useState('-1');

  const contextValues: IContext = {
    searchKeyword,
    openNodes,
    inSearchCloseNodes,
    selectedNode,
    setSearchKeyword,
    setOpenNodes,
    setInSearchCloseNodes,
    setSelectedNode,
  };

  return <Context.Provider value={contextValues}>{children}</Context.Provider>;
}

export const useContextValues = () => React.useContext(Context);
