import { Button } from '@daouoffice/ui';
import { AddStorageIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import * as Dialog from '@radix-ui/react-dialog';
import { PropsWithChildren, useEffect, useState } from 'react';
import { FileSizeType, MemberDataInfo } from '../../../types';
import { gbToBytes, mbToBytes } from '../../../utils/byteFormatter';
import { useValuesContext } from '../Provider';
import { CloseButton } from './CloseButton';
import { OverView } from './OverView';
import { StorageField } from './StorageFiled';
import { Table } from './Table';

export interface Props {
  availableStorage: number;
}

export function StorageSettingsDialog({
  availableStorage,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation('component');
  const { selectedRows } = useValuesContext();
  const [mailStorage, setMailStorage] = useState<number>(0);
  const [folderStorage, setFolderStorage] = useState<number>(0);
  const [contents, setContents] = useState<MemberDataInfo[]>([]);
  const [openState, setOpenState] = useState(false);

  // 일괄 설정 위한 값
  const [batchMailConfigValue, setBatchMailConfigValue] = useState<number>();
  const [batchFolderConfigValue, setBatchFolderConfigValue] =
    useState<number>();
  const [batchMailSizeType, setBatchMailSizeType] =
    useState<FileSizeType>('MB');
  const [batchFolderSizeType, setBatchFolderSizeType] =
    useState<FileSizeType>('MB');

  const contentsChangeHandler = (index: number, value: number, key: string) => {
    if (key === 'mailStorage') {
      setContents((prev) => {
        const newContents = [...prev].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              mailStorage: {
                ...item.mailStorage,
                size: value,
              },
            };
          } else {
            return item;
          }
        });
        return newContents;
      });
    } else {
      setContents((prev) => {
        const newContents = [...prev].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              folderStorage: {
                ...item.folderStorage,
                size: value,
              },
            };
          } else {
            return item;
          }
        });
        return newContents;
      });
    }
  };

  useEffect(() => {
    setContents(selectedRows);
    setBatchMailConfigValue(undefined);
    setBatchFolderConfigValue(undefined);
  }, [setContents, selectedRows, openState]);

  useEffect(() => {
    let mailSum = 0;
    let folderSum = 0;

    contents.forEach((item) => {
      mailSum +=
        item.mailStorage?.sizeType === 'GB'
          ? gbToBytes(item.mailStorage?.size ?? 0)
          : mbToBytes(item.mailStorage?.size ?? 0);
      folderSum +=
        item.folderStorage?.sizeType === 'GB'
          ? gbToBytes(item.folderStorage?.size ?? 0)
          : mbToBytes(item.folderStorage?.size ?? 0);
    });

    setMailStorage(mailSum);
    setFolderStorage(folderSum);
  }, [contents, setMailStorage, setFolderStorage]);

  const onAdapterHandler = () => {
    // TODO : 용량 추가 API 호출 전, Validation 체크
    setContents((prev) => {
      return prev.map((item) => {
        return {
          ...item,
          mailStorage: {
            size: batchMailConfigValue,
            sizeType: batchMailSizeType,
          },
          folderStorage: {
            size: batchFolderConfigValue,
            sizeType: batchFolderSizeType,
          },
        };
      });
    });
  };

  const onSaveHandler = () => {
    // TODO : 용량 추가 API 호출 전, Validation 체크
    setOpenState(false);
  };

  return (
    <Dialog.Root open={openState} onOpenChange={setOpenState}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <div className="size-screen bg-gray-400 z-[1000]" />
        <Dialog.Overlay className="fixed inset-0 bg-[rgba(0,0,0,0.5)] z-[999]" />
        <Dialog.Content className="gap-4 z-[999] fixed top-[50%] left-[50%] min-h-[755px] min-w-[900px] translate-x-[-50%] translate-y-[-50%] rounded-[16px] bg-white p-[32px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="m-0 text-[20px] font-medium -tracking-[0.8px] leading-[30px]">
            {t('globalconfig.dataManagement.memberManagement.dialog.title')}
          </Dialog.Title>
          <CloseButton />
          <div className="flex gap-5 items-center mb-8">
            <StorageField
              title={t(
                'globalconfig.dataManagement.memberManagement.dialog.addmailstorage',
              )}
              defaultValue={batchMailConfigValue}
              onValueChange={setBatchMailConfigValue}
              fileSizeType={batchMailSizeType}
              onFileSizeTypeChange={setBatchMailSizeType}
            />
            <StorageField
              title={t(
                'globalconfig.dataManagement.memberManagement.dialog.addfolderstorage',
              )}
              defaultValue={batchFolderConfigValue}
              onValueChange={setBatchFolderConfigValue}
              fileSizeType={batchFolderSizeType}
              onFileSizeTypeChange={setBatchFolderSizeType}
              maxSize={10240}
            />
            <Button
              styleType="line"
              colorset="major"
              title={t(
                'globalconfig.dataManagement.memberManagement.dialog.adapt',
              )}
              className="flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center border-[#DBDBDB] text-[#383838]"
              onClick={onAdapterHandler}
            >
              <AddStorageIcon className="relative top size-5 top-[4px] stroke-[#383838]" />
            </Button>
          </div>
          <Table
            contents={contents}
            onChangeContentsByIndex={contentsChangeHandler}
          />
          <OverView
            availableStorage={availableStorage}
            allocatedStorage={mailStorage + folderStorage}
          />
          <div className="flex w-full gap-4 justify-end mt-10">
            <Dialog.Close>
              <Button
                className="px-4 py-2 flex justify-center items-center text-black bg-white"
                styleType="line"
                title={t('dialog.cancel')}
              />
            </Dialog.Close>
            <Dialog.Close>
              <Button
                className="px-4 py-2 flex justify-center items-center text-white bg-black rounded-lg border-solid"
                styleType="solid"
                title={t('dialog.save')}
                onClick={onSaveHandler}
              />
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default StorageSettingsDialog;
