import { MOBILE_ACCESS_REQUEST_PERMIT_LIST_URL } from '../../constants';
import { BaseResponse, MobileAccessType, MobileInfo } from '../types';

export const QUERY_KEY = 'mobileDeviceAccessListQuery';

export async function getMobileDeviceAccessStatus(
  status: MobileAccessType,
  queryString: string,
) {
  try {
    const res = (await (
      await fetch(
        `${MOBILE_ACCESS_REQUEST_PERMIT_LIST_URL}/${status}?${queryString}`,
      )
    ).json()) as BaseResponse<MobileInfo>;

    return res;
  } catch (error) {
    console.error(error);
  }
}
