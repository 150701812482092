import { fetch } from '../../../../../../lib/fetch/client';
import { SECURITY_SETTINGS_URL } from '../../constants';

export const MUTATION_KEY = 'mobileAppAccessMutation';

export async function updateMobileAppAccessStatus(isBlocked: boolean) {
  try {
    return await fetch(SECURITY_SETTINGS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        configType: 'AUTH',
        configName: 'USE_MOBILE_APP_SERVICE',
        configValue: isBlocked,
      }),
    });
  } catch (error) {
    console.error(error);
  }
}
