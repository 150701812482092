import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';

import ConfigItem from './config-item';
import Button from '@dop-ui/react/shared/ui/button';
import { TRANSLATE_PREFIX } from './constants';
import styles from './password-policy-config.module.css';

import * as Tooltip from '@dop-ui/react/shared/ui/tooltip';
import { useState } from 'react';
import { QuestionCircleIcon } from '@daouoffice/ui/lib/icons/dop/16';
export function PasswordReset() {
  const { t } = useTranslation('component');
  const { alert } = useMessageDialog();

  const handleOnClickReset = async () => {
    await alert(t(`${TRANSLATE_PREFIX}notReady`));
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipItem = (
    <Tooltip.Root open={tooltipOpen} onOpenChange={setTooltipOpen}>
      <Tooltip.Trigger className={styles.Tooltip}>
        <QuestionCircleIcon size={16} />
      </Tooltip.Trigger>
      <Tooltip.Content className="!min-w-[550px]">
        {t(`${TRANSLATE_PREFIX}reset.confirm`)}
      </Tooltip.Content>
    </Tooltip.Root>
  );
  return (
    <div className={styles.Card}>
      <p className={styles.CardTitle}>{t(`${TRANSLATE_PREFIX}reset.title`)}</p>

      <div className={styles.ContentList}>
        <ConfigItem
          title={t(`${TRANSLATE_PREFIX}reset.title`)}
          description={t(`${TRANSLATE_PREFIX}reset.description`)}
          subDescription={t(`${TRANSLATE_PREFIX}reset.lastReset`)}
          tooltip={tooltipItem}
        >
          <Button
            styleType="line"
            size="medium"
            onClick={() => void handleOnClickReset()}
          >
            {t(`${TRANSLATE_PREFIX}reset`)}
          </Button>
        </ConfigItem>
      </div>
    </div>
  );
}
