'use client';
import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
  useContext,
} from 'react';

export type TContextValue = Record<string, string>;

export interface IContextValues {
  readonly tab: string;
}

export interface IContextActions {
  setTab: Dispatch<SetStateAction<string>>;
}

export const ValuesContext = createContext<IContextValues>({
  tab: '',
});

export const ActionsContext = createContext<IContextActions>({
  setTab: () => undefined,
});

export const Context = createContext<TContextValue>({});

export function Provider({ children }: PropsWithChildren) {
  const [tab, setTab] = useState('All');

  const ctxValues = {
    tab,
  };

  const ctxActions = {
    setTab,
  };

  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => useContext(ValuesContext);
export const useActionsContext = () => useContext(ActionsContext);

export const useShortcutContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
