import {
  AllEvents,
  ElementDragType,
  Input,
  NativeMediaType,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

/**
 * DraggableGetFeedbackArgs 타입 정의
 * 드래그 시작 시 사용자 입력과 관련된 정보를 포함
 */
type DraggableGetFeedbackArgs = {
  /**
   * 드래그가 시작될 때의 사용자 입력 (initial input)
   */
  input: Input;
  /**
   * `draggable` 요소
   */
  element: HTMLElement;
  /**
   * `draggable` 요소에 대한 `dragHandle` 요소
   */
  dragHandle: Element | null;
};

/**
 * DraggableArgs 타입 정의
 * draggable 요소의 설정을 포함
 * https://atlassian.design/components/pragmatic-drag-and-drop/core-package/adapters/element/about#draggable
 */
type DraggableArgs = {
  /**
   * draggable이 될 요소
   * @property {HTMLElement} element - 드래그 가능한 HTML 요소
   */
  element: HTMLElement;

  /**
   * 전체를 드래그하는 데에 사용할 수 있는 요소
   * @property {Element} [dragHandle] - 드래그 핸들로 사용할 요소 (선택 사항)
   */
  dragHandle?: Element;

  /**
   * 드래그를 조건부로 허용하는 함수
   * @param {DraggableGetFeedbackArgs} args - 드래그 시작 시 전달되는 인수
   * @returns {boolean} - 드래그 허용 여부
   * @property {(args: DraggableGetFeedbackArgs) => boolean} [canDrag] - 드래그가 가능한지 여부를 결정하는 함수 (선택 사항)
   */
  canDrag?: (args: DraggableGetFeedbackArgs) => boolean;

  /**
   * 드래그 작업이 시작되기 직전에 호출되어 데이터를 첨부하는 함수
   * @param {DraggableGetFeedbackArgs} args - 드래그 시작 시 전달되는 인수
   * @returns {Record<string, unknown>} - 초기 데이터
   * @property {(args: DraggableGetFeedbackArgs) => Record<string, unknown>} [getInitialData] - 초기 드래그 데이터를 제공하는 함수 (선택 사항)
   */
  getInitialData?: (args: DraggableGetFeedbackArgs) => Record<string, unknown>;

  /**
   * 네이티브 드래그 데이터 스토어에 데이터를 첨부하는 함수
   * 다른 웹 페이지나 웹 애플리케이션에서 추출할 수 있는 네이티브 데이터를 첨부하는 데 유용
   * @param {DraggableGetFeedbackArgs} args - 드래그 시작 시 전달되는 인수
   * @returns {[Key in NativeMediaType]?: string} - 외부 애플리케이션에서 사용할 초기 데이터
   * @example getInitialDataForExternal(() => ({'text/plain': item.description}))
   * @property {(args: DraggableGetFeedbackArgs) => {[Key in NativeMediaType]?: string}} [getInitialDataForExternal] - 외부 애플리케이션에서 사용할 초기 데이터를 제공하는 함수 (선택 사항)
   */
  getInitialDataForExternal?: (args: DraggableGetFeedbackArgs) => {
    [Key in NativeMediaType]?: string;
  };
} & Partial<AllEvents<ElementDragType>>;

/**
 * DraggableParams 타입 정의
 * DraggableArgs 타입을 상속받음
 * @property {HTMLElement} element - 드래그 가능한 HTML 요소
 * @property {Element} [dragHandle] - 드래그 핸들로 사용할 요소 (선택 사항)
 * @property {(args: DraggableGetFeedbackArgs) => boolean} [canDrag] - 드래그가 가능한지 여부를 결정하는 함수 (선택 사항)
 * @property {(args: DraggableGetFeedbackArgs) => Record<string, unknown>} [getInitialData] - 초기 드래그 데이터를 제공하는 함수 (선택 사항)
 * @property {(args: DraggableGetFeedbackArgs) => {[Key in NativeMediaType]?: string}} [getInitialDataForExternal] - 외부 애플리케이션에서 사용할 초기 데이터를 제공하는 함수 (선택 사항)
 */
export type DraggableParams = DraggableArgs;

/**
 * draggable: 요소를 드래그 가능한 요소로 사용
 * @param {HTMLElement} element - HTML 요소
 */
export { draggable };

export default draggable;
