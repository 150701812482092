import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';

export interface Props {
  className?: string;
}

export function Contents({ className, children }: PropsWithChildren<Props>) {
  return (
    <div
      className={clsx(
        'w-[360px] grid content-center place-items-stretch',
        className,
      )}
    >
      {children}
    </div>
  );
}

export default Contents;
