'use client';
/**
 * @deprecated
 * @description @dop-ui/react/entities/user 패키지를 사용하세요.
 */
import {
  SessionContextValues,
  SessionContext,
  useSessionContext,
} from '@dop-ui/react/features/authenticate';

export interface AuthContextValues extends SessionContextValues {}
export const AuthContext = SessionContext;
export const useAuthContext = useSessionContext;
