import { fetch } from '../../../../../../lib/fetch/client';
import type { ApiResponse, MultiMember } from '../types';

interface Params {
  userList: MultiMember[];
  companyGroupId?: string | number;
}

export async function createMultiMember({ userList, companyGroupId }: Params) {
  const response = await fetch(`/api/portal/setting/integration`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      companyGroupId,
      userList,
    }),
  });
  const responseJson = (await response.json()) as ApiResponse<{ data: string }>;

  if (responseJson.message) {
    throw new Error(responseJson.message);
  }

  return responseJson;
}
