import { appInfoAtom, loadStateAtom, lockStateAtom } from '../model/atoms';
import { screenLockStore } from '../model/store';
import { AppInfo, ScreenLockActions } from '../types';
import { checkScreenLockTarget } from './check-screen-lock-target';

export function useScreenLock(): ScreenLockActions {
  const locked = (appInfo: AppInfo) => {
    screenLockStore.set(appInfoAtom, appInfo);

    if (!appInfo.useLock) {
      screenLockStore.set(loadStateAtom, true);
      screenLockStore.set(lockStateAtom, false);
    } else {
      screenLockStore.set(loadStateAtom, false);
      appInfo.appCode &&
        checkScreenLockTarget(appInfo.appCode)
          .then((res) => {
            res?.data?.isScreenLockTarget &&
              screenLockStore.set(lockStateAtom, res?.data?.isScreenLockTarget);
            screenLockStore.set(loadStateAtom, true);
          })
          .catch(() => {
            screenLockStore.set(lockStateAtom, false);
            screenLockStore.set(loadStateAtom, true);
          });
    }
  };

  return { locked };
}

export default useScreenLock;
