import { gql } from 'graphql-request';
import { RoleType } from '../../../types';
import { fetchGraphQL } from '@dop-ui/react/shared/lib/fetch/client';
import { OrganizationCode } from '../types';

const query = (type: RoleType) => gql`
  query {
    organizationCodes(input: { type: ${type} }) {
      id
      name
      type
      sortOrder
    }
  }
`;

interface QueryResponse {
  data: {
    organizationCodes: OrganizationCode[];
  };
}

export const QUERY_KEY =
  'GlobalConfig.SecureManagement.ClassListManager.SubSelectionList';

export async function getSubSelectionList(type: RoleType) {
  if (type === 'USER' || type === 'DEPARTMENT') return;

  const response = await fetchGraphQL({ query: query(type) });

  const responseData = (await response.json()) as QueryResponse;

  return responseData.data.organizationCodes.sort(
    (a, b) => a.sortOrder - b.sortOrder,
  );
}
