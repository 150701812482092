import { gql } from 'graphql-request';
import { fetchGraphQL } from '../../../lib/fetch/client';
import {
  DefaultItemInfo,
  ExposableItemInfo,
  ShortCut,
  ShortcutItemInfo,
} from '../types';

export const QUERY_KEY = 'getOrganizerQuery';
export const Query = gql`
  query ${QUERY_KEY}{
    me {
      organizer{
        exposableAppList {
          code
          id
          name
        }
        shortcutList {
          id
          shortcutType
          sortOrder
          faviconUrl
          title
          ... on AppShortcut{
            appType
            itemId
          }
          ... on LinkShortcut {
            id
            url
          }
        }
        useOrganizer
        defaultList {
          iconUrl
          name
          title
        }
      }
    }
  }
`;

interface OrganizerResponse {
  exposableAppList: ExposableItemInfo[];
  shortcutList: ShortcutItemInfo[];
  useOrganizer: boolean;
  defaultList: DefaultItemInfo[];
}
interface OrganizerQueryResponse {
  data: {
    me: {
      organizer: {
        exposableAppList: ExposableItemInfo[];
        shortcutList: ShortCut[];
        useOrganizer: boolean;
        defaultList: DefaultItemInfo[];
      };
    };
  };
}
export async function getOrganizer(): Promise<OrganizerResponse> {
  const response = await fetchGraphQL({ query: Query });
  const data = (await response.json()) as OrganizerQueryResponse;
  const { defaultList, shortcutList, useOrganizer, exposableAppList } =
    data.data.me.organizer;
  return {
    defaultList: [
      ...defaultList,
      { iconUrl: '', name: 'shortcut', title: '빠른 수행 도구' },
    ],
    shortcutList: shortcutList.map((item) => shortcutAdapter(item)),
    exposableAppList,
    useOrganizer,
  };
}

function shortcutAdapter(src: ShortCut): ShortcutItemInfo {
  return {
    uid: src.id,
    itemId: src.itemId,
    sortOrder: src.sortOrder,
    itemType: src.shortcutType,
    appType: src.appType,
    name: src.appType ? src.title : src.url,
    size: 'large',
    icon: src.faviconUrl,
    url: urlAdapter(src),
  };
}

function urlAdapter(src: ShortCut): string {
  if (src.appType) {
    if (src.appType === 'WORKS') {
      return `/gw/app/works/applet/${src.itemId}/home`;
    } else {
      // Todo : LINK_PLUS url 수정 필요
      return src.url;
    }
  } else {
    return src.url;
  }
}
