import { gql } from 'graphql-request';
import { fetchGraphQL } from '../../../../../../lib/fetch/client';
import { AuthConfig } from '../types';

const useMobileServiceQuery = gql`
  query {
    authConfig {
      USE_MOBILE_APP_SERVICE
    }
  }
`;

interface ResponseBody {
  data: {
    authConfig: AuthConfig;
  };
}

export const QUERY_KEY = 'mobileAppAccessQuery';

export async function getMobileAppAccessStatus() {
  try {
    const { data } = (await (
      await fetchGraphQL({ query: useMobileServiceQuery })
    ).json()) as ResponseBody;

    return data.authConfig.USE_MOBILE_APP_SERVICE;
  } catch (error) {
    console.error(error);
  }
}
