import { ColumnDef } from '@tanstack/react-table';
import { Provider as JotaiProvider } from 'jotai';
import { PropsWithChildren } from 'react';
import { Provider } from './Context';
import { tableStore } from './stores/tableStore';

export interface Props<T> {
  className?: string;
  contents: T[];
  columnDefs: ColumnDef<T>[];
  selectable?: boolean;
  onClickRow?: (selectedRowIndex: number) => void;
  onSelectContents?: (contents: T[]) => void;
  onChangeFilter?: (filters: Map<string, string>) => void;
}

/**
 * Table.Root 컴포넌트
 * @returns
 */
export function Root<T>({
  className,
  children,
  ...props
}: PropsWithChildren<Props<T>>) {
  return (
    <JotaiProvider store={tableStore}>
      <Provider {...props}>
        <div className={className}>{children}</div>
      </Provider>
    </JotaiProvider>
  );
}

export default Root;

Root.displayName = 'TableRoot';
