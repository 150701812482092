import { IconButton } from '@daouoffice/ui';
import { ArrowLeftIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { serviceMapOpenStateAtom } from '../../../../store/serviceMapOpenStateAtom';

export interface Props {}

export function Title() {
  // TODO : serviceMapOpenState jotai 코드 추가 시, serviceMap 열기, 닫기 기능 추가.
  const { t } = useTranslation();
  const [, setServiceMapOpenState] = useAtom(serviceMapOpenStateAtom);

  return (
    <div className="wrap_title">
      <IconButton
        title="back"
        onClick={() => setServiceMapOpenState((prev) => !prev)}
      >
        <ArrowLeftIcon />
      </IconButton>
      <h1 className="title heading_medium bold">
        {t('globalconfig.servicemap.title')}
      </h1>
    </div>
  );
}

export default Title;
