import { useDebounce } from '@daouoffice/ui';
import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import * as Icon24 from '@daouoffice/ui/lib/icons/dop/24';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';

export interface Props {
  title: string;
  placeholder: string;
  value?: string;
  onValueChange: (value: string) => void;
}

export function SearchInput({
  title,
  placeholder,
  value,
  onValueChange,
}: Props) {
  const [text, setText] = useState(value ? value : '');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setText(value ?? '');
  }, [value]);

  const searchKeywordDebouncer = useDebounce((value: string) => {
    console.log('value changed');

    onValueChange(value);
  }, 250);

  const onChangeHandler = (value: string) => {
    setText(value);
    searchKeywordDebouncer(value);
  };

  return (
    <div
      className={clsx('group_input', {
        focused: isFocused || (value && value !== ''),
      })}
    >
      <label className="label blind" htmlFor="test_search">
        검색
      </label>
      <div className="input_search">
        <i className="icon ic_small">
          <Icon24.SearchIcon />
        </i>
        <input
          className="input_txt body_medium regular"
          id="test_search"
          type="search"
          title={title}
          placeholder={placeholder}
          value={text}
          onChange={(e) => {
            onChangeHandler(e.target.value);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
        <IconButton
          title="초기화"
          className="clear"
          tabIndex={-1}
          onClick={() => {
            onValueChange('');
          }}
        >
          <Icon24.SearchClearIcon />
        </IconButton>
        <IconButton title="필터" size="small" className="filter" tabIndex={-1}>
          <Icon24.FilterIcon />
        </IconButton>
      </div>
    </div>
  );
}
