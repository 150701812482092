import { BASIC_MANAGEMENT } from '../main/menuPaths';

const menuKeyPath = BASIC_MANAGEMENT;

export const SERVICE_PATH = menuKeyPath + '/service';
export const SERVICE_INFO_PATH = SERVICE_PATH + '/info';

export const SERVICE_APP_PATH = menuKeyPath + '/service-app';
export const SERVICE_APP_EMPLOYEE_PATH = SERVICE_APP_PATH + '/employee';
export const SERVICE_APP_BUSINESS_PATH = SERVICE_APP_PATH + '/business';
export const SERVICE_APP_MAP_PATH = SERVICE_APP_PATH + '/map';

export const MANAGE_PATH = menuKeyPath + '/manage';
export const MANAGE_SCHEDULE_PATH = MANAGE_PATH + '/schedule';
export const MANAGE_POPUP_PATH = MANAGE_PATH + '/popup';
export const MANAGE_PROFILE_PATH = MANAGE_PATH + '/profile';
export const MANAGE_LOGO_PATH = MANAGE_PATH + '/logo';
export const MANAGE_DASHBOARD_PATH = MANAGE_PATH + '/dashboard';
