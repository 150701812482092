import { clsx } from 'clsx';

export interface Props {
  title?: string;
  className?: string;
}

export function Title({ title, className }: Props) {
  return (
    <h3
      className={clsx('text-center font-bold whitespace-pre-line', className)}
    >
      {title}
    </h3>
  );
}

export default Title;
