import { useContext } from '../Provider';
import { DefaultContents } from './DefaultContents';
import { DetailContents } from './DetailContents';
export function Contents() {
  const { deptId } = useContext();

  return <>{deptId ? <DetailContents /> : <DefaultContents />}</>;
}

export default Contents;
