import * as RadixPrimivites from '@radix-ui/react-progress';
import { clsx } from 'clsx';
export function Root({
  children,
  className = '',
  ...props
}: RadixPrimivites.ProgressProps) {
  return (
    <RadixPrimivites.Root
      {...props}
      className={clsx(className, 'bg-[#d9d9d9] w-full h-4 rounded-xl')}
    >
      {children}
    </RadixPrimivites.Root>
  );
}

interface ProgressIndicatorProps
  extends RadixPrimivites.ProgressIndicatorProps {
  percent: number;
}

export function Indicator({
  children,
  percent,
  className = '',
  ...props
}: ProgressIndicatorProps) {
  return (
    <RadixPrimivites.Indicator
      className={clsx(
        className,
        'relative h-full rounded-lg bg-gradient-to-r from-[#4DE1FA] to-[#58E5A7]',
      )}
      {...props}
      style={{ width: `${percent}%` }}
    >
      {children}
    </RadixPrimivites.Indicator>
  );
}
