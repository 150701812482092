'use client';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Suspense, useState } from 'react';
import { AdminTableClient } from './AdminTable/AdminTableClient';
import { Provider } from './Context';
import AuthListClient from './authorization/manager/AuthList/AuthListClient';
import EditAppListClient from './authorization/manager/EditAppList/EditAppListClient';
import { SubTabGroup } from './authorization/manager/SubTabGroup';
import { SubTabType } from './authorization/manager/types';

export default function Index() {
  const [type, setType] = useState<SubTabType>('perApps');
  const { t } = useTranslation('component');

  const handleOnclickSubTab = (type: SubTabType) => {
    setType(type);
  };

  return (
    <Provider title={t('globalconfig.secureManagement.adminManagement.master')}>
      <div className="content_page">
        <SubTabGroup.Root>
          <SubTabGroup.SubTab
            type={'perApps'}
            title={t(
              'globalconfig.secureManagement.adminManagement.setPerApps',
            )}
            onClickTab={handleOnclickSubTab}
          />
          <SubTabGroup.SubTab
            type={'perManagers'}
            title={t(
              'globalconfig.secureManagement.adminManagement.setPerManagers',
            )}
            onClickTab={handleOnclickSubTab}
          />
        </SubTabGroup.Root>
        <div className="border border-solid border-[#E6E7EA] p-10 mt-[42px] rounded-xl">
          <h2 className="text-[22px] font-medium -tracking-[1.32px]">
            {type === 'perApps'
              ? t('globalconfig.secureManagement.adminManagement.setPerApps')
              : t(
                  'globalconfig.secureManagement.adminManagement.setPerManagers',
                )}
          </h2>
          {type === 'perManagers' ? (
            <div className="flex items-stretch mt-6 gap-6">
              <AdminTableClient type="management" />
              <AuthListClient />
            </div>
          ) : type === 'perApps' ? (
            <div className="flex items-stretch mt-6 gap-6">
              <Suspense
                fallback={
                  <div className="w-[300px] border border-solid border-[#DBE0EB] rounded-3xl flex justify-center items-center">
                    Loading...
                  </div>
                }
              >
                <EditAppListClient />
              </Suspense>
              <AdminTableClient type="app" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Provider>
  );
}
