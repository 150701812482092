import { useSetAtom } from 'jotai';
import { Button } from '@dop-ui/react/shared/ui/button';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { setMenuByPathAtom } from '../../../../../store/setMenuByPathAtom';
import { Apps } from './apps';
import { AppInfo } from '../../types';

interface Props {
  portalName: string;
  appManagementDirectLink: string;
  basicAppInfoList: AppInfo[];
  purchasedAppInfoList: AppInfo[];
  className?: string;
}

export function AppsPerPortal({
  portalName,
  appManagementDirectLink,
  basicAppInfoList,
  purchasedAppInfoList,
  className,
}: Props) {
  const { t } = useTranslation('component');
  const setMenuByPath = useSetAtom(setMenuByPathAtom);

  const onClickLink = () => {
    setMenuByPath(appManagementDirectLink);
  };

  return (
    <div className={className}>
      <div className="flex items-center">
        <p className="py-[8px] text-[#333333] text-[16px] font-[500]">
          {portalName}
        </p>
        <Button
          className="flex items-center ml-[8px] px-[8px] h-[28px] rounded-[8px] border border-solid border-[#DDDDDD]"
          onClick={onClickLink}
        >
          <span className="text-[#333333] text-[12px] font-[400]">
            {t(
              'globalconfig.basicManagement.serviceInfo.inUseAppsInfo.linkToAppManagement',
            )}
          </span>
          <ChevronRightIcon className="ml-[4px] size-[12px]" color="#A3A3A3" />
        </Button>
      </div>
      <Apps
        className="mt-[16px]"
        title={t(
          'globalconfig.basicManagement.serviceInfo.inUseAppsInfo.basicApps',
        )}
        apps={basicAppInfoList}
      />
      <Apps
        className="mt-[16px]"
        title={t(
          'globalconfig.basicManagement.serviceInfo.inUseAppsInfo.purchasedApps',
        )}
        apps={purchasedAppInfoList}
      />
    </div>
  );
}
