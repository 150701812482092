import { fetch } from '../../../../../lib/fetch/client';

export const QUERY_KEY = 'organizer_memo_delete';

interface DeleteResponse {
  code?: string;
  message?: string;
}

export async function deleteMemo(memoId: number): Promise<DeleteResponse> {
  const pathUrl = `/api/portal/common/memo/${memoId}`;
  const apiResponse = await fetch(pathUrl, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const response = (await apiResponse.json()) as DeleteResponse;
  if (!apiResponse.ok) {
    throw new Error(response.message);
  }
  return response;
}
