import { fetch } from '../../../../../../lib/fetch/client';
import { SECURITY_SETTINGS_URL } from '../../constants';
import { MediaConfig } from '../types';

export const MUTATION_KEY = 'mediaScreenLockMutation';

export async function updateMediaScreenLockStatus(
  isLocked: boolean,
  media: MediaConfig,
) {
  try {
    return await fetch(SECURITY_SETTINGS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        configType: 'AUTH',
        configName: media,
        configValue: isLocked,
      }),
    });
  } catch (error) {
    console.error(error);
  }
}
