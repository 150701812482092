import { clsx } from 'clsx';
import { useMemoContext } from '../Context';
import * as getMemoQuery from '../apis/getMemo';
import { useQueryClient } from '@tanstack/react-query';
import { formatDate } from '../../../../../lib/utils/date';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { QUERY_KEY as getMemoListQueryId } from '../apis/getMemoList';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

import {
  useToastMessage,
  Templates,
} from '@dop-ui/react/features/toast-message';
import MemoScrollContent from './memo-scroll-content';
import MemoSummaryContent from './memo-summary-content';
import FavoriteButton from './favorite-button';
import { OrganizerMemoPages } from '..';

import memoStyles from '../memo.module.css';
import type { MemoType, MemoViewType } from '../types';
import { defaultTimeFormat, MemoColorClassNames } from '../constants';

interface Props {
  memo: MemoType;
  viewType: MemoViewType;
}

export function MemoItem({ memo, viewType }: Props) {
  const { push } = useStackMethod<OrganizerMemoPages>();

  const { t } = useTranslation();
  const { setMemoContext } = useMemoContext();

  const queryClient = useQueryClient();

  const { info } = useToastMessage();

  const handleOnClick = async () => {
    if (viewType === 'scroll') {
      return;
    }
    try {
      const { data, message } = await queryClient.fetchQuery({
        queryKey: [getMemoQuery.QUERY_KEY, memo.id],
        queryFn: async () => await getMemoQuery.getMemo(memo.id),
      });

      if (data) {
        const { content, color, updatedAt, isFavorite, textContent } = data;
        setMemoContext((prev) => ({
          ...prev,
          memoId: memo.id,
          content,
          color,
          updatedAt,
          isFavorite,
          textContent,
        }));
        push('memoDetail');
      } else {
        info(
          <Templates.Basic
            description={message ?? t('organizer.memo.error')}
          />,
        );
        listInvalidateQuery();
      }
    } catch (e) {
      info(<Templates.Basic description={t('organizer.memo.error')} />);
      listInvalidateQuery();
    }
  };

  const listInvalidateQuery = () => {
    void queryClient.invalidateQueries({
      queryKey: [getMemoListQueryId],
    });
  };

  const Container = viewType === 'summary' ? 'li' : 'div';

  return (
    <Container
      className={clsx(
        'gap-2',
        memoStyles.MemoItem,
        MemoColorClassNames[memo.color],
        { 'max-h-[190px] overflow-hidden': viewType === 'summary' },
      )}
      onClick={() => void handleOnClick()}
    >
      {viewType === 'summary' ? (
        <MemoSummaryContent memo={memo} />
      ) : (
        <MemoScrollContent memo={memo} />
      )}
      <div className="flex justify-between items-center h-[20px] py-1">
        <p className="text-[#999] text-[12px] leading-3">
          {formatDate({ format: defaultTimeFormat, date: memo.updatedAt })}
        </p>

        <FavoriteButton
          memoId={memo.id}
          isFavorite={memo.isFavorite}
          className={clsx(
            memoStyles.FavoriteBtn,
            memo.isFavorite && memoStyles.IsFavorite,
          )}
        />
      </div>
    </Container>
  );
}

export default MemoItem;
