import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useBasicInfoContext } from '../../Context';
import { useAtom } from 'jotai';
import { isOrgDisplayOrderNeedsSaveAtom } from '../../store/is-org-display-order-needs-save-atom';
import { useEffect, useState } from 'react';
import { Button } from '@dop-ui/react/shared/ui/button';
import { SaveConfirmDialog } from '../../../../../parts/components/save-confirm-dialog';
import {
  ArrowLeftIcon as ArrowLeftHeaderIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowLeftIcon,
  ChevronDownIcon,
} from '@heroicons/react/16/solid';
import { OrganizationChartOrder } from '../../type';
import * as DnDList from '@dop-ui/react/shared/ui/dnd/list';
import OrgOrderItem from './org-order-item';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateOrgConfig } from '../../apis/update-org-config';
import * as getOrgConfig from '../../apis/get-org-config';
import { useToastMessage } from '@dop-ui/react/features/toast-message';

export function OrgDisplayOrderConfig() {
  const { t } = useTranslation('component');

  const { confirm } = useMessageDialog();
  const stacker = useStackMethod();
  const toaster = useToastMessage();
  const queryClient = useQueryClient();

  const {
    basicInfoContext: {
      companyList,
      orgConfig,
      orgConfig: { organizationChartOrder },
    },
  } = useBasicInfoContext();

  const [isConfigNeedsSave, setIsConfigNeedsSave] = useAtom(
    isOrgDisplayOrderNeedsSaveAtom,
  );

  const exList = [
    {
      companyId: 402,
      companyName: '다우기술',
      sortOrder: 0,
      depth: 0,
    },
    {
      companyId: 452,
      companyName: 'depth2',
      sortOrder: 1,
      depth: 1,
    },
    {
      companyId: 453,
      companyName: '다우기술',
      sortOrder: 2,
      depth: 0,
    },
    {
      companyId: 502,
      companyName: 'depth2',
      sortOrder: 3,
      depth: 1,
    },
    {
      companyId: 503,
      companyName: 'depth3',
      sortOrder: 4,
      depth: 2,
    },
    {
      companyId: 504,
      companyName: 'depth3',
      sortOrder: 5,
      depth: 2,
    },
    {
      companyId: 505,
      companyName: 'depth4',
      sortOrder: 6,
      depth: 3,
    },
    {
      companyId: 506,
      companyName:
        '뎁스가 많아질 경우 자우스크롤 발생 뎁스가 많아질 경우 자우스크롤 발생 ',
      sortOrder: 7,
      depth: 4,
    },
    {
      companyId: 507,
      companyName: '다우기술',
      sortOrder: 8,
      depth: 0,
    },
    {
      companyId: 508,
      companyName: 'depth2',
      sortOrder: 9,
      depth: 1,
    },
  ];
  // Todo : 기본값 organizationChartOrder로 변경
  const [orgOrder, setOrgOrder] = useState(exList);
  const [selectedSite, setSelectedSite] = useState<OrganizationChartOrder>({
    companyId: 0,
    sortOrder: 0,
    depth: 0,
  });

  const displayOrgOrder = orgOrder.sort((a, b) => a.sortOrder - b.sortOrder);

  const updateOrgConfigMutation = useMutation({
    mutationFn: async () =>
      await updateOrgConfig({
        ...orgConfig,
        organizationChartOrder: orgOrder.map(
          ({ companyName, ...rest }) => rest,
        ),
      }),
    onSuccess: (data) => {
      void queryClient.invalidateQueries({
        queryKey: [getOrgConfig.QUERY_KEY],
      });
      setIsConfigNeedsSave(false);
      toaster.info(
        t('globalconfig.secureManagement.loginSecureConfig.ip.saved'),
      );
    },
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    // console.log(
    //   'organizationChartOrder.map((item) => item.companyId)',
    //   organizationChartOrder.map((item) => item.companyId),
    // );
    // console.log(
    //   'orgOrder.map((item) => item.companyId) ',
    //   orgOrder.map((item) => item.companyId),
    // );
    if (JSON.stringify(organizationChartOrder) === JSON.stringify(orgOrder)) {
      setIsConfigNeedsSave(false);
    } else {
      setIsConfigNeedsSave(true);
    }

    setSelectedSite(
      orgOrder.find((item) => selectedSite.companyId === item.companyId) ?? {
        companyId: 0,
        sortOrder: 0,
        depth: 0,
      },
    );
  }, [orgOrder]);

  const onClickBackButton = async () => {
    if (isConfigNeedsSave) {
      if (await confirm(<SaveConfirmDialog />)) {
        stacker.pop();
      }
    } else {
      stacker.pop();
    }
  };

  const onClickCancle = () => {
    // Todo : 목업 데이터
    // setOrgOrder(organizationChartOrder);
    setOrgOrder(exList);
    setSelectedSite({
      companyId: 0,
      sortOrder: 0,
      depth: 0,
    });
    setIsConfigNeedsSave(false);
  };

  const onClickSave = () => {
    updateOrgConfigMutation.mutate();
  };

  const onClickUp = () => {
    const selectedIndex = orgOrder.findIndex(
      (item) => item.companyId === selectedSite.companyId,
    );

    if (selectedIndex === 0) {
      return;
    }

    const upSiteIndex = selectedIndex - 1;

    setOrgOrder((prevItems) =>
      prevItems.map((item, index) => {
        if (index === upSiteIndex) {
          return {
            ...item,
            sortOrder: item.sortOrder + 1,
          };
        }
        if (index === selectedIndex) {
          return {
            ...item,
            sortOrder: item.sortOrder - 1,
          };
        }
        return item;
      }),
    );
  };

  const onClickDown = () => {
    const selectedIndex = orgOrder.findIndex(
      (item) => item.companyId === selectedSite.companyId,
    );

    if (selectedIndex === orgOrder.length - 1) {
      return;
    }

    const downSiteIndex = selectedIndex + 1;

    setOrgOrder((prevItems) =>
      prevItems.map((item, index) => {
        if (index === downSiteIndex) {
          return {
            ...item,
            sortOrder: item.sortOrder - 1,
          };
        }
        if (index === selectedIndex) {
          return {
            ...item,
            sortOrder: item.sortOrder + 1,
          };
        }
        return item;
      }),
    );
  };

  const onClickLeft = () => {
    const newDepth = selectedSite.depth - 1;

    if (newDepth < 0) {
      return;
    }

    setOrgOrder((prevItems) =>
      prevItems.map((item) =>
        item.companyId === selectedSite.companyId
          ? { ...item, depth: newDepth }
          : item,
      ),
    );
  };

  const onClickRight = () => {
    const newDepth = selectedSite.depth + 1;

    setOrgOrder((prevItems) =>
      prevItems.map((item) =>
        item.companyId === selectedSite.companyId
          ? { ...item, depth: newDepth }
          : item,
      ),
    );
  };

  const onDrop = ({ startIndex, targetIndex, edge }: DnDList.DropParams) => {
    if (startIndex === targetIndex) return;
    if (startIndex + 1 === targetIndex && edge === 'top') return;
    if (startIndex - 1 === targetIndex && edge === 'bottom') return;

    var newDepth = orgOrder[targetIndex]?.depth!;

    const newItems = [...orgOrder];

    const [itemToMove] = newItems.splice(startIndex, 1);
    itemToMove!.depth = newDepth;

    var changeTargetIndex = targetIndex;
    if (startIndex < targetIndex) {
      changeTargetIndex = changeTargetIndex - 1;
    }

    if (edge === 'top') {
      newItems.splice(changeTargetIndex, 0, itemToMove!);
    } else if (edge === 'bottom') {
      newItems.splice(changeTargetIndex + 1, 0, itemToMove!);
    }

    newItems.forEach((item, index) => {
      item.sortOrder = index;
    });

    setOrgOrder(newItems);
  };

  return (
    <div className="flex flex-col flex-wrap gap-[24px]">
      <div className="h-[38px] flex items-center">
        <Button
          className="!size-[24px] mr-[8px]"
          size="none"
          onClick={() => void onClickBackButton()}
        >
          <ArrowLeftHeaderIcon className="" />
        </Button>
        <h2 className="text-[#333] text-[20px] font-bold -tracking-[1.2px]">
          {t(
            'globalconfig.multiCompanyManagement.basicInfo.orgDisplayOrder.title',
          )}
        </h2>
      </div>
      <div className="flex gap-[40px]">
        <div className="flex flex-col h-[579px] min-w-[400px] border border-solid border-[#F2F2F2] rounded-2xl overflow-y-auto">
          <div className="flex px-[24px] pt-[24px] pb-[12px]">
            <p className="flex flex-grow items-center h-[32px] font-semibold text-[16px]">
              {t(
                'globalconfig.multiCompanyManagement.basicInfo.orgDisplayOrder.siteName',
              )}
            </p>
            <div className="flex items-center gap-[6px]">
              <Button
                className="flex items-center justify-center !w-[24px] !h-[24px] border border-solid border-[#D8D8D8] rounded"
                onClick={onClickUp}
              >
                <ArrowUpIcon width={16} height={16} />
              </Button>
              <Button
                className="flex items-center justify-center !w-[24px] !h-[24px] border border-solid border-[#D8D8D8] rounded"
                onClick={onClickDown}
              >
                <ArrowDownIcon width={16} height={16} />
              </Button>
              <Button
                className="flex items-center justify-center !w-[24px] !h-[24px] border border-solid border-[#D8D8D8] rounded"
                onClick={onClickLeft}
              >
                <ArrowLeftIcon width={16} height={16} />
              </Button>
              <Button
                className="flex items-center justify-center !w-[24px] !h-[24px] border border-solid border-[#D8D8D8] rounded"
                onClick={onClickRight}
              >
                <ArrowRightIcon width={16} height={16} />
              </Button>
            </div>
          </div>
          <hr className="mx-[24px]" />
          <DnDList.Provider idKey="companyId" name="org_order_setting">
            <DnDList.Container
              list={orgOrder}
              onDrop={onDrop}
              setList={setOrgOrder}
            >
              <div className="flex flex-col px-[24px] py-[16px]">
                {displayOrgOrder.map((site, index) => {
                  const companyName = companyList.find(
                    (item) => item.companyId === site.companyId,
                  )?.companyName!;

                  return (
                    <OrgOrderItem
                      key={index}
                      index={index}
                      item={site}
                      companyName={companyName}
                      selectedSite={selectedSite}
                      onSetSelectedSite={setSelectedSite}
                    />
                  );
                })}
              </div>
            </DnDList.Container>
          </DnDList.Provider>
        </div>
        <div className="flex flex-col h-[579px] w-[400px] pl-[24px] pt-[24px] gap-4 border border-solid rounded-2xl border-[#F2F2F2] bg-[#F9FBFC]">
          <div className="flex items-center w-[175px] h-[32px] px-[12px] gap-1 rounded-lg bg-[#D9E2EE]">
            <EyeIcon width={20} height={20} className="p-[1px]" />
            <p className="font-medium">
              {t(
                'globalconfig.multiCompanyManagement.basicInfo.orgDisplayOrder.preview',
              )}
            </p>
          </div>
          <div className="flex flex-col gap-1 pr-[8px] whitespace-nowrap overflow-x-auto overflow-y-auto">
            {displayOrgOrder.map((site) => {
              const companyName = companyList.find(
                (item) => item.companyId === site.companyId,
              )?.companyName;

              return (
                <div
                  key={site.companyId}
                  className="flex items-center w-full h-[40px] py-[6px]"
                  style={{ paddingLeft: `${site.depth * 24}px` }}
                >
                  <div className="flex items-center w-full p-[4px]">
                    <div className="flex justify-center items-center p-[3px]">
                      <ChevronDownIcon width={12} height={12} />
                    </div>
                    {site.companyName}
                    {companyName}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-[50px] flex items-center justify-center gap-[8px]">
        <Button
          size="medium"
          shape="rect"
          variant="outline"
          colorset="level2"
          onClick={onClickCancle}
        >
          {t('dialog.cancel')}
        </Button>
        <Button
          size="medium"
          shape="rect"
          variant="solid"
          colorset="level1"
          disabled={!isConfigNeedsSave}
          onClick={onClickSave}
        >
          {t('dialog.save')}
        </Button>
      </div>
    </div>
  );
}

export default OrgDisplayOrderConfig;
