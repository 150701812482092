import { Root } from './Root';
import { Menu } from './Menu';
import { Contents } from './Contents';
import { ServiceMap } from './ServiceMap';
import { useGlobalConfigStore } from '../store/globalConfigStore';

export const GlobalConfigPrimitives = {
  Root,
  Menu,
  Contents,
  ServiceMap,
  useGlobalConfigStore,
};
