import { fetch } from '../../../../../../lib/fetch/client';
import { TFA_ERROR_LOG_API } from '../../constants';
import { BaseResponseWithPage } from '../../types';
import { TableQueryInfo, TfaErrorLogInfo } from '../types';

export const QUERY_KEY =
  'GlobalConfig.SecureManagement.TfaErrorLog.TfaErrorLogList';

export async function getTfaErrorLogs(
  page: number,
  size: number,
  info: TableQueryInfo,
) {
  const query = new URLSearchParams();
  query.append('page', page.toString());
  query.append('size', size.toString());
  query.append('orderType', info.orderType);
  query.append('direction', info.direction);
  query.append('searchType', info.searchType);
  query.append('keyword', info.keyword);

  const response = await fetch(TFA_ERROR_LOG_API + '?' + query.toString(), {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponseWithPage<TfaErrorLogInfo>;

  return {
    elements: responseData.data.elements.filter(
      (info) => info.initStatus === 'WAIT',
    ),
    page: responseData.data.page,
  };
}

export async function resetUserState(userIds: number[]) {
  await fetch(TFA_ERROR_LOG_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({ data: userIds }),
  });
}
