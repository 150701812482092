import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { useMemoContext } from '../Context';
import '../memo-edit/memo-editor.css';
import { searchHilight } from '../utils';
import { MemoType } from '../types';
import memoStyles from '../../Memo/memo.module.css';

interface Props {
  memo: MemoType;
}

export function MemoSummaryContent({ memo }: Props) {
  const { memoContext } = useMemoContext();

  const textRef = useRef<HTMLDivElement>(null);

  const [displayContent, setDisplayContent] = useState(memo.content);

  useEffect(() => {
    if (memoContext.keyword !== '') {
      setDisplayContent(searchHilight(memoContext.keyword, memo.content));
    } else {
      setDisplayContent(memo.content);
    }
  }, [memoContext.keyword, memo.content]);

  return (
    <div className="overflow-hidden">
      <div
        ref={textRef}
        className={clsx('memo-editor-container', memoStyles.MemoSummaryItem)}
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 4,
        }}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(displayContent),
        }}
      />
    </div>
  );
}

export default MemoSummaryContent;
