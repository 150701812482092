const COLORS = [
  'ef7271',
  'f4ba73',
  'ffd03f',
  '91e850',
  '5be951',
  '76d89b',
  '62ecec',
  '86bbfc',
  '7b6ffb',
  'b76dfb',
  'f16ef8',
  'f070b7',
  'ec0000',
  'ef810d',
  'f0bd03',
  '77c857',
  '31d327',
  '00bd78',
  '3cdada',
  '427ffc',
  '5126fc',
  '9226f9',
  'ef26f6',
  'ef268e',
  'ab2626',
  'ad6426',
  'c4a835',
  '6ca356',
  '47ad1f',
  '18a471',
  '5cb8ba',
  '4664b6',
  '3d26b4',
  '6726b4',
  'ae26b4',
  'ac2662',
  'ababab',
  '6d6d6d',
  '464646',
];

/**
 * getRandomColor
 * 사용자
 * @param id 
 * @returns 
 */
export const getRandomColor = (id?: number) => {
  const len = COLORS.length;
  const idx = id ? id % len : Math.floor(Math.random() * len);
  return `#${COLORS[idx]}`;
};
