import { TFunction } from 'i18next';
import { AppType } from '../types';

export const getAppTypeString = (
  t: TFunction<'translation', unknown>,
  appType?: AppType,
) => {
  if (appType === 'approval') {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.approval.title',
    );
  } else if (appType === 'board') {
    return t('globalconfig.dataManagement.deletedDeptManagement.board.title');
  } else if (appType === 'report') {
    return t('globalconfig.dataManagement.deletedDeptManagement.report.title');
  } else {
    return t('globalconfig.dataManagement.deletedDeptManagement.group.title');
  }
};

export const getAppTypeFolderMoveString = (
  t: TFunction<'translation', unknown>,
  appType?: AppType,
) => {
  if (appType === 'approval') {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.moveApprovalFolder',
    );
  } else if (appType === 'board') {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.moveBoardFolder',
    );
  } else if (appType === 'report') {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.moveReportFolder',
    );
  } else {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.moveGroupFolder',
    );
  }
};

export const getNodeSelectString = (
  t: TFunction<'translation', unknown>,
  appType?: AppType,
) => {
  if (appType === 'approval') {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.moveApprovalFolder.description',
    );
  } else if (appType === 'board') {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.moveBoardFolder.description',
    );
  } else if (appType === 'report') {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.moveReportFolder.description',
    );
  } else {
    return t(
      'globalconfig.dataManagement.deletedDeptManagement.moveGroupFolder.description',
    );
  }
};
