import { PropsWithChildren, useEffect, useState } from 'react';
import { Portal } from '@radix-ui/react-portal';
import { clsx } from 'clsx';
import './index.css';

interface Props {
  width?: number;
  usePortal?: boolean;
  direction?: 'left' | 'right';
  className?: string;
  open: boolean;
}
function Panel({
  width = 350,
  usePortal = false,
  direction = 'right',
  open,
  className,
  children,
}: PropsWithChildren<Props>) {
  const [isVisible, setIsVisible] = useState(open);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (open) {
      setIsVisible(true);
      setAnimationClass(
        direction === 'left' ? 'panel-left-enter' : 'panel-right-enter',
      );
    } else {
      setAnimationClass(
        direction === 'left' ? 'panel-left-exit' : 'panel-right-exit',
      );
    }
  }, [open, direction]);

  const Comp = usePortal ? Portal : 'div';

  return (
    <Comp
      className={clsx(
        'panel',
        {
          hidden: !isVisible,
          'right-0': direction === 'right' && usePortal,
          'left-0': direction === 'left' && usePortal,
          fixed: usePortal,
          'h-full': !usePortal,
        },

        animationClass,
        className,
      )}
      style={{
        width: `${width}px`,
      }}
      onAnimationEnd={() => {
        if (!open) setIsVisible(false);
      }}
    >
      {children}
    </Comp>
  );
}

export default Panel;
