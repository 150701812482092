import { ExtensionInfo } from './types';

export function removeDuplicates(array: string[]): string[] {
  const set = new Set(array);
  return Array.from(set);
}

export function blacklistAddedList(
  oldList: ExtensionInfo[] = [],
  newList: ExtensionInfo[],
): ExtensionInfo[] {
  const nameSet = new Set<string>();
  oldList.forEach((info) => nameSet.add(info.extensionName));
  const resultList = [
    ...oldList,
    ...newList.filter((name) => !nameSet.has(name.extensionName)),
  ];
  return resultList;
}

export function oneDeletedBlacklist(
  oldList: ExtensionInfo[] = [],
  target: ExtensionInfo,
): ExtensionInfo[] {
  return oldList.filter((info) => info.extensionName !== target.extensionName);
}
