import * as Dialog from '../../../foundation/Dialog';
import { XMarkIcon } from '../../../icons/dop/24/XMark';
import { useActionsContext } from '../Context';

export function ServiceMapCloseButton() {
  const { t } = useActionsContext();

  return (
    <div className="wrap_btn">
      <Dialog.Close className="btn_icon small">
        <XMarkIcon />
        <span className="blind">{t('serviceMap.button.close')}</span>
      </Dialog.Close>
    </div>
  );
}
