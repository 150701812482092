import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useSuspenseQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import * as memberDataListQuery from '../../api/memberDataListQuery';
import { MemberDataInfo, Option } from '../../types';
import { Header } from './Header';
import { Provider } from './Provider';

export function MemberDataCard() {
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation('component');
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [memberDataListQuery.QUERY_KEY],
    queryFn: () =>
      memberDataListQuery.memberDataListQuery({
        size: 5,
        page: currentPage,
      } as Option),
  });

  const [selectedRows, setSelectedRows] = useState<MemberDataInfo[]>([]);

  const columnDefs: ColumnDef<MemberDataInfo>[] = [
    columnDefGenerator<MemberDataInfo>(
      'userName',
      t('globalconfig.dataManagement.memberManagement.field.name'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'userEmail',
      t('globalconfig.dataManagement.memberManagement.field.address'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'position',
      t('globalconfig.dataManagement.memberManagement.field.position'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'mail',
      t('globalconfig.dataManagement.memberManagement.field.mailstorage'),
    ),
    columnDefGenerator<MemberDataInfo>(
      'webFolder',
      t('globalconfig.dataManagement.memberManagement.field.folderstorage'),
    ),
  ];

  useEffect(() => {
    refetch({}).catch((error) => console.error(error));
  }, [currentPage, refetch]);

  if (error) {
    return <>{error}</>;
  }

  return (
    <div className="flex flex-col w-full gap-6">
      <Provider defaultSelectedRows={selectedRows}>
        <Header
          onClickSyncStorage={() => {
            refetch().catch((error) => console.error(error));
          }}
        />
        {res?.elements && res?.elements.length === 0 ? (
          <TablePrimitives.Root<MemberDataInfo>
            selectable={true}
            contents={res?.elements ?? []}
            columnDefs={columnDefs}
            onSelectContents={setSelectedRows}
          >
            <TablePrimitives.Contents />
            <TablePrimitives.Pagination
              currentPage={currentPage}
              totalPage={res?.page?.totalPages ?? 0}
              onNextPage={setCurrentPage}
              onPreviousPage={setCurrentPage}
              onClickPage={setCurrentPage}
              onFirstPage={() => setCurrentPage(0)}
              onLastPage={() =>
                setCurrentPage(
                  res?.page?.totalPages ? res.page.totalPages - 1 : 0,
                )
              }
            />
          </TablePrimitives.Root>
        ) : (
          <div className="flex items-center justify-center border rounded-lg w-full h-[256px] text-lg">
            {t('globalconfig.common.table.noData')}
          </div>
        )}
      </Provider>
    </div>
  );
}

export default MemberDataCard;
