import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

export function NoMemoTemplate() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-[800px]">
      <p className="text-[#999] font-medium">
        {t('organizer.memo.empty.memo')}
      </p>
    </div>
  );
}

export default NoMemoTemplate;
