'use client';

import { RectDotsIcon } from '@daouoffice/ui/lib/icons/dop/24/RectDots';
import { ServiceMapClient } from '@daouoffice/next/components/ServiceMap/ServiceMapClient';
import { useTranslation } from '@daouoffice/next/lib/i18n/client/useTranslation';

export function HeaderClient() {
  const { t } = useTranslation('common');
  const tFunc = (key: string) => t(key);

  return (
    <ServiceMapClient t={tFunc} serviceType="ep">
      <div role="button" className="btn_icon medium has_tooltip">
        <RectDotsIcon />
        <span className="tips right">{t('사이트 맵')}</span>
      </div>
    </ServiceMapClient>
  );
}

export default HeaderClient;
