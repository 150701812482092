import { FilterOption } from '@daouoffice/ui/lib/labs/Table/primitives/types';
import { TFunction } from 'i18next';
import { SearchType } from '../../types';

export const searchOptions = (t: TFunction<'component', unknown>) =>
  new Map<SearchType, string>([
    [
      'userName',
      t('globalconfig.secureManagement.memberAccessLog.table.field.name'),
    ],
    [
      'departmentName',
      t(
        'globalconfig.secureManagement.memberAccessLog.table.field.departmentName',
      ),
    ],
    ['ip', t('globalconfig.secureManagement.memberAccessLog.table.field.ip')],
  ]);

export const loginEventTypeOptions = (
  t: TFunction<'component', unknown>,
): FilterOption[] => [
  {
    key: 'loginEventType',
    value: 'LOGIN_SUCCESS',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.loginSuccess',
    ),
  },
  {
    key: 'loginEventType',
    value: 'PASSWORD_INCORRECT',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.passwordIncorrect',
    ),
  },
  {
    key: 'loginEventType',
    value: 'CAPTCHA_LOGIN_FAIL',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.captchaLoginFail',
    ),
  },
  {
    key: 'loginEventType',
    value: 'BLOCK_IP',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.blockIp',
    ),
  },
  {
    key: 'loginEventType',
    value: 'COMPANY_INVALID',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.companyInvalid',
    ),
  },
  {
    key: 'loginEventType',
    value: 'COMPANY_EXPIRED',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.companyExpired',
    ),
  },
  {
    key: 'loginEventType',
    value: 'BLOCK_MOBILE_ACCESS',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.blockMobileAccess',
    ),
  },
  {
    key: 'loginEventType',
    value: 'BLOCK_MAM',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.blockAccess',
    ),
  },
  {
    key: 'loginEventType',
    value: 'BLOCK_ACCESS',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.blockAccess',
    ),
  },
  {
    key: 'loginEventType',
    value: 'STOP_user',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.stopUser',
    ),
  },
  {
    key: 'loginEventType',
    value: 'DELETE_user',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.deleteUser',
    ),
  },
  {
    key: 'loginEventType',
    value: 'AUTH_LOGOUT_user',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.authLogoutUser',
    ),
  },
  {
    key: 'loginEventType',
    value: 'DUPLICATION_LOGIN',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.duplicationLogin',
    ),
  },
  {
    key: 'loginEventType',
    value: 'EXTRA_AUTH_ERROR',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.extraAuthError',
    ),
  },
  {
    key: 'loginEventType',
    value: 'PASSWORD_FORCE_CHANGE',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.passwordForceChange',
    ),
  },
  {
    key: 'loginEventType',
    value: 'UNKNOWN',
    title: t(
      'globalconfig.secureManagement.memberAccessLog.select.status.unknown',
    ),
  },
];

export const deviceOptions = [
  {
    key: 'deviceType',
    value: 'PC',
    title: 'PC',
  },
  {
    key: 'deviceType',
    value: 'PC_MESSANGER',
    title: 'PC_MESSANGER',
  },
  {
    key: 'deviceType',
    value: 'MOBILE_APP',
    title: 'MOBILE_APP',
  },
  {
    key: 'deviceType',
    value: 'MOBILE_BROWSER',
    title: 'MOBILE_BROWSER',
  },
];

export const userTypeOptions = [
  {
    key: 'userType',
    value: 'user',
    title: 'user',
  },
  {
    key: 'userType',
    value: 'admin',
    title: 'admin',
  },
];
