import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { clsx } from 'clsx';

export interface Props {
  deletedAt: string;
  storageUsage: string;
  className?: string;
}

export function DeptInfoCard({ deletedAt, storageUsage, className }: Props) {
  const { t } = useTranslation('component');

  return (
    <div className={clsx('bg-[#F9FAFB] rounded-lg px-5 py-4 gap-2', className)}>
      <div className="flex">
        <span className="w-1/5 text-sm text-[#aaaaaa]">
          {t('globalconfig.dataManagement.deletedDeptManagement.storage.usage')}
        </span>
        <span className="w-4/5 text-sm text-[#aaaaaa]">{storageUsage}</span>
      </div>
      <div className="flex mt-2">
        <span className="w-1/5 text-sm text-[#aaaaaa]">
          {t(
            'globalconfig.dataManagement.deletedDeptManagement.info.deletedAt',
          )}
        </span>
        <span className="w-4/5 text-sm text-[#aaaaaa]">{deletedAt}</span>
      </div>
    </div>
  );
}

export default DeptInfoCard;
