import { useRef } from 'react';
import type { IUserMenuInfo } from '../../../../types';
import { AppsDockConfigItem } from '../AppsDockConfigItem';
import { useContext } from '../Context';

export function AppsDockConfigList() {
  const { userApps, setUserApps } = useContext();
  const dragItem = useRef<number>();
  const dragOverItem = useRef<number>();

  const dragStart = (e: React.DragEvent<HTMLElement>, position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (e: React.DragEvent<HTMLElement>, position: number) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const newList: IUserMenuInfo[] = [...userApps];
    const dragItemValue = newList[dragItem.current];

    newList.splice(dragItem.current, 1);
    newList.splice(dragOverItem.current, 0, dragItemValue);
    dragItem.current = undefined;
    dragOverItem.current = undefined;
    setUserApps(newList);
  };

  return (
    <ul id="sortableList" className="ui-sortable">
      {userApps.map((item, index) => (
        <AppsDockConfigItem
          index={index}
          key={item.uid}
          item={item}
          onDragStart={dragStart}
          onDragEnter={dragEnter}
          onDragOver={(e) => e.preventDefault()}
          onDragEnd={drop}
        />
      ))}
    </ul>
  );
}

export default AppsDockConfigList;
