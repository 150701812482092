'use client';

import { useTranslation } from './use-translation';

export interface Props {
  namespace: string;
  i18nKey: string;
}

export function I18nText({ namespace, i18nKey }: Props) {
  const { t } = useTranslation(namespace);

  return <>{t(i18nKey)}</>;
}
