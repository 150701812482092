import { TFA_REGISTRATION_STATUS_URL } from '../../constants';

export async function deleteTfaMember(statusIdList: number[]) {
  try {
    await fetch(TFA_REGISTRATION_STATUS_URL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
      body: JSON.stringify({ data: statusIdList }),
    });
  } catch (error) {
    console.log('error', error);
  }
}
