import { useOrganizerAtom } from '../Provider';
import ToolItem from './tool-item';
import { FoldIcon } from '@daouoffice/ui/lib/icons/dop/16';

import { useTranslation } from 'react-i18next';
import styles from '../organizer-body.module.css';
import PanelMenu from '../panel-button';
import { useOrganizerContext } from '../Context';
import { isSettingItemsChanged } from '../lib/utils';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
function ToolItemList() {
  const { t } = useTranslation();
  const [organizerAtom, setOrganizerAtom] = useOrganizerAtom();

  const { shortcutItems, settingsItems, setSettingsItems } =
    useOrganizerContext();
  const { confirm } = useMessageDialog();

  const handleConfirm = async () => {
    if (await confirm(t('organizer.setting.alert.cancel'))) {
      return true;
    }
    return false;
  };

  const handleOnClickFoldButton = async () => {
    if (organizerAtom.panelState === 'setting') {
      if (
        isSettingItemsChanged(settingsItems, shortcutItems) &&
        !(await handleConfirm())
      ) {
        return;
      }
      setSettingsItems(shortcutItems);
    }
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'flip',
      panelState: 'none',
    }));
  };

  return (
    <ul className={styles.bottom_area}>
      <div className={styles.divider} />
      <PanelMenu
        hasBackground={false}
        key="organizer_settings"
        id="setting"
        name={t('organizer.setting')}
      />

      <ToolItem
        key="organizer_fold"
        className=""
        uid={0}
        name={t('organizer.fold')}
        itemType="svg"
        sortOrder={0}
        size="medium"
        onClick={() => void handleOnClickFoldButton()}
      >
        <FoldIcon />
      </ToolItem>
    </ul>
  );
}
export default ToolItemList;
