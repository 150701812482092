import { useSuspenseQuery } from '@tanstack/react-query';
import MemoList from '../memo-list';
import * as getMemoSearch from '../apis/getMemoSearch';
import NoMemoSearchResultTemplate from './no-data-template/no-memo-search-result-template';
import { useMemoContext } from '../Context';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

export function SearchResultList() {
  const { memoContext } = useMemoContext();

  const { t } = useTranslation();

  const {
    data: { data: memoListInfo },
    error,
  } = useSuspenseQuery({
    queryKey: [getMemoSearch.QUERY_KEY, memoContext.keyword],
    queryFn: async () => await getMemoSearch.getMemoSearch(memoContext.keyword),
  });

  if (error) {
    console.error(error);
    return;
  }

  return (
    <div>
      <div className="py-[12px]">
        <span className="text-[12px] text-[#999]">
          {t('organizer.memo.search.result')}
        </span>
        <span className="text-[12px] text-[#363636]">{` ${memoListInfo.count}`}</span>
      </div>
      {memoListInfo?.memoList.length > 0 ? (
        <MemoList memoList={memoListInfo.memoList} />
      ) : (
        <NoMemoSearchResultTemplate />
      )}
    </div>
  );
}

export default SearchResultList;
