import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';

interface Props {
  inUse: boolean;
}

export function InUseIndicator({ inUse: isOn }: Props) {
  const { t } = useTranslation('component');

  if (isOn)
    return (
      <p className="px-[6px] py-[4px] h-[28px] bg-[#1FCC77] text-center text-white text-[12px] font-[500] rounded-[8px]">
        {t('globalconfig.common.inUse')}
      </p>
    );

  return (
    <p className="px-[6px] py-[4px] h-[28px] bg-[#F1F3F4] text-center text-[#666666] text-[12px] font-[500] rounded-[8px]">
      {t('globalconfig.common.notInUse')}
    </p>
  );

  // if (isOn)
  //   return (
  //     <p className="px-[6px] py-[6px] h-[28px] bg-[--green-500] text-center text-white text-[12px] font-[500] rounded-[8px]">
  //       {t('globalconfig.common.inUse')}
  //     </p>
  //   );

  // return (
  //   <p className="px-[6px] py-[6px] h-[28px] bg-[--gray-200] text-center text-[--gray-700] text-[12px] font-[500] rounded-[8px]">
  //     {t('globalconfig.common.notInUse')}
  //   </p>
  // );
}
