/**
 * Preview 위치를 포인터의 위치에 상대적으로 계산
 * Preview가 어디에 위치할지를 결정하는 데 사용
 *
 * @param props.point.x - 포인터의 X 위치에서 수평 오프셋
 * @param props.point.y - 포인터의 Y 위치에서 수직 오프셋
 * @returns: GetOffsetFn 포인터의 위치에 기반한 오프셋을 계산하는 함수
 */
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
interface PointerOutsideOfPreviewProps {
  point: {
    x: string;
    y: string;
  };
}

export { pointerOutsideOfPreview, type PointerOutsideOfPreviewProps };
