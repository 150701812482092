import { Avatar, CheckBox } from '@daouoffice/ui';
import { ClassRoleInfo, RoleType } from '../../types';
import { Button } from '@dop-ui/react/shared/ui/button';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  roleType: RoleType;
  roleInfo: ClassRoleInfo;
  onClickDelete: (roleId: number) => void;
  onChangeConfig?: (roleId: number, config: ClassRoleInfo) => void;
}

export function ClassListItem({
  roleType,
  roleInfo,
  onClickDelete,
  onChangeConfig,
}: Props) {
  return (
    <div className="flex-shrink-0 flex items-center px-[12px] h-[32px] border border-solid border-[#D8D8D8] rounded-full">
      {roleType === 'USER' && (
        <Avatar className="mr-[7px]" alt={roleInfo.roleName} size={24} />
      )}
      <p className="mr-[7px] text-[#0F111A] text-[14px] font-[400]">
        {roleInfo.roleName}
      </p>
      {roleType === 'DEPARTMENT' && (
        <>
          {'(하위부서 포함 '}
          <CheckBox
            className="mt-[1px] px-[2px]"
            id={`department-${roleInfo.roleId}`}
            defaultChecked={roleInfo.isIncludeChildDepartment}
            onChange={() =>
              onChangeConfig &&
              onChangeConfig(roleInfo.roleId, {
                ...roleInfo,
                isIncludeChildDepartment: !roleInfo.isIncludeChildDepartment,
              })
            }
          />
          {' )'}
        </>
      )}
      <Button
        className="ml-[7px] p-[3px]"
        size="none"
        onClick={() => onClickDelete(roleInfo.roleId)}
      >
        <XMarkIcon className="size-[14px]" color="#363636" width={4} />
      </Button>
    </div>
  );
}
