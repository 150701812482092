export interface Props {
  /** @property 가이드 링크 이름 */
  name: string;
  /** @property 가이드 링크 */
  link?: string;
}

export default function GuideListItem({ name, link }: Props) {
  return (
    <li className="wrap_help_list">
      <a className="help_list" href={link ? link : `https://portal.daou.co.kr`}>
        {name}
      </a>
    </li>
  );
}
