import * as React from 'react';
import * as Dialog from '../../foundation/Dialog';
import { Provider, type ProviderProps } from './Context';

import '@daouoffice/design/dist/template/servicemap.css';

export interface Props extends Dialog.RootProps, ProviderProps {}
export function Root({
  children,
  t,
  serviceType,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <Dialog.Root {...props}>
      <Provider t={t} serviceType={serviceType}>
        {children}
      </Provider>
    </Dialog.Root>
  );
}

export default Root;
