import { useState } from 'react';
import clsx from 'clsx';
import { MagnifyingIcon } from '../../../icons/dop/24/Magnifying';
import { useActionsContext } from '../Context';

export function SearchBar() {
  const { t } = useActionsContext();
  const [isOpened, setOpenState] = useState(false);

  const handleOnFocus = () => {
    setOpenState(true);
  };

  const handleOnBlur = () => {
    setOpenState(false);
  };

  return (
    <div className={clsx('wrap_search', isOpened ? 'open' : '')}>
      <div className={clsx('group_input', isOpened ? 'focused' : '')}>
        <label className="label blind" htmlFor="test_search">
          {t('serviceMap.searchBar.label.searchMenu')}
        </label>
        <div className="input_search">
          <MagnifyingIcon />
          <input
            className="input_txt"
            id="test_search"
            type="search"
            title={t('serviceMap.searchBar.title')}
            placeholder={t('serviceMap.searchBar.placeholder')}
            value=""
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            readOnly
          />
          <button className="btn_icon clear">
            {t('serviceMap.searchBar.label.refresh')}
          </button>
        </div>
      </div>
      <div className="search_list">
        <div className="recent_searches">
          <p className="blind title">
            {t('serviceMap.searchBar.label.recentSearchList')}
          </p>
          <ul>
            <li className="search_item">
              <a href="https://portal.daou.co.kr" className="link">
                내 근태관리
              </a>
              <a href="https://portal.daou.co.kr" className="btn_icon delete">
                삭제
              </a>
            </li>
            <li className="search_item">
              <a href="https://portal.daou.co.kr" className="link">
                내 인사관리
              </a>
              <a href="https://portal.daou.co.kr" className="btn_icon delete">
                삭제
              </a>
            </li>
            <li className="search_item">
              <a href="https://portal.daou.co.kr" className="link">
                캘린더
              </a>
              <a href="https://portal.daou.co.kr" className="btn_icon delete">
                삭제
              </a>
            </li>
            <li className="search_item">
              <a href="https://portal.daou.co.kr" className="link">
                내 계약
              </a>
              <a href="https://portal.daou.co.kr" className="btn_icon delete">
                삭제
              </a>
            </li>
          </ul>
        </div>
        <div className="recent_used">
          <p className="title">최근 사용 앱</p>
          <ul className="group_app_list">
            <li className="wrap_app_list">
              <a href="https://portal.daou.co.kr" className="app_list">
                <div className="app_icon">
                  <i className="icon ic_mail"></i>
                </div>
                <div className="wrap_app_info">
                  <div className="app_name">
                    <p className="title">메일</p>
                  </div>
                </div>
              </a>
            </li>
            <li className="wrap_app_list">
              <a href="https://portal.daou.co.kr" className="app_list">
                <div className="app_icon">
                  <i className="icon ic_approval"></i>
                </div>
                <div className="wrap_app_info">
                  <div className="app_name">
                    <p className="title">전자결재</p>
                  </div>
                </div>
              </a>
            </li>
            <li className="wrap_app_list">
              <a href="https://portal.daou.co.kr" className="app_list">
                <div className="app_icon">
                  <i className="icon ic_calendar"></i>
                </div>
                <div className="wrap_app_info">
                  <div className="app_name">
                    <p className="title">캘린더</p>
                  </div>
                </div>
              </a>
            </li>
            <li className="wrap_app_list">
              <a href="https://portal.daou.co.kr" className="app_list">
                <div className="app_icon">
                  <i className="icon ic_boards"></i>
                </div>
                <div className="wrap_app_info">
                  <div className="app_name">
                    <p className="title">게시판</p>
                  </div>
                </div>
              </a>
            </li>
            <li className="wrap_app_list">
              <a href="https://portal.daou.co.kr" className="app_list">
                <div className="app_icon">
                  <i className="icon ic_boards"></i>
                </div>
                <div className="wrap_app_info">
                  <div className="app_name">
                    <p className="title">게시판</p>
                  </div>
                </div>
              </a>
            </li>
            <li className="wrap_app_list">
              <a href="https://portal.daou.co.kr" className="app_list">
                <div className="app_icon">
                  <i className="icon ic_boards"></i>
                </div>
                <div className="wrap_app_info">
                  <div className="app_name">
                    <p className="title">게시판</p>
                  </div>
                </div>
              </a>
            </li>
            <li className="wrap_app_list">
              <a href="https://portal.daou.co.kr" className="app_list">
                <div className="app_icon">
                  <i className="icon ic_boards"></i>
                </div>
                <div className="wrap_app_info">
                  <div className="app_name">
                    <p className="title">게시판</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
