import * as React from 'react';
import { AppContentsInfo, AppType } from '../types';

export interface IValuesContext {
  selectedAppType: AppType | undefined;
  deptName: string | undefined;
  deptId: number | undefined;
  selectedRows: AppContentsInfo[];
}

export interface IActionsContext {
  setSelectedAppType: React.Dispatch<React.SetStateAction<AppType | undefined>>;
  setDeptName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDeptId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedRows: React.Dispatch<React.SetStateAction<AppContentsInfo[]>>;
}

export const ValuesContext = React.createContext<IValuesContext>({
  selectedAppType: undefined,
  deptName: '',
  deptId: -1,
  selectedRows: [],
});

export const ActionsContext = React.createContext<IActionsContext>({
  setSelectedAppType: () => undefined,
  setDeptName: () => undefined,
  setDeptId: () => undefined,
  setSelectedRows: () => undefined,
});

export function Provider({ children }: React.PropsWithChildren) {
  const [selectedAppType, setSelectedAppType] = React.useState<
    AppType | undefined
  >();
  const [deptName, setDeptName] = React.useState<string | undefined>();
  const [deptId, setDeptId] = React.useState<number | undefined>();
  const [selectedRows, setSelectedRows] = React.useState<AppContentsInfo[]>([]);

  const valuesContext = {
    selectedAppType,
    deptName,
    deptId,
    selectedRows,
  };

  const actionsContext = {
    setSelectedAppType,
    setDeptName,
    setDeptId,
    setSelectedRows,
  };

  return (
    <ValuesContext.Provider value={valuesContext}>
      <ActionsContext.Provider value={actionsContext}>
        {children}
      </ActionsContext.Provider>
    </ValuesContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = React.useContext(ValuesContext);
  const actions = React.useContext(ActionsContext);

  return { ...values, ...actions };
};
