import { MemoType } from '../types';
import MemoItem from './memo-item';

interface Props {
  memoList: MemoType[];
}

export function MemoList({ memoList }: Props) {
  return (
    <ul className="flex flex-col w-full gap-3 pb-3">
      {memoList.map((memoItem) => (
        <MemoItem key={memoItem.id} memo={memoItem} viewType="summary" />
      ))}
    </ul>
  );
}

export default MemoList;
