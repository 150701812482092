import Link from '@tiptap/extension-link';

/**
 * LinkConfig
 * 에디터 내부에서 링크 동작 하지 않도록 사용 (Chat)
 * 이외의 경우 Link 가져와서 사용하면 된다.
 */
const LinkConfig = Link.extend({ inclusive: false }).configure({
  openOnClick: false,
});

export { Link, LinkConfig };
