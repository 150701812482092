import { PropsWithChildren, useState } from 'react';
import { SimpleCodeFormClient } from './simple-code-form-client';

export interface Props {
  title?: string;
}

/**
 * @component ExtScreenLock
 * 잠금화면 해제 로직만 포함되어 있는 ScreenLock. (통합설정에서 사용)
 */
export function ExtScreenLock({ title, children }: PropsWithChildren<Props>) {
  const [isLocked, setLockState] = useState<boolean>(true);

  return isLocked ? (
    <div className="flex size-full justify-center main">
      <SimpleCodeFormClient
        title={title}
        type="web"
        onSuccess={() => setLockState(false)}
      />
    </div>
  ) : (
    <>{children}</>
  );
}

export default ExtScreenLock;
