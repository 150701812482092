import {
  PasswordPolicyConfigCheckList,
  WritablePasswordPolicyConfig,
} from './types';

export const requireCharacterDatas: PasswordPolicyConfigCheckList[] = [
  {
    id: 'mandatoryLowercase',
    disabled: true,
    translationKey: 'requireCharacter.lowercase',
  },
  {
    id: 'englishUppercase',
    disabled: false,
    translationKey: 'requireCharacter.uppercase',
  },
  {
    id: 'mandatoryCharNumber',
    disabled: true,
    translationKey: 'requireCharacter.number',
  },
  {
    id: 'mandatoryCharSymbol',
    disabled: true,
    translationKey: 'requireCharacter.symbol',
  },
];

export const passwordRuleDatas: PasswordPolicyConfigCheckList[] = [
  {
    id: 'tabooCharSameSerialAlphabet',
    disabled: true,
    translationKey: 'passwordRule.alphabet',
  },
  {
    id: 'tabooCharSameSerialNumber',
    disabled: true,
    translationKey: 'passwordRule.number',
  },
  {
    id: 'tabooCharPersonalInformation',
    disabled: true,
    translationKey: 'passwordRule.information',
  },
  {
    id: 'keyboardSeq',
    disabled: false,
    translationKey: 'passwordRule.keyboard',
  },
];

type WritablePasswordPolicyConfigKeys = keyof WritablePasswordPolicyConfig;

// 키 값들의 배열을 변수로 정의
export const writablePasswordPolicyKeys: WritablePasswordPolicyConfigKeys[] = [
  'passwordErrorCount',
  'englishUppercase',
  'keyboardSeq',
  'passwordReuseLimit',
  'passwordChangeCycle',
  'forcePasswordChange',
];
