import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import type { MemoColors } from './types';

interface MemoContextInfo {
  favoriteCount: number;
  memoId: number | null;
  content: string;
  prevContent: string;
  textContent: string;
  prevTextContent: string;
  requiredSave: boolean;
  updatedAt: string;
  isFavorite: boolean;
  color: MemoColors;
  prevColor: MemoColors;
  keyword: string;
}

const MemoContext = createContext<IMemoContext>({
  memoContext: {
    memoId: null,
    favoriteCount: 0,
    content: '',
    prevContent: '',
    textContent: '',
    prevTextContent: '',
    updatedAt: '',
    requiredSave: false,
    isFavorite: false,
    color: 'yellow',
    prevColor: 'yellow',
    keyword: '',
  },
  setMemoContext: () => undefined,
});

interface IMemoContext {
  memoContext: MemoContextInfo;
  setMemoContext: Dispatch<SetStateAction<MemoContextInfo>>;
}

export function Provider({ children }: PropsWithChildren) {
  const [memoContext, setMemoContext] = useState<MemoContextInfo>({
    memoId: null,
    favoriteCount: 0,
    content: '',
    prevContent: '',
    textContent: '',
    prevTextContent: '',
    requiredSave: false,
    updatedAt: '',
    isFavorite: false,
    color: 'yellow',
    prevColor: 'yellow',
    keyword: '',
  });

  const values = {
    memoContext,
    setMemoContext,
  };

  return <MemoContext.Provider value={values}>{children}</MemoContext.Provider>;
}

export const useMemoContext = () => {
  const ctx = useContext(MemoContext);
  if (ctx === null) throw new Error('Memo Context Provider Missing');
  return ctx;
};
