import { gql } from 'graphql-request';

import { fetchGraphQL } from '../../../../../lib/fetch/client';
import type { IdentifiableShortcutItem, UpdatedLinkItem } from '../types';

const ID = 'rearrangeShortcutList';
const Query = gql`
  mutation ${ID}($input: shortcutRearrangeQueryInput!) {
    rearrangeShortcutList(input: $input)
  }
`;

export interface ReArrangeInput {
  sortedList: IdentifiableShortcutItem[];
  updatedLinkList: UpdatedLinkItem[];
  deletedList: IdentifiableShortcutItem[];
}

export interface ReArrangeResponse {
  errors: {
    message: string;
  }[];
  data: {
    rearrangeShortcutList: null;
  };
}

export async function rearrangeShortcutQuery(
  rearrangeInput: ReArrangeInput,
): Promise<ReArrangeResponse> {
  const response = await fetchGraphQL<{ input: ReArrangeInput }>({
    query: Query,
    variables: {
      input: rearrangeInput,
    },
  });
  return (await response.json()) as ReArrangeResponse;
}
