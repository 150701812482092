import { fetch } from '../../../../../../lib/fetch/client';
import { NOTIFICATION_CONFIG_API } from '../../constants';
import { BaseResponse } from '../../types';
import { NotificationConfig } from '../types';

export const QUERY_KEY =
  'QueryKeys.GlobalConfig.DataManagement.NotificationConfig';

export async function getNotificationConfig(): Promise<NotificationConfig> {
  const response = await fetch(NOTIFICATION_CONFIG_API, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<NotificationConfig>;

  return responseData.data;
}

export async function updateNotificationConfig(notificationConfig: {
  notiCriteria: number;
  notiMethod: string;
}) {
  return await fetch(NOTIFICATION_CONFIG_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(notificationConfig),
  });
}
