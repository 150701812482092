import { IPInfo } from '../../types';

export function ipInfoToString(ipInfo: IPInfo): string {
  const ipType = () => {
    if (ipInfo.ipType === 'SINGLE_IP') return 'ip';
    if (ipInfo.ipType === 'IP_RANGE') return 'range';
    if (ipInfo.ipType === 'SUBNET_MASK') return 'subnet';
  };
  const result = ipInfo.ipGroupName
    ? `[${ipInfo.ipGroupName}] ${ipType()} : ${ipInfo.ipAddress}`
    : `${ipType()} : ${ipInfo.ipAddress}`;
  return result;
}
