import { useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import * as Stacker from '@daouoffice/ui/lib/foundation/Stacker';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';
import { isIpBlockListNeedsSaveAtom } from './store/isIpBlockListNeedsSaveAtom';
import { isOverseasListNeedsSaveAtom } from './store/isOverseasListNeedsSaveAtom';
import { FirstPage } from './FirstPage';
import { AccessRestrictedIP } from './AccessRestrictedIP';
import { OverseasLoginRestriction } from './OverseasLoginRestriction';

export function LoginSecureConfig() {
  const isIpListNeedsSave = useAtomValue(isIpBlockListNeedsSaveAtom);
  const isOverseasListNeedsSave = useAtomValue(isOverseasListNeedsSaveAtom);

  const setNeedsSave = useSetAtom(isContentNeedsSaveAtom);

  useEffect(() => {
    setNeedsSave(isIpListNeedsSave || isOverseasListNeedsSave);
  }, [isIpListNeedsSave, isOverseasListNeedsSave, setNeedsSave]);

  return (
    <div className="px-[72px] py-[40px] overflow-y-scroll">
      <Stacker.Root defaultPage="firstPage">
        <Stacker.Content name="firstPage">
          <FirstPage />
        </Stacker.Content>
        <Stacker.Content name="restrictedIp">
          <AccessRestrictedIP />
        </Stacker.Content>
        <Stacker.Content name="overseasLogin">
          <OverseasLoginRestriction />
        </Stacker.Content>
      </Stacker.Root>
    </div>
  );
}
