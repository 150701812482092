import { Suspense } from 'react';
import { AuthList } from './index';

export default function AuthListClient() {
  return (
    <AuthList.Root>
      <Suspense
        fallback={
          <div className="flex justify-center items-center !border-solid !border-l-[1px] border-[#EDEDED]">
            Loading...
          </div>
        }
      >
        <AuthList.Content />
      </Suspense>
    </AuthList.Root>
  );
}
