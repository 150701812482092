import { fetch } from '../../../../../lib/fetch/client';
import type { EditMemoResponse, UpdateMemoRequest } from '../types';

export async function updateMemo(
  req: UpdateMemoRequest,
): Promise<EditMemoResponse> {
  const pathUrl = `/api/portal/common/memo/${req.id}`;
  const apiResponse = await fetch(pathUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      content: req.content,
      color: req.color,
      textContent: req.textContent,
    }),
  });
  const response = (await apiResponse.json()) as EditMemoResponse;
  if (!apiResponse.ok) {
    console.log('update Memo API 오류 발생');
    throw new Error(response.message);
  }
  return response;
}
