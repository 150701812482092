import { fetch } from '../../../../../../lib/fetch/client';
import type { ApiResponse, MultiMember } from '../types';

interface Params {
  integrationId: number;
  userList: MultiMember[];
  companyGroupId?: string | number;
}

export async function updateMultiMember({
  integrationId,
  userList,
  companyGroupId,
}: Params) {
  const response = await fetch(
    `/api/portal/setting/integration/${integrationId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companyGroupId,
        userList,
      }),
    },
  );
  const responseJson = (await response.json()) as ApiResponse<{ data: string }>;

  if (responseJson.message) {
    throw new Error(responseJson.message);
  }
  return responseJson;
}
