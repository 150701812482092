import { clsx } from 'clsx';
import { useEffect, useMemo } from 'react';
import { useMemoContext } from '../Context';
import {
  useStack,
  useStackMethod,
} from '@daouoffice/ui/lib/foundation/Stacker';
import { Button } from '@dop-ui/react/shared/ui/button';
import {
  PencilIcon,
  PreviousIndicatorIcon,
} from '@daouoffice/ui/lib/icons/dop/16';

import DeleteMemoForm from './delete-memo-form';

import { deleteMemo } from '../apis/deleteMemo';
import * as getMemoQuery from '../apis/getMemo';
import * as getMemoListQuery from '../apis/getMemoList';
import * as getMemoSearchQuery from '../apis/getMemoSearch';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import type { OrganizerMemoPages } from '..';
import styles from '../../../organizer-panel.module.css';
import { useOrganizerAtom } from '../../../Provider';
import MemoItem from '../memo-list/memo-item';
import PanelHeader from '../../panel-header';
import {
  useToastMessage,
  Templates,
} from '@dop-ui/react/features/toast-message';

export function OrganizerMemoDetail() {
  const { stack } = useStack<OrganizerMemoPages>();
  const { pop, push } = useStackMethod<OrganizerMemoPages>();
  const canPop = stack.length > 1;

  const { info } = useToastMessage();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [, setOrganizerAtom] = useOrganizerAtom();

  const {
    memoContext: {
      memoId,
      content,
      textContent,
      requiredSave,
      color,
      isFavorite,
      updatedAt,
      keyword,
    },
    setMemoContext,
  } = useMemoContext();

  useEffect(() => {
    setMemoContext((prev) => ({
      ...prev,
      prevContent: content,
      prevTextContent: textContent,
      prevColor: color,
    }));
  }, []);

  const deleteMemoMutation = useMutation({
    onSuccess: () => {
      info(<Templates.Basic description={t('organizer.memo.toast.deleted')} />);
      handlePop();
    },
    onError: (e) => {
      info(<Templates.Basic description={e.message} />);
      handlePop();
    },
    mutationFn: async () => await deleteMemo(memoId!),
  });

  const handleOnClickUpdate = () => {
    push('memo');
  };

  const handlePop = () => {
    void queryClient.invalidateQueries({
      queryKey: [getMemoQuery.QUERY_KEY, memoId],
    });

    void queryClient.invalidateQueries({
      queryKey: [getMemoListQuery.QUERY_KEY],
    });

    keyword &&
      void queryClient.invalidateQueries({
        queryKey: [getMemoSearchQuery.QUERY_KEY, keyword],
      });

    setMemoContext((prev) => ({
      ...prev,
      memoId: null,
      content: '',
      updatedAt: '',
      requiredSave: false,
      isFavorite: false,
      color: 'yellow',
    }));
    pop();
  };

  const handleOnClickPrev = () => {
    handlePop();
  };

  const handleClickCloseBtn = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'default',
      panelState: 'none',
    }));
  };

  const prev = useMemo(() => {
    return (
      canPop && (
        <Button
          className="!w-[32px] !h-[32px] !p-1 mr-2 rounded-lg bg-transparent hover:bg-gray-100"
          onClick={() => handleOnClickPrev()}
        >
          <PreviousIndicatorIcon />
        </Button>
      )
    );
  }, [canPop, requiredSave]);

  const deleteButton = useMemo(() => {
    return (
      memoId && (
        <DeleteMemoForm onClickConfirm={() => deleteMemoMutation.mutate()} />
      )
    );
  }, [memoId]);

  const updateButton = useMemo(() => {
    return (
      <Button
        size="small"
        className="flex justify-center items-center gap-1 align-bottom p-1 bg-transparent"
        onClick={handleOnClickUpdate}
      >
        <PencilIcon size={16} />
        <span className="text-[12px]">{t('organizer.memo.button.update')}</span>
      </Button>
    );
  }, [memoId]);

  return (
    <div className={styles.Wrapper}>
      <PanelHeader
        title={t('organizer.memo.title')}
        onClickClose={() => void handleClickCloseBtn()}
      />
      <div className={clsx(styles.Content, 'gap-2')}>
        <div
          className={clsx(styles.ContentHeader, 'w-[350px] pt-[16px] pb-[4px]')}
        >
          {prev}

          <span className="text-[16px] text-[#363636] font-medium flex-1 ">
            {t('organizer.memo.detail')}
          </span>

          {updateButton}
          {deleteButton}
        </div>
        <div className="w-full">
          <MemoItem
            memo={{
              id: memoId!,
              content: content,
              textContent: textContent,
              color: color,
              isFavorite: isFavorite,
              createdAt: '',
              updatedAt: updatedAt,
            }}
            viewType="scroll"
          />
        </div>
      </div>
    </div>
  );
}

export default OrganizerMemoDetail;
