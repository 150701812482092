import { fetch } from '../../../../../../lib/fetch/client';
import { MOBILE_ACCESS_LIMIT_CONFIG_URL } from '../../constants';

export const MUTATION_KEY = 'mobileDeviceAccessConfigMutation';

export async function updateMobileDeviceAccessStatus(isBlocked: boolean) {
  try {
    return await fetch(MOBILE_ACCESS_LIMIT_CONFIG_URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        configValue: isBlocked,
      }),
    });
  } catch (error) {
    console.error(error);
  }
}
