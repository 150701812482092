import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { QUERY_KEY, getOrgCodeList } from '../../apis/get-org-code';

interface AdditionalClassProps {
  id: number;
  name: string;
}

interface Props {
  selectedSiteId: number;
  selectedClassName: string;
  selectedAdditionalClass: AdditionalClassProps;
  setSelectedAdditionalClass: (newClass: AdditionalClassProps) => void;
}

export function AdditionalClassSelect({
  selectedSiteId,
  selectedClassName,
  selectedAdditionalClass,
  setSelectedAdditionalClass,
}: Props) {
  const { data: organizationCodes, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY, selectedSiteId, selectedClassName],
    queryFn: async () =>
      await getOrgCodeList(selectedSiteId, selectedClassName),
  });

  useEffect(() => {
    setSelectedAdditionalClass(
      organizationCodes[0]
        ? { id: organizationCodes[0].id, name: organizationCodes[0].name }
        : {
            id: -1,
            name: '',
          },
    );
  }, [organizationCodes]);

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > BasicInfo : ',
      error,
    );
  }

  return (
    <div className="flex items-center gap-2 min-w-[120px]">
      <select
        className="h-[32px] w-[180px] px-[8px] rounded-[4px] border border-solid border-[#D8D8D8]"
        value={selectedAdditionalClass.id}
        onChange={(e) => {
          const selectedId = Number(e.target.value);
          const selectedItem = organizationCodes.find(
            (item) => item.id === selectedId,
          );

          if (selectedItem) {
            setSelectedAdditionalClass({
              id: selectedItem.id,
              name: selectedItem.name,
            });
          }
        }}
      >
        {organizationCodes.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default AdditionalClassSelect;
