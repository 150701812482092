import { Option } from '../types';

export const searchParamGenerator = (options?: Option) => {
  const params = new URLSearchParams();
  if (options) {
    options.page && params.append('page', options.page.toString());
    options.sort && options.sort.forEach((sort) => params.append('sort', sort));
    options.size && params.append('size', options.size.toString());
  }
  return params.toString();
};
