import { fetch } from '../../../../../lib/fetch/client';
import type { MemoType } from '../types';

interface Response {
  data: MemoType;
  code?: string;
  message?: string;
}

export const QUERY_KEY = 'organizer-memo';

export async function getMemo(id: number): Promise<Response> {
  const url = `/api/portal/common/memo/${id}`;

  const apiResponse = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const response = (await apiResponse.json()) as Response;

  return response;
}
