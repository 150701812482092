import { PropsWithChildren } from 'react';

export interface Props {
  title: string;
}

export function InfoCard({ title, children }: PropsWithChildren<Props>) {
  return (
    <div className="flex flex-col rounded-lg bg-[#F9FAFB] p-6 min-h-[152px]">
      <span className="text-[#111] text-[18px] font-medium -tracking-[1.08px] leading-6 mb-4">
        {title}
      </span>
      {children}
    </div>
  );
}

export default InfoCard;
