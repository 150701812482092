import { PropsWithChildren } from 'react';

/**
 * ServiceMap.GuideList 컴포넌트 속성
 */
export interface Props {
  /** @property 가이드리스트 타이틀 */
  title: string;
}

export function GuideList({ children, title }: PropsWithChildren<Props>) {
  return (
    <>
      <h2 className="title heading_large semibold">{title}</h2>
      <div className="group_list">
        <ul className="group_help_list">{children}</ul>
      </div>
    </>
  );
}

export default GuideList;
