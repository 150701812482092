import { fetch } from '../../../../../../lib/fetch/client';
import { DetailRequestInfo } from '../types';
import { DATA_REQUEST_DETAIL_API } from '../../constants';
import { BaseResponse } from '../../types';

export const QUERY_KEY =
  'QueryKeys.GlobalConfig.DataManagement.DetailRequestInfo';

export async function getDetailInfo(id: string): Promise<DetailRequestInfo> {
  const response = await fetch(DATA_REQUEST_DETAIL_API + '/' + id, {
    headers: { 'Content-Type': 'application/json' },
  });

  const responseData =
    (await response.json()) as BaseResponse<DetailRequestInfo>;

  return responseData.data;
}
