import Button from '@dop-ui/react/shared/ui/button';
import styles from '../organizer-panel.module.css';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24';
interface Props {
  title: string;
  onClickClose: () => void;
}
function PanelHeader({ title, onClickClose }: Props) {
  return (
    <div className={styles.Header}>
      <p className={styles.HeaderTitle}>{title}</p>
      {onClickClose && (
        <Button className={styles.HeaderButton} onClick={onClickClose}>
          <CloseIcon className="text-[#363636] font-bold" size={24} />
        </Button>
      )}
    </div>
  );
}

export default PanelHeader;
