const BASIC_MANAGEMENT_PREFIX = '/api/portal/setting/basic';

/**
 * @description 기본 관리 서비스 정보 API
 */
export const BASIC_SERVICE_INFO_API = '/api/portal/setting/basic-service';
/**
 * @description 사용중인 앱 정보 API
 */
export const IN_USE_APPS_INFO_API = (portalType: 'employee' | 'business') =>
  BASIC_MANAGEMENT_PREFIX + '/' + portalType + '/app/usage-status';

/**
 * @description 포털별 설정 GNB 메뉴 API
 */
export const PORTAL_GNB_MENU_API = (portalType: 'EMPLOYEE' | 'BUSINESS') =>
  BASIC_MANAGEMENT_PREFIX + '/' + portalType + '/gnb/menu';
