import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Switch } from '@daouoffice/ui/lib/foundation/Switch';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useToastMessage } from '@dop-ui/react/features/toast-message/';
import { TitleAndDescription } from './TitleAndDescription';
import * as loginSecureConfigAPIs from './apis/loginSecureConfig';

export function DuplicatedLogin() {
  const { t } = useTranslation('component');
  const {
    data: duplicationLoginConfig,
    error: duplicaitonError,
    isLoading,
  } = useQuery({
    queryKey: [loginSecureConfigAPIs.QUERY_KEY_DUPLICATION],
    queryFn: loginSecureConfigAPIs.getDuplicationLoginConfig,
  });
  const [mutatedState, setMutatedState] = useState(false);
  const toaster = useToastMessage();

  const queryClient = useQueryClient();

  const duplicateLoginMutation = useMutation({
    mutationFn: loginSecureConfigAPIs.updateLoginDuplicateConfig,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [loginSecureConfigAPIs.QUERY_KEY_DUPLICATION],
      });
      if (mutatedState)
        toaster.info(
          t(
            'globalconfig.secureManagement.loginSecureConfig.blockDuplication.use',
          ),
        );
      else
        toaster.info(
          t(
            'globalconfig.secureManagement.loginSecureConfig.blockDuplication.noUse',
          ),
        );
    },
  });
  const onChangeDuplicateLoginStatus = (status: boolean) => {
    duplicateLoginMutation.mutate(status);
    setMutatedState(status);
  };

  if (duplicaitonError) {
    console.error(
      'GlobalCofig > SecureManagement > LoginSecureConfig > loginMethodConfig Error: ',
      duplicaitonError,
    );
  }

  if (isLoading) return;

  return (
    <TitleAndDescription
      title={t(
        'globalconfig.secureManagement.loginSecureConfig.blockDuplication.title',
      )}
      description={t(
        'globalconfig.secureManagement.loginSecureConfig.blockDuplication.description',
      )}
    >
      <Switch
        id={'globalConfig.secure.login.duplicateBlock'}
        checked={duplicationLoginConfig?.isBlockDuplicateLogin ?? false}
        onChange={onChangeDuplicateLoginStatus}
      />
    </TitleAndDescription>
  );
}
