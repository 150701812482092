import { BaseResponse, CompanyListInfo, CompanyListResponse } from '../type';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.BasicInfo.CompanyList';

const BASIC_INFO_COMPANY_LIST_URL =
  '/api/portal/setting/company-group/company/list';

export async function getCompanyList(
  companyGroupId: string | number,
): Promise<CompanyListInfo[]> {
  try {
    const response = await fetch(
      BASIC_INFO_COMPANY_LIST_URL + '/' + companyGroupId,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Referer-Info': window.location.host,
        },
      },
    );

    if (!response.ok) {
      throw new Error('getCompanyList 오류 발생.');
    }

    const resData =
      (await response.json()) as BaseResponse<CompanyListResponse>;

    return resData.data.companyList;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}
