import { fetch } from '../../../../../lib/fetch/client';

export interface FavoriteInfo {
  id: number;
  isFavorite: boolean;
  code?: string;
  message?: string;
}

export async function updateMemoFavorite({ id, isFavorite }: FavoriteInfo) {
  const pathUrl = `/api/portal/common/memo/favorite/${id}/${isFavorite}`;
  const apiResponse = await fetch(pathUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const response = (await apiResponse.json()) as FavoriteInfo;

  if (!apiResponse.ok) {
    throw new Error(response.message);
  }
}
