import { gql } from 'graphql-request';
import { MeResponse, UserData } from '../../lib/auth/types';
import { fetchGraphQL } from '../../lib/fetch/client';

export const ID = 'userProfileQuery';
export const Query = gql`
  query ${ID} {
    me {
      id
      name
      loginId
      email
      locale
      userInfo {
        profileImageUrl
        grade {
          name
        }
      }
      mainDepartment {
        name
      }
      isAccessibleSettingPortal
      isAccessibleBusinessPortal
    }
  }
`;

export async function getProfileInfo(): Promise<UserData> {
  const response = await fetchGraphQL({ query: Query });
  const data = (await response.json()) as MeResponse;

  return data.data.me;
}
