import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Ascend (16x16) 아이콘
 * @param props
 * @returns
 */
export function AscendIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 14 14" size={14}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69064 4.50314C6.8615 4.33229 7.1385 4.33229 7.30936 4.50314L11.6844 8.87814C11.8552 9.049 11.8552 9.326 11.6844 9.49686C11.5135 9.66771 11.2365 9.66771 11.0656 9.49686L7 5.43122L2.93436 9.49686C2.7635 9.66771 2.4865 9.66771 2.31564 9.49686C2.14479 9.326 2.14479 9.049 2.31564 8.87814L6.69064 4.50314Z"
        stroke="currentColor"
      />
    </IconContainer>
  );
}

export default AscendIcon;
