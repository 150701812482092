import fetch from '../../../../../lib/fetch/client';

export async function deleteMemoRecentlySearch(id: number) {
  try {
    const pathUrl = `/api/portal/common/memo/recent/searches/${id}`;
    const resp = await fetch(pathUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!resp.ok) {
      throw new Error('deleteMemoRecentlySearch API 오류 발생.');
    }
  } catch (e) {
    console.log(e);
  }
}
