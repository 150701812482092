import { CheckBox } from '@daouoffice/ui/lib/foundation/CheckBox/CheckBox';
import { CheckInfo } from './types';

export interface Props {
  info: CheckInfo;
  onCheckClick: (id: number) => void;
}

export function CheckInfoItem({ info, onCheckClick }: Props) {
  return (
    <li key={info.id} className="check_list">
      <CheckBox
        id={`${info.id}`}
        label={info.name}
        defaultChecked={info.isChecked}
        onChange={() => {
          onCheckClick(info.id);
        }}
      />
    </li>
  );
}
