import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { AuthListInfo } from './types';

export interface IContextValues {
  authList: AuthListInfo[];
  setAuthList: React.Dispatch<React.SetStateAction<AuthListInfo[]>>;
}

export const Context = createContext<IContextValues>({
  authList: [],
  setAuthList: () => undefined,
});

export function Provider({ children }: PropsWithChildren) {
  const [authList, setAuthList] = useState<AuthListInfo[]>([]);

  return (
    <Context.Provider value={{ authList, setAuthList }}>
      {children}
    </Context.Provider>
  );
}

export const useContextValues = () => useContext(Context);
