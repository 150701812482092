import { fetch } from '../../../../../../lib/fetch/client';
import { QuotaInfo, UserCountInfo } from '../types';

export const QUERY_KEY = 'memberInfoQuery';

interface ResponseBody {
  data?: {
    userCountInfoDto?: UserCountInfo;
    companyDto?: QuotaInfo;
  };
}

export async function memberInfoQuery() {
  try {
    const FETCH_URL = `/api/portal/setting/storage/user`;
    const res = (await fetch(FETCH_URL)).json();
    const data = (await res) as ResponseBody;
    return data;
  } catch (error) {
    console.error(error);
  }
}
