import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import * as appDataQuery from '../../../api/appDataQuery';
import { useContext } from '../../Provider';
import { AppInfoTable } from './AppInfoTable';
import { Header } from './Header';

export function AppContents() {
  const { deptId, selectedAppType } = useContext();
  const [currentPage, setCurrentPage] = useState(0);
  const onPageChangeHandler = (page: number) => setCurrentPage(page);
  // TODO : 각 앱별로 데이터 불러오는 로직 필요
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [appDataQuery.QUERY_KEY],
    queryFn: async () =>
      await appDataQuery.appDataQuery(deptId, selectedAppType),
  });

  useEffect(() => {
    refetch().catch((error) => console.error(error));
  }, [currentPage, selectedAppType, deptId, refetch]);

  if (error) {
    return <>{error}</>;
  }

  return (
    <div className="flex flex-col w-full gap-6">
      <Header />
      <AppInfoTable contents={res ? res?.data ?? [] : []} />
      <div className="flex justify-center">
        <TablePrimitives.Pagination
          currentPage={currentPage}
          onPreviousPage={onPageChangeHandler}
          onNextPage={onPageChangeHandler}
          onClickPage={onPageChangeHandler}
          onFirstPage={() => setCurrentPage(0)}
          onLastPage={() =>
            setCurrentPage(res?.page?.totalPage ? res?.page?.totalPage - 1 : 0)
          }
          maxPageCount={res?.page?.offset ?? 10}
          totalPage={res?.page?.totalPage ?? 0}
        />
      </div>
    </div>
  );
}

export default AppContents;
