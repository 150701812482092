import { AppsDockConfigList } from '../AppsDockConfigList';

export function Contents() {
  return (
    <div className="wrap_menu_contents">
      <AppsDockConfigList />
    </div>
  );
}

export default Contents;
