import fetch from '../../../../../../lib/fetch/client';
import { UPDATE_PASSWORD_POLICY_API } from '../constants';
import type {
  UpdatePasswordPolicyResponse,
  WritablePasswordPolicyConfig,
} from '../types';

export const QUERY_KEY =
  'GlobalConfig.SecureManagement.PasswordPolicyConfig.UpdateConfig';

export async function updatePasswordPolicyConfig(
  params: WritablePasswordPolicyConfig,
) {
  const response = await fetch(UPDATE_PASSWORD_POLICY_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(params),
  });

  const responseData = (await response.json()) as UpdatePasswordPolicyResponse;
  return responseData;
}
