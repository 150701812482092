import { useTranslation } from '../../../../../../lib/i18n/client/useTranslation';
import noDataImg from './no-data.png';

export default function NoMemoSearchResultTemplate() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center gap-3 h-[262px] py-[48px]">
      <img src={noDataImg.src} alt="" width={114} height={144} />
      <p className="text-[#999] font-medium">
        {t('organizer.memo.empty.search.result')}
      </p>
    </div>
  );
}
