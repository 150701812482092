import { useTranslation } from '../../../../../../lib/i18n/client/useTranslation';
import { InfoTriangle } from '@dop-ui/react/shared/ui/svg/info-triangle';
import styles from './no-data.module.css';
export function NoWorksTemplate() {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <InfoTriangle
        viewBox="0 0 40 40"
        width={40}
        height={40}
        className={styles.Icon}
      />
      <div className={styles.DescriptionGroup}>
        <p className={styles.Description}>{t('organizer.empty.works')}</p>
        <p className={styles.SubDescription}>
          {t('organizer.empty.works.desc')}
        </p>
      </div>
    </div>
  );
}

export default NoWorksTemplate;
