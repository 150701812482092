/**
 * Tiptap에서 주로 사용되는 기본 Extension
 * https://tiptap.dev/docs/editor/extensions/functionality/starterkit
 * 
 * Nodes는 다음을 포함함. Blockquote BulletList CodeBlock Document HardBreak Heading HorizontalRule ListItem OrderedList Paragraph Text
 * Marks는 다음을 포함함. Bold Code Italic Strike
 * Extension은 다음을 포함함. Dropcursor Gapcursor History
 * 
 * const editor = new Editor({
    content: '<p>Example Text</p>',
    extensions: [
      StarterKit.configure({
        // Disable an included extension
        history: false,

        // Configure an included extension
        heading: {
          levels: [1, 2],
        },
      }),
    ],
  })
 */
export { StarterKit } from '@tiptap/starter-kit';
