import { atom } from 'jotai';

const activateFilters = atom<Map<string, string>>(new Map());

export const activatedFiltersAtom = atom(
  (get) => get(activateFilters),
  (get, set, key: string, value: string) => {
    const newFilters = new Map(get(activateFilters));
    newFilters.set(key, value);
    set(activateFilters, newFilters);
  },
);

export const deleteActivatedFiltersAtom = atom(
  null,
  (get, set, key: string) => {
    const newFilters = new Map(get(activateFilters));
    newFilters.delete(key);
    set(activateFilters, newFilters);
  },
);
