'use client';
import * as React from 'react';
import type { DefaultItemInfo, ShortcutItemInfo } from './types';
import { QUERY_KEY, getOrganizer } from './apis/getOrganizerQuery';
import { useSuspenseQuery } from '@tanstack/react-query';

export type TContextValue = Record<string, string>;

export interface IContextValues {
  readonly defaultItems: DefaultItemInfo[];
  readonly shortcutItems: ShortcutItemInfo[];
  readonly settingsItems: ShortcutItemInfo[];
}

export interface IContextActions {
  setDefaultItems: React.Dispatch<React.SetStateAction<DefaultItemInfo[]>>;
  setShortcutItems: React.Dispatch<React.SetStateAction<ShortcutItemInfo[]>>;
  setSettingsItems: React.Dispatch<React.SetStateAction<ShortcutItemInfo[]>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  defaultItems: [],
  shortcutItems: [],
  settingsItems: [],
});

export const ActionsContext = React.createContext<IContextActions>({
  setDefaultItems: () => undefined,
  setShortcutItems: () => undefined,
  setSettingsItems: () => undefined,
});

export const Context = React.createContext<TContextValue>({});

export function Provider({ children }: React.PropsWithChildren) {
  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getOrganizer(),
  });

  const [defaultItems, setDefaultItems] = React.useState<DefaultItemInfo[]>(
    data.defaultList,
  );
  const [shortcutItems, setShortcutItems] = React.useState<ShortcutItemInfo[]>(
    data.shortcutList,
  );
  const [settingsItems, setSettingsItems] = React.useState<ShortcutItemInfo[]>(
    data.shortcutList,
  );

  React.useEffect(() => {
    setShortcutItems(data.shortcutList);
    setSettingsItems(data.shortcutList);
  }, [data.shortcutList]);

  React.useEffect(() => {
    setDefaultItems(data.defaultList);
  }, [data.defaultList]);

  const ctxValues = {
    defaultItems,
    shortcutItems,
    settingsItems,
  };

  const ctxActions = {
    setDefaultItems,
    setShortcutItems,
    setSettingsItems,
  };

  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useOrganizerContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
