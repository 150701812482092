import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { monitorForExternal } from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import type { DragTargets } from '../types';

export type MonitorForElementsArgs = Parameters<typeof monitorForElements>[0];
export type MonitorForExternalArgs = Parameters<typeof monitorForExternal>[0];

type MonitorableElementsParams = MonitorForElementsArgs & DragTargets;
type MonitorableExternalParams = MonitorForExternalArgs & DragTargets;

export type MonitorableParams =
  | MonitorableElementsParams
  | MonitorableExternalParams;

/**
 * Drag 이벤트를 받는 element를 monitor
 * Argument에 대한 명세는 ../types.ts
 * https://atlassian.design/components/pragmatic-drag-and-drop/core-package/monitors
 */
export function montiorable({ type, ...props }: MonitorableParams) {
  if (type === 'element') {
    return monitorForElements(props as MonitorableElementsParams);
  } else if (type === 'external') {
    return monitorForExternal(props as MonitorableExternalParams);
  } else {
    throw new Error('Invalid type provided');
  }
}

export { monitorForElements, monitorForExternal };

export default montiorable;
