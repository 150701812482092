import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { AppType } from '../../types';

interface Props {
  type: string;
  appList: AppType[];
  offset: number;
}

const QUERY_KEY = 'organizer-quickmenu-infinite-scroll';

export function useInfiniteScrollQuery({ type, appList, offset }: Props) {
  const getPageApp = ({ pageParam = 1 }) => {
    const start = offset * (pageParam - 1);
    const end = start + offset;
    const appPageList = appList.slice(start, end);

    return {
      appPageList: appPageList,
    };
  };

  const {
    data: appPageList,
    fetchNextPage: getNextPage,
    isSuccess: isScrollSuccess,
    hasNextPage: isHasNextPage,
    isError,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY, type],
    queryFn: getPageApp,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length + 1;
      return lastPage.appPageList.length === 0 ||
        lastPage.appPageList.length < offset
        ? undefined
        : nextPage;
    },
    retry: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const scrollList = useMemo(() => {
    const list = appPageList?.pages.map((item) => {
      return item.appPageList;
    });
    return list;
  }, [appPageList]);

  return {
    scrollList,
    getNextPage,
    isScrollSuccess,
    isHasNextPage,
    isError,
    isLoading,
    isFetching,
  };
}
