import { Editor } from '@daouoffice/ui/lib/foundation/Editor';
import { clsx } from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import type { MemoToolbarType } from '../../types';
import LetterCaseIcon from '@daouoffice/ui/lib/icons/dop/24/letter-case';
import ListCheckIcon from '@daouoffice/ui/lib/icons/dop/24/list-check';
import PalleteIcon from '@daouoffice/ui/lib/icons/dop/24/palette';
import { Button } from '@dop-ui/react/shared/ui/button';
import memoStyles from '../../memo.module.css';

interface Props {
  editor: Editor | null;
  setToolbarShow: Dispatch<SetStateAction<MemoToolbarType>>;
}
function MemoToolbar({ editor, setToolbarShow }: Props) {
  if (!editor) {
    return null;
  }

  return (
    <>
      <Button
        className={memoStyles.MemoIconBtn}
        onClick={() => setToolbarShow('format')}
      >
        <LetterCaseIcon size={20} />
      </Button>
      <Button
        className={clsx(
          {
            '!bg-[#E3E3E4]': editor.isActive('taskList'),
          },
          memoStyles.MemoIconBtn,
        )}
        onClick={() => editor.chain().focus().toggleTaskList().run()}
      >
        <ListCheckIcon size={20} />
      </Button>

      <Button
        className={memoStyles.MemoIconBtn}
        onClick={() => setToolbarShow('color')}
      >
        <PalleteIcon size={20} />
      </Button>
    </>
  );
}

export default MemoToolbar;
