import { fetch } from '../../../../../../lib/fetch/client';

export const QUERY_KEY =
  'QueryKeys.GlobalConfig.MultiCompanyManagement.GetPasswordSync';

export async function getMultiMemberList(
  companyGroupId?: string | number,
): Promise<boolean | null> {
  if (!companyGroupId) return null;

  const response = await fetch(
    `/api/portal/setting/integration/password/config/${companyGroupId}`,
  );

  const { data } = (await response.json()) as { data: boolean };

  return data;
}
