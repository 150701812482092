import * as fetchClient from '../../../../../../../../lib/fetch/client';
import {
  AdminTableData,
  AdminTableType,
  HeaderSort,
  TableRowInfo,
} from '../types';
import { getTableSortTypeQueryString } from '../utils';

export interface ResponseBody<T> {
  data: T;
}

export interface IAppManagerUserDto {
  userId: number;
  profileImageUrl?: string;
  username: string;
  loginId: string;
  positionName: string;
  createdAt: string;
  isMaster: boolean;
  memberList: AppManagerMemberDto[];
  appList: IAppInfo[];
}

export interface IAppInfo {
  id: number;
  appCode: string;
  appName: string;
}

export interface AppManagerMemberDto {
  departmentName: string;
  dutyName: string;
}

export interface Page {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
}

export interface IPageResponseDtoAppManagerUserDto {
  hasElements: boolean;
  page: Page;
  elements: IAppManagerUserDto[];
}
export interface IMasterListResponseDto {
  hasElements: boolean;
  page: Page;
  elements: IAppManagerUserDto[];
}

export interface DepartmentMemberList {
  memberId: number;
  departmentId: number;
  departmentName: string;
  departmentPath: string;
  dutyId: number;
  dutyName: string;
}

export const QUERY_KEY = 'admin-table-data';

export const fetchTableData = async (
  page: number,
  type: AdminTableType,
  selectedAppCode?: string,
  keyword?: string,
  selectedHeader?: HeaderSort,
  tableSize?: number,
) => {
  if (type === 'management') {
    return await getManagementTableInfo(
      getTableSortTypeQueryString(
        page,
        tableSize ?? 10,
        selectedHeader,
        keyword,
      ),
    );
  } else if (type === 'app')
    return await getAppsTableDataInfo(
      selectedAppCode,
      getTableSortTypeQueryString(
        page,
        tableSize ?? 10,
        selectedHeader,
        keyword,
      ),
    );
  else
    return await getMastersList(
      getTableSortTypeQueryString(
        page,
        tableSize ?? 10,
        selectedHeader,
        keyword,
      ),
    );
};

async function getAppsTableDataInfo(
  appCode?: string,
  queryString: string = '',
): Promise<AdminTableData> {
  try {
    const pathUrl = `/api/portal/admin/app-manager/app/${appCode}${queryString}`;
    // TODO : 최고관리자 메뉴 추가 시, 해당 코드 수정
    if (appCode && appCode !== '' && appCode !== 'masterApp') {
      const resp = await fetchClient.withAuth(pathUrl);

      if (!resp.ok) {
        throw new Error('앱 별 관리자 목록 조회 API 오류 발생.');
      }

      const { data } =
        (await resp.json()) as ResponseBody<IPageResponseDtoAppManagerUserDto>;

      return {
        page: data.page.pageNumber,
        size: data.page.pageSize,
        totalPage: data.page.totalPages,
        total: data.page.totalElements,
        isLastPage: data.page.pageNumber === data.page.totalPages,
        data: data.elements.map((item) => adapter(item)),
      };
    } else {
      return await getMastersList(queryString);
    }
  } catch (e) {
    console.error(e);
    return {
      page: 1,
      size: 0,
      totalPage: 1,
      total: 0,
      isLastPage: true,
      data: [],
    };
  }
}

async function getManagementTableInfo(
  queryString: string = '',
): Promise<AdminTableData> {
  try {
    const pathUrl = `/api/portal/admin/app-manager${queryString}`;
    const resp = await fetchClient.withAuth(pathUrl);

    if (!resp.ok) {
      throw new Error('관리자 별 관리자 목록 조회 API 오류 발생.');
    }

    const { data } =
      (await resp.json()) as ResponseBody<IPageResponseDtoAppManagerUserDto>;

    return {
      page: data.page.pageNumber,
      size: data.page.pageSize,
      totalPage: data.page.totalPages,
      total: data.page.totalElements,
      isLastPage: data.page.pageNumber === data.page.totalPages,
      data: data.elements.map((item) => adapter(item)),
    };
  } catch (e) {
    console.error(e);
    return {
      page: 1,
      size: 0,
      totalPage: 1,
      total: 0,
      isLastPage: true,
      data: [],
    };
  }
}

async function getMastersList(queryString?: string): Promise<AdminTableData> {
  try {
    const pathUrl = `/api/portal/admin/master${queryString ?? ''}`;
    const resp = await fetchClient.withAuth(pathUrl);

    if (!resp.ok) {
      throw new Error('최고관리자 조회 API 오류 발생.');
    }

    const { data } =
      (await resp.json()) as ResponseBody<IMasterListResponseDto>;

    return {
      page: data.page.pageNumber,
      size: data.page.pageSize,
      totalPage: data.page.totalPages,
      total: data.page.totalElements,
      isLastPage: data.page.pageNumber === data.page.totalPages,
      data: data.elements.map((item) => adapter(item)),
    };
  } catch (e) {
    console.error(e);
    return {
      page: 1,
      size: 0,
      totalPage: 1,
      total: 0,
      isLastPage: true,
      data: [],
    };
  }
}

function adapter(info: IAppManagerUserDto): TableRowInfo {
  return {
    type: 'body',
    name: info?.username,
    imgSrc: info.profileImageUrl,
    account: info.loginId,
    userId: info.userId,
    position: info.positionName,
    appList: info.appList.map((app) => app.id),
    department: info.memberList?.[0]?.departmentName ?? '-',
    appointment: info.memberList?.[0]?.dutyName ?? '-',
    date: info.createdAt,
    isMaster: info.isMaster,
  };
}
