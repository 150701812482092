import { Avatar } from '@daouoffice/ui/lib/foundation/Avatar';
import { useEffect, useState } from 'react';
import { getRandomColor } from '../../../lib/utils/color';

import { clsx } from 'clsx';
export interface Props {
  id?: number;
  name: string;
  faviconUrl?: string;
  size: number;
  className?: string;
}

function LinkItem({ id, name, faviconUrl, size, className = '' }: Props) {
  const [itemColor, setItemColor] = useState(getRandomColor(id));
  useEffect(() => {
    setItemColor(getRandomColor(id));
  }, [setItemColor, id]);

  const faviconName = name.replace(/^(https?:\/\/)?(www\.)?/, '');

  return (
    <Avatar
      src={faviconUrl}
      type="squircle"
      alt={faviconName}
      size={size}
      backgroundColor={itemColor}
      className={clsx(className, 'rounded-lg')}
    />
  );
}

export default LinkItem;
