import { APPS_MANAGEMENT } from '../../main/menuPaths';

const menuKeyPath = APPS_MANAGEMENT + '/mail';

export const CONFIG_PATH = menuKeyPath + '/config';
export const GROUP_PATH = menuKeyPath + '/group';
export const MEMBER_GROUP_PATH = menuKeyPath + '/member-group';
export const BANNER_PATH = menuKeyPath + '/banner';
export const ALIAS_PATH = menuKeyPath + '/alias';
export const BULK_MAIL_PATH = menuKeyPath + '/bulk-mail';
export const DOMAIN_MAILBOX_PATH = menuKeyPath + '/domain-mailbox';
export const STATIONERY_PATH = menuKeyPath + '/stationery';
export const TEMPLATE_PATH = menuKeyPath + '/template';
export const STATISTICS_PATH = menuKeyPath + '/statistics';
