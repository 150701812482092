/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import { CheckBox } from '@daouoffice/ui/lib/foundation/CheckBox/CheckBox';
import { ChevronRightIcon } from '@daouoffice/ui/lib/icons/dop/24';

import { getAllSubNodes, getDepartment } from './getOrg';
import { NodeProps } from './interface';
import NodeList from './NodeList';
import { useOrgChartContext } from './Provider';
import { addChilds, treesAtom, searchTreesAtom } from './Store';
import { DEPARTMENT, MEMBER } from './constants';

export interface Props {
  node: NodeProps;
  isSearch?: boolean;
  depth: number;
  isLoadedAll?: boolean;
  isActive: boolean;
  updateNodes: () => void;
}

const DeptNode = ({
  node,
  isSearch,
  depth,
  isLoadedAll = false,
  isActive,
  updateNodes,
}: Props) => {
  const context = useOrgChartContext();
  const { onNodeClick } = context.actions;
  const { useMultiSelect, uuid, type } = context.state;
  const [, setTrees] = useAtom(treesAtom);
  const [, setSearchTrees] = useAtom(searchTreesAtom);
  const nodes = node.childrenList || [];
  const [isOpend, setIsOpened] = useState(!!nodes.length);
  const [isLoaded, setIsLoaded] = useState(!!nodes.length); // TODO 한번에 가져올때 하위 노드들에 isLoaded 작업 해줘야함.
  const [isLoadedAllState, setIsLoadedAll] = useState(isLoadedAll);
  const isChecked = node.selected;
  const hasAdditional = isSearch && !!node.parentName;

  useEffect(() => {
    if (isLoadedAll) {
      setIsLoadedAll(true);
      setIsLoaded(true);
    }
  }, [isLoadedAll]);

  const toggleFold = async () => {
    setIsOpened(!isOpend);
    if (isLoaded) return;
    await getChildren();
  };

  const getChildren = async () => {
    if (isLoaded) return;
    setIsLoaded(true);
    const dept = (await getDepartment(
      node.departmentId as number,
      type,
    )) as NodeProps;
    if (dept.childrenList) {
      if (isSearch) {
        setSearchTrees((searchTrees) => addChilds(searchTrees, dept));
      } else {
        setTrees((trees) => addChilds(trees, dept));
      }
    }
  };

  const getAllChildrens = async () => {
    if (isLoadedAllState) return;
    setIsLoadedAll(true);
    const childrenList = await getAllSubNodes(
      node.departmentId as number,
      type,
    );
    if (isSearch) {
      setSearchTrees((searchTrees) =>
        addChilds(searchTrees, {
          ...node,
          childrenList,
        }),
      );
    } else {
      setTrees((trees) =>
        addChilds(trees, {
          ...node,
          childrenList,
        }),
      );
    }
  };

  const toggleSelect = async () => {
    type === DEPARTMENT ? await getChildren() : await getAllChildrens();
    updateNodes();
  };

  const clickNode = () => {
    console.log('부서 프로필 오픈');
    if (onNodeClick && type !== MEMBER) onNodeClick(node);
  };

  return (
    <>
      <div className="subject" onKeyPress={() => {}} role="presentation">
        <IconButton
          title=""
          size="small"
          className="open"
          onClick={() => {
            toggleFold().catch((e) => console.log(e));
          }}
          aria-hidden="true"
        >
          <ChevronRightIcon rotate={isOpend ? 90 : 0} />
        </IconButton>
        <div className={`info${isActive ? ' active' : ''}`} onClick={clickNode}>
          <p className="body_medium semibold" title={node.name}>
            {node.name}
          </p>
          {hasAdditional && <p className="additional">{node.parentName}</p>}
          {useMultiSelect && (
            <CheckBox
              id={`${node.nodeType}_${node.departmentId}_${uuid}`}
              onChange={() => {
                toggleSelect().catch((e) => console.log(e));
              }}
              defaultChecked={isChecked}
            ></CheckBox>
          )}
        </div>
      </div>
      {isOpend && (
        <NodeList
          nodes={nodes}
          className="sub"
          depth={depth + 1}
          isSearch={isSearch}
          isLoadedAll={isLoadedAllState}
        ></NodeList>
      )}
    </>
  );
};

export default DeptNode;
