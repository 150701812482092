import * as React from 'react';

export interface IContextValues {
  accessEnabled: boolean;
}

export interface IContextActions {
  setAccessEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  accessEnabled: false,
});

export const ActionsContext = React.createContext<IContextActions>({
  setAccessEnabled: () => undefined,
});

export function Provider({ children }: React.PropsWithChildren) {
  const [accessEnabled, setAccessEnabled] = React.useState(false);

  const contextValues = {
    accessEnabled,
  };

  const contextActions: IContextActions = {
    setAccessEnabled,
  };

  return (
    <ActionsContext.Provider value={contextActions}>
      <ValuesContext.Provider value={contextValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
