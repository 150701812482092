import * as Icon from '@daouoffice/ui/lib/icons/dop/24';
import { CheckInfoItem } from './CheckInfoItem';
import { useContextValues } from './Context';
import { AuthListInfo } from './types';

export interface Props {
  info: AuthListInfo;
}

export default function CheckList({ info: authListInfo }: Props) {
  const { authList, setAuthList } = useContextValues();

  const onCheckClick = (id: number) => {
    const newList = [
      ...authListInfo.checkList.map((info) => {
        if (info.id === id) return { ...info, isChecked: !info.isChecked };
        return info;
      }),
    ];
    setAuthList([
      ...authList.map((authInfo) => {
        if (authInfo.title === authListInfo.title) {
          return {
            ...authInfo,
            checkList: newList,
          };
        }
        return authInfo;
      }),
    ]);
  };

  return (
    <div className="wrap_check_list">
      <div className="check_list_title">
        <span className="body_medium bold">{authListInfo.title}</span>
        {authListInfo.tooltip ? (
          <div className="has_tooltip">
            <i className="icon ic_medium">
              <Icon.QuestionCircleIcon />
            </i>
            <span className="tips bottom">{authListInfo.tooltip}</span>
          </div>
        ) : (
          <></>
        )}
      </div>
      <ul className="check_list_contents">
        {authListInfo.checkList
          .sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          })
          .filter((info) => {
            return true || info.isChecked;
          })
          .map((info) => {
            return (
              <CheckInfoItem
                key={`${authListInfo.title}_${info.id}`}
                info={info}
                onCheckClick={onCheckClick}
              />
            );
          })}
      </ul>
    </div>
  );
}
