import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import * as deletedDeptListQuery from '../../../api/deletedDeptListQuery';
import { Option } from '../../../types';
import { useContext } from '../../Provider';
import { DeletedDeptTable } from './DeletedDeptTable';
import { Header } from './Header';

export function DefaultContents() {
  const { deptId, selectedAppType } = useContext();
  const [currentPage, setCurrentPage] = useState(0);

  const onPageChangeHandler = (page: number) => setCurrentPage(page);
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [deletedDeptListQuery.LIST_QUERY_KEY],
    queryFn: async () =>
      await deletedDeptListQuery.deletedDeptListQuery({ offset: 10 } as Option),
  });

  useEffect(() => {
    refetch().catch((error) => console.error(error));
  }, [currentPage, selectedAppType, deptId, refetch]);

  if (error) {
    return <>{error}</>;
  }

  return (
    <div className="flex flex-col w-full gap-6">
      <Header />
      <DeletedDeptTable contents={res ? res?.data ?? [] : []} />
      <div className="flex justify-center">
        <TablePrimitives.Pagination
          currentPage={currentPage}
          onPreviousPage={onPageChangeHandler}
          onNextPage={onPageChangeHandler}
          onClickPage={onPageChangeHandler}
          onFirstPage={() => setCurrentPage(0)}
          onLastPage={() =>
            setCurrentPage(res?.page?.totalPage ? res?.page?.totalPage - 1 : 0)
          }
          maxPageCount={res?.page?.offset ?? 10}
          totalPage={res?.page?.totalPage ?? 0}
        />
      </div>
    </div>
  );
}

export default DefaultContents;
