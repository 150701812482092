import { PropsWithChildren } from 'react';

import * as Jotai from 'jotai';
import { Primitives as ToastPrimitives } from '@daouoffice/ui/lib/foundation/Toast';

import { toastStore } from './store';
import ToastContainer from './Container';

export interface Props extends ToastPrimitives.ProviderProps {}

export function Provider({
  children,
  direction = 'right',
  duration = 5000,
  usePortal = false,
  maxLength = 5,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <Jotai.Provider store={toastStore}>
      <ToastPrimitives.Provider
        swipeDirection={direction}
        duration={duration}
        direction={direction}
        usePortal={usePortal}
        {...props}
      >
        <ToastContainer maxLength={maxLength} />
        <ToastPrimitives.Viewport />
        {children}
      </ToastPrimitives.Provider>
    </Jotai.Provider>
  );
}

export default Provider;
