import styles from './style.module.css';

export function Fallback() {
  return (
    <div className={styles.Fallback}>
      <div className={styles.Fallback__Viewport}>
        <div className={styles.Fallback__Button} />
        <div className={styles.Fallback__Button} />
        <div className={styles.Fallback__Button} />
      </div>
    </div>
  );
}

export default Fallback;
