import { AppContents } from './components/app-contents';
import { AppList } from './components/app-list';
import { Contents } from './components/contents';
import { Root } from './components/root';

export function AppManagement() {
  return (
    <Root>
      <Contents>
        <AppList />
        <AppContents />
      </Contents>
    </Root>
  );
}

export default AppManagement;
