import * as React from 'react';
import type { TTransFunc, ServiceType } from './types';

export type TContextValue = Record<string, string>;

export interface IContextValues {
  readonly serviceType: ServiceType;
  readonly isUsedOnly: boolean;
}

export interface IContextActions {
  t: TTransFunc;
  setUsedOnlyState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  serviceType: 'ep',
  isUsedOnly: false,
});

export const ActionsContext = React.createContext<IContextActions>({
  t: () => '',
  setUsedOnlyState: () => undefined,
});

export const Context = React.createContext<TContextValue>({});

export interface ProviderProps {
  serviceType: ServiceType;
  t?: TTransFunc;
}

export function Provider({
  children,
  serviceType,
  t,
}: React.PropsWithChildren<ProviderProps>) {
  const [isUsedOnly, setUsedOnlyState] = React.useState<boolean>(false);

  const transFunc = (key: string) => {
    if (t) {
      const parsedVal = t(key);
      return parsedVal ? parsedVal : key;
    }

    return key;
  };

  const ctxValues = {
    serviceType,
    isUsedOnly,
  };

  const ctxActions = {
    t: transFunc,
    setUsedOnlyState,
  };

  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
