import { ADMIN_LOG_URL } from '../../constants';
import { BaseResponseWithPage } from '../../types';
import { LogInfo } from '../types';

export const QUERY_KEY = 'adminLogQuery';

export async function getAdminLog(queryString: string) {
  try {
    const res = (await (
      await fetch(`${ADMIN_LOG_URL}?${queryString}`)
    ).json()) as BaseResponseWithPage<LogInfo>;

    return res;
  } catch (error) {
    console.error(error);
  }
}
