import { AppType, ResponseBody } from '../types';
import { approvalDataQuery } from './approvalDataQuery';
import { boardDataQuery } from './boardDataQuery';
import { groupDataQuery } from './groupDataQuery';
import { reportDataQuery } from './reportDataQuery';

// TODO: 추후 API 개발 후 수정 필요
export const MOCK_URL_PATH = '/gw/ad/api';
export const QUERY_KEY = 'appDataQuery';

export async function appDataQuery(
  deptId: number | undefined,
  selcectedAppType: AppType | undefined,
) {
  if (!deptId) return {};

  if (selcectedAppType === 'board') {
    return await boardDataQuery(deptId);
  } else if (selcectedAppType === 'report') {
    return await reportDataQuery(deptId);
  } else if (selcectedAppType === 'group') {
    return await groupDataQuery(deptId);
  } else if (selcectedAppType === 'approval') {
    return await approvalDataQuery(deptId);
  }
  return {} as ResponseBody;
}
