import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { DatePicker } from '../../../../../../foundation/DatePicker';
import {
  FILTER_KEY_DATE_END,
  FILTER_KEY_DATE_START,
  FILTER_OPTION_DATE_30DAYS,
  FILTER_OPTION_DATE_7DAYS,
  FILTER_OPTION_DATE_90DAYS,
  FILTER_OPTION_DATE_SELF_INPUT,
  FILTER_OPTION_DATE_TODAY,
} from '../../../constants';
import {
  activatedFiltersAtom,
  deleteActivatedFiltersAtom,
} from '../../../stores/activatedFilterAtom';
import { FilterOption } from '../../../types';
import { useFilterContext } from '../Context';

export interface DateOptionProps {
  option: FilterOption;
}

export function DateOption({ option }: DateOptionProps) {
  const [selfInputStartDate, setSelfInputStartDate] = useState<
    string | undefined
  >(undefined);
  const [selfInputEndDate, setSelfInputEndDate] = useState<string | undefined>(
    undefined,
  );
  const [, setActivateFilter] = useAtom(activatedFiltersAtom);
  const [, deleteActiveFilter] = useAtom(deleteActivatedFiltersAtom);
  const { selectedOptions, setSelectedOptions } = useFilterContext();

  const onClickSelect = () => {
    const newSelectedOptions = new Set<string>();

    if (!selectedOptions.has(option.key)) {
      newSelectedOptions.add(option.key);
      addFilter();
    } else {
      deleteActiveFilter(FILTER_KEY_DATE_START);
      deleteActiveFilter(FILTER_KEY_DATE_END);
    }
    setSelectedOptions(newSelectedOptions);
  };

  const addFilter = () => {
    switch (option.key) {
      case FILTER_OPTION_DATE_TODAY:
        setActivateFilter(FILTER_KEY_DATE_START, new Date().toISOString());
        setActivateFilter(FILTER_KEY_DATE_END, new Date().toISOString());
        break;
      case FILTER_OPTION_DATE_7DAYS:
        setActivateFilter(
          FILTER_KEY_DATE_START,
          calculateDateRangePastFromToday(7).toISOString(),
        );
        setActivateFilter(FILTER_KEY_DATE_END, new Date().toISOString());
        break;
      case FILTER_OPTION_DATE_30DAYS:
        setActivateFilter(
          FILTER_KEY_DATE_START,
          calculateDateRangePastFromToday(30).toISOString(),
        );
        setActivateFilter(FILTER_KEY_DATE_END, new Date().toISOString());
        break;
      case FILTER_OPTION_DATE_90DAYS:
        setActivateFilter(
          FILTER_KEY_DATE_START,
          calculateDateRangePastFromToday(90).toISOString(),
        );
        setActivateFilter(FILTER_KEY_DATE_END, new Date().toISOString());
        break;
      case FILTER_OPTION_DATE_SELF_INPUT:
        if (!selfInputStartDate || !selfInputEndDate) return;
        setActivateFilter(FILTER_KEY_DATE_START, selfInputStartDate);
        setActivateFilter(FILTER_KEY_DATE_END, selfInputEndDate);
        break;
    }
  };

  useEffect(() => {
    if (selfInputStartDate)
      setActivateFilter(FILTER_KEY_DATE_START, selfInputStartDate);
  }, [selfInputStartDate, setActivateFilter]);

  useEffect(() => {
    if (selfInputEndDate)
      setActivateFilter(FILTER_KEY_DATE_END, selfInputEndDate);
  }, [selfInputEndDate, setActivateFilter]);

  useEffect(() => {
    if (
      selfInputStartDate &&
      selfInputEndDate &&
      new Date(selfInputStartDate) > new Date(selfInputEndDate)
    ) {
      setSelfInputEndDate(selfInputStartDate);
    }
  }, [selfInputStartDate, selfInputEndDate]);

  return option.key === FILTER_OPTION_DATE_SELF_INPUT ? (
    <div
      className={clsx('flex flex-col justify-start', {
        'bg-gray-400': selectedOptions.has(option.key),
        'bg-gray-300': !selectedOptions.has(option.key),
      })}
    >
      <button
        className={'flex items-center w-full p-[8px]'}
        onClick={() => onClickSelect()}
      >
        {option.title}
      </button>
      <div className="block">
        <DatePicker
          defaultDate={new Date()}
          withBorder={false}
          onDateSelect={(date) => setSelfInputStartDate(formatDate(date))}
        />
        <div className="flex items-center">
          <span className="ps-2">~</span>
          <DatePicker
            defaultDate={new Date()}
            minDate={
              selfInputStartDate ? new Date(selfInputStartDate) : undefined
            }
            withBorder={false}
            onDateSelect={(date) => setSelfInputEndDate(formatDate(date))}
          />
        </div>
      </div>
    </div>
  ) : (
    <button
      className={clsx('flex items-center w-full p-[8px]', {
        'bg-gray-400': selectedOptions.has(option.key),
        'bg-gray-300': !selectedOptions.has(option.key),
      })}
      onClick={() => onClickSelect()}
    >
      {option.title}
    </button>
  );
}

function calculateDateRangePastFromToday(pastDays: number): Date {
  const range = pastDays * 24 * 60 * 60 * 1000;
  const today = new Date();
  const past = new Date(today.getTime() - range);
  return past;
}

function formatDate(date: Date | null): string {
  return date
    ? date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    : '';
}
