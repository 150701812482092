import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { TitleAndDescription } from './TitleAndDescription';
import * as loginSecureConfigAPIs from './apis/loginSecureConfig';

export function LoginTimeout() {
  const { t } = useTranslation('component');
  const {
    data: timeoutConfig,
    error: timeoutError,
    isLoading,
  } = useQuery({
    queryKey: [loginSecureConfigAPIs.QUERY_KEY_TIMEOUT],
    queryFn: loginSecureConfigAPIs.getLoginTimeoutConfig,
  });

  if (timeoutError) {
    console.error(
      'GlobalCofig > SecureManagement > LoginSecureConfig > timeoutConfig Error: ',
      timeoutError,
    );
  }

  if (isLoading) return;

  return (
    <TitleAndDescription
      title={t(
        'globalconfig.secureManagement.loginSecureConfig.loginMaintain.title',
      )}
      description={t(
        'globalconfig.secureManagement.loginSecureConfig.loginMaintain.description',
      )}
    >
      <p className="flex items-center text-[14px] text-[--color-text-level1] font-[400]">
        {t('service.employeePortal.name')}
        {' ' + timeoutConfig?.employeePortalLoginTimeout}
        {t(
          'globalconfig.secureManagement.loginSecureConfig.loginMaintain.time',
        )}
        <span className="text-[#C5CBD3] mx-[8px]">|</span>
        {t('service.businessPortal.name')}
        {' ' + timeoutConfig?.businessPortalLoginTimeout}
        {t(
          'globalconfig.secureManagement.loginSecureConfig.loginMaintain.time',
        )}
      </p>
    </TitleAndDescription>
  );
}
