export * from './extensions';

export type { Editor } from '@tiptap/react';

export { useEditor } from './useEditor';
export { EditorContent } from './EditorContent';

export { default as Provider } from './Provider';
export { EditorProvider, type EditorProviderProps } from './Provider';
export { useCurrentEditor } from './useCurrentEditor';
