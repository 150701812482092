import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table/index';
import {
  columnDefGenerator,
  SortChevron,
} from '@daouoffice/ui/lib/labs/Table/utils/columnDefGenerator';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { Button } from '@dop-ui/react/shared/ui/button';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import {
  calculateDateRangePastFromToday,
  formatDateString,
} from '../../../utils/dateTimeUtils';
import * as adminLogQuery from '../../api/admin-log-query';
import { DeviceType, Direction, LogInfo, SearchType } from '../../types';
import {
  loginEventTypeOptions,
  searchOptions,
  userTypeOptions,
} from './get-filter-option';

export function LogList() {
  // TODO: API 추후 적용 필요.
  // 1. 로그인 이벤트 타입 정의 후, 필터 기능 확립.
  // 2. 디바이스 타입 정의 후, 필터 기능 확립.
  // 3. 강제 로그아웃 API 추가 후, 기능 구현.
  // 4. 목록 다운로드 API 추가 후, 기능 구현.
  // 5. 필드 값, API 정의 후, 수정 예정.
  const { t } = useTranslation('component');
  const [currentPage, setCurrentPage] = useState(0);
  const [, setSelectedLogList] = useState<LogInfo[]>([]);
  const [pageOffet, setCurrentPageOffset] = useState(20);
  const [startDate] = useState<string>(calculateDateRangePastFromToday(7));
  const [endDate] = useState<string>(
    new Date().toISOString().split('T')[0] ?? '',
  );
  const [keyword, setKeyword] = useState('');
  // TODO: 필터 옵션 정의 후, 재정의 필요.
  // const [loginEventType, setLoginEventType] = useState<LoginEvent>();
  const [device] = useState<DeviceType>();
  const [orderType, setOrderType] = useState<string>('name');
  const [searchType, setSearchType] = useState<SearchType>('userName');
  const [direction, setDirection] = useState<Direction>('asc');

  const getQueryString = () => {
    const query = new URLSearchParams();
    query.append('page', currentPage.toString());
    query.append('size', pageOffet.toString());
    query.append('sort', `${orderType},${direction}`);
    keyword !== '' && query.append(searchType, keyword);
    device && query.append('device', device);
    query.append('startDate', startDate);
    query.append('endDate', endDate);
    return query.toString();
  };

  const days = {
    monday: t('days.monday'),
    tuesday: t('days.tuesday'),
    wednesday: t('days.wednesday'),
    thursday: t('days.thursday'),
    friday: t('days.friday'),
    saturday: t('days.saturday'),
    sunday: t('days.sunday'),
  };

  // TODO: 15일 필터 추가 필요.
  // const dateTitles = {
  //   days7: t('globalconfig.secureManagement.adminLogConfig.select.term.7days'),
  //   days15: t('globalconfig.secureManagement.adminLogConfig.select.term.15days'),
  //   days30: t('globalconfig.secureManagement.adminLogConfig.select.term.30days'),
  //   customInput: t('globalconfig.secureManagement.adminLogConfig.select.term.customInput'),
  // };
  const dateTitles = {
    today: t('globalconfig.dataManagement.requestManagement.date.today'),
    days7: t('globalconfig.dataManagement.requestManagement.date.7daysAgo'),
    days30: t('globalconfig.dataManagement.requestManagement.date.30daysAgo'),
    days90: t('globalconfig.dataManagement.requestManagement.date.90daysAgo'),
    selfInput: t(
      'globalconfig.dataManagement.requestManagement.date.selfInput',
    ),
  };

  const {
    data: defaultAdminLog,
    error,
    refetch,
  } = useQuery({
    queryKey: [adminLogQuery.QUERY_KEY, currentPage, pageOffet],
    queryFn: () => adminLogQuery.getAdminLog(getQueryString()),
  });

  useEffect(() => {
    refetch().catch(console.error);
  }, [
    currentPage,
    pageOffet,
    keyword,
    orderType,
    searchType,
    direction,
    refetch,
  ]);

  useEffect(() => {
    if (!defaultAdminLog) return;
    setCurrentPage(defaultAdminLog?.data.page.pageNumber ?? 0);
  }, [defaultAdminLog]);

  useEffect(() => {
    setCurrentPage(0);
  }, [keyword, pageOffet]);

  if (error) {
    return <></>;
  }

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
  };

  const onClickLastPage = () => {
    setCurrentPage(
      defaultAdminLog?.data.page.totalPages
        ? defaultAdminLog?.data.page.totalPages - 1
        : 0,
    );
  };

  const onOffsetChange = (offset: number) => {
    setCurrentPageOffset(offset);
  };

  const onSortChangeHandler = (direction: Direction, value: string) => {
    setDirection(direction);
    setOrderType(value);
  };

  const columnDefs: ColumnDef<LogInfo>[] = [
    columnDefGenerator<LogInfo>(
      'userName',
      t('globalconfig.secureManagement.adminLogConfig.table.field.name'),
      'name',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    columnDefGenerator<LogInfo>(
      'loginId',
      t('globalconfig.secureManagement.adminLogConfig.table.field.loginId'),
      'loginId',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    columnDefGenerator<LogInfo>(
      'departmentName',
      t(
        'globalconfig.secureManagement.adminLogConfig.table.field.departmentName',
      ),
      'departmentName',
      orderType,
      onSortChangeHandler,
      direction,
    ),
    {
      accessorKey: 'createdAt',
      id: 'createdAt',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t(
            'globalconfig.secureManagement.adminLogConfig.table.field.createdAt',
          )}
          <SortChevron
            filterValue={'last_connected'}
            selectedValue={orderType}
            direction={direction ?? 'asc'}
            onChange={onSortChangeHandler}
          />
        </div>
      ),
      cell: (value: CellContext<LogInfo, unknown>) => (
        <div className="py-3 text-center">
          {formatDateString(days, value.renderValue() as string)}
        </div>
      ),
    },
    {
      accessorKey: 'loginEventType',
      id: 'loginEventType',
      header: () => (
        <div className="flex items-center py-3 justify-center gap-1">
          {t(
            'globalconfig.secureManagement.adminLogConfig.table.field.loginEventType',
          )}
          {'loginEventType' && (
            <SortChevron
              filterValue={'last_connected'}
              selectedValue={orderType}
              direction={direction ?? 'asc'}
              onChange={onSortChangeHandler}
            />
          )}
        </div>
      ),
      cell: (value: CellContext<LogInfo, unknown>) => (
        <span
          className={clsx(
            'py-[6px] !px-[6px] text-[12px] font-mdeium leading-[12px] -tracking-[0.48px] text-center border border-solid rounded-[6px]',
            {
              'border-[#FF5252] text-[#FF5252]':
                value.renderValue() !== 'LOGIN_SUCCESS',
              'border-[#49C689] text-[#49C689]':
                value.renderValue() === 'LOGIN_SUCCESS',
            },
          )}
        >
          {
            loginEventTypeOptions(t).find(
              (event) => event.value === value.renderValue(),
            )?.title
          }
        </span>
      ),
    },
    columnDefGenerator<LogInfo>(
      'ip',
      t('globalconfig.secureManagement.adminLogConfig.table.field.ip'),
    ),
    columnDefGenerator<LogInfo>(
      'device',
      t('globalconfig.secureManagement.adminLogConfig.table.field.device'),
      'device',
      orderType,
      onSortChangeHandler,
      direction,
    ),
  ];

  const filterChangeHandler = (filters: Map<string, string>) => {
    console.log(filters);
  };

  return (
    <TablePrimitives.Root<LogInfo>
      contents={defaultAdminLog?.data?.elements ?? []}
      columnDefs={columnDefs}
      onSelectContents={setSelectedLogList}
      onChangeFilter={filterChangeHandler}
      selectable
    >
      <div className="flex flex-wrap justify-between">
        <div className="flex items-center gap-2">
          <TablePrimitives.SearchBar
            className="inline-block w-[240px] h-[40px]"
            onKeywordChange={onKeywordChange}
          >
            <select
              className="w-[50px] border-none mb-[2px] shirink-0 text-ellipsis"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value as SearchType)}
            >
              {Array.from(searchOptions(t)).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </TablePrimitives.SearchBar>
          <div className="h-3 border-l-1 border-[#D8D8D8] px-1" />
          <TablePrimitives.Filter.Container className="inline-block !gap-1">
            <TablePrimitives.Filter.DateOption
              className="border-[#D8D8D8] border bg-white rounded-lg !h-10 !px-3"
              title={t(
                'globalconfig.secureManagement.adminLogConfig.select.term.title',
              )}
              filterKey="createdAt"
              dateOptionTitles={dateTitles}
            />
            <TablePrimitives.Filter.Option
              className="border-[#D8D8D8] border bg-white rounded-lg !h-10 !px-3"
              title={t(
                'globalconfig.secureManagement.adminLogConfig.select.status.title',
              )}
              filterKey="loginEventType"
              filterType="select"
              options={loginEventTypeOptions(t)}
            />
            <TablePrimitives.Filter.Option
              className="border-[#D8D8D8] border bg-white rounded-lg !h-10 !px-3"
              title={t(
                'globalconfig.secureManagement.adminLogConfig.select.type.title',
              )}
              filterKey="userType"
              filterType="select"
              options={userTypeOptions}
            />
          </TablePrimitives.Filter.Container>
        </div>
        <div className="spacer flex-grow" />
        <div className="flex items-end gap-2">
          <Button
            onClick={() => alert('개발중인 기능입니다.')}
            className="flex items-center gap-1  !h-[32px]"
          >
            <ArrowDownTrayIcon className="size-6" />
            <span className="text-[14px] font-normal leading-[150%] -tracking-[0.28px] text-[#363636]">
              {t(
                'globalconfig.secureManagement.adminLogConfig.button.downloadList.title',
              )}
            </span>
          </Button>
          <TablePrimitives.SizeSelector
            className="w-[52px]"
            onChangeTableSize={onOffsetChange}
          />
        </div>
      </div>
      <TablePrimitives.Contents
        className="text-center mt-4"
        emptyNotice={
          <div className="w-full py-[60px] flex items-center justify-center ">
            <span className="w-full text-center font-normal leading-norml=al text-[#888] text-ellipsis">
              {t('globalconfig.secureManagement.adminLogConfig.table.nodata')}
            </span>
          </div>
        }
      />
      <div className="flex items-center justify-center gap-2">
        <TablePrimitives.Pagination
          className="mt-[16px]"
          currentPage={currentPage}
          totalPage={defaultAdminLog?.data?.page?.totalPages ?? 0}
          onNextPage={setCurrentPage}
          onPreviousPage={setCurrentPage}
          onClickPage={setCurrentPage}
          onFirstPage={() => setCurrentPage(0)}
          onLastPage={onClickLastPage}
        />
      </div>
    </TablePrimitives.Root>
  );
}

export default LogList;
