'use client';
import { ScreenLockProvider } from '@dop-ui/react/widgets/screen-lock';
import { PropsWithChildren } from 'react';
import { DOPContainer } from './DOPContainer';
import { GlobalNavBar } from './GlobalNavBar';
import { Header } from './Header';
import { Organizer } from './Organizer';

export function MainContainer({ children }: PropsWithChildren) {
  return (
    <DOPContainer>
      {/* header: 로고, 통합 검색, SNB 영역 위치 */}
      <Header />

      {/* 개별 앱 컨테이너 */}
      <div className="wrap_body">
        {/* GNB */}
        <GlobalNavBar />
        {/* 메인 컨텐츠 영역 */}
        <ScreenLockProvider>
          <main className="wrap_contents">{children}</main>
        </ScreenLockProvider>
        {/* 오거나이저 영역 */}
        <Organizer />
      </div>
    </DOPContainer>
  );
}

export default MainContainer;
