import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 오픈북 아이콘
 * @param props
 * @returns
 */
export function FoldIcon(props: IconCommonProps) {
  return (
    <IconContainer size={16} viewBox="0 0 16 16" {...props}>
      <path
        d="M5.5 3L10.5 8L5.5 13"
        stroke="#333333"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default FoldIcon;
