import { ToastTypes } from './types';

export function generateUniqueId() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export function getToastStyles(type: ToastTypes) {
  switch (type) {
    case 'alert':
      return 'bg-[#ff0000] text-white';
    case 'warning':
      return 'bg-[#F4BA73] text-white';
    default:
      return 'bg-gray-500 text-white';
  }
}
