import * as fetchClient from '@daouoffice/next/lib/fetch/client';
import type { IMenuListItemSource, IUserMenuInfo } from '../../types';
// import { getUserAppsSourceMock } from './mocks';

export interface ReponseData {
  data: {
    menuList: IMenuListItemSource[];
  };
}

export const QUERY_KEY = 'user-menus';

export async function getUserMenus(): Promise<IUserMenuInfo[]> {
  try {
    const pathUrl = `/api/portal/common/menu/user/employee`;
    const resp = await fetchClient.fetch(pathUrl);

    if (!resp.ok) {
      throw new Error('AppDock > getUserApps 오류 발생.');
    }

    const { data } = (await resp.json()) as ReponseData;
    const { menuList = [] } = data || {};
    // const menuList = getUserAppsSourceMock();

    return menuList
      .sort((prev, next) => prev.sortOrder - next.sortOrder)
      .map(adapter)
      .filter(Boolean);
  } catch (err) {
    console.error(err);
    return [];
  }
}

function adapter(src: IMenuListItemSource): IUserMenuInfo | undefined {
  const iconStr = src.icon;

  return {
    uid: src.menuId,
    appCode: src.appCode,
    name: src.menuName,
    seq: src.sortOrder,
    type: 'managed',
    icon: iconStr,
    url: src.url || '/',
    disabled: src.disabled,
    useLock: src.useLock,
  };
}
