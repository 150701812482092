import { ShortcutItemInfo } from '../types';

export function isSettingItemsChanged(
  prev: ShortcutItemInfo[],
  current: ShortcutItemInfo[],
) {
  if (prev.length !== current.length) return true;

  for (let i = 0; i < prev.length; i++) {
    const setting = prev[i]!;
    const shortcut = current[i]!;
    if (setting.uid !== shortcut.uid) return true;
    if (setting.name !== shortcut.name) return true;
  }
  return false;
}
