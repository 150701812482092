import { gql } from 'graphql-request';
import { fetchGraphQL } from '../../../../../lib/fetch/client';
import { AppType } from '../types';

export const CreateLinkShortcutQuery = gql`
  mutation CreateLinkShortcut($url: String!) {
    createLinkShortcut(url: $url) {
      id
    }
  }
`;

export const CreateAppShortcutQuery = gql`
  mutation CreateAppShortcut($input: createAppShortcutInput!) {
    createAppShortcut(input: $input) {
      itemId
    }
  }
`;

export async function postLinkShortcut(url: string) {
  const resp = await fetchGraphQL<{ url: string }>({
    query: CreateLinkShortcutQuery,
    variables: {
      url: url,
    },
    operationName: 'CreateLinkShortcut',
  });

  if (!resp.ok) {
    throw new Error('postLinkShortcut 오류 발생.');
  }
}

export interface CreateAppShortcutInput {
  itemId: string;
  type: string;
  title: string;
  faviconUrl: string;
}

export async function postAppShortcut(appData: CreateAppShortcutInput) {
  const resp = await fetchGraphQL<{ input: CreateAppShortcutInput }>({
    query: CreateAppShortcutQuery,
    variables: {
      input: appData,
    },
    operationName: 'CreateAppShortcut',
  });

  if (!resp.ok) {
    throw new Error('postAppShortcut 오류 발생.');
  }
}

export function mapper(appData: AppType, type: string): CreateAppShortcutInput {
  return {
    itemId: appData.id.toString(),
    type: type,
    title: appData.name,
    faviconUrl: appData.thumbnail,
  };
}
