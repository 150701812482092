import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useSuspenseQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import * as appListQuery from '../../api/app-list-query';
import { AppInfo } from '../../types';
import { useContext } from '../context';

export function AppList() {
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();
  const { isEditMode, selectedAppId, setSelectedAppId } = useContext();
  const { data: res, error } = useSuspenseQuery({
    queryKey: [appListQuery.QUERY_KEY],
    queryFn: async () => await appListQuery.getAppList(),
  });

  if (error) {
    return (
      <div>
        {t('globalconfig.secureManagement.appManagement.applist.not.load.data')}
      </div>
    );
  }

  const onClickHandler = (appInfo: AppInfo) => {
    const handleConfirm = async () => {
      return await confirm(
        <div className="size-full px-[24px]">
          <p className="text-[#1A1A1A] text-[20px] font-[500]">
            {t('globalconfig.common.dialog.saveConfirm.title')}
          </p>
          <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
            {t('globalconfig.common.dialog.saveConfirm.description')}
          </p>
        </div>,
      );
    };
    if (isEditMode) {
      handleConfirm()
        .then((result) => {
          if (result) {
            setSelectedAppId(appInfo.companyAppId);
          }
        })
        .catch(console.error);
    } else {
      setSelectedAppId(appInfo.companyAppId);
    }
  };

  return (
    <div className="overflow-y-scroll gap-[2px] w-[240px] max-h-[646px] flex flex-col ">
      {res?.data?.elements.map((appInfo) => (
        <button
          onClick={() => onClickHandler(appInfo)}
          key={appInfo.appCode}
          className={clsx(
            'h-8 flex w-full py-2 items-center rounded-lg hover:bg-[#F2F2F2] px-2 gap-2',
            {
              'bg-[#F2F2F2]': selectedAppId === appInfo.companyAppId,
              'bg-transparent': selectedAppId !== appInfo.companyAppId,
            },
          )}
        >
          {appInfo?.icon && (
            <div className="size-5">
              <i dangerouslySetInnerHTML={{ __html: appInfo.icon }} />
            </div>
          )}
          <span className="text-[14px] font-medium -tracking-[0.28px] text-[#363636]">
            {appInfo.appName}
          </span>
        </button>
      ))}
    </div>
  );
}

export default AppList;
