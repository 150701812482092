import { useTranslation } from '../../../../../../lib/i18n/client/useTranslation';
import { InfoTriangle } from '@dop-ui/react/shared/ui/svg/info-triangle';
import styles from './no-data.module.css';

export function NoLinkPlusTemplate() {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <InfoTriangle
        viewBox="0 0 40 40"
        width={40}
        height={40}
        className={styles.Icon}
      />

      <p className={styles.Description}>{t('organizer.empty.linkplus')}</p>
    </div>
  );
}

export default NoLinkPlusTemplate;
