import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import {
  combine,
  setCustomNativeDragPreview,
  attachClosestEdge,
  extractClosestEdge,
  pointerOutsideOfPreview,
} from '../../../lib/dnd/helper';
import { draggable, dropTargetForElements } from '../../../lib/dnd/core';
import { clsx } from 'clsx';

import { useDndContext } from './provider';
import { DropIndicator } from './drop-indicator';
import type { DragState, PreviewInfo, StyleSet } from './types';

export interface Props<T> {
  item: T;
  className?: string;
  dragHandleRef?: MutableRefObject<HTMLElement | null>;
  previewInfo?: PreviewInfo;
  dragOverClassName?: string;
  styleSet?: StyleSet;
}

export function ListItem<T>({
  item,
  previewInfo,
  dragHandleRef,
  className,
  styleSet = {
    'is-dragging': 'opacity-40',
  },
  children,
}: PropsWithChildren<Props<T>>) {
  const { getData, dataValidation, name, idKey } = useDndContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const idle: DragState = { type: 'idle' };
  const [state, setState] = useState<DragState>(idle);

  if (!styleSet['is-dragging']) {
    styleSet['is-dragging'] = 'opacity-40';
  }

  useEffect(() => {
    const dragHandleElement = dragHandleRef
      ? dragHandleRef.current
      : ref.current;
    if (dragHandleElement) dragHandleElement.style.cursor = 'grab';

    const dropElement = ref.current;

    if (dragHandleElement && dropElement) {
      return combine(
        draggable({
          element: dragHandleElement,
          getInitialData() {
            return getData(item as Record<string | symbol, unknown>);
          },
          onGenerateDragPreview({ nativeSetDragImage }) {
            if (previewInfo) {
              return setCustomNativeDragPreview({
                nativeSetDragImage,
                getOffset:
                  previewInfo.offset &&
                  pointerOutsideOfPreview(previewInfo.offset),
                render({ container }) {
                  setState({ type: 'preview', container });
                },
              });
            }
            return nativeSetDragImage;
          },

          onDragStart() {
            setState({ type: 'is-dragging' });
          },
          onDrop() {
            setState(idle);
          },
        }),
        dropTargetForElements({
          element: dropElement,
          canDrop({ source }) {
            if (source.element === dropElement) {
              return false;
            }
            return dataValidation(source.data, name);
          },
          getData({ input }) {
            const data = getData(item as Record<string | symbol, unknown>);

            return attachClosestEdge(data, {
              element: dropElement,
              input,
              allowedEdges: ['top', 'bottom'],
            });
          },
          getIsSticky() {
            return true;
          },
          onDragEnter({ self, source }) {
            if (source.data.id === self.data.id) {
              return;
            }
            const closestEdge = extractClosestEdge(self.data);
            setState({ type: 'is-dragging-over', closestEdge });
          },
          onDrag({ self, source }) {
            if (source.data.id === self.data.id) {
              return;
            }
            const closestEdge = extractClosestEdge(self.data);

            setState((current) => {
              if (
                current.type === 'is-dragging-over' &&
                current.closestEdge === closestEdge
              ) {
                return current;
              }
              return { type: 'is-dragging-over', closestEdge };
            });
          },
          onDragLeave({ self, source }) {
            if (source.data.id === self.data.id) {
              return;
            }
            setState(idle);
          },
          onDrop() {
            setState(idle);
          },
        }),
      );
    }
  }, [item]);

  return (
    <>
      <div
        data-id={name + item[idKey as keyof T]}
        ref={ref}
        className={clsx('relative', className, `${styleSet[state.type] ?? ''}`)}
      >
        {children}
        {state.type === 'is-dragging-over' && state.closestEdge && (
          <DropIndicator edge={state.closestEdge} gap={'8px'} color="#363636" />
        )}
      </div>

      {state.type === 'preview' &&
        previewInfo &&
        createPortal(previewInfo.preview, state.container)}
    </>
  );
}

export default ListItem;
