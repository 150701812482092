import { useAtom } from 'jotai';
import { FilterOption } from '../../../types';
import {
  activatedFiltersAtom,
  deleteActivatedFiltersAtom,
} from '../../../stores/activatedFilterAtom';
import { useFilterContext } from '../Context';

export interface CheckboxOptionProps {
  option: FilterOption;
}

export function CheckboxOption({ option }: CheckboxOptionProps) {
  const [, setActivateFilter] = useAtom(activatedFiltersAtom);
  const [, deleteActiveFilter] = useAtom(deleteActivatedFiltersAtom);
  const { selectedOptions, setSelectedOptions } = useFilterContext();

  const onCheckboxChange = () => {
    const newSelectedOptions = new Set(selectedOptions);
    if (newSelectedOptions.has(option.key)) {
      newSelectedOptions.delete(option.key);
      deleteActiveFilter(option.key);
    } else {
      newSelectedOptions.add(option.key);
      setActivateFilter(option.key, option.value);
    }
    setSelectedOptions(newSelectedOptions);
  };
  return (
    <div className="flex items-center w-full p-[8px]">
      <input
        className="mr-[8px]"
        type="checkbox"
        id={option.key}
        checked={selectedOptions.has(option.key)}
        onChange={() => onCheckboxChange()}
      />
      <label className="w-full" htmlFor={option.key}>
        {option.title}
      </label>
    </div>
  );
}
