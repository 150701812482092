import { Switch } from '@daouoffice/ui';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { clsx } from 'clsx';
import * as mediaScreenLockMutation from '../api/mediaScreenLockMutation';
import * as mediaScreenLockQuery from '../api/mediaScreenLockQuery';
import { MediaConfig } from '../types';

export interface Props {
  type: MediaConfig;
  withDivider?: boolean;
}

export function ScreenLockConfig({ type, withDivider = false }: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslation('component');
  const { info } = useToastMessage();
  const { data: defaultLockState, error } = useSuspenseQuery({
    queryKey: [mediaScreenLockQuery.QUERY_KEY, type],
    queryFn: () => mediaScreenLockQuery.getMediaScreenLock(type),
  });

  const getMessage = (newState: boolean) => {
    if (type === 'MESSENGER_MEDIA_SCREEN_LOCK') {
      return newState
        ? t(
            'globalconfig.secureManagement.mediaSecureConfig.messenger.allow.toast.title',
          )
        : t(
            'globalconfig.secureManagement.mediaSecureConfig.messenger.disallow.toast.title',
          );
    } else {
      return newState
        ? t(
            'globalconfig.secureManagement.mediaSecureConfig.mobile.allow.toast.title',
          )
        : t(
            'globalconfig.secureManagement.mediaSecureConfig.mobile.disallow.toast.title',
          );
    }
  };

  const updateLockState = useMutation({
    mutationKey: [mediaScreenLockMutation.MUTATION_KEY, type],
    mutationFn: async (newState: boolean) => {
      await mediaScreenLockMutation.updateMediaScreenLockStatus(newState, type);
      return newState;
    },
    onSuccess: async (newState: boolean) => {
      info(getMessage(newState));
      await queryClient.invalidateQueries({
        queryKey: [mediaScreenLockQuery.QUERY_KEY],
      });
    },
  });

  const onChangeHandler = (newState: boolean) => {
    updateLockState.mutate(newState);
  };

  if (error) {
    return <></>;
  }

  return (
    <div
      className={clsx('w-full flex items-center justify-between py-8', {
        'border-b border-[#E5E5E5]': withDivider,
      })}
    >
      <h2 className="w-[240px] text-[#111] text-ellipsis font-medium text-base leading-6 -tracking-[0.96px] overflow-hidden">
        {t(
          type === 'MESSENGER_MEDIA_SCREEN_LOCK'
            ? 'globalconfig.secureManagement.mediaSecureConfig.messenger.title'
            : 'globalconfig.secureManagement.mediaSecureConfig.mobile.title',
        )}
      </h2>
      <span className="font-normal leading-normal -tracking-[0.84px] text-[14px] text-[#AAA]">
        {t(
          type === 'MESSENGER_MEDIA_SCREEN_LOCK'
            ? 'globalconfig.secureManagement.mediaSecureConfig.messenger.description'
            : 'globalconfig.secureManagement.mediaSecureConfig.mobile.description',
        )}
      </span>
      <Switch
        id={`SWITCH-${type}`}
        checked={defaultLockState ?? false}
        onChange={onChangeHandler}
      />
    </div>
  );
}

export default ScreenLockConfig;
