import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { CompanyListInfo, OrgConfigInfo } from './type';

interface BasicInfoContextInfo {
  companyList: CompanyListInfo[];
  orgConfig: OrgConfigInfo;
}

const BasicInfoContext = createContext<IBasicInfoContext>({
  basicInfoContext: {
    companyList: [],
    orgConfig: {
      companyGroupId: 0,
      isOrganizationChartShare: 'false',
      shareTarget: 'ALL',
      targetList: [],
      shareRange: 'CHART_AND_SEARCH',
      organizationChartOrder: [],
    },
  },
  setBasicInfoContext: () => undefined,
});

interface IBasicInfoContext {
  basicInfoContext: BasicInfoContextInfo;
  setBasicInfoContext: Dispatch<SetStateAction<BasicInfoContextInfo>>;
}

export function Provider({ children }: PropsWithChildren) {
  const [basicInfoContext, setBasicInfoContext] =
    useState<BasicInfoContextInfo>({
      companyList: [],
      orgConfig: {
        companyGroupId: 0,
        isOrganizationChartShare: 'false',
        shareTarget: 'ALL',
        targetList: [],
        shareRange: 'CHART_AND_SEARCH',
        organizationChartOrder: [],
      },
    });

  const values = {
    basicInfoContext,
    setBasicInfoContext,
  };

  return (
    <BasicInfoContext.Provider value={values}>
      {children}
    </BasicInfoContext.Provider>
  );
}

export const useBasicInfoContext = () => {
  const ctx = useContext(BasicInfoContext);
  if (ctx === null) throw new Error('BasicInfo Context Provider Missing');
  return ctx;
};
