import { clsx } from 'clsx';
import { AppsDockConfigButton } from './AppsDockConfigButton';
import { OrgChartButton } from './OrgChartButton';

export function Footer({ className }: { className?: string }) {
  return (
    <ul className={clsx('footer', className)}>
      <li>
        <AppsDockConfigButton />
      </li>
      <li>
        <OrgChartButton />
      </li>
    </ul>
  );
}

export default Footer;
