import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { SubTabType } from '../types';
import { useContextValues } from './Context';

export interface Props {
  type: SubTabType;
  title: string;
  onClickTab: (tabType: SubTabType) => void;
}

export function SubTab({ type, title, onClickTab }: PropsWithChildren<Props>) {
  const { selectedType, setSelectedType } = useContextValues();
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();

  const onClickHandler = () => {
    const handleConfirm = async () => {
      if (type === 'perApps' && selectedType === 'perManagers') {
        const requestConfirm = (
          <div className="w-full !px-4 flex flex-col gap-4">
            <h2 className="text-[18px] text-black font-medium">
              {t(
                'globalconfig.secureManagement.adminManagement.messageDialog.checkChange.title',
              )}
            </h2>
            <span className="text-[14px] text-black font-normal whitespace-normal">
              {t(
                'globalconfig.secureManagement.adminManagement.messageDialog.checkChange.contents',
              )}
            </span>
          </div>
        );

        return await confirm(requestConfirm);
      } else return true;
    };

    handleConfirm()
      .then((result) => {
        if (result) {
          onClickTab(type);
          setSelectedType(type);
        }
      })
      .catch(console.error);
  };

  return (
    <Button
      title={title}
      shape="rect"
      className={clsx('select-none rect solid major tab_item square', {
        ['active']: selectedType === type,
      })}
      onClick={() => {
        onClickHandler();
      }}
    />
  );
}
