import { gql } from 'graphql-request';
import { fetchGraphQL } from '../../../../../../lib/fetch/client';
import { DeptInfo } from '../types';

export interface DeptResponse {
  data: { me: { mainDepartment: DeptInfo } };
}

export const QUERY_KEY = 'userDeptQuery';

export const Query = gql`
  query ${QUERY_KEY} {
    me {
      mainDepartment {
        id
        name
      }
    }
  }
`;

export async function getUserDept(): Promise<DeptInfo> {
  const response = await fetchGraphQL({ query: Query });
  const { data } = (await response.json()) as DeptResponse;

  return data.me.mainDepartment;
}
