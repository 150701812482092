import { PropsWithChildren } from 'react';

export function Root({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-col px-[75px] pb-[75px] pt-10 gap-8">
      {children}
    </div>
  );
}

export default Root;
