import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useQuery } from '@tanstack/react-query';
import { appCodesAtom } from '../../store/appCodesAtom';
import { favoriteMenuAtom } from '../../store/favoriteMenuAtom';
import { Sub } from './Sub';
import { Main } from './Main';
import * as favoriteMenuApis from './apis/favoriteMenuInfos';
import * as appCodeApis from './apis/managerAppCodes';

export function Menu() {
  const { data: appCodes, error } = useQuery({
    queryKey: [appCodeApis.QUERY_KEY],
    queryFn: appCodeApis.getAppCodes,
  });
  const setAppCodeData = useSetAtom(appCodesAtom);

  const { data: favoriteMenuInfos, error: favoriteError } = useQuery({
    queryKey: [favoriteMenuApis.QUERY_KEY],
    queryFn: favoriteMenuApis.getFavoriteMenuInfos,
  });
  const setFavoriteMenuInfos = useSetAtom(favoriteMenuAtom);

  useEffect(() => {
    if (appCodes) {
      setAppCodeData(appCodes);
    }
  }, [appCodes, setAppCodeData]);

  useEffect(() => {
    if (favoriteMenuInfos) {
      setFavoriteMenuInfos(favoriteMenuInfos);
    }
  }, [favoriteMenuInfos, setFavoriteMenuInfos]);

  if (error) {
    console.error('GlobalConfig > Menu error: ', error);
  }

  if (favoriteError) {
    console.error('GlobalConfig > Menu > FavoriteMenu error: ', favoriteError);
  }

  return (
    <div className="flex h-full w-[500px] bg-[#F9FBFC]">
      <Main
        settingMenus={appCodes ? appCodes.setting : []}
        isAppManageMenuExist={
          appCodes
            ? appCodes.business.length > 0 || appCodes.employee.length > 0
            : false
        }
      />
      <Sub
        employeeMenus={appCodes ? appCodes.employee : []}
        businessMenus={appCodes ? appCodes.business : []}
      />
    </div>
  );
}

export default Menu;
