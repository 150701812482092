import { createStore, Provider } from 'jotai';
import { PropsWithChildren } from 'react';
import { Container } from './Container';
import { globalConfigStore } from '../../store/globalConfigStore';

type Store = ReturnType<typeof createStore>;
export interface Props {
  contextPath?: string;
  configPath?: string;
  onPageChange?: (pageURL: string) => void;
  onClosed: () => void;
}

const JotaiProvider = ({
  store,
  children,
}: PropsWithChildren<{
  store?: Store;
}>) => {
  return <Provider store={store}>{children}</Provider>;
};

export function Root({ children, ...props }: PropsWithChildren<Props>) {
  return (
    <JotaiProvider store={globalConfigStore}>
      <Container {...props}>{children}</Container>
    </JotaiProvider>
  );
}

export default Root;

JotaiProvider.displayName = 'GlobalConfig.JotaiProvider';
