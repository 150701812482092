import * as mailMenuInfo from './mail';
import * as hrMenuInfo from './mp-hr';
import { MenuInfo } from '../../types';
import { paths } from '../main';

export const menuKeyPath = paths.APPS_MANAGEMENT;

export const wholeMenuInfoMap: Map<string, MenuInfo[]> = new Map([
  [mailMenuInfo.menuKeyCode, mailMenuInfo.menuInfo],
  [hrMenuInfo.menuKeyCode, hrMenuInfo.menuInfo],
]);

export const getAppManagementMenuInfo = (
  menuKeyCodes: string[],
): MenuInfo[] => {
  return menuKeyCodes
    .map((code) => wholeMenuInfoMap.get(code))
    .filter((menuInfo) => menuInfo !== undefined)
    .flat()
    .sort((a, b) => a.sortOrder - b.sortOrder);
};
