import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Switch } from '@daouoffice/ui/lib/foundation/Switch';
import { Button } from '@dop-ui/react/shared/ui/button/dop-button';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { isContentNeedsSaveAtom } from '../../../store/isContentNeedsSaveAtom';
import { ClassRoleInfo, IPInfo } from '../types';
import { IPListManager } from '../components/ip-list-manager';
import { ClassListManager } from '../components/class-list-manager';
import { TfaExceptionInfo } from './types';
import * as tfaExceptionConfigApis from './apis/tfa-config';

export function TfaExceptionConfig() {
  const { t } = useTranslation('component');
  const [isUsingTfaException, setIsUsingTfaException] = useState(false);
  const [ipList, setIpList] = useState<IPInfo[]>([]);
  const [classRoleInfo, setClassRoleInfo] = useState<ClassRoleInfo[]>([]);
  const [needsSave, setNeedsSave] = useAtom(isContentNeedsSaveAtom);

  const toaster = useToastMessage();
  const queryClient = useQueryClient();

  const { data: configData, error } = useQuery({
    queryKey: [tfaExceptionConfigApis.QUERY_KEY_USE_TFA_EXCEPTION_CONFIG],
    queryFn: tfaExceptionConfigApis.getIsUsingTfaException,
  });

  const { data: configInfo, error: infoError } = useQuery({
    queryKey: [tfaExceptionConfigApis.QUERY_KEY_USE_TFA_EXCEPTION_DETAIL_INFO],
    queryFn: tfaExceptionConfigApis.getTfaExceptionDetailInfo,
  });

  const updateMutation = useMutation({
    mutationFn: (info: TfaExceptionInfo) =>
      tfaExceptionConfigApis.updateExceptionDetailInfo(info),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [tfaExceptionConfigApis.QUERY_KEY_USE_TFA_EXCEPTION_CONFIG],
      });
      void queryClient.invalidateQueries({
        queryKey: [
          tfaExceptionConfigApis.QUERY_KEY_USE_TFA_EXCEPTION_DETAIL_INFO,
        ],
      });
      toaster.info(t('globalconfig.common.toastMessage.success'));
      setNeedsSave(false);
    },
    onError: (error) => {
      toaster.warning(
        t('globalconfig.common.toastMessage.fail') + ': ' + error.message,
      );
    },
  });

  useEffect(() => {
    if (configData) {
      setIsUsingTfaException(configData.USE_EXTRA_AUTH_EXCLUDE);
    }
  }, [configData]);

  useEffect(() => {
    if (configInfo) {
      setIpList(configInfo.ipAccessPolicyInfoList || []);
      setClassRoleInfo(configInfo.policyExcludeRoleInfoList || []);
    }
  }, [configInfo]);

  if (error) {
    console.error(
      'GlobalConfig > SecureManagement > TfaExceptionConfig Error: ',
      error,
    );
  }

  if (infoError) {
    console.error(
      'GlobalConfig > SecureManagement > TfaExceptionConfig Error: ',
      infoError,
    );
  }

  const onSwitchChange = (checked: boolean) => {
    setIsUsingTfaException(checked);
    if (configData?.USE_EXTRA_AUTH_EXCLUDE === checked) setNeedsSave(false);
    else setNeedsSave(true);
  };

  const onClickSave = () => {
    updateMutation.mutate({
      ipAccessPolicyInfoList: ipList,
      policyExcludeRoleInfoList: classRoleInfo,
      isUseExtraAuthExclude: isUsingTfaException,
    });
  };

  const onClickCancel = () => {
    setIsUsingTfaException(configData?.USE_EXTRA_AUTH_EXCLUDE || false);
    setIpList(configInfo?.ipAccessPolicyInfoList || []);
    setClassRoleInfo(configInfo?.policyExcludeRoleInfoList || []);
    setNeedsSave(false);
  };

  return (
    <div className="px-[72px] py-[24px] overflow-y-scroll">
      <div className="flex flex-col flex-wrap p-[40px] border border-solid border-[#D8D8D8] rounded-[12px]">
        <p className="text-[#363636] text-[--color-text-level1] text-[20px] font-[600]">
          {t('globalconfig.menu.secureManagement.tfa.exception')}
        </p>
        <div className="mt-[48px] h-[40px] flex flex-wrap items-center">
          <p className="w-[264px] text-[#363636] text-[--color-text-level1] text-[16px] font-[500]">
            {t('globalconfig.secureManagement.tfaExceptionConfig.useTitle')}
          </p>
          <p className="ml-[2px] text-[#999999] text-[--color-text-level3] text-[14px] font-[400]">
            {t('globalconfig.secureManagement.tfaExceptionConfig.useTitle')}
          </p>
          <div className="spacer flex-grow" />
          <Switch
            id={'globalConfig.secure.tfa.exception'}
            checked={isUsingTfaException}
            onChange={onSwitchChange}
          />
        </div>
        {isUsingTfaException && (
          <div className="mt-[12px] p-[24px] border border-dashed border-[#D8D8D8] rounded-[8px]">
            <div className="flex flex-wrap 2xl:flex-nowrap items-start">
              <div className="flex items-center h-[32px] text-[#111111] text-[14px] font-[400] w-[240px]">
                <p>
                  {t(
                    'globalconfig.secureManagement.tfaExceptionConfig.ipList.title',
                  )}
                </p>
              </div>
              <IPListManager
                ipList={ipList}
                onChangeIpList={(list) => {
                  setIpList(list);
                  setNeedsSave(true);
                }}
              />
            </div>
            <div className="mt-[48px] w-full flex flex-wrap 2xl:flex-nowrap">
              <div className="flex items-center h-[32px] text-[#111111] text-[14px] font-[400] w-[240px]">
                <p>
                  {t(
                    'globalconfig.secureManagement.tfaExceptionConfig.classList.title',
                  )}
                </p>
              </div>
              <ClassListManager
                classRoleInfo={classRoleInfo}
                onClassInfoChange={(info) => {
                  setClassRoleInfo(info);
                  setNeedsSave(true);
                }}
                accessDeviceType={'ALL'}
                accessLimitTargetType={'EXCLUDE'}
              />
            </div>
          </div>
        )}
        <div className="w-full mt-[50px] flex items-center justify-center gap-[8px]">
          <Button
            size="medium"
            shape="rect"
            styleType="line"
            onClick={onClickCancel}
          >
            {t('dialog.cancel')}
          </Button>
          <Button
            size="medium"
            shape="rect"
            styleType="solid"
            disabled={!needsSave}
            onClick={onClickSave}
          >
            {t('dialog.save')}
          </Button>
        </div>
      </div>
    </div>
  );
}
