import { LogList } from './log-list';

export function DetailConfig() {
  return (
    <div className="flex flex-col gap-[34px] border rounded-lg border-dashed p-6">
      <LogList />
    </div>
  );
}

export default DetailConfig;
