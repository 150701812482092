import { fetch } from '../../../../../../lib/fetch/client';
import { PER_PORTAL_DATA_USAGE_API } from '../../constants';
import { BaseResponse } from '../../types';
import { PerPortalDataUsage } from '../types';

export const PER_PORTAL_DATA_USAGE_KEY =
  'QueryKeys.GlobalConfig.DataManagement.PerPortalDataUsage';

export async function getPerPortalDataUsage(): Promise<PerPortalDataUsage> {
  const response = await fetch(PER_PORTAL_DATA_USAGE_API, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<PerPortalDataUsage>;

  return responseData.data;
}
