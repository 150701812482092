/**
 *
 * @param dateString "yyyy-MM-ddTHH:mm:ss.tttZ"
 * @returns "yyyy-MM-dd(DD) HH:mm"
 */
interface Days {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}

export function formatDateString(days: Days, dateString: string): string {
  const date = new Date(dateString);

  const dayNames = [
    days.sunday,
    days.monday,
    days.tuesday,
    days.wednesday,
    days.thursday,
    days.friday,
    days.saturday,
  ];

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const weekday = dayNames[date.getDay()];
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}(${weekday}) ${hours}:${minutes}`;
}

export function getDateSixMonthsAgo(date: Date): string {
  date.setMonth(date.getMonth() - 6);
  console.log(date.toISOString().split('T')[0]);
  return date.toISOString().split('T')[0] ?? '';
}

export function calculateDateRangePastFromToday(pastDays: number): string {
  const range = pastDays * 24 * 60 * 60 * 1000;
  const today = new Date();
  const past = new Date(today.getTime() - range);
  return past.toISOString().split('T')[0] ?? '';
}
