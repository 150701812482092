import { clsx } from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { Button, IconButton } from '@daouoffice/ui/lib/foundation/Button';
import { Templates, useToast } from '../../../../Toast';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import {
  byteToMegabyte,
  megabyteToByte,
} from '../../../utils/byteConvertUtils';
import * as uploadPolicyApi from './apis/uploadPolicy';
import {
  blacklistAddedList,
  oneDeletedBlacklist,
  removeDuplicates,
} from './utils';
import { ExtensionInfo, Policy, StoragePolicyInfo } from './types';
import { BatchDialog } from './BatchDialog';

export function UploadPolicyManagement() {
  const { t } = useTranslation();

  const { data: policyData, error } = useQuery({
    queryKey: [uploadPolicyApi.QUERY_KEY],
    queryFn: uploadPolicyApi.getUploadPolicy,
  });

  if (error) {
    console.error(
      'Error: GlobalConfig > DataManagement > UploadPolicyManagement',
      error,
    );
  }

  const [currentStorages, setCurrentStorages] = useState<StoragePolicyInfo[]>(
    [],
  );
  const [isStoragesHaveChange, setIsStoragesHaveChange] = useState(false);
  const [currentMaxAttachCount, setCurrentMaxAttachCount] = useState(0);
  const [maxCountOption, setMaxCountOption] = useState('0');
  const [isVisibleAttachCountSelfInput, setIsVisibleAttachCountSelfInput] =
    useState(false);

  const [isMaxAttachCountDirty, setIsMaxAttachCountDirty] = useState(false);
  const [currentMaxAttachSize, setCurrentMaxAttachSize] = useState(100);
  const [maxSizeOption, setMaxSizeOption] = useState('100');
  const [isVisibleAttachSizeSelfInput, setIsVisibleAttachSizeSelfInput] =
    useState(false);
  const [isMaxAttachSizeDirty, setIsMaxAttachSizeDirty] = useState(false);

  const [selectedStorageId, setSelectedStorageId] = useState<
    number | undefined
  >();
  const [selectedStorageBlackList, setSelectedStorageBlackList] = useState<
    ExtensionInfo[]
  >([]);
  const [blacklistText, setBlacklistText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const queryClient = useQueryClient();

  const toaster = useToast();

  const saveMutation = useMutation({
    mutationFn: async (policy: Policy) =>
      uploadPolicyApi.setUploadPolicy(policy),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [uploadPolicyApi.QUERY_KEY],
      });
      toaster.info(
        <Templates.Basic description={t('organizer.memo.toast.saved')} />,
      );
    },
    onError: (error) => {
      console.error(
        'Error: GlobalConfig > DataManagement > UploadPolicyManagement',
        error,
      );
    },
  });

  const setPolicyData = useCallback((policyData: Policy) => {
    setCurrentStorages(policyData.extensionTargetStorageList);
    setCurrentMaxAttachCount(policyData.maxAttachCount);
    setCurrentMaxAttachSize(policyData.maxAttachSize);
    const maxSize = byteToMegabyte(policyData.maxAttachSize);
    if (
      policyData.maxAttachCount === 0 ||
      policyData.maxAttachCount === 1 ||
      policyData.maxAttachCount === 10
    ) {
      setMaxCountOption(policyData.maxAttachCount.toString());
      setIsVisibleAttachCountSelfInput(false);
    } else {
      setMaxCountOption(attachCountSelfInputKey);
      setIsVisibleAttachCountSelfInput(true);
    }
    if (maxSize === 100 || maxSize === 500 || maxSize === 1024) {
      setMaxSizeOption(policyData.maxAttachSize.toString());
      setIsVisibleAttachSizeSelfInput(false);
    } else {
      setMaxSizeOption(attachSizeSelfInputKey);
      setIsVisibleAttachSizeSelfInput(true);
    }
    setIsStoragesHaveChange(false);
    setIsMaxAttachCountDirty(false);
    setIsMaxAttachSizeDirty(false);
  }, []);

  useEffect(() => {
    if (policyData) {
      setPolicyData(policyData);
    }
  }, [policyData, setPolicyData]);

  useEffect(() => {
    if (selectedStorageId) {
      const storage = currentStorages.find(
        (info) => info.storageInfoId === selectedStorageId,
      );
      if (storage) {
        setSelectedStorageBlackList(storage.extensionBlacklist);
      } else {
        setSelectedStorageBlackList([]);
      }
    }
  }, [currentStorages, selectedStorageId]);

  function applyStorageDiff(newStorages: StoragePolicyInfo[]) {
    setBlacklistText('');
    setCurrentStorages(newStorages);
    setIsStoragesHaveChange(true);
  }

  const onChangeBlackList = (text: string) => {
    if (text.match(/[^,a-zA-Z0-9]/g)) {
      setErrorMessage('영어,숫자,쉼표(,)만 입력 가능합니다.');
    } else {
      setErrorMessage('');
    }
    setBlacklistText(text.replace(/[^,a-zA-Z0-9]/g, ''));
  };

  const onClickStorage = (storageId: number) => {
    setSelectedStorageId(storageId);
    const storage = currentStorages.find(
      (info) => info.storageInfoId === storageId,
    );
    if (storage) {
      setSelectedStorageBlackList(storage.extensionBlacklist);
    } else {
      setSelectedStorageBlackList([]);
    }
  };

  const onDeleteBlackList = (info: ExtensionInfo) => {
    const newStorages = currentStorages.map((storage) => {
      if (storage.storageInfoId === selectedStorageId) {
        const newList = oneDeletedBlacklist(storage.extensionBlacklist, info);
        return {
          ...storage,
          extensionBlacklist: newList,
        };
      }
      return storage;
    });
    applyStorageDiff(newStorages);
  };

  const onClickAddBlackList = () => {
    if (blacklistText) {
      const blackList = blacklistText
        .split(',')
        .filter((extensionName) => extensionName.trim().length > 0)
        .map((self) => self.toLowerCase());

      const duplicatesRemovedList = removeDuplicates(blackList).map((info) => ({
        extensionName: info,
      }));

      const newStorages = currentStorages.map((storage) => {
        if (storage.storageInfoId === selectedStorageId) {
          const newList = blacklistAddedList(
            storage.extensionBlacklist,
            duplicatesRemovedList,
          );
          return {
            ...storage,
            extensionBlacklist: newList,
          };
        }
        return storage;
      });

      applyStorageDiff(newStorages);
    }
  };

  const attachCountSelfInputKey = 'attachCount.selfInput';

  const onSelectCountLimit = (count: string) => {
    setMaxCountOption(count);
    if (count === attachCountSelfInputKey) {
      setIsVisibleAttachCountSelfInput(true);
    } else {
      setCurrentMaxAttachCount(Number(count));
      setIsVisibleAttachCountSelfInput(false);
    }
    setIsMaxAttachCountDirty(policyData?.maxAttachCount !== Number(count));
  };

  const onChangeCountLimit = (count: number) => {
    if (count < 0) {
      setCurrentMaxAttachCount(1);
    } else if (count > 99) {
      setCurrentMaxAttachCount(99);
    } else {
      setCurrentMaxAttachCount(count);
    }
    setIsMaxAttachCountDirty(policyData?.maxAttachCount !== count);
  };

  const attachSizeSelfInputKey = 'attachSize.selfInput';

  const onSelectSizeLimit = (sizeLimit: string) => {
    setMaxSizeOption(sizeLimit);
    if (sizeLimit === attachSizeSelfInputKey) {
      setIsVisibleAttachSizeSelfInput(true);
    } else {
      setCurrentMaxAttachSize(Number(sizeLimit));
      setIsVisibleAttachSizeSelfInput(false);
    }
    setIsMaxAttachSizeDirty(policyData?.maxAttachSize !== Number(sizeLimit));
  };

  const onChangeSizeLimit = (size: number) => {
    if (size <= 0) {
      setCurrentMaxAttachSize(megabyteToByte(1));
    } else if (size > 1024) {
      setCurrentMaxAttachSize(megabyteToByte(1024));
    } else {
      setCurrentMaxAttachSize(megabyteToByte(size));
    }
    setIsMaxAttachSizeDirty(policyData?.maxAttachSize !== size);
  };

  const onClickSave = () => {
    saveMutation.mutate({
      defaultExtensionBlacklist: policyData?.defaultExtensionBlacklist || [],
      extensionTargetStorageList: currentStorages,
      maxAttachCount: currentMaxAttachCount,
      maxAttachSize: currentMaxAttachSize,
    });
  };

  const onClickCancel = () => {
    if (policyData) {
      setPolicyData(policyData);
    }
  };

  const onClickBatchApply = (blackList: string[]) => {
    const newStorages = currentStorages.map((storage) => {
      const newList = blacklistAddedList(
        storage.extensionBlacklist,
        blackList.map((info) => ({ extensionName: info })),
      );
      return {
        ...storage,
        extensionBlacklist: newList,
      };
    });
    applyStorageDiff(newStorages);
  };

  const ExtensionList = ({
    title,
    list,
  }: {
    title: string;
    list: StoragePolicyInfo[];
  }) => {
    return (
      <div className="w-full flex flex-col">
        <p className="mt-[8px]">{title}</p>
        <div className="my-[4px] w-full h-[1px] bg-[#CECECE]" />
        {list &&
          list.map((policy) => {
            return (
              <button
                key={policy.storageInfoId}
                className={clsx(
                  'w-full py-[4px] h-[32px] text-start hover:bg-[#CECECE]',
                  {
                    'bg-[#CECECE]': selectedStorageId === policy.storageInfoId,
                  },
                )}
                onClick={() => onClickStorage(policy.storageInfoId)}
              >
                {policy.storageName}
              </button>
            );
          })}
      </div>
    );
  };

  return (
    <div className="mx-[72px] my-[40px] overflow-y-scroll">
      <h1>파일 설정</h1>
      <h2>업로드 불가 확장자 설정</h2>
      <p>다우오피스에서 업로드 불가한 파일 확장자를 공통 설정합니다.</p>
      <div className="flex flex-wrap mt-[32px] min-h-[720px] overflow-y-scroll gap-[24px]">
        <div className="p-[20px] border border-solid border-[#AAAAAA] rounded-[8px]">
          <div className="flex items-center justify-between gap-[24px]">
            <h2>적용 대상 앱</h2>
            <BatchDialog onClickApply={onClickBatchApply} />
          </div>
          <ExtensionList
            title="임직원 포털"
            list={currentStorages.filter(
              (info) => info.portalType === 'EMPLOYEE',
            )}
          />
          <ExtensionList
            title="경영업무 포털"
            list={currentStorages.filter(
              (info) => info.portalType === 'BUSINESS',
            )}
          />
        </div>
        <div className="flex flex-col p-[20px] border border-solid border-[#AAAAAA] rounded-[8px] min-w-[520px]">
          <div className="flex items-center h-[40px] gap-[24px]">
            <input
              className="px-[8px] h-full flex-grow border border-solid border-[#AAAAAA] rounded-[8px] disabled:opacity-20"
              placeholder="업로드를 제한할 확장자를 ,(쉼표)로 구분하여 입력해주세요. ex) pdf,txt"
              disabled={!selectedStorageId}
              value={blacklistText}
              onChange={(e) => onChangeBlackList(e.target.value)}
            />
            <Button
              title="추가"
              size="medium"
              shape="rect"
              onClick={onClickAddBlackList}
              disabled={blacklistText.length === 0}
            />
          </div>
          {errorMessage && <p className="text-[red]">{errorMessage}</p>}
          <p className="mt-[12px]">업로드 불가 확장자</p>
          <p className="mt-[4px]">
            업로드 불가 확장자 설정 시 최대 2분 내 적용됩니다.
          </p>
          <div className="flex flex-wrap mt-[12px] flex-grow gap-3">
            {selectedStorageBlackList &&
              selectedStorageBlackList.map((info) => {
                return (
                  <div
                    key={`${info.extensionId}` + info.extensionName}
                    className="pl-[8px] border border-solid border-[#AAAAAA] h-[32px] rounded-[8px] flex items-center"
                  >
                    {info.extensionName}
                    <IconButton
                      title={info.extensionName}
                      size="small"
                      onClick={() => onDeleteBlackList(info)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                );
              })}
          </div>
          <p>기존 업로드 불가 확장자</p>
          <div className="my-[4px] w-full h-[1px] bg-[#CECECE]" />
          <p>
            안전한 다우오피스 사용을 위해 기본적으로 업로드를 제한하고 있는
            확장자 목록입니다.
          </p>
          <div className="mt-[12px]">
            {policyData?.defaultExtensionBlacklist &&
              policyData.defaultExtensionBlacklist.join(', ')}
          </div>
        </div>
      </div>
      <div className="mt-[24px] px-[40px] py-[12px] border border-solid border-[#DDDDDD] rounded-[8px] flex flex-wrap items-center gap-[8px]">
        <p className="text-[16px] text-[#111111] font-[500] min-w-[240px]">
          첨부파일 최대 개수
        </p>
        <select
          className="ml-[24px] px-[8px] h-[40px] rounded-[8px] min-w-[90px]"
          value={maxCountOption}
          onChange={(e) => onSelectCountLimit(e.target.value)}
        >
          <option value={0}>제한없음</option>
          <option value={1}>1</option>
          <option value={10}>10</option>
          <option value={attachCountSelfInputKey}>
            {t('dataManagement.setNotification.standard.selfInput')}
          </option>
        </select>
        {isVisibleAttachCountSelfInput && (
          <div className="flex items-center px-[8px] w-[60px] h-[40px] border border-solid border-[#D8D8D8] rounded-[8px]">
            <input
              className="w-full"
              value={currentMaxAttachCount}
              type="number"
              onChange={(e) => onChangeCountLimit(Number(e.target.value))}
            />
          </div>
        )}
      </div>
      <div className="mt-[24px] px-[40px] py-[12px] border border-solid border-[#DDDDDD] rounded-[8px] flex flex-wrap items-center gap-[8px]">
        <p className="text-[16px] text-[#111111] font-[500] min-w-[240px]">
          첨부파일 용량 제한
        </p>
        <select
          className="ml-[24px] px-[8px] h-[40px] rounded-[8px] min-w-[90px]"
          value={maxSizeOption}
          onChange={(e) => onSelectSizeLimit(e.target.value)}
        >
          <option value={megabyteToByte(100)}>100MB</option>
          <option value={megabyteToByte(500)}>500MB</option>
          <option value={megabyteToByte(1024)}>1GB</option>
          <option value={attachSizeSelfInputKey}>
            {t('dataManagement.setNotification.standard.selfInput')}
          </option>
        </select>
        {isVisibleAttachSizeSelfInput && (
          <>
            <div className="flex items-center px-[8px] w-[60px] h-[40px] border border-solid border-[#D8D8D8] rounded-[8px]">
              <input
                className="w-full"
                value={byteToMegabyte(currentMaxAttachSize)}
                type="number"
                onChange={(e) => onChangeSizeLimit(Number(e.target.value))}
              />
            </div>
            <span className="text-[14px] text-[#333333] font-[400]">MB</span>
          </>
        )}
      </div>
      <div className="mt-[24px] py-[16px] flex items-center justify-center gap-[8px]">
        <Button
          title={t('dialog.cancel')}
          colorset="minor"
          shape="rect"
          styleType="line"
          size="medium"
          onClick={onClickCancel}
        />
        <Button
          title={t('dialog.save')}
          colorset="major"
          shape="rect"
          styleType="solid"
          size="medium"
          disabled={
            !(
              isStoragesHaveChange ||
              isMaxAttachCountDirty ||
              isMaxAttachSizeDirty
            )
          }
          onClick={onClickSave}
        />
      </div>
    </div>
  );
}
