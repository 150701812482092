import { useTranslation } from '../../../../../../lib/i18n/client/useTranslation';

export default function NoMemoSearchTemplate() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center gap-3 h-[262px] py-[48px]">
      <p className="text-[#999] font-medium">
        {t('organizer.memo.empty.recently.search')}
      </p>
    </div>
  );
}
