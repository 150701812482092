import { useSuspenseQuery } from '@tanstack/react-query';
import { SiteList } from './site-list';
import TotalAccount from './total-account';
import { QUERY_KEY, getCompanyList } from '../../apis/get-company-list';
import { useBasicInfoContext } from '../../Context';
import { useAuthContext } from '../../../../../../../lib/auth/client';
import { useEffect } from 'react';

export function MultiCompanyBasicInfo() {
  const { setBasicInfoContext } = useBasicInfoContext();

  const { me } = useAuthContext();
  if (!me) {
    return;
  }

  const { data: companyList, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: async () => await getCompanyList(me.companyGroupId),
  });

  useEffect(() => {
    setBasicInfoContext((prev) => ({
      ...prev,
      companyList: companyList,
    }));
  }, [companyList]);

  if (error) {
    console.error(
      'Error: GlobalConfig > MultiCompanyManagement > BasicInfo : ',
      error,
    );
  }

  return (
    <div className="flex flex-col px-[48px] py-[24px] rounded-xl bg-[#F9FBFC] ">
      <SiteList />
      <TotalAccount />
    </div>
  );
}

export default MultiCompanyBasicInfo;
