import { fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { CONFIG_FAVORITE_APPS_API_URL } from '../../../constants';
import { FavoriteMenuInfo } from '../../../types';

export const QUERY_KEY = 'GlobalConfig.Menu.FavoriteAppInfos';

interface AppInfo {
  id: number;
  appCode: string;
  path: string;
}

interface FavoriteMenuInfoResponse {
  code: string;
  message: string;
  data: {
    elements: AppInfo[];
  };
}

export async function getFavoriteMenuInfos() {
  const response = await fetch(CONFIG_FAVORITE_APPS_API_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData = (await response.json()) as FavoriteMenuInfoResponse;

  return responseData.data.elements.map(adaptor);
}

function adaptor(appInfo: AppInfo): FavoriteMenuInfo {
  return {
    id: appInfo.id,
    menuCode: appInfo.appCode,
    menuPath: appInfo.path,
  };
}

export async function addFavoriteMenuInfo(menuInfo: FavoriteMenuInfo) {
  await fetch(CONFIG_FAVORITE_APPS_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({
      appCode: menuInfo.menuCode,
      path: menuInfo.menuPath,
    }),
  });
}

export async function deleteFavoriteMenuInfo(menuId: number) {
  await fetch(CONFIG_FAVORITE_APPS_API_URL + '/' + menuId, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });
}
