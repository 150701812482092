const SECURE_MANAGEMENT_PREFIX = '/api/portal/setting/security';

const PASSWORD_POLICY = 'password-policy';

export const UPDATE_PASSWORD_POLICY_API = `${SECURE_MANAGEMENT_PREFIX}/${PASSWORD_POLICY}`;

export const PASSWORD_LIMIT_OPTIONS = [2, 3, 5];

export const PASSWORD_CYCLE_OPTIONS = [3, 6, 9, 12];

export const PASSWORD_ERROR_COUNT_OPTIONS = [3, 4, 5];

export const TRANSLATE_PREFIX =
  'globalconfig.secureManagement.passwordPolicyConfig.';
