import { getSessionData } from '../../lib/auth/client/Provider';
import * as fetchClient from '../../lib/fetch/client';
import { DEPARTMENT } from './constants';

import {
  DepartmentResponseData,
  OrgResponseData,
  SearchDepartmentsResponseBody,
  SearchUsersResponseBody,
} from './interface';

export async function getUserOrg(type: string) {
  try {
    const myData = await getSessionData();

    console.log(myData);
    const resp = await fetchClient.fetch(
      `/api/portal/common/tree/user/${myData.id}${type === DEPARTMENT ? '?isOnlyDepartment=true' : ''}`,
    );
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as OrgResponseData;
    return res.data.elements;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCompanyOrg() {
  try {
    const resp = await fetchClient.fetch(`/api/portal/admin/tree/company`);
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as OrgResponseData;
    return res.data.elements;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCompanyGroupOrg(companyGroupId: number) {
  try {
    const resp = await fetchClient.fetch(
      `/api/portal/admin/tree/company-group/${companyGroupId}`,
    );
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as OrgResponseData;
    return res.data.elements;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getDepartment(deptId: number, type: string) {
  const resp = await fetchClient.withAuth(
    `/api/portal/common/tree/department/${deptId}${type === DEPARTMENT ? '?isOnlyDepartment=true' : ''}`,
  );
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const { data }: DepartmentResponseData =
    (await resp.json()) as DepartmentResponseData;
  return data;
}

export async function searchUsers(keyword: string, page = 0, size = 20) {
  const resp = await fetchClient.withAuth(
    `/api/portal/common/tree/search/member?keyword=${keyword}&page=${page}&size=${size}`,
  );
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const { data }: SearchUsersResponseBody =
    (await resp.json()) as SearchUsersResponseBody;
  return data;
}

export async function searchDepartments(keyword: string) {
  const resp = await fetchClient.withAuth(
    `/api/portal/common/tree/search/department?keyword=${keyword}`,
  );
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const { data }: SearchDepartmentsResponseBody =
    (await resp.json()) as SearchDepartmentsResponseBody;
  return data.elements;
}

export async function getAllSubNodes(deptId: number, type: string) {
  const resp = await fetchClient.withAuth(
    `/api/portal/common/tree/department/all/${deptId}${type === DEPARTMENT ? '?isOnlyDepartment=true' : ''}`,
  );
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const res = (await resp.json()) as OrgResponseData;
  return res.data.elements;
}
