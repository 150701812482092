import { SearchBar } from './SearchBar';
import { Title } from './Title';

export function Header() {
  return (
    <div className="wrap_header">
      <Title />
      <SearchBar />
    </div>
  );
}

export default Header;
