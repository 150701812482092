import { I18nText } from '@dop-ui/react/shared/lib/i18n/client/i18n-text';
import * as multicompanyManagementPages from '../../pages/multi-company-management';
import { MenuInfo } from '../../types';
import { codes, paths } from '../main';
import * as multicompanyManagementPaths from './paths';

const i18nNamespace = 'component';

export const menuKeyPath = paths.MULTICOMPANY_MANAGEMENT;

export const menuInfo: MenuInfo[] = [
  {
    menuCode: codes.MULTICOMPANY_MANAGEMENT,
    name: (
      <I18nText
        namespace={i18nNamespace}
        i18nKey="globalconfig.menu.multicompanyManagement.setting"
      />
    ),
    menuPath: multicompanyManagementPaths.MULTICOMPANY_SETTING_PATH,
    sortOrder: 0,
    children: [
      {
        menuCode: codes.MULTICOMPANY_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.multicompanyManagement.basicInfo"
          />
        ),
        menuPath: multicompanyManagementPaths.BASIC_INFO_PATH,
        sortOrder: 0,
        page: <multicompanyManagementPages.BasicInfoPage />,
      },
      {
        menuCode: codes.MULTICOMPANY_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.multicompanyManagement.multiMember"
          />
        ),
        menuPath: multicompanyManagementPaths.MULTI_MEMBER_PATH,
        sortOrder: 1,
        page: <multicompanyManagementPages.MultiMemberPage />,
      },
      {
        menuCode: codes.MULTICOMPANY_MANAGEMENT,
        name: (
          <I18nText
            namespace={i18nNamespace}
            i18nKey="globalconfig.menu.multicompanyManagement.appShare"
          />
        ),
        menuPath: multicompanyManagementPaths.MULTI_APP_SHARE_PATH,
        sortOrder: 2,
        page: <multicompanyManagementPages.AppSharePage />,
      },
    ],
  },
];
