import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { useMenusAtom } from '../../../../store';
import * as userMenusMutation from '../../../api/userMenusMutation';
import { useContext } from '../Context';
import type { IUserMenuInfo } from '../../../../types';

export function Footer() {
  const [, setMenusAtom] = useMenusAtom();
  const { userApps, userAppsInitialValue, setUserApps } = useContext();
  const { mutate: updateUserMenus } = useMutation({
    mutationFn: (menus: IUserMenuInfo[]) =>
      userMenusMutation.updateUserMenus(menus),
    onSuccess: () => setMenusAtom(userApps),
    onError: () => setMenusAtom(userAppsInitialValue),
  });

  const handleOnClickClear = () => {
    setUserApps(userAppsInitialValue);
    // console.log(userAppsInitialValue);
  };

  const handleOnClickSave = React.useCallback(() => {
    // TODO : API 생성 후, 추가 예정.
    // 홈은 클라이언트에서 임의로 생성한 메뉴이므로 서버 전송시에는 걸러낸다.
    const payload = userApps.slice(1);
    updateUserMenus(payload);
    // 전송 성공하면 로컬 스토어에 업데이트
    // setMenusAtom(userApps);
  }, [userApps, updateUserMenus]);

  return (
    <div className="group_btn half">
      <Button
        title="초기화"
        size="medium"
        shape="rect"
        styleType="line"
        onClick={handleOnClickClear}
      />
      <Button
        title="저장"
        size="medium"
        shape="rect"
        styleType="solid"
        onClick={handleOnClickSave}
      />
    </div>
  );
}

export default Footer;
