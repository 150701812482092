import { clsx } from 'clsx';

export interface Props {
  message: string;
  className?: string;
}

export function ErrorMessage({ message, className }: Props) {
  return (
    <p
      className={clsx(
        'text-center whitespace-pre-line text-xs text-[#F72121] mb-4',
        className,
      )}
    >
      {message}
    </p>
  );
}

export default ErrorMessage;
