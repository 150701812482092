import MultiMemberContents from './contents';
import { Provider as MultiMemberContextProvider } from './context';
import styles from './multi-member.module.css';
export function MultiMember() {
  return (
    <div className={styles.Container}>
      <div className={styles.Card}>
        <p className={styles.CardTitle}>겸직자 목록</p>
        <MultiMemberContextProvider>
          <MultiMemberContents />
        </MultiMemberContextProvider>
      </div>
    </div>
  );
}
