import { fetch } from '../../../../../../lib/fetch/client';
import { DeletedDeptInfo, Option, Page } from '../types';
import { searchParamGenerator } from '../utils/searchParamGenerator';

// TODO: 기존 API Prefix : 추후 수정 예정 (ad 제거 예정)
const MOCK_URL_PATH = '/gw/ad/api';

export const LIST_QUERY_KEY = 'deletedDeptListQuery';
export const DOWNLOAD_QUERY_KEY = 'deletedDeptListDownloadQuery';

export interface ResponseBody {
  page?: Page;
  data?: DeletedDeptInfo[];
}

export async function deletedDeptListDownloadQuery() {
  try {
    const FETCH_URL = `${MOCK_URL_PATH}/deletedepts/download`;
    return (await fetch(FETCH_URL)).blob();
  } catch (error) {
    console.error(error);
  }
}

export async function deletedDeptListQuery(options?: Option) {
  try {
    const FETCH_URL = `${MOCK_URL_PATH}/deletedepts?${searchParamGenerator(options)}`;
    const res = (await fetch(FETCH_URL)).json();
    const data = (await res) as ResponseBody;
    return data;
  } catch (error) {
    console.error(error);
  }
}
