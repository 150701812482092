import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { TitleAndDescription } from './TitleAndDescription';
import * as loginSecureConfigAPIs from './apis/loginSecureConfig';
import { LoginIdType } from './types';

export function LoginMethod() {
  const { t } = useTranslation('component');
  const { data: loginMethodConfig, error: loginMethodError } = useQuery({
    queryKey: [loginSecureConfigAPIs.QUERY_KEY_LOGINMETHOD],
    queryFn: loginSecureConfigAPIs.getLoginMethodTypeConfig,
  });

  const queryClient = useQueryClient();
  const { confirm } = useMessageDialog();

  const loginMethodMutation = useMutation({
    mutationFn: loginSecureConfigAPIs.updateLoginMethodConfig,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [loginSecureConfigAPIs.QUERY_KEY_LOGINMETHOD],
      });
    },
  });
  const onChangeLoginMethod = async (value: string) => {
    const method = value as LoginIdType;
    if (
      await confirm(
        <div className="size-full">
          <p className="text-[#1A1A1A] text-[18px] font-[500]">
            {method === 'ID'
              ? t(
                  'globalconfig.secureManagement.loginSecureConfig.loginMethod.id.select.title',
                )
              : t(
                  'globalconfig.secureManagement.loginSecureConfig.loginMethod.number.select.title',
                )}
          </p>
          <p className="mt-[24px] text-[#1A1A1A] text-[14px] font-[400] whitespace-pre-wrap">
            {method === 'ID'
              ? t(
                  'globalconfig.secureManagement.loginSecureConfig.loginMethod.id.select.description',
                )
              : t(
                  'globalconfig.secureManagement.loginSecureConfig.loginMethod.number.select.description',
                )}
          </p>
        </div>,
      )
    )
      loginMethodMutation.mutate(method);
  };

  if (loginMethodError) {
    console.error(
      'GlobalCofig > SecureManagement > LoginSecureConfig > loginMethodConfig Error: ',
      loginMethodError,
    );
  }

  return (
    <TitleAndDescription
      title={t(
        'globalconfig.secureManagement.loginSecureConfig.loginMethod.title',
      )}
      description={t(
        'globalconfig.secureManagement.loginSecureConfig.loginMethod.description',
      )}
    >
      <select
        className="px-[8px] h-[40px] rounded-[8px] border border-solid border-[#D8D8D8]"
        value={loginMethodConfig?.loginIdType ?? 'ID'}
        onChange={(e) => void onChangeLoginMethod(e.target.value)}
      >
        <option value={'ID'}>
          {t('globalconfig.secureManagement.loginSecureConfig.loginMethod.id')}
        </option>
        <option value={'ID_NUMBER'}>
          {t(
            'globalconfig.secureManagement.loginSecureConfig.loginMethod.number',
          )}
        </option>
      </select>
    </TitleAndDescription>
  );
}
