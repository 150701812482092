import { atom } from 'jotai';
import { openSubMenusAtom } from './openSubMenusAtom';
import { selectedMainCategoryAtom } from './selectedMainCategoryAtom';
import { currentSelectedMenuInfoAtom } from './currentSelectedMenuInfoAtom';
import { addRecentUsedMenuInfoAtom } from './recentUsedAppsAtom';
import { getMenuInfoWithPath } from '../menus';

export const setMenuByPathAtom = atom(null, (get, set, path?: string) => {
  if (!path) {
    set(selectedMainCategoryAtom, '');
    set(openSubMenusAtom, new Set());
    set(currentSelectedMenuInfoAtom, { menuCode: '', menuPath: '' });
    return;
  }

  const paths = path.split('/');

  if (!paths) return;

  const openNodes = get(openSubMenusAtom);
  const newOpenNodes = new Set(openNodes);
  if (paths.length === 2) {
    set(selectedMainCategoryAtom, path);
  } else if (paths.length === 3) {
    set(selectedMainCategoryAtom, `/${paths[1]}`);
    newOpenNodes.add(path);
    set(openSubMenusAtom, newOpenNodes);
  } else if (paths.length === 4) {
    set(selectedMainCategoryAtom, `/${paths[1]}`);
    newOpenNodes.add(`/${paths[1]}/${paths[2]}`);
    set(openSubMenusAtom, newOpenNodes);

    const menuInfo = getMenuInfoWithPath(path);

    const simpleInfo = menuInfo
      ? { menuCode: menuInfo.menuCode, menuPath: menuInfo.menuPath }
      : { menuCode: '', menuPath: '' };

    set(addRecentUsedMenuInfoAtom, simpleInfo);
    set(currentSelectedMenuInfoAtom, simpleInfo);
  }
});
