import { ReactElement } from 'react';

import { toastStore } from './store';
import { generateUniqueId } from './utils';
import { queueAtom } from './store/queueAtom';

export function useToast() {
  const message = (contents: string | ReactElement) => {
    toastStore.set(queueAtom, (prev) => [
      ...prev,
      { type: 'message', contents, id: generateUniqueId(), open: true },
    ]);
  };

  const info = (contents: string | ReactElement) => {
    toastStore.set(queueAtom, (prev) => [
      ...prev,
      { type: 'info', contents, id: generateUniqueId(), open: true },
    ]);
  };

  const alert = (contents: string | ReactElement) => {
    toastStore.set(queueAtom, (prev) => [
      ...prev,
      { type: 'alert', contents, id: generateUniqueId(), open: true },
    ]);
  };

  const warning = (contents: string | ReactElement) => {
    toastStore.set(queueAtom, (prev) => [
      ...prev,
      { type: 'warning', contents, id: generateUniqueId(), open: true },
    ]);
  };

  return { message, alert, warning, info };
}

export default useToast;
