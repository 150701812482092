import {
  DiabledHamburgerMenuIcon,
  HamburgerMenuIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import type { IUserMenuInfo } from '../../../../types';
import { useContext } from '../Context';

export interface Props {
  index: number;
  item: IUserMenuInfo;
  onDragStart: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
  onDragEnter: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
  onDragEnd: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
  onDragOver: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
}

function getAppIcon(item: IUserMenuInfo) {
  return item.icon as string;
}

export function AppsDockConfigItem({
  index,
  item,
  onDragStart,
  onDragEnter,
  onDragEnd,
  onDragOver,
}: Props) {
  const ctx = useContext();

  const handleOnChange = (isChecked: boolean) => {
    // TODO : AppsDock API 생성 후 수정
    ctx.setUserApps(
      ctx.userApps.map((item, idx) => {
        if (idx === index)
          return {
            ...ctx.userApps[index],
            disabled: !isChecked,
          } as IUserMenuInfo;
        else return item;
      }),
    );
  };

  return (
    <li
      className="dop_drag_list immovable ui-sortable-handle"
      id={`${item.uid}`}
      draggable={!item.isDefault}
      onDragStart={(e) => onDragStart(e, index)}
      onDragEnter={(e) => onDragEnter(e, index)}
      onDragOver={(e) => onDragOver(e, index)}
      onDragEnd={(e) => onDragEnd(e, index)}
    >
      <div className="menu_name">
        <div className="btn_icon medium handler text-[#aaaaaa]">
          {item.isDefault ? (
            <DiabledHamburgerMenuIcon className="ic_medium" />
          ) : (
            <HamburgerMenuIcon className="ic_medium" />
          )}
        </div>
        <i
          className="icon ic_medium"
          dangerouslySetInnerHTML={{ __html: getAppIcon(item) }}
        />
        <span className="txt">{item.name}</span>
      </div>
      <div className="dop_switch">
        <div className="toggle">
          <input
            type="checkbox"
            id="toggle_home"
            checked={!ctx.userApps[index].disabled}
            onChange={(e) => handleOnChange(e.target.checked)}
          />
          <label htmlFor="toggle_home">ON</label>
        </div>
      </div>
    </li>
  );
}

export default AppsDockConfigItem;
