import { PropsWithChildren, createContext, useContext } from 'react';

export interface Props {
  name: string;
  idKey: string;
}

export interface IDnDContexts {
  name: string;
  idKey: string;
  getData: (
    item: Record<string | symbol, unknown>,
  ) => Record<string | symbol, unknown>;

  dataValidation: (
    data: Record<string | symbol, unknown>,
    symbol: string,
  ) => boolean;
}

function createDefaultDnDContext(): IDnDContexts {
  return {
    name: '',
    idKey: '',
    dataValidation: () => false,
    getData: () => ({}),
  };
}

export const DndContext = createContext<IDnDContexts>(
  createDefaultDnDContext(),
);

export function Provider({ name, idKey, children }: PropsWithChildren<Props>) {
  const dataValidation = (
    data: Record<string | symbol, unknown>,
    name: string,
  ) => {
    return data[name] === true;
  };

  const getData = (item: Record<string | symbol, unknown>) => {
    return { [name]: true, [idKey]: item[idKey], id: item[idKey] };
  };

  const ctx: IDnDContexts = {
    idKey,
    name: name,
    dataValidation,
    getData,
  };

  return <DndContext.Provider value={ctx}>{children}</DndContext.Provider>;
}

export const useDndContext = () => useContext(DndContext);

export default Provider;
