import { mockedWorksList } from '../mocks';
import type { AppType } from '../types';

export interface Response {
  message: string;
  code: string;
  __go_checksum__: boolean;
  data: OriginWorksType[];
}

export interface OriginWorksType {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  thumbSmall: string;
  favoriteFlag: boolean;
  desc: string;
  showDescription: boolean;
  admin: boolean;
  docCreatedAt?: string;
}

export const QUERY_KEY = 'organizer-works-list';

export interface WorksListType {
  worksList: AppType[];
}

export function getMockedData() {
  const mockedData = mockedWorksList;
  return mockedData;
}

export async function getWorksList(): Promise<WorksListType> {
  try {
    return new Promise((resolve) => {
      setTimeout(() => {
        const resData = getMockedData();
        resolve(adapter(resData));
      }, 100);
    });
  } catch (error) {
    console.log('getWorksList 오류 발생', error);
    return {
      worksList: [],
    };
  }
}

function adapter(source: Response): WorksListType {
  return {
    worksList: source.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        thumbnail: data.thumbSmall,
        desc: data.desc,
        size: 'large',
      };
    }),
  };
}
