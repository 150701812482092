import { gql } from 'graphql-request';
import { fetchGraphQL } from '../../../../../../lib/fetch/client';
import {
  GetPasswordPolicy,
  GetPasswordPolicyResponse,
  PasswordPolicyConfig,
} from '../types';

export const QUERY_KEY = 'getPasswordPolicy';

export const Query = gql`
  query ${QUERY_KEY} {
    authConfig {
      FAIL_COUNT
      MIN_PASSWORD_LENGTH
      MAX_PASSWORD_LENGTH
      MANDATORY_CHAR_LOWERCASE
      MANDATORY_CHAR_UPPERCASE
      MANDATORY_CHAR_NUMBER
      MANDATORY_CHAR_SYMBOL

      TABOO_CHAR_SAME_SERIAL_ALPHABET
      TABOO_CHAR_SAME_SERIAL_NUMBER
      TABOO_CHAR_PERSONAL_INFORMATION
      TABOO_CHAR_CLOSE_UP_KEYBOARD

      LIMIT_PASSWORD_REUSE_COUNT
      FORCE_TO_CHANGE_PW_PERIOD
      FORCE_TO_CHANGE_PW
    }
  }
`;

export async function getPasswordPolicy(): Promise<PasswordPolicyConfig> {
  const response = await fetchGraphQL({ query: Query });
  const {
    data: { authConfig },
  } = (await response.json()) as GetPasswordPolicyResponse;
  return adpater(authConfig);
}

function adpater(response: GetPasswordPolicy): PasswordPolicyConfig {
  return {
    passwordErrorCount: response.FAIL_COUNT,
    minPasswordLength: response.MIN_PASSWORD_LENGTH,
    maxPasswordLength: response.MAX_PASSWORD_LENGTH,
    mandatoryLowercase: response.MANDATORY_CHAR_LOWERCASE,
    englishUppercase: response.MANDATORY_CHAR_UPPERCASE,
    mandatoryCharNumber: response.MANDATORY_CHAR_NUMBER,
    mandatoryCharSymbol: response.MANDATORY_CHAR_SYMBOL,
    tabooCharSameSerialAlphabet: response.TABOO_CHAR_SAME_SERIAL_ALPHABET,
    tabooCharPersonalInformation: response.TABOO_CHAR_PERSONAL_INFORMATION,
    tabooCharSameSerialNumber: response.TABOO_CHAR_SAME_SERIAL_NUMBER,
    keyboardSeq: response.TABOO_CHAR_CLOSE_UP_KEYBOARD,
    passwordChangeCycle: response.FORCE_TO_CHANGE_PW_PERIOD,
    passwordReuseLimit: response.LIMIT_PASSWORD_REUSE_COUNT,
    forcePasswordChange: response.FORCE_TO_CHANGE_PW,
  };
}
