import { PropsWithChildren } from 'react';
import { ActionItemInfo } from '../types';
import { clsx } from 'clsx';
import * as Tooltip from '@dop-ui/react/shared/ui/tooltip';
import styles from '../organizer-body.module.css';

interface Props extends ActionItemInfo {
  className?: string;
}

function ToolItem({
  children,
  uid,
  name,
  url,
  onClick,
  className,
}: PropsWithChildren<Props>) {
  return (
    <li key={uid} className={clsx(styles.link_list, className)}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <a href={url} onClick={onClick} key={uid}>
            {children}
          </a>
        </Tooltip.Trigger>
        <Tooltip.Content side="left" sideOffset={30}>
          {name}
        </Tooltip.Content>
      </Tooltip.Root>
    </li>
  );
}

export default ToolItem;
