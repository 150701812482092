import { Contents } from './components/Contents';
import { MobileConfig } from './components/MobileConfig';
import { Root } from './components/Root';

export function MobileSecureConfig() {
  return (
    <Root>
      <Contents>
        <MobileConfig type="ATTACHED_FILE_DOWNLOAD" withDivider />
        <MobileConfig type="ATTACHED_FILE_PREVIEW" withDivider />
        <MobileConfig type="MOBILE_CAPTURE" />
      </Contents>
    </Root>
  );
}

export default MobileSecureConfig;
