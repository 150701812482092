import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { SimpleMenuInfo } from '../types';

const recentMenuInfosAtom = atomWithStorage<SimpleMenuInfo[]>(
  'LocalKeys.GlobalConfig.RecentUsedApps.RecentAppInfos',
  [],
);

export const recentUsedMenuInfoAtom = atom((get) => get(recentMenuInfosAtom));

const maxRecentUsedApps = 5;
const maxAppIndex = maxRecentUsedApps - 1;

export const addRecentUsedMenuInfoAtom = atom(
  null,
  (get, set, update: SimpleMenuInfo) => {
    const recentAppIds = get(recentMenuInfosAtom);
    if (
      recentAppIds.filter((info) => info.menuPath === update.menuPath).length >
      0
    )
      return;
    const newAppIds = [update, ...recentAppIds.slice(0, maxAppIndex)];
    set(recentMenuInfosAtom, newAppIds);
  },
);
