import { ChangeEvent, SetStateAction } from 'react';
import { useOrganizerContext } from '../../../Context';
import { ShortcutItemType } from '../../../index';
import { SettingsMenuItem } from '../settings-menu-item';
import { IdentifiableShortcutItem } from '../../Shortcut/types';

import * as DnDList from '@dop-ui/react/shared/ui/dnd/list';
import styles from '../organizer-shortcut-setting.module.css';

export interface Props {
  /** @property Shortcut 삭제 반영 함수 */
  setDeletedItemList: React.Dispatch<
    SetStateAction<IdentifiableShortcutItem[]>
  >;
}

export function SettingsMenuItemList({ setDeletedItemList }: Props) {
  const ctx = useOrganizerContext();

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: number,
    itemType: ShortcutItemType,
  ) => {
    if (itemType === 'APP') return;
    const { value } = e.target;

    ctx.setSettingsItems((prev) =>
      prev.map((item) => {
        if (item.uid === id) {
          const updated = { ...item };
          updated.name = value;
          return updated;
        } else return item;
      }),
    );
  };

  const handleOnDelete = (uid: number, type: ShortcutItemType) => {
    ctx.setSettingsItems((prev) => {
      const res = prev.filter(
        (item) => !(item.uid === uid && item.itemType === type),
      );
      return res;
    });
    setDeletedItemList((prev) => [
      ...prev,
      {
        id: uid,
        type: type,
      },
    ]);
  };

  return (
    <DnDList.Provider idKey="uid" name="shortcut_setting">
      <DnDList.Container
        list={ctx.settingsItems}
        setList={ctx.setSettingsItems}
      >
        <ul className={styles.shortcut_list}>
          {ctx.settingsItems.map((item, index) => {
            return (
              <SettingsMenuItem
                item={item}
                itemType={item.itemType}
                key={index}
                index={index}
                onDelete={handleOnDelete}
                onChange={handleOnChange}
              />
            );
          })}
        </ul>
      </DnDList.Container>
    </DnDList.Provider>
  );
}

export default SettingsMenuItemList;
