import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';

interface Props {
  siteName: string;
  siteURL: string;
  siteID: number;
  domain: string;
}

export function DomainInfo({ siteName, siteURL, siteID, domain }: Props) {
  const { t } = useTranslation('component');

  return (
    <div className="flex items-center gap-[48px] flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <div className="flex-grow">
        <p className="text-[#333333] text-[32px] font-[700] text-wrap">
          {siteName}
        </p>
        <p className="mt-[8px] text-[#555A5F] text-[14px] font-[500] opacity-50">
          {siteURL}
        </p>
      </div>
      <div className="flex flex-col justify-evenly self-stretch">
        <div className="flex flex-wrap items-center text-[#AAAAAA] text-[14px] font-[500]">
          <p className="w-[80px] mr-[40px]">
            {t('globalconfig.basicManagement.serviceInfo.domainInfo.siteID')}
          </p>
          <p className="min-w-[210px]">{siteID}</p>
        </div>
        <div className="flex flex-wrap items-center text-[#AAAAAA] text-[14px] font-[500]">
          <p className="w-[80px] mr-[40px]">
            {t('globalconfig.basicManagement.serviceInfo.domainInfo.domain')}
          </p>
          <p className="min-w-[210px]">{domain}</p>
        </div>
      </div>
    </div>
  );
}
