import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

/**
 * 드래그 상태가 될 때 Preview에 보여질 요소를 정의한다.
 * @property: getOffset: 드래그 시작 위치에서의 Offset 정의
 * @property: render: HTMLElement => CleanupFn | void; render될 요소 반환
 * @property: nativeSetDragImage
 * @example
 * draggable({
 *  onGenerateDragPreview: ({ nativeSetDragImage }) => {
 *    setCustomNativeDragPreview({
 *      render: ({ container }) => {
 *        ReactDOM.render(<Preview item={item} />, container);
 *        return () => ReactDOM.unmountComponentAtNode(container);
 *      },
 *      nativeSetDragImage,
 *    });
 *    },
 * });
 */
export { setCustomNativeDragPreview };
