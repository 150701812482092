import { clsx } from 'clsx';
import { AppInfo } from '../../types';
import { NoSymbolIcon } from '@heroicons/react/24/outline';

interface Props {
  title: string;
  apps: AppInfo[];
  className?: string;
}

export function Apps({ title, apps, className }: Props) {
  return (
    <div className={clsx('p-[24px] bg-[#FBFCFC] rounded-[12px]', className)}>
      <div className="flex items-center">
        <span className="text-[#888888] text-[14px] font-[500]">{title}</span>
        <p className="ml-[4px] px-[5px] bg-[#B2E4EB] text-[#024EE0] text-[12px] font-[500] rounded-full text-center">
          {apps.length}
        </p>
      </div>
      <div className="flex flex-wrap gap-[24px] w-full mt-[16px]">
        {apps.map((app) => (
          <App key={app.id} info={app} />
        ))}
      </div>
    </div>
  );
}

function App({ info }: { info: AppInfo }) {
  const icon = info.icon ? (
    <i dangerouslySetInnerHTML={{ __html: info.icon }} />
  ) : (
    <NoSymbolIcon />
  );

  return (
    <div className="max-w-[64px] flex-shrink-0">
      <div className="p-[14px] border border-solid rounded-[20px] size-[64px] flex items-center justify-center">
        {icon}
      </div>
      <p className="mt-[6px] text-[#333333] text-[14px] font-[400] text-center">
        {info.name}
      </p>
    </div>
  );
}
