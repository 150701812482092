import { useWindowResizeObserver } from '@daouoffice/ui/lib/hooks/useWindowResizeObserver';
import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ShortcutItemInfo } from '../types';
import ShortcutListItem from './shortcut-item';

import { useTranslation } from '../../../lib/i18n/client/useTranslation';

import { AscendIcon, DescendIcon } from '@daouoffice/ui/lib/icons/dop/16';
import PanelMenu from '../panel-button';

import { Button } from '@dop-ui/react/shared/ui/button';
import { SHORTCUT_ITEM_HEIGHT } from '../constants';
import Blur from './blur';
import styles from '../organizer-body.module.css';

export interface Props {
  /** @property Shortcut 리스트 */
  shortcutItemList?: ShortcutItemInfo[];
  /** @property 클래스네임 */
  className?: string;
}

/**
 * Organizer.ActionItemList 컴포넌트
 * @returns
 */

export function ShortcutItemList({ shortcutItemList, className }: Props) {
  const contRef = useRef<HTMLDivElement>(null);

  const [tabPage, setTabPage] = useState(0);
  const [renderLength, setRenderLength] = useState(5);

  const itemLength = shortcutItemList && shortcutItemList.length;

  useEffect(() => {
    calculateUlHeight();
  }, [shortcutItemList]);

  const calculateUlHeight = () => {
    if (contRef.current && shortcutItemList) {
      contRef.current.style.height = '';

      const { height } = contRef.current.getBoundingClientRect();
      const renderLength = Math.floor(height / SHORTCUT_ITEM_HEIGHT) || 1;
      setRenderLength(renderLength);

      const elementHeight =
        SHORTCUT_ITEM_HEIGHT * renderLength + SHORTCUT_ITEM_HEIGHT / 3;
      contRef.current.style.height = String(elementHeight + 'px');
    }
  };
  useWindowResizeObserver(calculateUlHeight, {
    debounced: true,
    debouncedTimeMs: 100,
  });

  useEffect(() => {
    if (itemLength && tabPage + renderLength > itemLength) {
      const updateTabPage = itemLength - renderLength;
      setTabPage(Math.max(updateTabPage, 0));
    }
  }, [renderLength]);

  const { t } = useTranslation();

  const hasPrev = tabPage > 0;
  const hasNext = itemLength ? tabPage + renderLength < itemLength : false;

  const handleOnTabPrev = () => {
    setTabPage((prevPage) => prevPage - 1);
  };

  const handleOnTabNext = () => {
    setTabPage((prevPage) => prevPage + 1);
  };
  const listExist = shortcutItemList && shortcutItemList.length > 0;

  return (
    <>
      {listExist && (
        <>
          <Button
            className={clsx(
              'px-3',
              { 'hover:bg-[#f2f2f2]': hasPrev },
              styles.btn_controller,
            )}
            disabled={!hasPrev}
            onClick={handleOnTabPrev}
          >
            <AscendIcon
              className={clsx({
                'text-[#363636]': hasPrev,
                'text-[#D7D7D7]': !hasPrev,
              })}
            />
          </Button>

          <div className={styles.shortcut_list} ref={contRef}>
            <ul
              className={clsx(className, 'flex-1 pb-5 overflow-hidden')}
              style={{
                transform: `translate(0px, -${tabPage * SHORTCUT_ITEM_HEIGHT}px) translateZ(0px)`,
                transition: 'transform .1s ease',
              }}
            >
              {shortcutItemList?.map((item) => (
                <ShortcutListItem
                  key={item.uid + item.itemType}
                  uid={item.uid}
                  sortOrder={item.sortOrder}
                  name={item.name}
                  itemId={item.itemId}
                  appType={item.appType}
                  itemType={item.itemType}
                  size={item.size}
                  icon={item.icon}
                  url={item.url}
                />
              ))}
            </ul>

            <Blur className="absolute w-12 h-5 bottom-[-5px]" />
          </div>

          <Button
            className={clsx(
              'px-3',
              { 'hover:bg-[#f2f2f2]': hasNext },
              styles.btn_controller,
            )}
            disabled={!hasNext}
            onClick={handleOnTabNext}
          >
            <DescendIcon
              className={clsx({
                'text-[#363636]': hasNext,
                'text-[#D7D7D7]': !hasNext,
              })}
            />
          </Button>
        </>
      )}
      <PanelMenu
        hasBackground={false}
        id="shortcut"
        name={t('organizer.shortcut.add')}
      />
    </>
  );
}

export default ShortcutItemList;
