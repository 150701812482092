import { gql } from 'graphql-request';
import { fetch, fetchGraphQL } from '../../../../../../lib/fetch/client';
import {
  AccessIpConfig,
  DuplicationLoginConfig,
  LoginIdType,
  LoginMethodTypeConfig,
  LoginTimeoutConfig,
  OverseasLoginConfig,
} from '../types';
import { UPDATE_SECURE_CONFIG_API } from '../../constants';

const loginTimeoutQuery = gql`
  query {
    authConfig {
      BUSINESS_PORTAL_LOGIN_TIMEOUT
      EMPLOYEE_PORTAL_LOGIN_TIMEOUT
    }
  }
`;

const loginMethodTypeQuery = gql`
  query {
    authConfig {
      LOGIN_ID_TYPE
    }
  }
`;

const duplicationLoginQuery = gql`
  query {
    authConfig {
      BLOCK_DUPLICATE_LOGIN
    }
  }
`;

const accessIpQuery = gql`
  query {
    authConfig {
      BLOCK_ACCESS_IP
    }
  }
`;

const abroadLoginQuery = gql`
  query {
    authConfig {
      BLOCK_ABROAD_LOGIN
    }
  }
`;

interface LoginSecureConfigQueryResponse<T> {
  data: {
    authConfig: T;
  };
}

interface LoginTimeoutConfigResponse {
  BUSINESS_PORTAL_LOGIN_TIMEOUT: number;
  EMPLOYEE_PORTAL_LOGIN_TIMEOUT: number;
}

interface LoginMethodTypeConfigResponse {
  LOGIN_ID_TYPE: LoginIdType;
}

interface DuplicationLoginConfigResponse {
  BLOCK_DUPLICATE_LOGIN: boolean;
}

interface AccessIpConfigResponse {
  BLOCK_ACCESS_IP: boolean;
}

interface OverseasLoginConfigResponse {
  BLOCK_ABROAD_LOGIN: boolean;
}

export const QUERY_KEY_TIMEOUT =
  'GlobalConfig.SecureManagement.LoginSecureConfig.loginTimeout';

export async function getLoginTimeoutConfig(): Promise<LoginTimeoutConfig> {
  const response = await fetchGraphQL({ query: loginTimeoutQuery });
  const responseData =
    (await response.json()) as LoginSecureConfigQueryResponse<LoginTimeoutConfigResponse>;
  return {
    employeePortalLoginTimeout:
      responseData.data.authConfig.BUSINESS_PORTAL_LOGIN_TIMEOUT,
    businessPortalLoginTimeout:
      responseData.data.authConfig.BUSINESS_PORTAL_LOGIN_TIMEOUT,
  };
}

export const QUERY_KEY_LOGINMETHOD =
  'GlobalConfig.SecureManagement.LoginSecureConfig.loginMehtodType';

export async function getLoginMethodTypeConfig(): Promise<LoginMethodTypeConfig> {
  const response = await fetchGraphQL({ query: loginMethodTypeQuery });
  const responseData =
    (await response.json()) as LoginSecureConfigQueryResponse<LoginMethodTypeConfigResponse>;
  return {
    loginIdType: responseData.data.authConfig.LOGIN_ID_TYPE,
  };
}

export const QUERY_KEY_DUPLICATION =
  'GlobalConfig.SecureManagement.LoginSecureConfig.duplicationLogin';

export async function getDuplicationLoginConfig(): Promise<DuplicationLoginConfig> {
  const response = await fetchGraphQL({ query: duplicationLoginQuery });
  const responseData =
    (await response.json()) as LoginSecureConfigQueryResponse<DuplicationLoginConfigResponse>;
  return {
    isBlockDuplicateLogin: responseData.data.authConfig.BLOCK_DUPLICATE_LOGIN,
  };
}

export const QUERY_KEY_ACCESS_IP =
  'GlobalConfig.SecureManagement.LoginSecureConfig.accessIp';

export async function getAccessIpConfig(): Promise<AccessIpConfig> {
  const response = await fetchGraphQL({ query: accessIpQuery });
  const responseData =
    (await response.json()) as LoginSecureConfigQueryResponse<AccessIpConfigResponse>;
  return {
    isBlockAccessIp: responseData.data.authConfig.BLOCK_ACCESS_IP,
  };
}

export const QUERY_KEY_OVERSEAS_LOGIN =
  'GlobalConfig.SecureManagement.LoginSecureConfig.overseasLogin';

export async function getOverseasLoginConfig(): Promise<OverseasLoginConfig> {
  const response = await fetchGraphQL({ query: abroadLoginQuery });
  const responseData =
    (await response.json()) as LoginSecureConfigQueryResponse<OverseasLoginConfigResponse>;
  return {
    isBlockAbroadLogin: responseData.data.authConfig.BLOCK_ABROAD_LOGIN,
  };
}

async function configPostFetch(key: string, value: unknown) {
  await fetch(UPDATE_SECURE_CONFIG_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({
      configType: 'AUTH',
      configName: key,
      configValue: value,
    }),
  });
}

export async function updateLoginMethodConfig(value: LoginIdType) {
  await configPostFetch('LOGIN_ID_TYPE', value);
}

export async function updateLoginDuplicateConfig(value: boolean) {
  await configPostFetch('BLOCK_DUPLICATE_LOGIN', value);
}

export async function updateIpBlockConfig(value: boolean) {
  await configPostFetch('BLOCK_ACCESS_IP', value);
}

export async function updateOverseasLoginConfig(value: boolean) {
  await configPostFetch('BLOCK_ABROAD_LOGIN', value);
}
