import { Switch } from '@daouoffice/ui';
import { useToastMessage } from '@dop-ui/react/features/toast-message';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { clsx } from 'clsx';
import * as mobileConfigMutation from '../api/mobileConfigMutation';
import * as mobileConfigTypeQuery from '../api/mobileConfigTypeQuery';
import { MobileConfigType } from '../types';

export interface Props {
  type: MobileConfigType;
  withDivider?: boolean;
}

export function MobileConfig({ type, withDivider = false }: Props) {
  const queryClient = useQueryClient();
  const { info } = useToastMessage();
  const { t } = useTranslation('component');
  const { data: defaultMobileConfigState, error } = useSuspenseQuery({
    queryKey: [mobileConfigTypeQuery.QUERY_KEY, type],
    queryFn: () => mobileConfigTypeQuery.getMobileConfigType(type),
  });

  const updateLockState = useMutation({
    mutationKey: [mobileConfigMutation.MUTATION_KEY, type],
    mutationFn: async (newState: boolean) => {
      await mobileConfigMutation.updateMobileConfig(newState, type);
      return newState;
    },
    onSuccess: async (newState: boolean) => {
      info(getToastMessage(newState, type));
      await queryClient.invalidateQueries({
        queryKey: [mobileConfigTypeQuery.QUERY_KEY],
      });
    },
  });

  const getToastMessage = (newState: boolean, type: MobileConfigType) => {
    if (newState) {
      return t(
        type === 'ATTACHED_FILE_DOWNLOAD'
          ? 'globalconfig.secureManagement.mobileConfig.attachedFileDownload.toast.allow'
          : type === 'MOBILE_CAPTURE'
            ? 'globalconfig.secureManagement.mobileConfig.mobileCapture.toast.allow'
            : 'globalconfig.secureManagement.mobileConfig.attachFilePreview.toast.allow',
      );
    } else {
      return t(
        type === 'ATTACHED_FILE_DOWNLOAD'
          ? 'globalconfig.secureManagement.mobileConfig.attachedFileDownload.toast.disallow'
          : type === 'MOBILE_CAPTURE'
            ? 'globalconfig.secureManagement.mobileConfig.mobileCapture.toast.disallow'
            : 'globalconfig.secureManagement.mobileConfig.attachFilePreview.toast.disallow',
      );
    }
  };

  const onChangeHandler = (newState: boolean) => {
    updateLockState.mutate(newState);
  };

  if (error) {
    return <></>;
  }

  return (
    <div
      className={clsx('w-full flex items-center justify-between py-8', {
        'border-b border-[#E5E5E5]': withDivider,
      })}
    >
      <h2 className="w-[240px] text-[#111] text-ellipsis font-medium text-base leading-6 -tracking-[0.96px] overflow-hidden">
        {t(
          type === 'ATTACHED_FILE_DOWNLOAD'
            ? 'globalconfig.secureManagement.mobileConfig.attachedFileDownload.title'
            : type === 'MOBILE_CAPTURE'
              ? 'globalconfig.secureManagement.mobileConfig.mobileCapture.title'
              : 'globalconfig.secureManagement.mobileConfig.attachedFilePreview.title',
        )}
      </h2>
      <span className="font-normal leading-normal -tracking-[0.84px] text-[14px] text-[#AAA]">
        {t(
          type === 'ATTACHED_FILE_DOWNLOAD'
            ? 'globalconfig.secureManagement.mobileConfig.attachedFileDownload.description'
            : type === 'MOBILE_CAPTURE'
              ? 'globalconfig.secureManagement.mobileConfig.mobileCapture.description'
              : 'globalconfig.secureManagement.mobileConfig.attachedFilePreview.description',
        )}
      </span>
      <Switch
        id={`SWITCH-${type}`}
        checked={defaultMobileConfigState ?? false}
        onChange={onChangeHandler}
      />
    </div>
  );
}

export default MobileConfig;
