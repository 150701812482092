import { useSuspenseQuery } from '@tanstack/react-query';
import * as getWorksList from '../api/getWorksList';
import AppList from './app-list';
import NoWorksTemplate from './no-data-template/no-works-template';

interface Props {
  scrollable: boolean;
}
export function WorksList({ scrollable }: Props) {
  const {
    data: { worksList },
    error,
  } = useSuspenseQuery({
    queryKey: [getWorksList.QUERY_KEY],
    queryFn: async () => await getWorksList.getWorksList(),
  });

  if (error) {
    console.error(error);
    return;
    // throw error
  }

  if (!worksList || worksList.length === 0) {
    return <NoWorksTemplate />;
  }

  return (
    <AppList
      type="WORKS"
      appList={worksList}
      scrollable={scrollable}
      offset={scrollable ? 20 : 5}
    />
  );
}

export default WorksList;
