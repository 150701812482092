import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import Avatar from '@dop-ui/react/shared/ui/avatar';
import Button from '@dop-ui/react/shared/ui/button';
import { TRANSLATION_PREFIX } from '../constants';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24';

import styles from './multi-member-setting.module.css';
import type { MultiMember } from '../types';

interface Props {
  memberList: MultiMember[];
  setMemberList: Dispatch<SetStateAction<MultiMember[]>>;
}
function SelectedMultiMemberList({ memberList, setMemberList }: Props) {
  const { t } = useTranslation('component');

  const handleOnDelete = (companyId: number, userId: number) => {
    setMemberList((prevMemberList) =>
      prevMemberList.filter(
        (member) => member.companyId !== companyId || member.userId !== userId,
      ),
    );
  };

  return (
    <div className={styles.SideCard}>
      <div className={styles.SideCardTitle}>
        <p>{t(`${TRANSLATION_PREFIX}.multimember`)}</p>
        <p>{memberList.length}</p>
      </div>
      <ul className={styles.SideCardContents}>
        {memberList.map((member) => (
          <li
            className={styles.SelectedMember}
            key={member.companyId + member.userId}
          >
            <Avatar
              size={40}
              alt={member.userName}
              src={member.profileImageUrl}
            />
            {/* TODO: 조직도에서 내려오는 데이터 변경 후 positionName 값 존재함. 처리 필요 */}
            <div className={styles.MemberInfo}>
              <p>{`${member.userName} ${member.positionName || ''}`}</p>
              <p>{member.departmentPathList.join('>')}</p>
              <p>{member.email}</p>
            </div>
            <Button
              onClick={() => handleOnDelete(member.companyId, member.userId)}
            >
              <CloseIcon size={12} />
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SelectedMultiMemberList;
