import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { columnDefGenerator } from '@daouoffice/ui/lib/labs/Table';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { clsx } from 'clsx';
import { AppContentsInfo, AppType } from '../../../types';
import { useActionsContext, useValuesContext } from '../../Provider';

export interface Props {
  contents: AppContentsInfo[];
}

export function AppInfoTable({ contents }: Props) {
  const { t } = useTranslation('component');
  const { selectedAppType } = useValuesContext();
  const { setSelectedRows } = useActionsContext();

  const getAppTypeString = (appType?: AppType) => {
    if (appType === 'approval') {
      return t('globalconfig.dataManagement.deptManagement.approval.title');
    } else if (appType === 'board') {
      return t('globalconfig.dataManagement.deptManagement.board.title');
    } else if (appType === 'report') {
      return t('globalconfig.dataManagement.deptManagement.report.title');
    } else {
      return t('globalconfig.dataManagement.deptManagement.group.title');
    }
  };

  const statusColumnDef = {
    accessorKey: 'status',
    id: 'status',
    header: () => (
      <div className="flex items-center px-5 py-3 gap-1">
        {t('globalconfig.common.table.header.status')}
      </div>
    ),
    cell: (value: CellContext<AppContentsInfo, unknown>) => (
      <div className="text-left px-5 py-3">
        <span
          className={clsx(
            'rounded-[8px] px-[6px] py-1 text-white text-[13px] font-[500] -tracking-[1px]',
            {
              'bg-[#7BD18B]': (value.renderValue() as string) === 'ACTIVE',
              'bg-[#A7A7A7]': (value.renderValue() as string) === 'INACTIVE',
            },
          )}
        >
          {(value.renderValue() as string) === 'ACTIVE'
            ? t('globalconfig.dataManagement.deptManagement.status.active')
            : t('globalconfig.dataManagement.deptManagement.status.inactive')}
        </span>
      </div>
    ),
  };

  const addedColumDefs =
    selectedAppType === 'report'
      ? [
          statusColumnDef,
          columnDefGenerator<AppContentsInfo>(
            'storageUsage',
            t('globalconfig.common.table.header.storageUsage'),
          ),
        ]
      : selectedAppType === 'board'
        ? [
            statusColumnDef,
            columnDefGenerator<AppContentsInfo>(
              'shared',
              t('globalconfig.common.table.header.shared'),
            ),
            columnDefGenerator<AppContentsInfo>(
              'storageUsage',
              t('globalconfig.common.table.header.storageUsage'),
            ),
          ]
        : [
            columnDefGenerator<AppContentsInfo>(
              'count',
              t('globalconfig.common.table.header.count'),
            ),
          ];
  const columnDefs: ColumnDef<AppContentsInfo>[] = [
    columnDefGenerator<AppContentsInfo>(
      'name',
      getAppTypeString(selectedAppType),
    ),
    ...addedColumDefs,
  ];

  return contents.length === 0 ? (
    <div className="flex items-center justify-center border rounded-lg w-full h-[256px] text-lg">
      {t('globalconfig.common.table.noData')}
    </div>
  ) : (
    <TablePrimitives.Root
      selectable={true}
      contents={contents}
      columnDefs={columnDefs}
      onSelectContents={setSelectedRows}
    >
      <TablePrimitives.Contents />
    </TablePrimitives.Root>
  );
}

export default AppInfoTable;
