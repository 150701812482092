import { clsx } from 'clsx';
import DOMPurify from 'dompurify';
import { MemoType } from '../types';
import memoStyles from '../../Memo/memo.module.css';

interface Props {
  memo: MemoType;
}

export function MemoScrollContent({ memo }: Props) {
  return (
    <div
      className={clsx('memo-editor-container', memoStyles.MemoScrollItem)}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(memo.content),
      }}
    />
  );
}

export default MemoScrollContent;
