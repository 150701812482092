import { fetch } from '../../../../../../lib/fetch/client';
import { DATA_REQUEST_LIST_API } from '../../constants';
import { DataRequestInfo, Pagination, SortDirection } from '../types';

interface Page {
  page: number;
  totalPage: number;
  offset: number;
  total: number;
  lastPage: boolean;
}

interface ListResponse {
  code: string;
  message: string;
  page: Page;
  data: DataRequestInfo[];
}

export const QUERY_KEY =
  'QueryKeys.GlobalConfig.DataManagement.DataRequestManagement';

export async function getRequestList(
  page: number,
  offset: number = 20,
  sortStandard: string,
  sortDirection: SortDirection,
) {
  const query = `?page=${page}&offset=${offset}&property=${sortStandard}&direction=${sortDirection}`;
  const response = await fetch(DATA_REQUEST_LIST_API + query, {
    headers: { 'Content-Type': 'application/json' },
  });

  const responseData = (await response.json()) as ListResponse;

  return { page: pageToPagination(responseData.page), data: responseData.data };
}

function pageToPagination(page: Page): Pagination {
  return {
    currentPage: page.page,
    totalPage: page.totalPage,
  };
}
