import { mockedLinkPlusList } from '../mocks';
import type { AppType } from '../types';

export interface Response {
  code: string;
  message: string;
  data: OriginLinkPlusType[];
}

export interface OriginLinkPlusType {
  id: number;
  createdAt: string;
  updatedAt: string;
  partnerId: number;
  partnerName: string;
  categoryId: number;
  categoryName: string;
  categoryCode: string;
  name: string;
  code: string;
  summary: string;
  description: string;
  confirmation: string;
  registrationId: number;
  registrationStatus: string;
  registrationUrl: string;
  registrationReturnPath?: string | null;
  cancelUrl: string;
  cancelReturnPath?: string | null;
  ssoUrl: string;
  directLinkUrl?: string | null;
  linkType: string;
  regApiResultFlag: boolean;
  cancelApiResultFlag: boolean;
  sortOrder: number;
  termsProvided: boolean;
  linkComponentBeanName: any;
  guideTitle: string;
  guides: any[];
  banners: Banner[];
  exposure: boolean;
  deletedAt: any;
  defaultProduct: boolean;
  markNew: boolean;
  productAccessAuthority: boolean;
  recommended: boolean;
  recommendationsCount: number;
  contact?: string | null;
  email?: string | null;
  watch?: string | null;
  regProductName: any;
  regProductSummary: any;
  regProductBanners: any;
  accessAuthority: string;
  accessTarget: any;
  companyName: any;
  clusterName: any;
  registrationApplyDate: string;
  bookmarked: boolean;
}

export interface Banner {
  id: number;
  attach: Attach;
  sortOrder: number;
  bannerName: string;
  bannerType: string;
}

export interface Attach {
  id: number;
  name: string;
  path: string;
  size: number;
  extension: string;
  thumbnail: string;
}

export const QUERY_KEY = 'organizer-link-plus-list';

export interface LinkPlusListType {
  linkPlusList: AppType[];
}

export function getMockedData() {
  const mockedData = mockedLinkPlusList;
  return mockedData;
}

export async function getLinkPlusList(): Promise<LinkPlusListType> {
  try {
    return new Promise((resolve) => {
      setTimeout(() => {
        const resData = getMockedData();
        resolve(adapter(resData));
      }, 100);
    });
  } catch (error) {
    console.log('getLinkPlusList 오류 발생', error);
    return {
      linkPlusList: [],
    };
  }
}

function adapter(source: Response): LinkPlusListType {
  return {
    linkPlusList: source.data.map((data) => {
      return {
        id: data.id,
        name: data.name,
        // Todo : 정확한 이미지 경로 확인 필요
        thumbnail: data.banners[0]?.attach.thumbnail ?? '',
        desc: data.summary,
        size: 'large',
      };
    }),
  };
}
