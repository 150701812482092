import { Root } from './Root';
import { Content } from './Content';
import { Trigger } from './Trigger';
import { Card } from './Card';

export const ProfileCard = {
  Root,
  Content,
  Trigger,
  Card,
};

export { ProfileCardClient as DefaultProfileCard } from './ProfileCardClient';
