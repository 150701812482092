import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { LoginTimeout } from './LoginTimeout';
import { LoginMethod } from './LoginMethod';
import { DuplicatedLogin } from './DuplicatedLogin';
import { AccessRestrictedIPIndicator } from './AccessRestrictedIPIndicator';
import { OverseasLoginRestrictionIndicator } from './OverseasLoginRestrictionIndicator';

export function FirstPage() {
  const { t } = useTranslation('component');

  return (
    <div className="flex flex-col flex-wrap p-[40px] border border-solid border-[#E6E7EA] rounded-[8px]">
      <p className="text-[#333333] text-[22px] font-[500] mb-[48px]">
        {t('globalconfig.secureManagement.loginSecureConfig.title')}
      </p>
      <div className="flex flex-wrap gap-[24px]">
        <LoginTimeout />
        <HDivider />
        <LoginMethod />
        <HDivider />
        <DuplicatedLogin />
        <HDivider />
        <AccessRestrictedIPIndicator />
        <HDivider />
        <OverseasLoginRestrictionIndicator />
      </div>
    </div>
  );
}

function HDivider() {
  return <div className="w-full h-[1px] bg-[#E5E5E5]" />;
}
