import clsx from 'clsx';
import { useContext } from './Context';
import { UserProfileInfo } from './types';
import { ReactNode } from 'react';

export interface Props {
  profileInfo: UserProfileInfo;
}

export function Content({ children }: { children: ReactNode }) {
  const { cardOpenState } = useContext();
  return (
    <div className={clsx('profile', { open: cardOpenState })}>{children}</div>
  );
}
