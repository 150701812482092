import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 통합설정 메뉴에서 하위 메뉴가 더 있음을 표시하는 아이콘 (16x16) 아이콘
 * @param props
 * @returns
 */
export function RightArrowMoreMarkIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <g opacity="0.2">
        <path
          d="M6 3.99969L9.29289 7.29259C9.62623 7.62592 9.79289 7.79259 9.79289 7.99969C9.79289 8.2068 9.62623 8.37347 9.29289 8.7068L6 11.9997"
          stroke="#111111"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </IconContainer>
  );
}

export default RightArrowMoreMarkIcon;
