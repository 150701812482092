import fetch from '../../../../../../lib/fetch/client';
import { ApiResponse } from '../types';

interface Params {
  integrationIds: number[];
  companyGroupId?: string | number;
}

export async function deleteMultiMemberList({
  integrationIds,
  companyGroupId,
}: Params): Promise<boolean | null> {
  if (!companyGroupId) return null;

  const response = await fetch(
    `/api/portal/setting/integration/${companyGroupId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: integrationIds,
      }),
    },
  );

  const responseJson = (await response.json()) as ApiResponse<boolean>;

  if (responseJson.message) {
    throw new Error(responseJson.message);
  }

  return responseJson.data;
}
