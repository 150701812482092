import type { PropsWithChildren } from 'react';
import type { SvgCommonProps } from './types';

export interface Props extends SvgCommonProps {
  viewBox?: string;
}

export function SvgContainer({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  children,
  className,
}: PropsWithChildren<Props>) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="none"
      width={width}
      height={height}
    >
      {children}
    </svg>
  );
}

export default SvgContainer;
