import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@dop-ui/react/shared/ui/button';
import { RefreshIcon } from '@daouoffice/ui/lib/icons/dop/24/Refresh';
import { DataUsingApps } from './DataUsingApps';
import { PresentCondition } from './PresentCondition';
import { UsagePerPortal } from './UsagePerPortal';
import { DATA_USING_STORAGES_KEY } from './apis/getDataUsingStorages';
import { PER_PORTAL_DATA_USAGE_KEY } from './apis/getPerPortalDataUsage';
import { COMPANY_DATA_USAGE_KEY } from './apis/getCompanyDataStatus';

export function CurrentDataUsage() {
  const queryClient = useQueryClient();

  const onClickRefresh = () => {
    void queryClient.invalidateQueries({
      queryKey: [DATA_USING_STORAGES_KEY],
    });
    void queryClient.invalidateQueries({
      queryKey: [PER_PORTAL_DATA_USAGE_KEY],
    });
    void queryClient.invalidateQueries({
      queryKey: [COMPANY_DATA_USAGE_KEY],
    });
  };
  return (
    <div className="relative px-[72px] pt-[24px]">
      <Button
        className="absolute right-[72px] -top-[24px] flex items-center px-[16px] h-[40px]"
        size="none"
        onClick={onClickRefresh}
      >
        <RefreshIcon className="size-[24px] mr-[4px]" />
        동기화
      </Button>
      <div className="flex flex-wrap gap-[32px] ">
        <PresentCondition />
        <UsagePerPortal />
      </div>
      <DataUsingApps />
    </div>
  );
}
