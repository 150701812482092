import { ClassRoleInfo, RoleType } from '../../types';
import { ClassListItem } from './class-list-item';

interface Props {
  title: string;
  roleType: RoleType;
  roleInfos: ClassRoleInfo[];
  onChangeList: (roleInfos: ClassRoleInfo[]) => void;
}

export function ClassList({ title, roleType, roleInfos, onChangeList }: Props) {
  const onClickDelete = (roleId: number) => {
    const deletedInfo = roleInfos.filter((info) => {
      return info.roleId !== roleId;
    });
    onChangeList(deletedInfo);
  };

  const onChangeConfig = (roleId: number, config: ClassRoleInfo) => {
    onChangeList(
      roleInfos.map((info) =>
        info.roleId === roleId ? { ...info, ...config } : info,
      ),
    );
  };

  return (
    <div className="w-full">
      <p className="text-[13px] text-[#999999] font-[700] py-[4px]">{title}:</p>
      <div className="w-full flex flex-wrap py-[8px] gap-[4px]">
        {roleInfos.map((info) => (
          <ClassListItem
            key={info.roleId}
            roleType={roleType}
            roleInfo={info}
            onClickDelete={onClickDelete}
            onChangeConfig={onChangeConfig}
          />
        ))}
      </div>
    </div>
  );
}
