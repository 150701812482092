import * as React from 'react';
import { IconContainer } from '@daouoffice/ui/lib/icons/dop/IconContainer';

export interface Props {
  // className?: string;
  icon: typeof IconContainer;
  label?: string;
}

export const FuncButton = React.forwardRef<HTMLDivElement, Props>(
  ({ icon: Icon, label }: Props, ref) => {
    // 버튼 컴포넌트를 다시 만들기 전까지 임시로 다음 코드 사용
    return (
      <div role="button" className="btn_icon medium has_tooltip" ref={ref}>
        <Icon />
        {label && <span className="tips right">{label}</span>}
      </div>
    );
  },
);

FuncButton.displayName = 'FuncButton';

export default FuncButton;
