export const mockedWorksList = {
  message: 'OK',
  code: '200',
  __go_checksum__: true,
  data: [
    {
      id: 1222,
      createdAt: '2016-05-09T14:49:19.731+09:00',
      updatedAt: '2021-03-10T13:57:41.010+09:00',
      name: '##산업분류',
      thumbSmall: '/thumb/attach/tiny/246297-4515',
      favoriteFlag: true,
      desc: '빈 템플릿으로 새로운 앱을 만들 수 있습니다. 빈 템플릿으로 새로운 앱을 만들 수 있습니다.빈 템플릿으로 새로운 앱을 만들 수 있습니다.빈 템플릿으로 새로운 앱을 만들 수 있습니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 1780,
      createdAt: '2016-10-14T18:38:15.834+09:00',
      updatedAt: '2019-09-04T13:17:28.412+09:00',
      name: '##주소록',
      thumbSmall: '/thumb/attach/tiny/326746-2083',
      favoriteFlag: true,
      desc: '빈 템플릿으로 새로운 앱을 만들 수 있습니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 5921,
      createdAt: '2021-02-26T16:33:49.596+09:00',
      updatedAt: '2021-02-26T16:33:49.596+09:00',
      name: '(법무팀)(예정)2022년 계약서 접수 대장',
      thumbSmall: '/thumb/attach/tiny/1482364-757',
      favoriteFlag: false,
      desc: '2022년 계약서 접수 현황 관리',
      showDescription: true,
      admin: false,
    },
    {
      id: 7668,
      createdAt: '2023-01-02T09:48:01.568+09:00',
      updatedAt: '2023-01-02T10:07:14.033+09:00',
      name: '(법무팀)2023년 계약서 접수 대장',
      thumbSmall: '/thumb/attach/tiny/2501502-757',
      docCreatedAt: '2023-12-29T15:40:40.131+09:00',
      favoriteFlag: false,
      desc: '2023년 계약서 접수 현황 관리',
      showDescription: true,
      admin: false,
    },
    {
      id: 8361,
      createdAt: '2023-12-21T13:46:06.636+09:00',
      updatedAt: '2024-02-02T08:43:24.703+09:00',
      name: '(법무팀)2024년 계약서 접수 대장',
      thumbSmall: '/thumb/attach/tiny/3154279-757',
      docCreatedAt: '2024-06-12T08:52:44.749+09:00',
      favoriteFlag: false,
      desc: '2024년 계약서 접수 현황 관리',
      showDescription: true,
      admin: false,
    },
    {
      id: 4300,
      createdAt: '2019-03-05T10:05:23.791+09:00',
      updatedAt: '2019-03-05T10:05:23.791+09:00',
      name: '2019년 자산 발주 관리',
      thumbSmall: '/thumb/attach/tiny/807075-893',
      favoriteFlag: true,
      desc: '',
      showDescription: false,
      admin: false,
    },
    {
      id: 5360,
      createdAt: '2020-06-23T17:58:45.157+09:00',
      updatedAt: '2020-08-05T11:21:21.565+09:00',
      name: '2020 New Mobile 메신저 이슈접수',
      thumbSmall: '/thumb/attach/tiny/1235802-780',
      docCreatedAt: '2021-02-25T17:34:47.855+09:00',
      favoriteFlag: false,
      desc: '새 모바일 메신저의 버그 및 건의사항을 접수받습니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 6007,
      createdAt: '2021-03-25T10:41:20.708+09:00',
      updatedAt: '2022-05-03T13:47:33.427+09:00',
      name: '2021-2022 New  Mobile 비콘 도우미 이슈접수',
      thumbSmall: '/thumb/attach/tiny/1515410-780',
      favoriteFlag: false,
      desc: '새롭게 개선된 비콘도우미의 버그 및 건의사항을 접수받습니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 7534,
      createdAt: '2022-11-01T15:47:45.139+09:00',
      updatedAt: '2022-11-01T15:50:12.515+09:00',
      name: '2022 품질관리팀 신입사원 교육자료 관리',
      thumbSmall: '/thumb/attach/tiny/2387031-757',
      favoriteFlag: false,
      desc: '2022 품질관리팀 신입사원 교육자료 관리',
      showDescription: false,
      admin: false,
    },
    {
      id: 8293,
      createdAt: '2023-11-13T18:07:45.958+09:00',
      updatedAt: '2023-11-21T16:44:15.356+09:00',
      name: '2023년 겨울성수기 콘도 추첨 접수',
      thumbSmall: '/thumb/attach/tiny/3079034-3552',
      docCreatedAt: '2023-12-01T10:09:55.984+09:00',
      favoriteFlag: false,
      desc: '2023년 겨울성수기 콘도 신청',
      showDescription: false,
      admin: false,
    },
    {
      id: 7993,
      createdAt: '2023-06-02T17:26:15.252+09:00',
      updatedAt: '2023-10-04T14:19:07.725+09:00',
      name: '2023년 여름 성수기 콘도 추첨 접수',
      thumbSmall: '/thumb/attach/tiny/2779818-2397',
      docCreatedAt: '2023-07-03T21:44:16.845+09:00',
      favoriteFlag: false,
      desc: '2023년 여름성수기 콘도 신청',
      showDescription: false,
      admin: false,
    },
    {
      id: 8417,
      createdAt: '2024-01-24T18:59:03.781+09:00',
      updatedAt: '2024-02-27T19:22:20.577+09:00',
      name: '2024 DAOU 챌린저\uD83D\uDC69‍\uD83D\uDCBB',
      thumbSmall: '/thumb/attach/tiny/3215051-757',
      docCreatedAt: '2024-03-22T12:00:08.293+09:00',
      favoriteFlag: false,
      desc: '2024년 DAOU 챌린저 운영을 위한 works입니다.\n - 학습계획 수립 및 실행',
      showDescription: false,
      admin: false,
    },
    {
      id: 8684,
      createdAt: '2024-06-21T15:54:37.111+09:00',
      updatedAt: '2024-06-24T16:50:50.126+09:00',
      name: '2024 여름성수기 콘도 추첨 접수(~6/28(금))',
      thumbSmall: '/thumb/attach/tiny/3501860-3552',
      docCreatedAt: '2024-06-28T23:58:11.520+09:00',
      favoriteFlag: false,
      desc: '<2024년 여름성수기 기간>\n2024.07.19(금) ~ 2024.08.31(토)\n※ 해당 기간에는 추첨 접수를 통해서만 예약이 가능합니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 5872,
      createdAt: '2021-02-05T15:36:05.152+09:00',
      updatedAt: '2021-02-05T15:38:02.989+09:00',
      name: 'Biz플랫폼 - 통합인증, 통합메뉴 개발 ',
      thumbSmall: '/thumb/attach/tiny/1459663-893',
      favoriteFlag: false,
      desc: '1. 자산화 프로젝트 기간: 2020.7 ~ 2021.4 (10개월)\n2. 해당 프로젝트 기간 : 2020.8 ~ 2021.2 (7개월)\n3. 실무PM: \n    통합인증: 이재희 (DIS 통합인증토큰관리, 조직/계정 동기화 외)\n    통합메뉴: 이경희 (채널메뉴 및 플랫폼화된 DO UI 변경사항)\n4. 실무자: (기획) 조준호 (디자인) 이경희 (개발) 이재희, 장인선, 신광호\n- 자산화 변경계획: PPT 첨부\n- 기획서 및 디자인시안 관련: PPT 및 URL 첨부',
      showDescription: true,
      admin: false,
    },
    {
      id: 599,
      createdAt: '2015-11-02T14:38:46.932+09:00',
      updatedAt: '2015-11-02T15:02:14.022+09:00',
      name: 'DAOU 카풀존 (함께 가요, 우리!)',
      thumbSmall: '/thumb/attach/tiny/167889-5102',
      docCreatedAt: '2016-04-19T12:35:40.011+09:00',
      favoriteFlag: false,
      desc: '출퇴근을 함께할 수 있도록 도와주는 애플리케이션입니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 2953,
      createdAt: '2017-09-01T11:02:55.826+09:00',
      updatedAt: '2021-06-23T11:45:36.135+09:00',
      name: 'New PC메신저 이슈 접수',
      thumbSmall: '/thumb/attach/tiny/496112-985',
      docCreatedAt: '2018-11-30T13:54:54.135+09:00',
      favoriteFlag: false,
      desc: '새 PC메신저의 버그 및 건의사항을 접수받습니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 7352,
      createdAt: '2022-08-22T11:10:12.116+09:00',
      updatedAt: '2022-08-22T11:11:12.764+09:00',
      name: 'SW개발본부 업무관리',
      thumbSmall: '/thumb/attach/tiny/2266665-4515',
      docCreatedAt: '2022-08-23T13:23:15.249+09:00',
      favoriteFlag: false,
      desc: '빈 템플릿으로 새로운 앱을 만들 수 있습니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 8010,
      createdAt: '2023-06-15T08:42:16.930+09:00',
      updatedAt: '2023-08-02T15:56:18.885+09:00',
      name: 'VPC 모니터링 (장애 및 경고)',
      thumbSmall: '/thumb/attach/tiny/2799726-1792',
      favoriteFlag: false,
      desc: 'VPC\n장애알림(및 처리)\n경고리포트\n플랫폼운영팀',
      showDescription: false,
      admin: false,
    },
    {
      id: 82,
      createdAt: '2015-08-03T20:38:51.541+09:00',
      updatedAt: '2024-05-22T09:58:56.729+09:00',
      name: '[4층 OA존] 우편물 수령 관리',
      thumbSmall: '/thumb/attach/tiny/142870-2451',
      docCreatedAt: '2024-02-16T10:48:32.856+09:00',
      favoriteFlag: false,
      desc: '사내에 도착한 우편물(등기, 일반우편)을 접수하고 관리하는 애플리케이션입니다.\n- 4층 경영지원실 OA존 > 우편물 수령 대장 작성 > 우편물 수령\n',
      showDescription: false,
      admin: false,
    },
    {
      id: 8520,
      createdAt: '2024-03-22T11:54:26.042+09:00',
      updatedAt: '2024-07-01T09:41:37.260+09:00',
      name: '[DOACC] 경리회계•경영지원 업무지원 통합관리',
      thumbSmall: '/thumb/attach/tiny/3327024-893',
      docCreatedAt: '2024-05-23T15:26:32.040+09:00',
      favoriteFlag: false,
      desc: ' - VOC, 개선, 결함, 스펙 문의 등 통합관리 목적\n - 관리 흐름 안내 \n - 경영서비스개발팀 운영방안 \n\n[참고문서]\nhttps://www.figma.com/file/CkQ1zBtDJWu1N7Jxh3za4R/%5B%EA%B2%BD%EC%84%9C%ED%8C%80%5D-%EB%B0%B0%ED%8F%AC%ED%94%84%EB%A1%9C%EC%84%B8%EC%8A%A4-v0.5?type=whiteboard&node-id=0-1&t=WIQfK9dp11NCpl1F-0\n\n[우선순위 기준]\n긴급 - 장애 발생, 즉시처리요청 (사업부), 프로모션 대응, 연계기관 오류 등\n즉시 - 업무 진행 불가능, 서비스 접근 불가능, 제도변경대응, DOMS 주문/개설관련 현상(플서팀 협업 필요)\n        * 우회방법 없음, 조치 대응 불가, DB 대응 불가 \n높음 - 조치는 가능하나, VOC 인입양이 많음, 사용량이 매우 떨어짐 (편의성이 너무 좋지 않음\n보통 - 업무 진행은 가능하나, 간헐적 오류 발생 but 조치 대응가능, 우회방법가능 제시안 있음\n낮음 - 업무에 전혀 지장이 없으나, 불편함 ex) 오탈자, 단순 UI 깨짐, 정렬조건 \n기타 - 위와 다른 이유로 인입된 경우',
      showDescription: true,
      admin: false,
    },
    {
      id: 6580,
      createdAt: '2021-12-02T21:39:52.241+09:00',
      updatedAt: '2024-05-07T08:43:30.763+09:00',
      name: '[DOACC] 작업 관리',
      thumbSmall: '/thumb/attach/tiny/1856591-4515',
      docCreatedAt: '2023-09-11T13:26:50.825+09:00',
      favoriteFlag: false,
      desc: 'DaouOffice 경리회계 서비스의  패치 및 업그레이드 작업에 대한 점검 및 승인 절차를 위한 앱입니다. \n\nDO 경리회계 서비스의 모든 변경은 이앱의 요청으로 부터 이루어 집니다 \n\n모든 작업은 업무외 시간 이후에 진행합니다. 긴급패치를 요하는 경우에는 운영위원회(관련부서)의 동의하에 작업이 진행됩니다.  ',
      showDescription: false,
      admin: false,
    },
    {
      id: 3491,
      createdAt: '2018-03-08T09:12:55.448+09:00',
      updatedAt: '2024-04-29T09:30:18.889+09:00',
      name: '[DOSS] 작업 관리',
      thumbSmall: '/thumb/attach/tiny/599222-4515',
      docCreatedAt: '2024-03-13T16:09:34.191+09:00',
      favoriteFlag: false,
      desc: 'DaouOffice SaaS 서비스의  패치 및 업그레이드 작업에 대한 점검 및 승인 절차를 위한 앱입니다. \n\nDO SaaS 서비스의 모든 변경은 이앱의 요청으로 부터 이루어 집니다 \n\n모든 작업은 업무시간 이후에 진행합니다. / 긴급패치를 요하는 경우에는 운영위원회(관련부서)의 동의하에 작업이 진행됩니다.  ',
      showDescription: false,
      admin: false,
    },
    {
      id: 2017,
      createdAt: '2016-12-06T09:32:07.400+09:00',
      updatedAt: '2024-03-21T11:21:49.306+09:00',
      name: '[DO] 공유형 : 사업팀(부문)-개발센터 요청/수행',
      thumbSmall: '/thumb/attach/tiny/352446-1259',
      docCreatedAt: '2024-05-17T17:11:58.136+09:00',
      favoriteFlag: false,
      desc: '사업/기획/개발팀 플랫폼운영팀 회의, 요청 결과를 수행, 관리하기 위한 앱입니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 8523,
      createdAt: '2024-03-25T13:14:03.404+09:00',
      updatedAt: '2024-04-03T09:18:39.734+09:00',
      name: '[DO] 단독형: 사업팀(부문)<->개발센터 요청/수행',
      thumbSmall: '/thumb/attach/tiny/3329365-1259',
      favoriteFlag: false,
      desc: '사업/기획/개발팀 플랫폼운영팀 회의, 요청 결과를 수행, 관리하기 위한 앱입니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 5892,
      createdAt: '2021-02-22T09:36:35.999+09:00',
      updatedAt: '2024-06-05T16:25:08.406+09:00',
      name: '[DO] 업무지원 통합 관리',
      thumbSmall: '/thumb/attach/tiny/1474231-4084',
      docCreatedAt: '2024-04-16T16:24:17.443+09:00',
      favoriteFlag: false,
      desc: ' - 통합 관리에 대한 목적 \n - 사용되는 상태 설명 \n - 플랫폼 기획팀 운영방안 \n 아래 구글 링크 참고 부탁드립니다! \n [구글 링크] https://docs.google.com/document/d/1VbwmYwDCgkchPl7HDrq-3G_gU6e9G5R7HzVVnrqQYGE/edit?usp=sharing',
      showDescription: true,
      admin: false,
    },
    {
      id: 7088,
      createdAt: '2022-04-27T17:14:34.219+09:00',
      updatedAt: '2023-11-13T17:20:46.660+09:00',
      name: '[고객지원팀] 업무 요청 데이터',
      thumbSmall: '/thumb/attach/tiny/2079743-1575',
      docCreatedAt: '2022-05-10T17:47:39.245+09:00',
      favoriteFlag: false,
      desc: '',
      showDescription: false,
      admin: false,
    },
    {
      id: 7812,
      createdAt: '2023-03-14T13:08:18.795+09:00',
      updatedAt: '2023-11-13T17:20:46.660+09:00',
      name: '[다우기술 데이터센터] 네임(명칭) 선호도 조사',
      thumbSmall: '/thumb/attach/tiny/2628342-4515',
      docCreatedAt: '2023-04-28T13:33:53.476+09:00',
      favoriteFlag: false,
      desc: 'You can create new App with a blank template.',
      showDescription: false,
      admin: false,
    },
    {
      id: 7328,
      createdAt: '2022-08-11T13:17:31.266+09:00',
      updatedAt: '2023-11-14T14:10:49.025+09:00',
      name: '[방문차량] 다우디지털스퀘어 주차 신청',
      thumbSmall: '/thumb/attach/tiny/2254125-2393',
      docCreatedAt: '2023-12-05T17:17:43.120+09:00',
      favoriteFlag: false,
      desc: '- 외부업체의 본사 방문차량에 대해서 일일 주차등록을 신청합니다.\n- 임직원의 본사 방문차량에 대해서 일일 주차증록을 신청합니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 3495,
      createdAt: '2018-03-12T10:04:01.651+09:00',
      updatedAt: '2024-02-16T11:29:52.573+09:00',
      name: '[정기주차] 신규·변경·임시변경',
      thumbSmall: '/thumb/attach/tiny/601141-2393',
      favoriteFlag: false,
      desc: '- 정기주차 신규·변경·임시변경에 대해서 신청합니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 6704,
      createdAt: '2022-01-10T10:00:21.315+09:00',
      updatedAt: '2024-03-18T13:10:18.930+09:00',
      name: '[종료] DOMS 업무지원 통합 관리',
      thumbSmall: '/thumb/attach/tiny/2420824-4515',
      docCreatedAt: '2022-11-07T16:16:07.157+09:00',
      favoriteFlag: false,
      desc: 'DOMS 운영이슈, 기능 문의, 버그 신고 및 개선 요청을 할 수 있는 앱 입니다. ',
      showDescription: true,
      admin: false,
    },
    {
      id: 7058,
      createdAt: '2022-04-20T16:06:07.761+09:00',
      updatedAt: '2022-09-07T09:18:36.523+09:00',
      name: '[플랫폼기획] 2022 개발센터 신입사원 실습 교육',
      thumbSmall: '/thumb/attach/tiny/2073070-1575',
      docCreatedAt: '2022-04-27T14:46:17.072+09:00',
      favoriteFlag: false,
      desc: '빈 템플릿으로 새로운 앱을 만들 수 있습니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 1716,
      createdAt: '2016-09-05T17:45:11.392+09:00',
      updatedAt: '2020-07-16T15:02:20.436+09:00',
      name: 'eDM 요청',
      thumbSmall: '/thumb/attach/tiny/304777-3861',
      favoriteFlag: false,
      desc: 'eDM요청',
      showDescription: true,
      admin: false,
    },
    {
      id: 7879,
      createdAt: '2023-04-13T11:36:39.579+09:00',
      updatedAt: '2023-04-13T13:08:13.578+09:00',
      name: 'info-smtp.daouoffice.com IP 등록요청',
      thumbSmall: '/thumb/attach/tiny/2687936-5174',
      favoriteFlag: false,
      desc: '외부 공통 SMTP 장비 info-smtp.daouoffice.com 의 릴레이 IP 허용요청',
      showDescription: false,
      admin: false,
    },
    {
      id: 6031,
      createdAt: '2021-04-07T09:07:18.007+09:00',
      updatedAt: '2023-03-13T09:20:12.033+09:00',
      name: '개발센터업무관리',
      thumbSmall: '/thumb/attach/tiny/1533103-3777',
      docCreatedAt: '2021-04-14T17:22:17.086+09:00',
      favoriteFlag: false,
      desc: '진행하는 프로젝트에 대해 입력하는 앱입니다. ',
      showDescription: false,
      admin: false,
    },
    {
      id: 6512,
      createdAt: '2021-11-17T11:21:40.521+09:00',
      updatedAt: '2024-06-10T11:14:33.735+09:00',
      name: '경영지원시스템 이슈관리',
      thumbSmall: '/thumb/attach/tiny/1834089-4515',
      docCreatedAt: '2024-05-16T15:08:12.739+09:00',
      favoriteFlag: false,
      desc: '● e-HR 시스템 지원이력 \n - https://portal.daou.co.kr/app/task/folder/681/task (접근권한 필요)\n● 상태 설명 \n - Issue : 최초 등록된 요청 내용. 전산운영팀에서 1차 검토 후 \n               담당자를 할당합니다.\n - Assigned : 담당개발자 or 외주개발업체에 이슈 할당\n - In Progress : 이슈 분석/수정 진행\n - Completed : 이슈 분석/개발 완료\n - Feedback : 분석결과 및 개발 반영일정, 지원불가 등에 대하여 \n                       issue 등록자에게 피드백 합니다. \n - Released : 수정완료 및 시스템에 반영\n - Closed : 요청자가 Issue를 Closed 합니다. ',
      showDescription: true,
      admin: false,
    },
    {
      id: 6085,
      createdAt: '2021-04-28T10:40:51.013+09:00',
      updatedAt: '2021-04-28T11:40:26.995+09:00',
      name: '계열회사 관리',
      thumbSmall: '/thumb/attach/tiny/1562611-533',
      docCreatedAt: '2021-05-06T17:43:00.245+09:00',
      favoriteFlag: false,
      desc: '- 다우키움그룹 계열회사 목록',
      showDescription: false,
      admin: false,
    },
    {
      id: 6771,
      createdAt: '2022-01-18T14:17:54.026+09:00',
      updatedAt: '2022-01-18T14:22:11.743+09:00',
      name: '고객 관리 앱',
      thumbSmall: '/thumb/attach/tiny/1925431-4170',
      favoriteFlag: false,
      desc: '홈페이지를 통해 유입된 고객를 관리하는 앱 입니다. ',
      showDescription: false,
      admin: false,
    },
    {
      id: 5019,
      createdAt: '2020-02-01T08:24:41.880+09:00',
      updatedAt: '2024-01-19T09:42:46.127+09:00',
      name: '공문 접수 발송',
      thumbSmall: '/thumb/attach/tiny/1073351-893',
      favoriteFlag: false,
      desc: '',
      showDescription: false,
      admin: false,
    },
    {
      id: 8318,
      createdAt: '2023-12-04T19:39:16.003+09:00',
      updatedAt: '2024-06-11T10:22:55.645+09:00',
      name: '근태관리 확인 요청',
      thumbSmall: '/thumb/attach/tiny/3120177-3717',
      docCreatedAt: '2024-06-26T09:08:25.477+09:00',
      favoriteFlag: false,
      desc: '근태관리 오류사항을 확인하고 관리하는 애플리케이션입니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 6958,
      createdAt: '2022-03-15T09:45:00.759+09:00',
      updatedAt: '2022-04-20T08:47:35.878+09:00',
      name: '다우닷컴 홈페이지 오류 신고',
      thumbSmall: '/thumb/attach/tiny/2006180-893',
      favoriteFlag: false,
      desc: '다우닷컴 홈페이지 관리용 앱입니다.\n홈페이지 오류사항이나 수정요청 사항을 기재해주시면 담당부서에서 확인 후 처리 도와드리겠습니다',
      showDescription: true,
      admin: false,
    },
    {
      id: 6194,
      createdAt: '2021-06-17T14:57:06.438+09:00',
      updatedAt: '2021-11-03T16:36:21.361+09:00',
      name: '다우오피스 리드 관리',
      thumbSmall: '/thumb/attach/tiny/1629560-4170',
      docCreatedAt: '2021-05-31T09:50:00.000+09:00',
      favoriteFlag: false,
      desc: '홈페이지를 통해 유입된 다우오피스 리드를 관리하는 앱 입니다. ',
      showDescription: false,
      admin: false,
    },
    {
      id: 120,
      createdAt: '2015-08-04T19:30:20.944+09:00',
      updatedAt: '2024-02-21T15:51:15.899+09:00',
      name: '다우포탈 문의/개선요청',
      thumbSmall: '/thumb/attach/tiny/165261-4084',
      docCreatedAt: '2020-11-02T08:29:42.599+09:00',
      favoriteFlag: false,
      desc: '다우포털에 적용된 DaouOffice 의 기능 문의 및 개선 요청을 할 수 있는 애플리케이션입니다.\n[통합이전] DO업무지원 앱 > https://portal.daou.co.kr/app/works/applet/5892/home',
      showDescription: true,
      admin: false,
    },
    {
      id: 6431,
      createdAt: '2021-10-18T16:13:07.770+09:00',
      updatedAt: '2023-10-26T13:52:36.244+09:00',
      name: '도서 대여 하기 ',
      thumbSmall: '/thumb/attach/tiny/1832221-4254',
      docCreatedAt: '2023-10-18T15:04:35.129+09:00',
      favoriteFlag: false,
      desc: '← "등록" 버튼 클릭 : 도서 대여 요청 또는 신규 도서 신청',
      showDescription: true,
      admin: false,
    },
    {
      id: 5553,
      createdAt: '2020-08-31T12:28:27.957+09:00',
      updatedAt: '2020-08-31T12:28:27.957+09:00',
      name: '링크플러스',
      thumbSmall: '/thumb/attach/tiny/1279816-2271',
      docCreatedAt: '2021-04-28T14:24:07.314+09:00',
      favoriteFlag: false,
      desc: '빈 템플릿으로 새로운 앱을 만들 수 있습니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 1219,
      createdAt: '2016-05-04T11:14:46.738+09:00',
      updatedAt: '2023-04-24T13:55:30.645+09:00',
      name: '메세징 고객 기술지원',
      thumbSmall: '/thumb/attach/tiny/1998034-5085',
      docCreatedAt: '2023-01-30T09:47:59.139+09:00',
      favoriteFlag: false,
      desc: '메세징 고객 기술지원 관리 앱',
      showDescription: false,
      admin: false,
    },
    {
      id: 6231,
      createdAt: '2021-07-02T10:01:41.465+09:00',
      updatedAt: '2024-01-16T13:18:51.487+09:00',
      name: '비즈메세징 계약서 관리대장',
      thumbSmall: '/thumb/attach/tiny/1648914-757',
      docCreatedAt: '2024-05-17T10:48:06.732+09:00',
      favoriteFlag: false,
      desc: '메세징사업 1팀/2팀 계약서 제출 관리대장 입니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 7733,
      createdAt: '2023-02-06T17:02:13.991+09:00',
      updatedAt: '2023-02-06T17:07:44.273+09:00',
      name: '삐약삐약 요청 Works',
      thumbSmall: '/thumb/attach/tiny/2565062-780',
      favoriteFlag: false,
      desc: '삐약삐약 요청 Works 입니다',
      showDescription: true,
      admin: false,
    },
    {
      id: 7600,
      createdAt: '2022-12-02T16:15:47.362+09:00',
      updatedAt: '2022-12-09T10:22:08.365+09:00',
      name: '삐약삐약 이슈 등록 앱',
      thumbSmall: '/thumb/attach/tiny/2450934-1575',
      docCreatedAt: '2022-12-15T08:10:22.963+09:00',
      favoriteFlag: false,
      desc: '삐약삐약을 사용하시면서 발생한 이슈를 등록하는 앱입니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 7838,
      createdAt: '2023-03-24T13:54:51.397+09:00',
      updatedAt: '2024-06-24T13:04:59.644+09:00',
      name: '삐약삐약 챌린지\uD83D\uDC23',
      thumbSmall: '/thumb/attach/tiny/2650047-2271',
      docCreatedAt: '2023-04-04T17:19:14.671+09:00',
      favoriteFlag: false,
      desc: '삐약삐약과 함께하는 건강관리 챌랜지!',
      showDescription: false,
      admin: false,
    },
    {
      id: 72,
      createdAt: '2015-08-03T19:51:37.215+09:00',
      updatedAt: '2024-06-17T13:25:22.066+09:00',
      name: '사내 IT 지원 요청',
      thumbSmall: '/thumb/attach/tiny/159282-5598',
      docCreatedAt: '2024-06-17T09:47:05.842+09:00',
      favoriteFlag: false,
      desc: '다우기술의 IT 시스템과 관련하여 문의사항을 접수하고 처리하는 애플리케이션입니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 81,
      createdAt: '2015-08-03T20:32:30.896+09:00',
      updatedAt: '2023-03-03T11:42:23.484+09:00',
      name: '사내공지 요청',
      thumbSmall: '/thumb/attach/tiny/137076-1797',
      docCreatedAt: '2020-03-09T14:42:09.288+09:00',
      favoriteFlag: false,
      desc: '임직원 대상으로 전체 공지가 필요한 경우 요청해주세요. \n다우 Portal > 사내공지 게시판을 통해 전체 공지해드립니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 4603,
      createdAt: '2019-08-09T07:43:00.886+09:00',
      updatedAt: '2022-05-10T09:37:22.310+09:00',
      name: '신규 사업 검토',
      thumbSmall: '/thumb/attach/tiny/933650-1437',
      docCreatedAt: '2020-09-28T11:27:19.437+09:00',
      favoriteFlag: false,
      desc: '신규 사업 검토 이력 전사 공유',
      showDescription: false,
      admin: false,
    },
    {
      id: 7045,
      createdAt: '2022-04-14T16:38:36.195+09:00',
      updatedAt: '2022-04-15T17:15:08.557+09:00',
      name: '신규 서비스 네이밍 공모전',
      thumbSmall: '/thumb/attach/tiny/2060731-1575',
      docCreatedAt: '2022-04-18T14:42:37.047+09:00',
      favoriteFlag: false,
      desc: '사내에서 준비 중인 신규 건강관리 서비스 네이밍 공모전 참여용 App입니다',
      showDescription: true,
      admin: false,
    },
    {
      id: 1341,
      createdAt: '2016-05-24T16:18:39.240+09:00',
      updatedAt: '2016-05-24T16:18:39.240+09:00',
      name: '아나바다 장터',
      thumbSmall: '/thumb/attach/tiny/253523-5085',
      docCreatedAt: '2016-05-25T08:56:06.542+09:00',
      favoriteFlag: false,
      desc: '버리기는 아깝지만 쓰기엔 어려운 물품 사고 팝니다!',
      showDescription: true,
      admin: false,
    },
    {
      id: 6982,
      createdAt: '2022-03-23T10:42:49.996+09:00',
      updatedAt: '2024-02-16T11:30:30.886+09:00',
      name: '업무용 차량 예약 관리',
      thumbSmall: '/thumb/attach/tiny/2018891-2393',
      docCreatedAt: '2024-05-22T17:39:30.021+09:00',
      favoriteFlag: false,
      desc: '다우기술 임직원용으로 업무용 차량을 신청하고 예약합니다. ',
      showDescription: false,
      admin: false,
    },
    {
      id: 7142,
      createdAt: '2022-05-18T14:40:56.668+09:00',
      updatedAt: '2024-01-04T16:59:55.980+09:00',
      name: '업무자동화_RPA_요청(지원/개발)',
      thumbSmall: '/thumb/attach/tiny/2128918-5598',
      docCreatedAt: '2024-02-02T14:28:30.487+09:00',
      favoriteFlag: false,
      desc: '업무자동화(RPA)  운영 및 개발/리뉴얼 등에 대한 문의사항을 접수하고 처리하는 애플리케이션입니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 83,
      createdAt: '2015-08-03T20:45:08.807+09:00',
      updatedAt: '2023-05-23T09:10:10.685+09:00',
      name: '인력 현황 자료 요청',
      thumbSmall: '/thumb/attach/tiny/495632-533',
      favoriteFlag: false,
      desc: '제안서, 사업계획서 등 문서작업에 다우기술 인력 현황이 필요한 경우 인사팀에 정보를 요청하는 애플리케이션입니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 5007,
      createdAt: '2020-01-28T09:55:46.171+09:00',
      updatedAt: '2024-05-08T07:14:48.819+09:00',
      name: '임직원 간편 인재 추천',
      thumbSmall: '/thumb/attach/tiny/1067947-2271',
      docCreatedAt: '2024-03-11T15:06:43.790+09:00',
      favoriteFlag: false,
      desc: '다우기술의 신입, 경력사원 채용 시 임직원 인재 추천을 간소화 하기 위한 앱입니다.\n\n인재추천서만 작성해주시면, 추천대상자에게 채용담당자가 직접 채용 정보를 담은 이메일과 문자를 전달해드립니다.\n\n* 인재추천서를 작성해주신 분들에 대해서는, 추천대상자와 함께 사용하실 수 있도록 1만원 상당의 상품권 2매를 지급합니다!\n  (단, 유효한 추천에 한하여 지급)',
      showDescription: true,
      admin: false,
    },
    {
      id: 3953,
      createdAt: '2018-09-05T16:36:51.180+09:00',
      updatedAt: '2022-05-25T16:19:20.819+09:00',
      name: '자산 관리',
      thumbSmall: '/thumb/attach/tiny/706312-893',
      docCreatedAt: '2018-11-16T14:47:50.702+09:00',
      favoriteFlag: false,
      desc: '',
      showDescription: false,
      admin: false,
    },
    {
      id: 1173,
      createdAt: '2016-04-14T17:40:56.829+09:00',
      updatedAt: '2021-11-11T17:43:11.366+09:00',
      name: '전사 거래처 관리',
      thumbSmall: '/thumb/attach/tiny/236335-4254',
      docCreatedAt: '2023-05-22T18:37:57.894+09:00',
      favoriteFlag: false,
      desc: '',
      showDescription: false,
      admin: false,
    },
    {
      id: 1494,
      createdAt: '2016-06-15T14:27:10.169+09:00',
      updatedAt: '2022-06-14T13:45:27.569+09:00',
      name: '전사 연락처 관리',
      thumbSmall: '/thumb/attach/tiny/264529-4170',
      docCreatedAt: '2023-06-01T20:10:19.654+09:00',
      favoriteFlag: false,
      desc: '',
      showDescription: false,
      admin: false,
    },
    {
      id: 942,
      createdAt: '2016-02-11T16:50:53.198+09:00',
      updatedAt: '2024-02-08T10:55:04.563+09:00',
      name: '전사 영업 활성화 프로모션 등록',
      thumbSmall: '/thumb/attach/tiny/208666-4170',
      docCreatedAt: '2023-12-20T08:41:19.325+09:00',
      favoriteFlag: false,
      desc: '',
      showDescription: true,
      admin: false,
    },
    {
      id: 1171,
      createdAt: '2016-04-14T16:04:03.520+09:00',
      updatedAt: '2022-01-28T09:52:18.902+09:00',
      name: '전사 캠페인 관리',
      thumbSmall: '/thumb/attach/tiny/236232-4084',
      docCreatedAt: '2021-10-25T09:27:24.202+09:00',
      favoriteFlag: false,
      desc: '',
      showDescription: false,
      admin: false,
    },
    {
      id: 8421,
      createdAt: '2024-01-25T09:28:23.090+09:00',
      updatedAt: '2024-01-25T13:32:34.310+09:00',
      name: '주니어교육과정 관리대장 Test',
      thumbSmall: '/thumb/attach/tiny/3215316-893',
      docCreatedAt: '2024-01-31T09:18:00.932+09:00',
      favoriteFlag: false,
      desc: '',
      showDescription: false,
      admin: false,
    },
    {
      id: 7606,
      createdAt: '2022-12-06T18:23:25.741+09:00',
      updatedAt: '2022-12-07T13:04:06.808+09:00',
      name: '차세대 다우오피스 TF',
      thumbSmall: '/thumb/attach/tiny/2456875-4515',
      favoriteFlag: false,
      desc: '차세대 다우오피스 TF 관리앱입니다.\nTF 일정 : 2022년 11월 1일 ~ 2023년 4월 30일\nTF 멤버 : 이재희 차장, 장인선 과장, 김성래 과장, 최상열 부장, 조윤호 대리, 이경희 차장, 이승진 차장, 봉원훈 부장, 최기용 상무',
      showDescription: true,
      admin: false,
    },
    {
      id: 8641,
      createdAt: '2024-05-27T09:22:43.225+09:00',
      updatedAt: '2024-05-29T13:06:23.588+09:00',
      name: '차세대 다우오피스 개발센터-사업부간 업무요청관리',
      thumbSmall: '/thumb/attach/tiny/3444521-3731',
      docCreatedAt: '2024-06-04T17:52:27.354+09:00',
      favoriteFlag: false,
      desc: '빈 템플릿으로 새로운 앱을 만들 수 있습니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 7343,
      createdAt: '2022-08-19T09:52:25.636+09:00',
      updatedAt: '2022-08-31T18:12:10.340+09:00',
      name: '창고/물류관리 서비스(WMS) 네이밍 공모전',
      thumbSmall: '/thumb/attach/tiny/2281271-1575',
      favoriteFlag: false,
      desc: ' \uD83D\uDC93네이밍 주요 가이드 \uD83D\uDC93\n✔️ 사방넷을 메인 브랜드로하는 서브 브랜드 공모 \n       (ex. "사방넷 000", "000 By 사방넷")\n✔️ 명칭 자체가 창고, 물류관리, 재고관리 등 어떤 서비스인지\n       직관적으로 알 수 있는 네이밍\n✔️ 특허청(KIPRIS) 상표 등록 및 출원 가능한 네이밍\n✔️ 국내 외 도메인 (com, co.kr, co, io) 등록이 가능한 네이밍',
      showDescription: true,
      admin: false,
    },
    {
      id: 2859,
      createdAt: '2017-07-24T14:06:01.765+09:00',
      updatedAt: '2023-10-04T14:18:07.927+09:00',
      name: '콘도 예약 관리',
      thumbSmall: '/thumb/attach/tiny/474601-2397',
      docCreatedAt: '2024-03-11T17:12:32.192+09:00',
      favoriteFlag: true,
      desc: '다우기술 임직원용으로 국내 콘도를 신청 접수하고 예약합니다. ',
      showDescription: true,
      admin: false,
    },
    {
      id: 4014,
      createdAt: '2018-10-04T08:22:41.565+09:00',
      updatedAt: '2018-10-15T14:33:41.169+09:00',
      name: '크리에이티브Lab',
      thumbSmall: '/thumb/attach/tiny/720488-1575',
      docCreatedAt: '2019-03-15T10:34:24.770+09:00',
      favoriteFlag: false,
      desc: '크리에이티브한 서비스를 만들어가는 아이디어 관리 앱입니다. ',
      showDescription: false,
      admin: false,
    },
    {
      id: 4296,
      createdAt: '2019-02-28T15:47:04.864+09:00',
      updatedAt: '2021-11-11T17:43:11.366+09:00',
      name: '포탈 신규 근태관리 버그/개선요청',
      thumbSmall: '/thumb/attach/tiny/805570-893',
      docCreatedAt: '2020-08-05T09:52:48.677+09:00',
      favoriteFlag: false,
      desc: '',
      showDescription: true,
      admin: false,
    },
    {
      id: 4655,
      createdAt: '2019-09-06T15:39:33.016+09:00',
      updatedAt: '2021-03-10T13:57:52.417+09:00',
      name: '포탈 차기 버전 적용 항목 이슈 등록 앱',
      thumbSmall: '/thumb/attach/tiny/955979-3731',
      docCreatedAt: '2019-09-11T10:30:03.091+09:00',
      favoriteFlag: false,
      desc: '9월 10일 포탈 패치 후 개편된 사항 중 결함 건에 대하여 등록해주시면 조치하도록 하겠습니다.',
      showDescription: true,
      admin: false,
    },
    {
      id: 6689,
      createdAt: '2022-01-07T13:24:33.571+09:00',
      updatedAt: '2022-01-20T13:28:46.803+09:00',
      name: '플랫폼기획 업무현황 - 2022',
      thumbSmall: '/thumb/attach/tiny/1911461-3717',
      docCreatedAt: '2022-02-16T17:43:16.134+09:00',
      favoriteFlag: true,
      desc: '빈 템플릿으로 새로운 앱을 만들 수 있습니다.',
      showDescription: false,
      admin: false,
    },
    {
      id: 933,
      createdAt: '2016-02-02T16:08:07.433+09:00',
      updatedAt: '2024-06-21T07:37:30.522+09:00',
      name: '홍보기사 요청 및 결과',
      thumbSmall: '/thumb/attach/tiny/206220-4515',
      docCreatedAt: '2022-05-23T09:27:19.223+09:00',
      favoriteFlag: false,
      desc: '홍보기사 작성을 요청하고 담당자가 기사 작성 및 배포 후 배포결과를 확인할 수 있는 애플리케이션입니다. 내용의 시급성에 따라 배포가 지연될 수 있는 점 양해 부탁 드리고, 배포희망일 최소 5영업일 전에 등록 바랍니다. ',
      showDescription: true,
      admin: false,
    },
  ],
};

export const mockedLinkPlusList = {
  code: '200',
  message: 'OK',
  data: [
    {
      id: 14,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2024-01-23 09:09:41',
      partnerId: 14,
      partnerName: '㈜현대이지웰',
      categoryId: 11,
      categoryName: '기업복지몰',
      categoryCode: '',
      name: '현대이지웰',
      code: 'weltree',
      summary:
        '현대이지웰 복지몰이란, 기본 생필품 구매부터 여행/레저, 영화관람권, 도서, 인기브랜드 제품까지! 다양한 카테고리의 상품들로 구성되어 있어 편리하게 할인 혜택을 누릴수 있습니다.',
      description:
        '&lt;h2&gt;이제 다우오피스를 통해 현대이지웰 복지몰을 부담없이 만나보세요.&lt;/h2&gt;\n    &lt;p class="desc"&gt;직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    혹시 별도의 복지몰 운영비용이 부담되셨나요?&lt;br&gt;\n    우리 회사만에 복지몰을 무료로 이용할수 있습니다.&lt;br&gt;\n    무제한 우대 혜택을 경험해보시기 바랍니다.&lt;br&gt;&lt;br&gt;\n    [혜택내용]&lt;br&gt;\n    건강관리, 자기개발, 가족친화, 여행문화, 생활서비스, 복지SHOP 등 6개 카테고리로 다양한 서비스 제공&lt;br&gt;&lt;br&gt;\n    [이용방법]&lt;br&gt;\n    복지몰(현대이지웰) 회원가입 후 무료로 이용 가능&lt;br&gt;\n    현대이지웰 복지몰은 매일 최저가 상품으로 진행중~&lt;br&gt;\n    * 슈퍼세븐데이즈 -&gt; 매주 1가지 상품을 네이버 최저가 대비 최대 45% 추가할인 판매&lt;br&gt;\n    * 특가중독 -&gt; 매주 수요일 10시 일괄 오픈, 한정판매&lt;br&gt;\n    * 뭉치면 내려간다 -&gt; 폐쇄몰 전용 공동구매 특가전\n\t&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\n    기존 현대이지웰 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;현대이지웰&rsquo;에서 진행됩니다.&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;현대이지웰&rsquo; 가입 및 결제 , 고객지원 문의 : 02-3282-7987&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 30분 ~ 오후 5시 30분&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오전 11시 30분 ~ 오후 1시 30분&lt;br&gt;',
      registrationId: 90229,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://daouoffice.ezwel.com/ezwelApi/apiReceiver.ez',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 23,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 93,
          attach: {
            id: 169,
            name: '현대이지웨 로고 v4.png',
            path: '/opt/linkplus/attach/system/A0D5ECD1F9554457845C2FDE4FA3A23B',
            size: 2418,
            extension: 'png',
            thumbnail: 'thumb/original/169-2418',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-3282-7987',
      email: null,
      watch:
        '(월~금요일) 오전 9시 30분 ~ 오후 5시 30분 / (점심시간) 오전 11시 30분 ~ 오후 1시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 22,
      createdAt: '2021-12-13 07:48:56',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 25,
      partnerName: '레드캡투어',
      categoryId: 44,
      categoryName: 'BTMS',
      categoryCode: '',
      name: '레드캡투어',
      code: 'redcaptour',
      summary:
        '출장 관리 시스템인 BTMS를 통하여 실시간 항공,호텔,비자 예약을 One-Stop으로 이용 할 수 있는 서비스이며, 출장계획부터 출장 규정 관리까지 가능한 서비스 입니다.',
      description:
        '&lt;h2&gt;레드캡투어에서 편리하고 간편하게 출장 예약을 진행해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n\t해외 출장 한번 갈 때마다 항공,호텔 예약을 따로 진행하는게 불편하지 않으신가요?&lt;br&gt;\n\t항공,호텔,비자,보험,로밍까지 출장에 필요한 모든 것을 한번에 해결할 수 있는 레드캡투어 BTMS 시스템을 통하여 다양한 서비스 이용을 할 수 있습니다.&lt;br&gt;&lt;br&gt;\n\t\n\t[혜택내용]&lt;br&gt;\n\t항공,호텔,비자,여행자보험,로밍 서비스 \n\t기업행사, 단체행사 ,보고서 작성, 비용 정산 등&lt;br&gt;\n\t\n\t[이용방법]&lt;br&gt;\n\t출장 관리 BTMS 시스템 무료이용 가능\n\t출장 서비스 예약 시 해피콜 혹은 메일로 연락\t\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;기존 BTMS를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;레드캡투어&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;레드캡투어&rsquo; 가입 및 고객지원 문의 : 02-2001-4700&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;-  예약 서비스 운영시간 : 평일 09:00 ~ 18:00  TEL : 02-2139-2777&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(24시간 긴급 센터 : 업무시간 외 주말, 공휴일 운영 / TEL: 02-3296-5500)',
      registrationId: 90230,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://btms4.redcap.co.kr/landingLegacyCompany/daou',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 6,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 196,
          attach: {
            id: 114,
            name: 'redcap_logo.png',
            path: '/opt/linkplus/attach/system/C0658E95D75F470C8AC202F579A9D046',
            size: 3730,
            extension: 'png',
            thumbnail: 'thumb/original/114-3730',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-2001-4700',
      email: null,
      watch: '평일 09:00 ~ 18:00',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 19,
      createdAt: '2021-09-28 10:44:59',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 19,
      partnerName: '컬투꽃배달',
      categoryId: 24,
      categoryName: '꽃배달서비스',
      categoryCode: '',
      name: '컬투플라워',
      code: 'cultwoflower',
      summary:
        '컬투플라워는 최저가, 최고 품질의 꽃바구니, 화환, 난, 개업 화분 등을 전 지역 당일 3시간 이내 배송해드리는 꽃배달 서비스입니다.',
      description:
        '&lt;h2&gt;꽃바구니, 화환, 난, 개업 화분 등이 필요할 땐 컬투플라워&lt;/h2&gt;\n&lt;p class="desc"&gt;\n컬투플라워는 2006년 창립하여 15년간의 운영 노하우를 가진  꽃배달서비스 1위 업체로 대한민국 꽃배달 쇼핑몰 중 최저가, 최고 품질을 지향합니다.&lt;br&gt;\n또한 엄선된 전국 체인망의 자체 인트라넷 구축으로 전 지역 당일 2 ~ 3시간 이내로 배송이 가능합니다.&lt;br&gt;\n보내시는 분의 정성까지 함께 할 수 있도록 상품의 고급화, 차별화는 물론 세심한 배송서비스를 통해 최상의 서비스를 제공할 것을 약속드립니다.\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다. 기존 컬투플라워를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;컬투플라워&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;컬투플라워&rsquo; 가입 및 결제 , 고객지원 문의 :1544-1141&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 ~ 오후 7시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주말, 공유일) 오전 9시 ~ 오후 5시 ',
      registrationId: 90231,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://daou.cultwo-flower.com/sso.html',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 7,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 97,
          attach: {
            id: 97,
            name: 'cultwo_logo.png',
            path: '/opt/linkplus/attach/system/2B5ADBC5559D4B8BADB2592D17FC8645',
            size: 7795,
            extension: 'png',
            thumbnail: 'thumb/original/97-7795',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: null,
      email: null,
      watch: null,
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 17,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 17,
      partnerName: 'BGF리테일',
      categoryId: 16,
      categoryName: '택배배송',
      categoryCode: '',
      name: 'CU',
      code: 'cu',
      summary:
        'CU편의점택배 를 해당 URL을 통해 사전 예약하고 발급된 승인번호로  CU편의점에 방문하여 무인택배장비에 입력하는 서비스입니다.',
      description:
        '&lt;h2&gt;CU편의점 택배 사용 시 운임 할인 혜택!&lt;br&gt;간편하고 저렴하게 택배 서비스를 이용해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n회사에서 필요한 택배를 이용할 때 어떻게 이용하고 계신가요?&lt;br&gt;\n택배 서비스를 꼭 지정된 장소,지정된 시간에 보내고 계신가요?&lt;br&gt;\n원하는 시간 24시간 / 저렴한 운임 / 안심/ 신속한 배송이 가능한 CU편의점 택배 서비스 입니다.&lt;br&gt;\n전국 13,500여개 CU편의점에서 택배 접수를 해보세요!\n&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;비지에프네트웍스&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;CUPOST&rsquo;  고객지원 문의 : 1566-1025&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 09시 ~ 오후 6시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      registrationId: 90232,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: '',
      directLinkUrl:
        'https://www.cupost.co.kr/partnership.cupost?svcKey=c86eb867faee7c842e6f6242a2fde473d97b2f54313f318146d34c0f0736cd69',
      linkType: 'NONE',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 8,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 95,
          attach: {
            id: 95,
            name: 'cu_logo.png',
            path: '/opt/linkplus/attach/system/7A47F1820165420080A4B08782BB7281',
            size: 5974,
            extension: 'png',
            thumbnail: 'thumb/original/95-5974',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1566-1025',
      email: null,
      watch:
        '(월~금요일) 오전 09시 ~ 오후 6시 / (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 3,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 3,
      partnerName: '알파(주)',
      categoryId: 9,
      categoryName: '문구/사무용품',
      categoryCode: '',
      name: '알파몰',
      code: 'alpha',
      summary:
        '문구/아트의 선두주자 알파몰은 오피스/생활/화방/학용 등 15만여가지의 다양한 상품과 고객맞춤 서비스를 제공해드립니다.',
      description:
        '&lt;h2&gt;기업에서 필요한 모든 사무/문구용품을 간편하게 구매하세요.&lt;/h2&gt;\r\n    &lt;p class="desc"&gt;\r\n    귀사에서는 반복적으로 일어나는 사무생활용품의 구매를 어떻게 진행하고 계신가요?&lt;br&gt;\r\n    기업에 필요한 사무용품부터 사무기기, 그리고 꼭 필요한 생필품까지!&lt;br&gt;\r\n    이제 다우오피스와 연동되어 편리한, 알파몰을 온라인으로 편리하게 만나보실 수 있습니다.&lt;br&gt;\r\n    알파몰은 문구 종합유통 프랜차이즈 기업인 &lsquo;알파(주)&rsquo;에서 운영하는 온라인 전용몰로써,\r\n    전국 800여 개의 체인 유통망 뿐만 아니라 온라인을 통해 사무용품, 문구용품, 전산용품, 생활용품, 화방용품까지\r\n    국내 최대 15만여 가지의 상품을 구매할 수 있습니다.&lt;br&gt;\r\n    이제 직원을 위한 편리한 사무용품 온라인몰 &lsquo;알파몰&rsquo;을 귀사에서도 경험해보시기 바랍니다.\r\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\r\n    기존 알파몰을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다. &lt;br&gt;\r\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;알파몰&rsquo;에서 진행됩니다.  &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;알파몰&rsquo; 가입 및 고객지원 문의 : 1811-0096 &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간 : 평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      registrationId: 90233,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: 'https://www.alpha.co.kr/member/login/daou/ssoLogin.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 11,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 84,
          attach: {
            id: 84,
            name: 'alpha_logo.png',
            path: '/opt/linkplus/attach/system/E6567ACC14874B2B974B490524E2166F',
            size: 14743,
            extension: 'png',
            thumbnail: 'thumb/original/84-14743',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1811-0096',
      email: null,
      watch:
        '평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 8,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 12,
      partnerName: '(주)다우기술',
      categoryId: 12,
      categoryName: '모바일쿠폰',
      categoryCode: '',
      name: '쿠팝',
      code: 'donutbook',
      summary:
        '국내 최대규모 모바일쿠폰 서비스 쿠팝에서는 정가보다 5~15% 저렴하게 모바일쿠폰 구매가 가능 합니다. (개인/기업 모두 가능)',
      description:
        '&lt;h2&gt;모바일쿠폰을 더 스마트하게 발송하는 방법 \'쿠팝\'&lt;/h2&gt;\n    &lt;p class="desc"&gt;\n    마케팅/영업부서에서 고객대상으로 이벤트 진행 시, 많이 사용하시는 모바일쿠폰!&lt;br&gt;\n    귀사에서는 어떻게 구매하시고 운영하시고 계신가요?&lt;br&gt;\n    매 번 최저가 상품을 찾아다니는데 시간을 헛되이 보내신다면,&lt;br&gt;\n    혹은 구매사이트에 접속해서도 수신자를 매 번 등록하는 불편한 업무를 계속 반복중이시라면,&lt;br&gt;\n    기업을 위한 모바일쿠폰 서비스인 &lsquo;쿠팝&rsquo;을 만나보시기 바랍니다.&lt;br&gt;\n    &lsquo;쿠팝&rsquo;은 기업을 위한 모바일쿠폰 서비스로써 3,000여개의 최저가 상품 제공은 물론, &lt;br&gt;\n    구매부터 발송, 그 이후 이력관리까지! 모바일쿠폰 관련 모든 업무를 한 번에 해결할 수 있는 솔루션을 제공합니다.\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n    기존 쿠팝을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;쿠팝&rsquo;에서 진행됩니다. &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;쿠팝&rsquo; 가입 및 고객지원 문의 : 1599-3785 / coupop@daou.co.kr&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 고객지원센터 운영시간 &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무&lt;br&gt;',
      registrationId: 90234,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://www.coupop.co.kr/api/daouoffice/sso.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 18,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 90,
          attach: {
            id: 143,
            name: 'coupop_logo.png',
            path: '/opt/linkplus/attach/system/B8C0F93D729848F4B418B376B3921DDE',
            size: 3497,
            extension: 'png',
            thumbnail: 'thumb/original/143-3497',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1599-3785',
      email: 'coupop@daou.co.kr',
      watch:
        '평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 142,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2024-01-23 09:09:41',
      partnerId: 14,
      partnerName: '㈜현대이지웰',
      categoryId: 11,
      categoryName: '기업복지몰',
      categoryCode: '',
      name: '현대이지웰2',
      code: 'weltree',
      summary:
        '현대이지웰 복지몰이란, 기본 생필품 구매부터 여행/레저, 영화관람권, 도서, 인기브랜드 제품까지! 다양한 카테고리의 상품들로 구성되어 있어 편리하게 할인 혜택을 누릴수 있습니다.',
      description:
        '&lt;h2&gt;이제 다우오피스를 통해 현대이지웰 복지몰을 부담없이 만나보세요.&lt;/h2&gt;\n    &lt;p class="desc"&gt;직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    혹시 별도의 복지몰 운영비용이 부담되셨나요?&lt;br&gt;\n    우리 회사만에 복지몰을 무료로 이용할수 있습니다.&lt;br&gt;\n    무제한 우대 혜택을 경험해보시기 바랍니다.&lt;br&gt;&lt;br&gt;\n    [혜택내용]&lt;br&gt;\n    건강관리, 자기개발, 가족친화, 여행문화, 생활서비스, 복지SHOP 등 6개 카테고리로 다양한 서비스 제공&lt;br&gt;&lt;br&gt;\n    [이용방법]&lt;br&gt;\n    복지몰(현대이지웰) 회원가입 후 무료로 이용 가능&lt;br&gt;\n    현대이지웰 복지몰은 매일 최저가 상품으로 진행중~&lt;br&gt;\n    * 슈퍼세븐데이즈 -&gt; 매주 1가지 상품을 네이버 최저가 대비 최대 45% 추가할인 판매&lt;br&gt;\n    * 특가중독 -&gt; 매주 수요일 10시 일괄 오픈, 한정판매&lt;br&gt;\n    * 뭉치면 내려간다 -&gt; 폐쇄몰 전용 공동구매 특가전\n\t&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\n    기존 현대이지웰 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;현대이지웰&rsquo;에서 진행됩니다.&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;현대이지웰&rsquo; 가입 및 결제 , 고객지원 문의 : 02-3282-7987&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 30분 ~ 오후 5시 30분&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오전 11시 30분 ~ 오후 1시 30분&lt;br&gt;',
      registrationId: 90229,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://daouoffice.ezwel.com/ezwelApi/apiReceiver.ez',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 23,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 93,
          attach: {
            id: 169,
            name: '현대이지웨 로고 v4.png',
            path: '/opt/linkplus/attach/system/A0D5ECD1F9554457845C2FDE4FA3A23B',
            size: 2418,
            extension: 'png',
            thumbnail: 'thumb/original/169-2418',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-3282-7987',
      email: null,
      watch:
        '(월~금요일) 오전 9시 30분 ~ 오후 5시 30분 / (점심시간) 오전 11시 30분 ~ 오후 1시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 222,
      createdAt: '2021-12-13 07:48:56',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 25,
      partnerName: '레드캡투어',
      categoryId: 44,
      categoryName: 'BTMS',
      categoryCode: '',
      name: '레드캡투어2',
      code: 'redcaptour',
      summary:
        '출장 관리 시스템인 BTMS를 통하여 실시간 항공,호텔,비자 예약을 One-Stop으로 이용 할 수 있는 서비스이며, 출장계획부터 출장 규정 관리까지 가능한 서비스 입니다.',
      description:
        '&lt;h2&gt;레드캡투어에서 편리하고 간편하게 출장 예약을 진행해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n\t해외 출장 한번 갈 때마다 항공,호텔 예약을 따로 진행하는게 불편하지 않으신가요?&lt;br&gt;\n\t항공,호텔,비자,보험,로밍까지 출장에 필요한 모든 것을 한번에 해결할 수 있는 레드캡투어 BTMS 시스템을 통하여 다양한 서비스 이용을 할 수 있습니다.&lt;br&gt;&lt;br&gt;\n\t\n\t[혜택내용]&lt;br&gt;\n\t항공,호텔,비자,여행자보험,로밍 서비스 \n\t기업행사, 단체행사 ,보고서 작성, 비용 정산 등&lt;br&gt;\n\t\n\t[이용방법]&lt;br&gt;\n\t출장 관리 BTMS 시스템 무료이용 가능\n\t출장 서비스 예약 시 해피콜 혹은 메일로 연락\t\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;기존 BTMS를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;레드캡투어&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;레드캡투어&rsquo; 가입 및 고객지원 문의 : 02-2001-4700&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;-  예약 서비스 운영시간 : 평일 09:00 ~ 18:00  TEL : 02-2139-2777&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(24시간 긴급 센터 : 업무시간 외 주말, 공휴일 운영 / TEL: 02-3296-5500)',
      registrationId: 90230,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://btms4.redcap.co.kr/landingLegacyCompany/daou',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 6,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 196,
          attach: {
            id: 114,
            name: 'redcap_logo.png',
            path: '/opt/linkplus/attach/system/C0658E95D75F470C8AC202F579A9D046',
            size: 3730,
            extension: 'png',
            thumbnail: 'thumb/original/114-3730',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-2001-4700',
      email: null,
      watch: '평일 09:00 ~ 18:00',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 192,
      createdAt: '2021-09-28 10:44:59',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 19,
      partnerName: '컬투꽃배달',
      categoryId: 24,
      categoryName: '꽃배달서비스',
      categoryCode: '',
      name: '컬투플라워2',
      code: 'cultwoflower',
      summary:
        '컬투플라워는 최저가, 최고 품질의 꽃바구니, 화환, 난, 개업 화분 등을 전 지역 당일 3시간 이내 배송해드리는 꽃배달 서비스입니다.',
      description:
        '&lt;h2&gt;꽃바구니, 화환, 난, 개업 화분 등이 필요할 땐 컬투플라워&lt;/h2&gt;\n&lt;p class="desc"&gt;\n컬투플라워는 2006년 창립하여 15년간의 운영 노하우를 가진  꽃배달서비스 1위 업체로 대한민국 꽃배달 쇼핑몰 중 최저가, 최고 품질을 지향합니다.&lt;br&gt;\n또한 엄선된 전국 체인망의 자체 인트라넷 구축으로 전 지역 당일 2 ~ 3시간 이내로 배송이 가능합니다.&lt;br&gt;\n보내시는 분의 정성까지 함께 할 수 있도록 상품의 고급화, 차별화는 물론 세심한 배송서비스를 통해 최상의 서비스를 제공할 것을 약속드립니다.\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다. 기존 컬투플라워를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;컬투플라워&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;컬투플라워&rsquo; 가입 및 결제 , 고객지원 문의 :1544-1141&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 ~ 오후 7시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주말, 공유일) 오전 9시 ~ 오후 5시 ',
      registrationId: 90231,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://daou.cultwo-flower.com/sso.html',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 7,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 97,
          attach: {
            id: 97,
            name: 'cultwo_logo.png',
            path: '/opt/linkplus/attach/system/2B5ADBC5559D4B8BADB2592D17FC8645',
            size: 7795,
            extension: 'png',
            thumbnail: 'thumb/original/97-7795',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: null,
      email: null,
      watch: null,
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 172,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 17,
      partnerName: 'BGF리테일',
      categoryId: 16,
      categoryName: '택배배송',
      categoryCode: '',
      name: 'CU2',
      code: 'cu',
      summary:
        'CU편의점택배 를 해당 URL을 통해 사전 예약하고 발급된 승인번호로  CU편의점에 방문하여 무인택배장비에 입력하는 서비스입니다.',
      description:
        '&lt;h2&gt;CU편의점 택배 사용 시 운임 할인 혜택!&lt;br&gt;간편하고 저렴하게 택배 서비스를 이용해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n회사에서 필요한 택배를 이용할 때 어떻게 이용하고 계신가요?&lt;br&gt;\n택배 서비스를 꼭 지정된 장소,지정된 시간에 보내고 계신가요?&lt;br&gt;\n원하는 시간 24시간 / 저렴한 운임 / 안심/ 신속한 배송이 가능한 CU편의점 택배 서비스 입니다.&lt;br&gt;\n전국 13,500여개 CU편의점에서 택배 접수를 해보세요!\n&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;비지에프네트웍스&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;CUPOST&rsquo;  고객지원 문의 : 1566-1025&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 09시 ~ 오후 6시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      registrationId: 90232,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: '',
      directLinkUrl:
        'https://www.cupost.co.kr/partnership.cupost?svcKey=c86eb867faee7c842e6f6242a2fde473d97b2f54313f318146d34c0f0736cd69',
      linkType: 'NONE',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 8,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 95,
          attach: {
            id: 95,
            name: 'cu_logo.png',
            path: '/opt/linkplus/attach/system/7A47F1820165420080A4B08782BB7281',
            size: 5974,
            extension: 'png',
            thumbnail: 'thumb/original/95-5974',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1566-1025',
      email: null,
      watch:
        '(월~금요일) 오전 09시 ~ 오후 6시 / (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 32,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 3,
      partnerName: '알파(주)',
      categoryId: 9,
      categoryName: '문구/사무용품',
      categoryCode: '',
      name: '알파몰2',
      code: 'alpha',
      summary:
        '문구/아트의 선두주자 알파몰은 오피스/생활/화방/학용 등 15만여가지의 다양한 상품과 고객맞춤 서비스를 제공해드립니다.',
      description:
        '&lt;h2&gt;기업에서 필요한 모든 사무/문구용품을 간편하게 구매하세요.&lt;/h2&gt;\r\n    &lt;p class="desc"&gt;\r\n    귀사에서는 반복적으로 일어나는 사무생활용품의 구매를 어떻게 진행하고 계신가요?&lt;br&gt;\r\n    기업에 필요한 사무용품부터 사무기기, 그리고 꼭 필요한 생필품까지!&lt;br&gt;\r\n    이제 다우오피스와 연동되어 편리한, 알파몰을 온라인으로 편리하게 만나보실 수 있습니다.&lt;br&gt;\r\n    알파몰은 문구 종합유통 프랜차이즈 기업인 &lsquo;알파(주)&rsquo;에서 운영하는 온라인 전용몰로써,\r\n    전국 800여 개의 체인 유통망 뿐만 아니라 온라인을 통해 사무용품, 문구용품, 전산용품, 생활용품, 화방용품까지\r\n    국내 최대 15만여 가지의 상품을 구매할 수 있습니다.&lt;br&gt;\r\n    이제 직원을 위한 편리한 사무용품 온라인몰 &lsquo;알파몰&rsquo;을 귀사에서도 경험해보시기 바랍니다.\r\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\r\n    기존 알파몰을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다. &lt;br&gt;\r\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;알파몰&rsquo;에서 진행됩니다.  &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;알파몰&rsquo; 가입 및 고객지원 문의 : 1811-0096 &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간 : 평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      registrationId: 90233,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: 'https://www.alpha.co.kr/member/login/daou/ssoLogin.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 11,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 84,
          attach: {
            id: 84,
            name: 'alpha_logo.png',
            path: '/opt/linkplus/attach/system/E6567ACC14874B2B974B490524E2166F',
            size: 14743,
            extension: 'png',
            thumbnail: 'thumb/original/84-14743',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1811-0096',
      email: null,
      watch:
        '평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 82,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 12,
      partnerName: '(주)다우기술',
      categoryId: 12,
      categoryName: '모바일쿠폰',
      categoryCode: '',
      name: '쿠팝2',
      code: 'donutbook',
      summary:
        '국내 최대규모 모바일쿠폰 서비스 쿠팝에서는 정가보다 5~15% 저렴하게 모바일쿠폰 구매가 가능 합니다. (개인/기업 모두 가능)',
      description:
        '&lt;h2&gt;모바일쿠폰을 더 스마트하게 발송하는 방법 \'쿠팝\'&lt;/h2&gt;\n    &lt;p class="desc"&gt;\n    마케팅/영업부서에서 고객대상으로 이벤트 진행 시, 많이 사용하시는 모바일쿠폰!&lt;br&gt;\n    귀사에서는 어떻게 구매하시고 운영하시고 계신가요?&lt;br&gt;\n    매 번 최저가 상품을 찾아다니는데 시간을 헛되이 보내신다면,&lt;br&gt;\n    혹은 구매사이트에 접속해서도 수신자를 매 번 등록하는 불편한 업무를 계속 반복중이시라면,&lt;br&gt;\n    기업을 위한 모바일쿠폰 서비스인 &lsquo;쿠팝&rsquo;을 만나보시기 바랍니다.&lt;br&gt;\n    &lsquo;쿠팝&rsquo;은 기업을 위한 모바일쿠폰 서비스로써 3,000여개의 최저가 상품 제공은 물론, &lt;br&gt;\n    구매부터 발송, 그 이후 이력관리까지! 모바일쿠폰 관련 모든 업무를 한 번에 해결할 수 있는 솔루션을 제공합니다.\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n    기존 쿠팝을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;쿠팝&rsquo;에서 진행됩니다. &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;쿠팝&rsquo; 가입 및 고객지원 문의 : 1599-3785 / coupop@daou.co.kr&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 고객지원센터 운영시간 &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무&lt;br&gt;',
      registrationId: 90234,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://www.coupop.co.kr/api/daouoffice/sso.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 18,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 90,
          attach: {
            id: 143,
            name: 'coupop_logo.png',
            path: '/opt/linkplus/attach/system/B8C0F93D729848F4B418B376B3921DDE',
            size: 3497,
            extension: 'png',
            thumbnail: 'thumb/original/143-3497',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1599-3785',
      email: 'coupop@daou.co.kr',
      watch:
        '평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 143,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2024-01-23 09:09:41',
      partnerId: 14,
      partnerName: '㈜현대이지웰',
      categoryId: 11,
      categoryName: '기업복지몰',
      categoryCode: '',
      name: '현대이지웰3',
      code: 'weltree',
      summary:
        '현대이지웰 복지몰이란, 기본 생필품 구매부터 여행/레저, 영화관람권, 도서, 인기브랜드 제품까지! 다양한 카테고리의 상품들로 구성되어 있어 편리하게 할인 혜택을 누릴수 있습니다.',
      description:
        '&lt;h2&gt;이제 다우오피스를 통해 현대이지웰 복지몰을 부담없이 만나보세요.&lt;/h2&gt;\n    &lt;p class="desc"&gt;직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    혹시 별도의 복지몰 운영비용이 부담되셨나요?&lt;br&gt;\n    우리 회사만에 복지몰을 무료로 이용할수 있습니다.&lt;br&gt;\n    무제한 우대 혜택을 경험해보시기 바랍니다.&lt;br&gt;&lt;br&gt;\n    [혜택내용]&lt;br&gt;\n    건강관리, 자기개발, 가족친화, 여행문화, 생활서비스, 복지SHOP 등 6개 카테고리로 다양한 서비스 제공&lt;br&gt;&lt;br&gt;\n    [이용방법]&lt;br&gt;\n    복지몰(현대이지웰) 회원가입 후 무료로 이용 가능&lt;br&gt;\n    현대이지웰 복지몰은 매일 최저가 상품으로 진행중~&lt;br&gt;\n    * 슈퍼세븐데이즈 -&gt; 매주 1가지 상품을 네이버 최저가 대비 최대 45% 추가할인 판매&lt;br&gt;\n    * 특가중독 -&gt; 매주 수요일 10시 일괄 오픈, 한정판매&lt;br&gt;\n    * 뭉치면 내려간다 -&gt; 폐쇄몰 전용 공동구매 특가전\n\t&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\n    기존 현대이지웰 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;현대이지웰&rsquo;에서 진행됩니다.&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;현대이지웰&rsquo; 가입 및 결제 , 고객지원 문의 : 02-3282-7987&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 30분 ~ 오후 5시 30분&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오전 11시 30분 ~ 오후 1시 30분&lt;br&gt;',
      registrationId: 90229,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://daouoffice.ezwel.com/ezwelApi/apiReceiver.ez',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 23,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 93,
          attach: {
            id: 169,
            name: '현대이지웨 로고 v4.png',
            path: '/opt/linkplus/attach/system/A0D5ECD1F9554457845C2FDE4FA3A23B',
            size: 2418,
            extension: 'png',
            thumbnail: 'thumb/original/169-2418',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-3282-7987',
      email: null,
      watch:
        '(월~금요일) 오전 9시 30분 ~ 오후 5시 30분 / (점심시간) 오전 11시 30분 ~ 오후 1시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 223,
      createdAt: '2021-12-13 07:48:56',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 25,
      partnerName: '레드캡투어',
      categoryId: 44,
      categoryName: 'BTMS',
      categoryCode: '',
      name: '레드캡투어3',
      code: 'redcaptour',
      summary:
        '출장 관리 시스템인 BTMS를 통하여 실시간 항공,호텔,비자 예약을 One-Stop으로 이용 할 수 있는 서비스이며, 출장계획부터 출장 규정 관리까지 가능한 서비스 입니다.',
      description:
        '&lt;h2&gt;레드캡투어에서 편리하고 간편하게 출장 예약을 진행해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n\t해외 출장 한번 갈 때마다 항공,호텔 예약을 따로 진행하는게 불편하지 않으신가요?&lt;br&gt;\n\t항공,호텔,비자,보험,로밍까지 출장에 필요한 모든 것을 한번에 해결할 수 있는 레드캡투어 BTMS 시스템을 통하여 다양한 서비스 이용을 할 수 있습니다.&lt;br&gt;&lt;br&gt;\n\t\n\t[혜택내용]&lt;br&gt;\n\t항공,호텔,비자,여행자보험,로밍 서비스 \n\t기업행사, 단체행사 ,보고서 작성, 비용 정산 등&lt;br&gt;\n\t\n\t[이용방법]&lt;br&gt;\n\t출장 관리 BTMS 시스템 무료이용 가능\n\t출장 서비스 예약 시 해피콜 혹은 메일로 연락\t\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;기존 BTMS를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;레드캡투어&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;레드캡투어&rsquo; 가입 및 고객지원 문의 : 02-2001-4700&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;-  예약 서비스 운영시간 : 평일 09:00 ~ 18:00  TEL : 02-2139-2777&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(24시간 긴급 센터 : 업무시간 외 주말, 공휴일 운영 / TEL: 02-3296-5500)',
      registrationId: 90230,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://btms4.redcap.co.kr/landingLegacyCompany/daou',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 6,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 196,
          attach: {
            id: 114,
            name: 'redcap_logo.png',
            path: '/opt/linkplus/attach/system/C0658E95D75F470C8AC202F579A9D046',
            size: 3730,
            extension: 'png',
            thumbnail: 'thumb/original/114-3730',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-2001-4700',
      email: null,
      watch: '평일 09:00 ~ 18:00',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 193,
      createdAt: '2021-09-28 10:44:59',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 19,
      partnerName: '컬투꽃배달',
      categoryId: 24,
      categoryName: '꽃배달서비스',
      categoryCode: '',
      name: '컬투플라워3',
      code: 'cultwoflower',
      summary:
        '컬투플라워는 최저가, 최고 품질의 꽃바구니, 화환, 난, 개업 화분 등을 전 지역 당일 3시간 이내 배송해드리는 꽃배달 서비스입니다.',
      description:
        '&lt;h2&gt;꽃바구니, 화환, 난, 개업 화분 등이 필요할 땐 컬투플라워&lt;/h2&gt;\n&lt;p class="desc"&gt;\n컬투플라워는 2006년 창립하여 15년간의 운영 노하우를 가진  꽃배달서비스 1위 업체로 대한민국 꽃배달 쇼핑몰 중 최저가, 최고 품질을 지향합니다.&lt;br&gt;\n또한 엄선된 전국 체인망의 자체 인트라넷 구축으로 전 지역 당일 2 ~ 3시간 이내로 배송이 가능합니다.&lt;br&gt;\n보내시는 분의 정성까지 함께 할 수 있도록 상품의 고급화, 차별화는 물론 세심한 배송서비스를 통해 최상의 서비스를 제공할 것을 약속드립니다.\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다. 기존 컬투플라워를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;컬투플라워&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;컬투플라워&rsquo; 가입 및 결제 , 고객지원 문의 :1544-1141&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 ~ 오후 7시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주말, 공유일) 오전 9시 ~ 오후 5시 ',
      registrationId: 90231,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://daou.cultwo-flower.com/sso.html',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 7,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 97,
          attach: {
            id: 97,
            name: 'cultwo_logo.png',
            path: '/opt/linkplus/attach/system/2B5ADBC5559D4B8BADB2592D17FC8645',
            size: 7795,
            extension: 'png',
            thumbnail: 'thumb/original/97-7795',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: null,
      email: null,
      watch: null,
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 173,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 17,
      partnerName: 'BGF리테일',
      categoryId: 16,
      categoryName: '택배배송',
      categoryCode: '',
      name: 'CU3',
      code: 'cu',
      summary:
        'CU편의점택배 를 해당 URL을 통해 사전 예약하고 발급된 승인번호로  CU편의점에 방문하여 무인택배장비에 입력하는 서비스입니다.',
      description:
        '&lt;h2&gt;CU편의점 택배 사용 시 운임 할인 혜택!&lt;br&gt;간편하고 저렴하게 택배 서비스를 이용해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n회사에서 필요한 택배를 이용할 때 어떻게 이용하고 계신가요?&lt;br&gt;\n택배 서비스를 꼭 지정된 장소,지정된 시간에 보내고 계신가요?&lt;br&gt;\n원하는 시간 24시간 / 저렴한 운임 / 안심/ 신속한 배송이 가능한 CU편의점 택배 서비스 입니다.&lt;br&gt;\n전국 13,500여개 CU편의점에서 택배 접수를 해보세요!\n&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;비지에프네트웍스&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;CUPOST&rsquo;  고객지원 문의 : 1566-1025&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 09시 ~ 오후 6시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      registrationId: 90232,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: '',
      directLinkUrl:
        'https://www.cupost.co.kr/partnership.cupost?svcKey=c86eb867faee7c842e6f6242a2fde473d97b2f54313f318146d34c0f0736cd69',
      linkType: 'NONE',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 8,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 95,
          attach: {
            id: 95,
            name: 'cu_logo.png',
            path: '/opt/linkplus/attach/system/7A47F1820165420080A4B08782BB7281',
            size: 5974,
            extension: 'png',
            thumbnail: 'thumb/original/95-5974',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1566-1025',
      email: null,
      watch:
        '(월~금요일) 오전 09시 ~ 오후 6시 / (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 33,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 3,
      partnerName: '알파(주)',
      categoryId: 9,
      categoryName: '문구/사무용품',
      categoryCode: '',
      name: '알파몰3',
      code: 'alpha',
      summary:
        '문구/아트의 선두주자 알파몰은 오피스/생활/화방/학용 등 15만여가지의 다양한 상품과 고객맞춤 서비스를 제공해드립니다.',
      description:
        '&lt;h2&gt;기업에서 필요한 모든 사무/문구용품을 간편하게 구매하세요.&lt;/h2&gt;\r\n    &lt;p class="desc"&gt;\r\n    귀사에서는 반복적으로 일어나는 사무생활용품의 구매를 어떻게 진행하고 계신가요?&lt;br&gt;\r\n    기업에 필요한 사무용품부터 사무기기, 그리고 꼭 필요한 생필품까지!&lt;br&gt;\r\n    이제 다우오피스와 연동되어 편리한, 알파몰을 온라인으로 편리하게 만나보실 수 있습니다.&lt;br&gt;\r\n    알파몰은 문구 종합유통 프랜차이즈 기업인 &lsquo;알파(주)&rsquo;에서 운영하는 온라인 전용몰로써,\r\n    전국 800여 개의 체인 유통망 뿐만 아니라 온라인을 통해 사무용품, 문구용품, 전산용품, 생활용품, 화방용품까지\r\n    국내 최대 15만여 가지의 상품을 구매할 수 있습니다.&lt;br&gt;\r\n    이제 직원을 위한 편리한 사무용품 온라인몰 &lsquo;알파몰&rsquo;을 귀사에서도 경험해보시기 바랍니다.\r\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\r\n    기존 알파몰을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다. &lt;br&gt;\r\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;알파몰&rsquo;에서 진행됩니다.  &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;알파몰&rsquo; 가입 및 고객지원 문의 : 1811-0096 &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간 : 평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      registrationId: 90233,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: 'https://www.alpha.co.kr/member/login/daou/ssoLogin.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 11,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 84,
          attach: {
            id: 84,
            name: 'alpha_logo.png',
            path: '/opt/linkplus/attach/system/E6567ACC14874B2B974B490524E2166F',
            size: 14743,
            extension: 'png',
            thumbnail: 'thumb/original/84-14743',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1811-0096',
      email: null,
      watch:
        '평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 83,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 12,
      partnerName: '(주)다우기술',
      categoryId: 12,
      categoryName: '모바일쿠폰',
      categoryCode: '',
      name: '쿠팝3',
      code: 'donutbook',
      summary:
        '국내 최대규모 모바일쿠폰 서비스 쿠팝에서는 정가보다 5~15% 저렴하게 모바일쿠폰 구매가 가능 합니다. (개인/기업 모두 가능)',
      description:
        '&lt;h2&gt;모바일쿠폰을 더 스마트하게 발송하는 방법 \'쿠팝\'&lt;/h2&gt;\n    &lt;p class="desc"&gt;\n    마케팅/영업부서에서 고객대상으로 이벤트 진행 시, 많이 사용하시는 모바일쿠폰!&lt;br&gt;\n    귀사에서는 어떻게 구매하시고 운영하시고 계신가요?&lt;br&gt;\n    매 번 최저가 상품을 찾아다니는데 시간을 헛되이 보내신다면,&lt;br&gt;\n    혹은 구매사이트에 접속해서도 수신자를 매 번 등록하는 불편한 업무를 계속 반복중이시라면,&lt;br&gt;\n    기업을 위한 모바일쿠폰 서비스인 &lsquo;쿠팝&rsquo;을 만나보시기 바랍니다.&lt;br&gt;\n    &lsquo;쿠팝&rsquo;은 기업을 위한 모바일쿠폰 서비스로써 3,000여개의 최저가 상품 제공은 물론, &lt;br&gt;\n    구매부터 발송, 그 이후 이력관리까지! 모바일쿠폰 관련 모든 업무를 한 번에 해결할 수 있는 솔루션을 제공합니다.\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n    기존 쿠팝을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;쿠팝&rsquo;에서 진행됩니다. &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;쿠팝&rsquo; 가입 및 고객지원 문의 : 1599-3785 / coupop@daou.co.kr&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 고객지원센터 운영시간 &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무&lt;br&gt;',
      registrationId: 90234,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://www.coupop.co.kr/api/daouoffice/sso.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 18,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 90,
          attach: {
            id: 143,
            name: 'coupop_logo.png',
            path: '/opt/linkplus/attach/system/B8C0F93D729848F4B418B376B3921DDE',
            size: 3497,
            extension: 'png',
            thumbnail: 'thumb/original/143-3497',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1599-3785',
      email: 'coupop@daou.co.kr',
      watch:
        '평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 144,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2024-01-23 09:09:41',
      partnerId: 14,
      partnerName: '㈜현대이지웰',
      categoryId: 11,
      categoryName: '기업복지몰',
      categoryCode: '',
      name: '현대이지웰4',
      code: 'weltree',
      summary:
        '현대이지웰 복지몰이란, 기본 생필품 구매부터 여행/레저, 영화관람권, 도서, 인기브랜드 제품까지! 다양한 카테고리의 상품들로 구성되어 있어 편리하게 할인 혜택을 누릴수 있습니다.',
      description:
        '&lt;h2&gt;이제 다우오피스를 통해 현대이지웰 복지몰을 부담없이 만나보세요.&lt;/h2&gt;\n    &lt;p class="desc"&gt;직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    직원을 위한 복지몰 사용, 대기업의 특권이라고 생각하셨나요?&lt;br&gt;\n    혹시 별도의 복지몰 운영비용이 부담되셨나요?&lt;br&gt;\n    우리 회사만에 복지몰을 무료로 이용할수 있습니다.&lt;br&gt;\n    무제한 우대 혜택을 경험해보시기 바랍니다.&lt;br&gt;&lt;br&gt;\n    [혜택내용]&lt;br&gt;\n    건강관리, 자기개발, 가족친화, 여행문화, 생활서비스, 복지SHOP 등 6개 카테고리로 다양한 서비스 제공&lt;br&gt;&lt;br&gt;\n    [이용방법]&lt;br&gt;\n    복지몰(현대이지웰) 회원가입 후 무료로 이용 가능&lt;br&gt;\n    현대이지웰 복지몰은 매일 최저가 상품으로 진행중~&lt;br&gt;\n    * 슈퍼세븐데이즈 -&gt; 매주 1가지 상품을 네이버 최저가 대비 최대 45% 추가할인 판매&lt;br&gt;\n    * 특가중독 -&gt; 매주 수요일 10시 일괄 오픈, 한정판매&lt;br&gt;\n    * 뭉치면 내려간다 -&gt; 폐쇄몰 전용 공동구매 특가전\n\t&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\n    기존 현대이지웰 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;현대이지웰&rsquo;에서 진행됩니다.&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;현대이지웰&rsquo; 가입 및 결제 , 고객지원 문의 : 02-3282-7987&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 30분 ~ 오후 5시 30분&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오전 11시 30분 ~ 오후 1시 30분&lt;br&gt;',
      registrationId: 90229,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://daouoffice.ezwel.com/ezwelApi/apiReceiver.ez',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 23,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 93,
          attach: {
            id: 169,
            name: '현대이지웨 로고 v4.png',
            path: '/opt/linkplus/attach/system/A0D5ECD1F9554457845C2FDE4FA3A23B',
            size: 2418,
            extension: 'png',
            thumbnail: 'thumb/original/169-2418',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-3282-7987',
      email: null,
      watch:
        '(월~금요일) 오전 9시 30분 ~ 오후 5시 30분 / (점심시간) 오전 11시 30분 ~ 오후 1시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 224,
      createdAt: '2021-12-13 07:48:56',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 25,
      partnerName: '레드캡투어',
      categoryId: 44,
      categoryName: 'BTMS',
      categoryCode: '',
      name: '레드캡투어4',
      code: 'redcaptour',
      summary:
        '출장 관리 시스템인 BTMS를 통하여 실시간 항공,호텔,비자 예약을 One-Stop으로 이용 할 수 있는 서비스이며, 출장계획부터 출장 규정 관리까지 가능한 서비스 입니다.',
      description:
        '&lt;h2&gt;레드캡투어에서 편리하고 간편하게 출장 예약을 진행해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n\t해외 출장 한번 갈 때마다 항공,호텔 예약을 따로 진행하는게 불편하지 않으신가요?&lt;br&gt;\n\t항공,호텔,비자,보험,로밍까지 출장에 필요한 모든 것을 한번에 해결할 수 있는 레드캡투어 BTMS 시스템을 통하여 다양한 서비스 이용을 할 수 있습니다.&lt;br&gt;&lt;br&gt;\n\t\n\t[혜택내용]&lt;br&gt;\n\t항공,호텔,비자,여행자보험,로밍 서비스 \n\t기업행사, 단체행사 ,보고서 작성, 비용 정산 등&lt;br&gt;\n\t\n\t[이용방법]&lt;br&gt;\n\t출장 관리 BTMS 시스템 무료이용 가능\n\t출장 서비스 예약 시 해피콜 혹은 메일로 연락\t\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;기존 BTMS를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;레드캡투어&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;레드캡투어&rsquo; 가입 및 고객지원 문의 : 02-2001-4700&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;-  예약 서비스 운영시간 : 평일 09:00 ~ 18:00  TEL : 02-2139-2777&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(24시간 긴급 센터 : 업무시간 외 주말, 공휴일 운영 / TEL: 02-3296-5500)',
      registrationId: 90230,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://btms4.redcap.co.kr/landingLegacyCompany/daou',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 6,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 196,
          attach: {
            id: 114,
            name: 'redcap_logo.png',
            path: '/opt/linkplus/attach/system/C0658E95D75F470C8AC202F579A9D046',
            size: 3730,
            extension: 'png',
            thumbnail: 'thumb/original/114-3730',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '02-2001-4700',
      email: null,
      watch: '평일 09:00 ~ 18:00',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 194,
      createdAt: '2021-09-28 10:44:59',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 19,
      partnerName: '컬투꽃배달',
      categoryId: 24,
      categoryName: '꽃배달서비스',
      categoryCode: '',
      name: '컬투플라워4',
      code: 'cultwoflower',
      summary:
        '컬투플라워는 최저가, 최고 품질의 꽃바구니, 화환, 난, 개업 화분 등을 전 지역 당일 3시간 이내 배송해드리는 꽃배달 서비스입니다.',
      description:
        '&lt;h2&gt;꽃바구니, 화환, 난, 개업 화분 등이 필요할 땐 컬투플라워&lt;/h2&gt;\n&lt;p class="desc"&gt;\n컬투플라워는 2006년 창립하여 15년간의 운영 노하우를 가진  꽃배달서비스 1위 업체로 대한민국 꽃배달 쇼핑몰 중 최저가, 최고 품질을 지향합니다.&lt;br&gt;\n또한 엄선된 전국 체인망의 자체 인트라넷 구축으로 전 지역 당일 2 ~ 3시간 이내로 배송이 가능합니다.&lt;br&gt;\n보내시는 분의 정성까지 함께 할 수 있도록 상품의 고급화, 차별화는 물론 세심한 배송서비스를 통해 최상의 서비스를 제공할 것을 약속드립니다.\n&lt;/p&gt;',
      confirmation:
        '1. 다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다. 기존 컬투플라워를 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며, 가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;컬투플라워&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;컬투플라워&rsquo; 가입 및 결제 , 고객지원 문의 :1544-1141&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 9시 ~ 오후 7시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (주말, 공유일) 오전 9시 ~ 오후 5시 ',
      registrationId: 90231,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: null,
      cancelUrl: '',
      cancelReturnPath: null,
      ssoUrl: 'https://daou.cultwo-flower.com/sso.html',
      directLinkUrl: '',
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 7,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 97,
          attach: {
            id: 97,
            name: 'cultwo_logo.png',
            path: '/opt/linkplus/attach/system/2B5ADBC5559D4B8BADB2592D17FC8645',
            size: 7795,
            extension: 'png',
            thumbnail: 'thumb/original/97-7795',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: null,
      email: null,
      watch: null,
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 174,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 17,
      partnerName: 'BGF리테일',
      categoryId: 16,
      categoryName: '택배배송',
      categoryCode: '',
      name: 'CU4',
      code: 'cu',
      summary:
        'CU편의점택배 를 해당 URL을 통해 사전 예약하고 발급된 승인번호로  CU편의점에 방문하여 무인택배장비에 입력하는 서비스입니다.',
      description:
        '&lt;h2&gt;CU편의점 택배 사용 시 운임 할인 혜택!&lt;br&gt;간편하고 저렴하게 택배 서비스를 이용해보세요.&lt;/h2&gt;\n&lt;p class="desc"&gt;\n회사에서 필요한 택배를 이용할 때 어떻게 이용하고 계신가요?&lt;br&gt;\n택배 서비스를 꼭 지정된 장소,지정된 시간에 보내고 계신가요?&lt;br&gt;\n원하는 시간 24시간 / 저렴한 운임 / 안심/ 신속한 배송이 가능한 CU편의점 택배 서비스 입니다.&lt;br&gt;\n전국 13,500여개 CU편의점에서 택배 접수를 해보세요!\n&lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;비지에프네트웍스&rsquo;에서 진행됩니다.&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;CUPOST&rsquo;  고객지원 문의 : 1566-1025&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (월~금요일) 오전 09시 ~ 오후 6시&lt;br&gt;\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      registrationId: 90232,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: '',
      directLinkUrl:
        'https://www.cupost.co.kr/partnership.cupost?svcKey=c86eb867faee7c842e6f6242a2fde473d97b2f54313f318146d34c0f0736cd69',
      linkType: 'NONE',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 8,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 95,
          attach: {
            id: 95,
            name: 'cu_logo.png',
            path: '/opt/linkplus/attach/system/7A47F1820165420080A4B08782BB7281',
            size: 5974,
            extension: 'png',
            thumbnail: 'thumb/original/95-5974',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1566-1025',
      email: null,
      watch:
        '(월~금요일) 오전 09시 ~ 오후 6시 / (점심시간) 오후 11시 30분 ~ 오후 12시 30분',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 34,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 3,
      partnerName: '알파(주)',
      categoryId: 9,
      categoryName: '문구/사무용품',
      categoryCode: '',
      name: '알파몰4',
      code: 'alpha',
      summary:
        '문구/아트의 선두주자 알파몰은 오피스/생활/화방/학용 등 15만여가지의 다양한 상품과 고객맞춤 서비스를 제공해드립니다.',
      description:
        '&lt;h2&gt;기업에서 필요한 모든 사무/문구용품을 간편하게 구매하세요.&lt;/h2&gt;\r\n    &lt;p class="desc"&gt;\r\n    귀사에서는 반복적으로 일어나는 사무생활용품의 구매를 어떻게 진행하고 계신가요?&lt;br&gt;\r\n    기업에 필요한 사무용품부터 사무기기, 그리고 꼭 필요한 생필품까지!&lt;br&gt;\r\n    이제 다우오피스와 연동되어 편리한, 알파몰을 온라인으로 편리하게 만나보실 수 있습니다.&lt;br&gt;\r\n    알파몰은 문구 종합유통 프랜차이즈 기업인 &lsquo;알파(주)&rsquo;에서 운영하는 온라인 전용몰로써,\r\n    전국 800여 개의 체인 유통망 뿐만 아니라 온라인을 통해 사무용품, 문구용품, 전산용품, 생활용품, 화방용품까지\r\n    국내 최대 15만여 가지의 상품을 구매할 수 있습니다.&lt;br&gt;\r\n    이제 직원을 위한 편리한 사무용품 온라인몰 &lsquo;알파몰&rsquo;을 귀사에서도 경험해보시기 바랍니다.\r\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.\r\n    기존 알파몰을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다. &lt;br&gt;\r\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;알파몰&rsquo;에서 진행됩니다.  &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;알파몰&rsquo; 가입 및 고객지원 문의 : 1811-0096 &lt;br&gt;\r\n    &nbsp;&nbsp;&nbsp;&nbsp;- 지원센터 운영시간 : 평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      registrationId: 90233,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '/store/v1/approval/result',
      cancelUrl: '',
      cancelReturnPath: '/store/v1/cancel/result',
      ssoUrl: 'https://www.alpha.co.kr/member/login/daou/ssoLogin.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 11,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 84,
          attach: {
            id: 84,
            name: 'alpha_logo.png',
            path: '/opt/linkplus/attach/system/E6567ACC14874B2B974B490524E2166F',
            size: 14743,
            extension: 'png',
            thumbnail: 'thumb/original/84-14743',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1811-0096',
      email: null,
      watch:
        '평일 09:00 ~ 17:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
    {
      id: 84,
      createdAt: '2021-07-05 15:07:19',
      updatedAt: '2023-11-17 16:16:47',
      partnerId: 12,
      partnerName: '(주)다우기술',
      categoryId: 12,
      categoryName: '모바일쿠폰',
      categoryCode: '',
      name: '쿠팝4',
      code: 'donutbook',
      summary:
        '국내 최대규모 모바일쿠폰 서비스 쿠팝에서는 정가보다 5~15% 저렴하게 모바일쿠폰 구매가 가능 합니다. (개인/기업 모두 가능)',
      description:
        '&lt;h2&gt;모바일쿠폰을 더 스마트하게 발송하는 방법 \'쿠팝\'&lt;/h2&gt;\n    &lt;p class="desc"&gt;\n    마케팅/영업부서에서 고객대상으로 이벤트 진행 시, 많이 사용하시는 모바일쿠폰!&lt;br&gt;\n    귀사에서는 어떻게 구매하시고 운영하시고 계신가요?&lt;br&gt;\n    매 번 최저가 상품을 찾아다니는데 시간을 헛되이 보내신다면,&lt;br&gt;\n    혹은 구매사이트에 접속해서도 수신자를 매 번 등록하는 불편한 업무를 계속 반복중이시라면,&lt;br&gt;\n    기업을 위한 모바일쿠폰 서비스인 &lsquo;쿠팝&rsquo;을 만나보시기 바랍니다.&lt;br&gt;\n    &lsquo;쿠팝&rsquo;은 기업을 위한 모바일쿠폰 서비스로써 3,000여개의 최저가 상품 제공은 물론, &lt;br&gt;\n    구매부터 발송, 그 이후 이력관리까지! 모바일쿠폰 관련 모든 업무를 한 번에 해결할 수 있는 솔루션을 제공합니다.\n    &lt;/p&gt;',
      confirmation:
        '1.  다우오피스를 통해 가입한 기업만 해당 제휴서비스와 다우오피스간의 SSO (Single Sign On) 연동기능을 사용하실 수 있습니다.&lt;br&gt;\n    기존 쿠팝을 사용중인 고객 대상의 연동은 향후 지원 예정이니 양해 부탁드립니다.&lt;br&gt;\n    2. 해당 제휴서비스는 다우오피스에서 연동만 지원하며,  가입 및 결제, 고객지원 모두 제휴서비스인 &lsquo;쿠팝&rsquo;에서 진행됩니다. &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- &lsquo;쿠팝&rsquo; 가입 및 고객지원 문의 : 1599-3785 / coupop@daou.co.kr&lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;- 고객지원센터 운영시간 &lt;br&gt;\n    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무&lt;br&gt;',
      registrationId: 90234,
      registrationStatus: 'REGISTRATION_COMPLETE',
      registrationUrl: '',
      registrationReturnPath: '',
      cancelUrl: '',
      cancelReturnPath: '',
      ssoUrl: 'https://www.coupop.co.kr/api/daouoffice/sso.do',
      directLinkUrl: null,
      linkType: 'SSO',
      regApiResultFlag: false,
      cancelApiResultFlag: false,
      sortOrder: 18,
      termsProvided: true,
      linkComponentBeanName: null,
      guideTitle: '가입/연동/혜택',
      guides: [],
      banners: [
        {
          id: 90,
          attach: {
            id: 143,
            name: 'coupop_logo.png',
            path: '/opt/linkplus/attach/system/B8C0F93D729848F4B418B376B3921DDE',
            size: 3497,
            extension: 'png',
            thumbnail: 'thumb/original/143-3497',
          },
          sortOrder: 0,
          bannerName: '',
          bannerType: 'DEFAULT_BANNER',
        },
      ],
      exposure: true,
      deletedAt: null,
      defaultProduct: false,
      markNew: false,
      productAccessAuthority: false,
      recommended: false,
      recommendationsCount: 0,
      contact: '1599-3785',
      email: 'coupop@daou.co.kr',
      watch:
        '평일 09:00 ~ 18:00 (점심시간 12:00 ~ 13:00) / 토,일요일 및 공휴일 휴무',
      regProductName: null,
      regProductSummary: null,
      regProductBanners: null,
      accessAuthority: 'ALL',
      accessTarget: null,
      companyName: null,
      clusterName: null,
      registrationApplyDate: '2024-01-29 18:36:02',
      bookmarked: false,
    },
  ],
};
