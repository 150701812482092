import { Contents } from './components/contents';
import { Root } from './components/root';

export function AppShare() {
  return (
    <Root>
      <Contents />
    </Root>
  );
}
