import { OrgConfigInfo } from '../type';

export const QUERY_KEY =
  'GlobalConfig.MultiCompanyManagement.BasicInfo.OrgConfig.Update';

const BASIC_INFO_ORG_CONFIG_URL = '/api/portal/setting/company-group/config';

export async function updateOrgConfig(orgConfig: OrgConfigInfo) {
  try {
    const response = await fetch(BASIC_INFO_ORG_CONFIG_URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Referer-Info': window.location.host,
      },
      body: JSON.stringify(orgConfig),
    });

    if (!response.ok) {
      throw new Error('updateOrgConfig 오류 발생.');
    }

    return response;
  } catch (error) {
    console.log('error', error);
  }
}
