import Link from 'next/link';

import * as Popover from '@daouoffice/ui/lib/foundation/Popover';
import { EllipsisHorizontalIcon } from '@daouoffice/ui/lib/icons/dop/24/EllipsisHorizontal';
import { useScreenLock } from '@dop-ui/react/widgets/screen-lock';
import { FuncButton } from '../../FuncButton';
import { IUserMenuInfo } from '../../types';

export interface Props {
  data: IUserMenuInfo[];
}

export function AppMoreMenuLayer({ data }: Props) {
  const { locked } = useScreenLock();

  const onClickHandler = (item: IUserMenuInfo) => {
    locked({
      appCode: item.appCode,
      appName: item.name,
      appUrl: item.url,
      useLock: item.useLock,
    });
  };

  return (
    <div className="group_more menu_open open">
      <Popover.Root>
        <Popover.Trigger>
          <FuncButton icon={EllipsisHorizontalIcon} />
        </Popover.Trigger>
        <Popover.Content side="right" sideOffset={10} className="select_list">
          <ul>
            {data.map((item) => (
              <li key={item.uid}>
                <Link
                  href={item.url}
                  onClick={() => onClickHandler(item)}
                  className="py-[3px] px-[12px] gap-[13px]"
                >
                  <i
                    className="icon ic_medium"
                    dangerouslySetInnerHTML={{ __html: item.icon as string }}
                  />
                  <span className="txt">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}

export default AppMoreMenuLayer;
