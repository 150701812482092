import { clsx } from 'clsx';
import { PropsWithChildren, useEffect, useState } from 'react';

interface Props {
  visible: boolean;
}
function DefaultAppContainer({ visible, children }: PropsWithChildren<Props>) {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsOpened(true);
    }
  }, [visible]);

  if (!isOpened) {
    return null;
  }

  return (
    <div className={clsx('w-full h-full', { hidden: !visible })}>
      {children}
    </div>
  );
}

export default DefaultAppContainer;
