import { type PropsWithChildren } from 'react';
import { useActionsContext } from './Context';
import { SearchBar } from './SearchBar';
import { ServiceMapCloseButton } from './ServiceMapCloseButton';
import { ServiceMapHeader } from './ServiceMapHeader';
import { ServiceMapTitle } from './ServiceMapTitle';

import { Divider } from './Divider';
import { GuideList, GuideListItem } from './GuideList';

export function ContentContainer({ children }: PropsWithChildren) {
  const { t } = useActionsContext();

  return (
    <div className="service_map">
      <ServiceMapHeader>
        <ServiceMapTitle />
        <SearchBar />
        <ServiceMapCloseButton />
      </ServiceMapHeader>
      <div className="wrap_contents">
        <div className="group_columns">
          {children}

          <div className="column right">
            {/** TODO: 기획 결정 시 link 수정 */}
            <GuideList title={t('seviceMap.guide.title.help')}>
              <GuideListItem
                name={t('seviceMap.guide.item.title.detailManual')}
                link={undefined}
              />
              <GuideListItem
                name={t('seviceMap.guide.item.title.empGuideCenter')}
                link={undefined}
              />
              <GuideListItem
                name={t('seviceMap.guide.item.title.mgpGuideCenter')}
                link={undefined}
              />
              <GuideListItem
                name={t('seviceMap.guide.item.title.eduCenter')}
                link={undefined}
              />
              <GuideListItem
                name={t('seviceMap.guide.item.title.apiCenter')}
                link={undefined}
              />
            </GuideList>
            <Divider />
            <GuideList title={t('seviceMap.guide.title.admin')}>
              <GuideListItem
                name={t('seviceMap.guide.item.title.settings')}
                link={undefined}
              />
              <GuideListItem
                name={t('seviceMap.guide.item.title.portal')}
                link={undefined}
              />
              <GuideListItem
                name={t('seviceMap.guide.item.title.doas')}
                link={undefined}
              />
            </GuideList>
          </div>
        </div>
      </div>
    </div>
  );
}
