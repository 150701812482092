import { NodeProps } from './interface';
import Node from './Node';

export interface Props {
  nodes: NodeProps[];
  className?: string;
  isSearch?: boolean;
  depth?: number;
  isLoadedAll?: boolean;
}

const NodeList = ({
  nodes: nodes = [],
  className: className = 'group_organization',
  isSearch,
  depth = 0,
  isLoadedAll,
}: Props) => {
  if (nodes === null) nodes = []; // TODO 임시코드. 현재 null 로 오는 경우가 있음. [] 로 바뀔 예정.
  return (
    <>
      <ul className={className}>
        {nodes.map((item: NodeProps) => (
          <Node
            key={item.id}
            node={item}
            isSearch={isSearch}
            depth={depth}
            isLoadedAll={isLoadedAll}
          ></Node>
        ))}
      </ul>
    </>
  );
};

export default NodeList;
