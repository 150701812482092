'use client';

import { useAuthContext } from '../../lib/auth/client';
import { getRandomColor } from '../../lib/utils/color';
import { Toolbar, type Props as ToolbarProps } from './Toolbar';

export function ToolbarClient(
  props: Omit<ToolbarProps, 'user' | 'roleType' | 'subRoleType'>,
) {
  const { me } = useAuthContext();

  // me가 없을 경우 로그인 페이지로 이동하므로 이 경우에는 렌더링하지 않음.
  if (!me) {
    return;
  }

  const avatarUserData = {
    name: me.name,
    avatarUrl: me.profileImageUrl,
    // 아바타 배경색을 변경하는 기능이 있으면 추가 로직 구성할 것.
    color: getRandomColor(me.id),
    email: me.email,
    gradeName: me.gradeName,
    departmentNames: me.departmentMemberList.map(
      (member) => member.departmentName,
    ),
  };

  const roleType =
    me.isBusinessPortalAccessible ||
    me.isSettingAccessible ||
    me.isAccessibleArchivingService
      ? 'admin'
      : 'user';
  const subRoleType = me.isAccessibleArchivingService
    ? 'restoreAdmin'
    : undefined;

  return (
    <Toolbar
      user={avatarUserData}
      roleType={roleType}
      subRoleType={subRoleType}
      {...props}
    />
  );
}

export default ToolbarClient;
