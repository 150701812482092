import { COMPANY, DEPARTMENT, MEMBER, NORMAL } from './constants';
import { NodeProps } from './interface';

export const getNodeId = (node: NodeProps) => {
  return `${node.nodeType}_${node.userId || node.departmentId}`;
};

export const isSameType = (nodeType: string | undefined, orgType: string) => {
  if (orgType === NORMAL) return true;
  if (
    orgType === DEPARTMENT &&
    (nodeType === DEPARTMENT || nodeType === COMPANY)
  )
    return true;
  if (orgType === MEMBER && nodeType === MEMBER) return true;
  return false;
};
