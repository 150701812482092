import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { PageIndicatorButtonType } from '../types';

export interface Props {
  className?: string;
  title?: string;
  type: PageIndicatorButtonType;
  disabled?: boolean;
  onClick?: () => void;
}

export function Button({
  className,
  title,
  type,
  disabled = false,
  onClick,
  children,
}: PropsWithChildren<Props>) {
  return (
    <button
      title={title}
      className={clsx(
        'w-7 h-7 rounded-md hover:rounded-md hover:bg-[#f4f4f4] disabled:hover:bg-transparent',
        className,
        {
          border: type === 'page',
          'flex items-center justify-center': type === 'action',
        },
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
