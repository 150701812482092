/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import * as React from 'react';

export interface IContextValues {
  readonly selectable: boolean;
  readonly columnDefs: ColumnDef<any>[];
  readonly contents: any[];
}

export interface IContextActions {
  onClickRow?: (selectedRowIndex: number) => void;
  onSelectContents?: (contents: any[]) => void;
  onChangeFilter?: (filters: Map<string, string>) => void;
}

export const ValuesContext = React.createContext<IContextValues>({
  selectable: false,
  columnDefs: [],
  contents: [],
});

export const ActionsContext = React.createContext<IContextActions>({
  onClickRow: undefined,
  onSelectContents: undefined,
  onChangeFilter: undefined,
});

export interface Props<T> {
  contents: T[];
  columnDefs: ColumnDef<T>[];
  selectable?: boolean;
  onClickRow?: (selectedRowIndex: number) => void;
  onSelectContents?: (contents: T[]) => void;
  onChangeFilter?: (filters: Map<string, string>) => void;
}

export function Provider<T>({
  contents: contentsProp,
  columnDefs,
  selectable = false,
  onClickRow,
  onSelectContents,
  onChangeFilter,
  children,
}: React.PropsWithChildren<Props<T>>) {
  const [contents, setContents] = React.useState<T[]>(contentsProp);

  React.useEffect(() => {
    setContents(contentsProp);
  }, [contentsProp]);

  const ctxValues = { contents, columnDefs, selectable };

  const ctxActions = { onClickRow, onSelectContents, onChangeFilter };

  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useTableValuesContext = () => React.useContext(ValuesContext);
export const useTableActionsContext = () => React.useContext(ActionsContext);

export const useTableContext = () => {
  const values = useTableValuesContext();
  const actions = useTableActionsContext();

  return {
    ...values,
    ...actions,
  };
};

export function useTableGenericValuesContext<T>() {
  const { contents, selectable, columnDefs } = useTableValuesContext();

  return {
    selectable,
    columnDefs: columnDefs as ColumnDef<T>[],
    contents: contents as T[],
  };
}

export function useTableGenericActionsContext<T>() {
  const { onChangeFilter, onClickRow, onSelectContents } =
    useTableActionsContext();

  return {
    onClickRow,
    onChangeFilter: onChangeFilter as (filters: Map<string, string>) => void,
    onSelectContents: onSelectContents as (contents: T[]) => void,
  };
}

export function useTableGenericContext<T>() {
  const values = useTableGenericValuesContext<T>();
  const actions = useTableGenericActionsContext<T>();

  return {
    ...values,
    ...actions,
  };
}

Provider.displayName = 'TableContextProvider';
