'use client';

import * as React from 'react';
import * as Jotai from 'jotai';
import type { IUserMenuInfo } from './types';

const store = Jotai.createStore();

export function Provider({ children }: React.PropsWithChildren) {
  return <Jotai.Provider store={store}>{children}</Jotai.Provider>;
}

export const menusAtom = Jotai.atom<IUserMenuInfo[]>([]);
export const useMenusAtom = () => Jotai.useAtom(menusAtom);
