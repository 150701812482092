import { Button } from '@daouoffice/ui';
import {
  AddStorageIcon,
  StorageSettingsIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { quotaAtom } from '../../store';
import { useContext } from './Provider';
import { StorageSettingsDialog } from './StorageSettingsDialog';

export interface Props {
  onClickSyncStorage: () => void;
}

export function Header({ onClickSyncStorage }: Props) {
  const quota = useAtomValue(quotaAtom);
  const { selectedRows } = useContext();
  const { t } = useTranslation('component');
  const isDisabled = selectedRows.length === 0;
  const [, setStorageSettingsLayerOpen] = useState(false);

  const onClickSyncStorageUsageHandler = () => {
    onClickSyncStorage && onClickSyncStorage();
  };

  const onClickStorageSettingsHandler = () => {
    // 링크로 이동 -> TODO: 링크 연결
  };

  const titleBlock = (
    <div className="flex items-center">
      <h2 className="ms-2 font-medium text-[22px] -tracking-[0.88px] mr-3">
        {t('globalconfig.dataManagement.memberManagement.title.data')}
      </h2>
    </div>
  );

  const actionButtonListBlock = (
    <div className="flex items-center gap-2">
      <Button
        styleType="line"
        colorset="major"
        title={t(
          'globalconfig.dataManagement.memberManagement.button.storageusage.sync',
        )}
        onClick={onClickSyncStorageUsageHandler}
        className="flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center border-[#AAAAAA] text-[#383838]"
      >
        <ArrowPathIcon className="size-4 stroke-[#383838]" />
      </Button>
      <Button
        styleType="line"
        colorset="major"
        title={t(
          'globalconfig.dataManagement.memberManagement.button.storageusage.settings.detail',
        )}
        onClick={onClickStorageSettingsHandler}
        className="flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center border-[#AAAAAA] text-[#383838]"
      >
        <StorageSettingsIcon className="relative top size-5 top-[4px] stroke-[#383838]" />
      </Button>
      <StorageSettingsDialog availableStorage={quota?.availableQuota ?? 0}>
        <Button
          styleType="line"
          colorset="major"
          title={t(
            'globalconfig.dataManagement.memberManagement.button.storageusage.add',
          )}
          onClick={() => !isDisabled && setStorageSettingsLayerOpen(true)}
          className={clsx(
            'flex gap-2 !border !border-solid rounded-lg px-4 py-2 items-center',
            {
              'border-[#AAAAAA] text-[#383838]': !isDisabled,
              'border-[#d8d8d8] text-[#d8d8d8]': isDisabled,
            },
          )}
        >
          <AddStorageIcon
            className={clsx('relative top size-5 top-[4px]', {
              'stroke-[#d8d8d8]': isDisabled,
              'stroke-[#383838]': !isDisabled,
            })}
          />
        </Button>
      </StorageSettingsDialog>
    </div>
  );

  return (
    <>
      <div className="flex items-center justify-between">
        {titleBlock}
        {actionButtonListBlock}
      </div>
    </>
  );
}

export default Header;
