import { fetch } from '../../../../../../lib/fetch/client';
import { APP_LOCK_CONFIG_URL } from '../../constants';

export const QUERY_KEY = 'lockConfigMutation';

export async function updateLockConfig(
  companyAppId: number,
  useLock: boolean,
  requiredLock: boolean = true,
) {
  try {
    return await fetch(APP_LOCK_CONFIG_URL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ companyAppId, useLock, requiredLock }),
    });
  } catch (error) {
    console.error(error);
  }
}
