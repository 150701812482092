'use client';

import '@daouoffice/design/dist/pages/employee/layout/root/organizer.css';
import { OrganizerPrimitives } from '@daouoffice/next/components/Organizer';

import { LazyBody } from '../LazyBody';

export function OrganizerClient() {
  return (
    <OrganizerPrimitives.Root>
      <LazyBody />
    </OrganizerPrimitives.Root>
  );
}
