import { atom } from 'jotai';

import * as React from 'react';
import * as Jotai from 'jotai';
import type { IOrganizerAtom, PanelState } from './types';

const store = Jotai.createStore();

export function Provider({ children }: React.PropsWithChildren) {
  return <Jotai.Provider store={store}>{children}</Jotai.Provider>;
}

export const organizerAtom = atom<IOrganizerAtom>({
  organizerState: 'default',
  panelState: 'none',
  settingMenuOpenState: false,
});

const changePanelAtom = atom(organizerAtom, (get, set, appName: PanelState) => {
  set(organizerAtom, (prev) => {
    const isCurrent = appName === prev.panelState;
    return {
      ...prev,
      organizerState: isCurrent ? 'default' : 'panel',
      panelState: isCurrent ? 'none' : appName,
    };
  });
});

export const useChangePanel = () => Jotai.useSetAtom(changePanelAtom);

export const useOrganizerValuesAtom = () => Jotai.useAtomValue(organizerAtom);

export const useOrganizerAtom = () => Jotai.useAtom(organizerAtom);
