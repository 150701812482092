import type { CreateMemoRequest, EditMemoResponse } from '../types';
import { fetch } from '../../../../../lib/fetch/client';

export async function createMemo(
  req: CreateMemoRequest,
): Promise<EditMemoResponse> {
  const pathUrl = `/api/portal/common/memo`;
  const apiResponse = await fetch(pathUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: req.content.toString(),
      color: req.color,
      textContent: req.textContent,
    }),
  });
  const response = (await apiResponse.json()) as EditMemoResponse;
  if (!apiResponse.ok) {
    console.log('create Memo API 오류 발생');
    throw new Error(response.message);
  }
  return response;
}
