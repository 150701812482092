import * as React from 'react';

export interface IContextValues {
  readonly selectedOptions: Set<string>;
}

export interface IContextActions {
  setSelectedOptions: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  selectedOptions: new Set(),
});

export const ActionsContext = React.createContext<IContextActions>({
  setSelectedOptions: () => undefined,
});

export function Provider({ children }: React.PropsWithChildren) {
  const [selectedOptions, setSelectedOptions] = React.useState<Set<string>>(
    new Set(),
  );

  const ctxValues = { selectedOptions };

  const ctxActions = { setSelectedOptions };

  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useFilterValuesContext = () => React.useContext(ValuesContext);
export const useFilterActionsContext = () => React.useContext(ActionsContext);

export const useFilterContext = () => {
  const values = useFilterValuesContext();
  const actions = useFilterActionsContext();

  return {
    ...values,
    ...actions,
  };
};

Provider.displayName = 'FilterContextProvider';
