import * as appManagementInfo from './appManagement';
import * as dataManagementInfo from './dataManagement';
import * as secureManagementInfo from './secureManagement';
import * as basicManagementInfo from './basicManagement';
import * as multiCompanyManagementInfo from './multicompanyManagement';
import * as mainMenuInfo from './main';
import { MenuInfo } from '../types';
import { keywordSearchedResult } from '../../../lib/search';

export const APP_MANAGEMENT_MENU_CODE = mainMenuInfo.codes.APPS_MANAGEMENT;
export const FAVORITE_MENU_CODE = mainMenuInfo.codes.FAVORITE_APP;
export const RECENT_MENU_CODE = mainMenuInfo.codes.RECENT_USED_APP;

export const APP_MANAGEMENT_MENU_PATH = mainMenuInfo.paths.APPS_MANAGEMENT;
export const FAVORITE_MENU_PATH = mainMenuInfo.paths.FAVORITE_APP;
export const RECENT_MENU_PATH = mainMenuInfo.paths.RECENT_USED_APP;

export const getMyMenuInfos = () => mainMenuInfo.myMenuInfo;

export const getMainMenuInfos = mainMenuInfo.getMainMenuInfos;

export function getSubMenuInfos(
  menuKeyPath: string,
  menuCodes?: string[],
): MenuInfo[] {
  switch (menuKeyPath) {
    case appManagementInfo.menuKeyPath:
      return appManagementInfo.getAppManagementMenuInfo(menuCodes ?? []);
    case dataManagementInfo.menuKeyPath:
      return dataManagementInfo.menuInfo;
    case secureManagementInfo.menuKeyPath:
      return secureManagementInfo.menuInfo;
    case basicManagementInfo.menuKeyPath:
      return basicManagementInfo.menuInfo;
    case multiCompanyManagementInfo.menuKeyPath:
      return multiCompanyManagementInfo.menuInfo;
    default:
      return [];
  }
}

function flattenMenu(menus: MenuInfo[]): MenuInfo[] {
  return menus.reduce((acc: MenuInfo[], menu: MenuInfo) => {
    acc.push(menu);
    if (menu.children && menu.children.length > 0) {
      acc.push(...flattenMenu(menu.children));
    }
    return acc;
  }, []);
}

function getAllMenuInfos(): MenuInfo[] {
  const mainMenuInfos = Array.from(mainMenuInfo.mainMenuInfo.values()).flat();
  const appManagementMenuInfos = Array.from(
    appManagementInfo.wholeMenuInfoMap.values(),
  ).flat();
  return [
    ...mainMenuInfos,
    ...flattenMenu(appManagementMenuInfos),
    ...flattenMenu(dataManagementInfo.menuInfo),
    ...flattenMenu(secureManagementInfo.menuInfo),
    ...flattenMenu(basicManagementInfo.menuInfo),
    ...flattenMenu(multiCompanyManagementInfo.menuInfo),
  ];
}

export function getMenuInfoWithPath(menuKeyPath: string): MenuInfo | undefined {
  const wholeData = getAllMenuInfos();
  const result = keywordSearchedResult<MenuInfo>(
    wholeData,
    menuKeyPath,
    ['menuPath'],
    {
      threshold: 0.0,
      findAllMatches: false,
      shouldSort: true,
      includeMatches: false,
    },
  );
  return result.find((menuInfo) => menuInfo.menuPath === menuKeyPath);
}
