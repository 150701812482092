import { useStack } from './Root';

export const usePush = <T>() => {
  const { setStack } = useStack();
  return (item: T) => {
    setStack((prevStack) => [...prevStack, item]);
  };
};

export const usePop = () => {
  const { setStack } = useStack();
  return () => {
    setStack((prevStack) => prevStack.slice(0, -1));
  };
};

export const useNavigate = <T>() => {
  const { setStack } = useStack();
  return (page: T) => {
    setStack((prev) => {
      const newStack = [...prev];
      newStack[newStack.length - 1] = page;
      return newStack;
    });
  };
};

export const useStackMethod = <T>() => {
  return { push: usePush<T>(), pop: usePop(), navigate: useNavigate<T>() };
};
