import { CharacterCount } from '@tiptap/extension-character-count';

interface Props {
  limit: number;
}

/**
 * CharCountConfig
 * @param limit: 최대 글자 수
 */
export const CharCountConfig = ({ limit }: Props) => {
  return CharacterCount.configure({ limit });
};

export { CharacterCount };

export default CharCountConfig;
