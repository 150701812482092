import { fetchGraphQL, fetch } from '@dop-ui/react/shared/lib/fetch/client';
import { gql } from 'graphql-request';
import { TFA_EXCEPTION_DETAIL_CONFIG_API } from '../../constants';
import { BaseResponse } from '../../types';
import { TfaExceptionInfo } from '../types';

const query = gql`
  query {
    authConfig {
      USE_EXTRA_AUTH_EXCLUDE
    }
  }
`;

interface GraphQlResponse {
  data: {
    authConfig: {
      USE_EXTRA_AUTH_EXCLUDE: boolean;
    };
  };
}

export const QUERY_KEY_USE_TFA_EXCEPTION_CONFIG =
  'GlobalConfig.SecureManagement.useTfaExceptionConfig';

export async function getIsUsingTfaException() {
  const response = await fetchGraphQL({ query });

  const responseData = (await response.json()) as GraphQlResponse;

  return responseData.data.authConfig;
}

export const QUERY_KEY_USE_TFA_EXCEPTION_DETAIL_INFO =
  'GlobalConfig.SecureManagement.tfaExceptionDetailInfo';

export async function getTfaExceptionDetailInfo() {
  const response = await fetch(TFA_EXCEPTION_DETAIL_CONFIG_API, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<TfaExceptionInfo>;

  return responseData.data;
}

export async function updateExceptionDetailInfo(info: TfaExceptionInfo) {
  const response = await fetch(TFA_EXCEPTION_DETAIL_CONFIG_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(info),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as BaseResponse<string>;
    throw new Error(responseData.message);
  }
}
