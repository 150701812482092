import { PropsWithChildren } from 'react';
import * as ToolTip from '@dop-ui/react/shared/ui/tooltip/dop-tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export function TitleAndDescription({
  title,
  description,
  tooltip,
  children,
}: PropsWithChildren<{
  title: string;
  description?: string;
  tooltip?: string;
}>) {
  return (
    <div className="w-full min-h-[40px] flex flex-wrap items-center gap-[24px]">
      <span className="text-[#111111] text-[16px] font-[500] min-w-[240px]">
        {title}
        {tooltip && (
          <ToolTip.Root>
            <ToolTip.Trigger>
              <QuestionMarkCircleIcon
                className="size-[16px] ml-[4px]"
                color="#AAAAAA"
              />
            </ToolTip.Trigger>
            <ToolTip.Portal>
              <ToolTip.Content className="max-w-full z-[1000]">
                {tooltip}
              </ToolTip.Content>
            </ToolTip.Portal>
          </ToolTip.Root>
        )}
      </span>
      <span className="text-[#AAAAAA] text[14px] font-[400] flex-grow">
        {description}
      </span>
      {children}
    </div>
  );
}
