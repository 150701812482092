import { Avatar } from '@daouoffice/ui';
import { useQuery } from '@tanstack/react-query';
import { ProfileCard } from './index';
import { getRandomColor } from '../../lib/utils/color';

import '@daouoffice/design/dist/template/profilecard.css';
import * as userProfileQuery from './userProfileQuery';

export function ProfileCardClient() {
  const { data: userData, error } = useQuery({
    queryKey: [userProfileQuery.ID],
    queryFn: userProfileQuery.getProfileInfo,
  });

  if (error) {
    console.error(error);
    return;
  }

  return (
    <ProfileCard.Root>
      <ProfileCard.Content>
        <ProfileCard.Trigger>
          <div className="avatar_medium has_tooltip">
            <Avatar
              size={36}
              alt={userData?.name ?? 'no-name'}
              src={userData?.userInfo.profileImageUrl}
              backgroundColor={getRandomColor(userData?.id)}
            />
            <span className="tips bottom">{userData?.name ?? 'no-name'}</span>
          </div>
        </ProfileCard.Trigger>
        <ProfileCard.Card
          profileInfo={{
            name: userData?.name ?? 'no-name',
            gradeName: userData?.userInfo.grade?.name ?? 'no-grade',
            departmentNames: [
              userData?.mainDepartment?.name ?? 'no-department',
            ],
            email: userData?.email ?? 'no-email',
            color: getRandomColor(userData?.id),
          }}
        />
      </ProfileCard.Content>
    </ProfileCard.Root>
  );
}
