import { MOBILE_APP_ACCESS_LIST_URL } from '../../constants';
import { BaseResponse, MobileInfo } from '../types';

export const QUERY_KEY = 'mobileAppAccessListQuery';

export async function getMobileAppAccessStatus(queryString: string) {
  try {
    const { data } = (await (
      await fetch(`${MOBILE_APP_ACCESS_LIST_URL}?${queryString}`)
    ).json()) as BaseResponse<MobileInfo>;

    return data;
  } catch (error) {
    console.error(error);
  }
}
