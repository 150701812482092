import { useAtomValue } from 'jotai';
import { PropsWithChildren } from 'react';
import { serviceMapOpenStateAtom } from '../../store/serviceMapOpenStateAtom';

export interface Props {}

export function Root({ children }: PropsWithChildren<Props>) {
  const serviceMapOpenState = useAtomValue(serviceMapOpenStateAtom);

  return (
    serviceMapOpenState && (
      <div className="absolute top-0 left-0 right-0 bottom-0 z-50">
        <div className="service_map bg-white h-full">{children}</div>
      </div>
    )
  );
}

export default Root;
