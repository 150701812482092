import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import * as deptEntireDataQuery from '../../../api/deptEntireDataQuery';
import { formatBytes } from '../../../utils/byteFormatter';
import { useContext } from '../../Provider';
import { AppContents } from '../AppContents';
import { AppList } from './AppList';
import { DeptInfoCard } from './DeptInfoCard';
import { Header } from './Header';

export function DetailContents() {
  const { t } = useTranslation('component');
  const { deptId, selectedAppType } = useContext();
  const {
    data: res,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [deptEntireDataQuery.QUERY_KEY],
    queryFn: async () => await deptEntireDataQuery.deptEntireDataQuery(deptId),
  });

  if (error) {
    <>{error}</>;
  }

  useEffect(() => {
    refetch().catch((error) => console.error(error));
  }, [deptId, refetch]);

  return (
    <>
      {selectedAppType ? (
        <AppContents />
      ) : (
        <div className="pt-6 px-2 h-full w-full">
          <Header />
          <DeptInfoCard
            className="mt-6"
            deletedAt={
              res?.data?.deletedAt ?? t('globalconfig.common.nullInfo')
            }
            storageUsage={formatBytes(res?.data?.attachSize ?? 0)}
          />
          <div className="mt-10">
            <AppList appList={res?.data?.appList ?? []} />
          </div>
        </div>
      )}
    </>
  );
}
