import { fetch } from '../../../../../../lib/fetch/client';
import { REQUEST_MOBILE_ACCESS_URL } from '../../constants';
import { MobileAccessType } from '../types';

export const MUTATION_KEY = 'mobileAccessRequestMutation';

export async function requestMobileAccess(
  accessType: MobileAccessType,
  userIds: number[],
) {
  try {
    return await fetch(`${REQUEST_MOBILE_ACCESS_URL}/${accessType}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: userIds,
      }),
    });
  } catch (error) {
    console.error(error);
  }
}
