import { PropsWithChildren } from 'react';

import styles from './password-policy-config.module.css';

import type { IConfigItem } from './types';

export function ConfigItem({
  title,
  description,
  subDescription,
  hasList = false,
  tooltip,
  children,
}: PropsWithChildren<IConfigItem>) {
  if (hasList) {
    return (
      <div className={styles.HasList}>
        <div className={styles.Content}>
          <div className={styles.TitleContainer}>
            <p className={styles.Title}>{title}</p>
          </div>
          <p className={styles.Description}>{description}</p>
        </div>
        <ul className={styles.List}>{children}</ul>
      </div>
    );
  }
  return (
    <div className={styles.Content}>
      <div className={styles.TitleContainer}>
        <p className={styles.Title}>{title}</p>
        {tooltip}
      </div>

      <div className={styles.DescriptionList}>
        <p className={styles.Description}>{description}</p>
        {subDescription && (
          <p className={styles.SubDescription}>{subDescription}</p>
        )}
      </div>
      {children}
    </div>
  );
}

export default ConfigItem;
