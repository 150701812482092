import fetch from '../../../../../lib/fetch/client';
import { MemoResponse } from '../types';

export const QUERY_KEY = 'organizer-memo-list';

export async function getMemoList(): Promise<MemoResponse> {
  try {
    const url = '/api/portal/common/memo';

    const resp = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!resp.ok) {
      throw new Error('getMemoList 오류 발생.');
    }

    const resData = (await resp.json()) as MemoResponse;
    return resData;
  } catch (e) {
    console.log(e);
    return {
      data: {
        count: 0,
        memoList: [],
      },
    };
  }
}
