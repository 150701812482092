import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * AdminTable Page Indicator 마지막 페이지로 이동하는 (16x16) 아이콘
 * @param props
 * @returns
 */
export function LastIndicatorIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37712 2.62288C9.5854 2.83116 9.5854 3.16884 9.37712 3.37712L4.75425 8L9.37712 12.6229C9.5854 12.8312 9.5854 13.1688 9.37712 13.3771C9.16884 13.5854 8.83116 13.5854 8.62288 13.3771L3.62288 8.37712C3.4146 8.16884 3.4146 7.83116 3.62288 7.62288L8.62288 2.62288C8.83116 2.4146 9.16884 2.4146 9.37712 2.62288ZM13.3771 2.62288C13.5854 2.83116 13.5854 3.16884 13.3771 3.37712L8.75425 8L13.3771 12.6229C13.5854 12.8312 13.5854 13.1688 13.3771 13.3771C13.1688 13.5854 12.8312 13.5854 12.6229 13.3771L7.62288 8.37712C7.4146 8.16884 7.4146 7.83116 7.62288 7.62288L12.6229 2.62288C12.8312 2.4146 13.1688 2.4146 13.3771 2.62288Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default LastIndicatorIcon;
