import { clsx } from 'clsx';
import { Header } from './Header';
import { AppsDock } from './AppsDock';
import { Footer } from './Footer';
import { Provider as GnbStoreProvider } from './store';
import '@daouoffice/design/dist/pages/employee/layout/root/gnb.css';

export function GlobalNavBar({ className }: { className?: string }) {
  return (
    <aside className={clsx('gnb', className)}>
      <div className="wrap_gnb">
        {/* 서비스 맵 버튼 */}
        <Header />

        <GnbStoreProvider>
          {/* 사용자 앱 목록 */}
          <AppsDock />

          {/* footer */}
          <Footer className="group_fixed_btn" />
        </GnbStoreProvider>
      </div>
    </aside>
  );
}

export default GlobalNavBar;
