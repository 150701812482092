import { Button } from '../../../../shared/ui/button';
import { buttonType } from '../../types';

export interface Props {
  title: string;
  className?: string;
  type?: buttonType;
  handleOnAction: () => void;
}

export function ActionButton({
  title,
  className,
  type = 'positive',
  handleOnAction,
}: Props) {
  return (
    <Button
      shape="rect"
      size="large"
      colorset="level1"
      variant={type === 'positive' ? 'solid' : 'outline'}
      className={className}
      tabIndex={0}
      onClick={handleOnAction}
    >
      {title}
    </Button>
  );
}

export default ActionButton;
