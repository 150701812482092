import {
  MailIcon,
  AdditionIcon,
  SettingsIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import { CalendarIcon } from '@daouoffice/ui/lib/icons/dop/24/Calendar';
import { DocumentIcon } from '@daouoffice/ui/lib/icons/dop/24/Document';
import { PanelState } from '../types';

export function getDefaultAppIcon(appName: PanelState) {
  switch (appName) {
    case 'calendar':
      return <CalendarIcon className="text-white" size={20} />;
    case 'mail':
      return <MailIcon size={20} className="text-white" />;
    case 'memo':
      return <DocumentIcon className="text-white" size={20} />;
    case 'shortcut':
      return <AdditionIcon size={20} className="text-black" />;
    case 'setting':
      return <SettingsIcon className="text-black" size={20} />;
    default:
      return <></>;
  }
}
