'use client';

import { useTranslation } from '@daouoffice/next/lib/i18n/client/useTranslation';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { CogIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { AppsDockConfigClient } from '../AppsDock/AppsDockConfig/AppsDockConfigClient';
import { FuncButton } from '../FuncButton';

export function AppsDockConfigButton() {
  const { t } = useTranslation();

  return (
    <Dialog.Root modal={false}>
      <Dialog.Trigger>
        <FuncButton icon={CogIcon} label={t('메뉴 설정')} />
      </Dialog.Trigger>
      <Dialog.Content size="resizeable">
        <AppsDockConfigClient />
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default AppsDockConfigButton;
