import { PropsWithChildren, Suspense } from 'react';
import { Provider } from './Provider';

export function Root({ children }: PropsWithChildren) {
  return (
    <div className="flex px-[75px] pb-[75px] pt-10">
      <div className="flex w-full h-full border rounded-xl gap-1 p-10">
        <Suspense fallback={<div className="h-full">Loading...</div>}>
          <Provider>{children}</Provider>
        </Suspense>
      </div>
    </div>
  );
}

export default Root;
