import { fetch } from '../../../../../../lib/fetch/client';
import { BaseResponse } from '../../types';
import { IP_BLOCK_LIST_API as LOGIN_IP_ACCESS_CONFIG_API } from '../../constants';
import { DetailLoginIpPolicyConfig } from '../types';

export const QUERY_KEY =
  'GlobalConfig.SecureManagement.LoginSecureConfig.IpAccessPolicyConfig';

export async function getDetailIpPolicyConfig() {
  const response = await fetch(LOGIN_IP_ACCESS_CONFIG_API, {
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
  });

  const responseData =
    (await response.json()) as BaseResponse<DetailLoginIpPolicyConfig>;

  return responseData.data;
}

export async function updateRestrictedIPList(
  detailConfig: DetailLoginIpPolicyConfig,
) {
  const response = await fetch(LOGIN_IP_ACCESS_CONFIG_API, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify(detailConfig),
  });

  if (!response.ok) {
    const responseData = (await response.json()) as BaseResponse<string>;
    throw new Error(responseData.message);
  }
}
