import { useInView } from 'react-intersection-observer';
import { useInfiniteScrollQuery } from './use-infinite-scroll-query';
import { useEffect } from 'react';
import AppItem from '../app-item';
import type { AppType } from '../../types';
import styles from '../../shortcut.module.css';

interface Props {
  type: string;
  appList: AppType[];
  offset: number;
}

export function ScrollList({ type, appList, offset }: Props) {
  const {
    scrollList,
    getNextPage,
    isScrollSuccess,
    isHasNextPage,
    isError,
    isLoading,
    isFetching,
  } = useInfiniteScrollQuery({ type, appList, offset });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && isHasNextPage) {
      void getNextPage();
    }
  }, [inView]);

  if (isLoading) {
    return <div>Loading ... </div>;
  }

  if (isError) {
    return <></>;
  }

  return (
    <ul id={type} className={styles.AppList}>
      {isScrollSuccess &&
        scrollList &&
        scrollList.map((page_data) =>
          page_data.map((appItem) => (
            <AppItem key={appItem.id} type={type} appItem={appItem} />
          )),
        )}
      {isHasNextPage && !isFetching && <div ref={ref} />}
    </ul>
  );
}

export default ScrollList;
