import { fetch } from '../../../../../../../../../../lib/fetch/client';

export async function putAuthData(userId: number, companyAppIds: number[]) {
  try {
    const path = '/api/portal/admin/app-manager';
    const resp = await fetch(path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        companyAppIds,
      }),
    });

    if (!resp.ok) throw new Error(`Failed to PUT ${path}` + resp.statusText);

    return resp.json();
  } catch (e) {
    console.error(e);
  }
}
