'use client';
import { Root } from './Root';

/**
 * Organizer 컴포넌트 속성
 */

/**
 * Organizer 컴포넌트
 * @returns
 */

export function Organizer() {
  return <Root />;
}

export default Organizer;
