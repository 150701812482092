import * as fetchClient from '../../../shared/lib/fetch/client';
import { ResponseBody } from '../types';
import { API_SIMPLE_CODE_URL } from './constants';

export interface IExistSimpleCodeResponseData {
  isExistSimpleCode: boolean;
}

export async function checkExistSimpleCode() {
  try {
    const resp = await fetchClient.fetch(`${API_SIMPLE_CODE_URL}/exist`, {
      method: 'GET',
      headers: {
        'X-Referer-Info': window.location.hostname,
        'Content-Type': 'application/json',
      },
    });

    return resp.json() as ResponseBody<IExistSimpleCodeResponseData>;
  } catch (e) {
    console.error(e);
    return {
      data: { isExistSimpleCode: false },
    } as ResponseBody<IExistSimpleCodeResponseData>;
  }
}
