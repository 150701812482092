import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { clsx } from 'clsx';
import { PropsWithChildren, useState } from 'react';
import { GlobalConfigPrimitives } from './parts';

import '@daouoffice/design/dist/template/preferences.css';
import { ScreenLockProvider } from '@dop-ui/react/widgets/screen-lock';

export interface Props {
  asChild?: boolean;
  configPath?: string;
}

export function GlobalConfigDialog({
  asChild = false,
  configPath,
  children,
}: PropsWithChildren<Props>) {
  const [openState, setOpenState] = useState(false);

  return (
    <Dialog.Root
      modal={true}
      open={openState}
      onOpenChange={(isOpened: boolean) => {
        setOpenState(isOpened);
      }}
    >
      <Dialog.Trigger asChild={asChild}>{children}</Dialog.Trigger>
      <Dialog.Content
        className={clsx('bottom', { open: openState })}
        dim={true}
        size="full"
      >
        <Dialog.Title className="hidden"></Dialog.Title>
        <ScreenLockProvider lockType="globalconfig">
          <GlobalConfigPrimitives.Root
            configPath={configPath}
            onClosed={() => setOpenState(false)}
          >
            <GlobalConfigPrimitives.ServiceMap />
            <GlobalConfigPrimitives.Menu />
            <GlobalConfigPrimitives.Contents />
          </GlobalConfigPrimitives.Root>
        </ScreenLockProvider>
      </Dialog.Content>
    </Dialog.Root>
  );
}
