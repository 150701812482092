import { clsx } from 'clsx';
import { useTranslation } from '@dop-ui/react/shared/lib/i18n/client/use-translation';
import { useMessageDialog } from '@dop-ui/react/features/message-dialog';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  OrderType,
  SearchType,
  TableQueryInfo,
  TfaMemberInfo,
} from '../../types';
import { QUERY_KEY, getTfaMemberList } from '../../apis/get-tfa-member-list';
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';
import Avatar from '@dop-ui/react/shared/ui/avatar';
import * as TablePrimitives from '@daouoffice/ui/lib/labs/Table';
import { Button } from '@dop-ui/react/shared/ui/button';
import { formatDateString } from '../../../utils/dateTimeUtils';
import { XMarkIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { deleteTfaMember } from '../../apis/delete-tfa-member';

export function MemberList() {
  const { t } = useTranslation('component');
  const { confirm } = useMessageDialog();
  const queryClient = useQueryClient();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [selectedContents, setSelectedContents] = useState<TfaMemberInfo[]>([]);
  const isSelectedContent = selectedContents && selectedContents.length !== 0;

  const [tableInfo, setTableInfo] = useState<TableQueryInfo>({
    orderType: 'name',
    direction: 'asc',
    searchType: 'name',
    keyword: '',
  });

  const {
    data: tfaMemberList,
    error,
    refetch,
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY, page, size],
    queryFn: async () => await getTfaMemberList(page, size, tableInfo),
  });

  useEffect(() => {
    refetch().catch(console.error);
  }, [tableInfo]);

  useEffect(() => {
    setPage(0);
  }, [tableInfo.keyword, size]);

  const deleteTfaMemberMutaion = useMutation({
    mutationFn: async () =>
      await deleteTfaMember(selectedContents.map((item) => item.id)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
  });

  const days = {
    monday: t('days.monday'),
    tuesday: t('days.tuesday'),
    wednesday: t('days.wednesday'),
    thursday: t('days.thursday'),
    friday: t('days.friday'),
    saturday: t('days.saturday'),
    sunday: t('days.sunday'),
  };

  const searchOptions = new Map<SearchType, string>([
    [
      'name',
      t(
        'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.name',
      ),
    ],
    [
      'loginId',
      t(
        'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.account',
      ),
    ],
  ]);

  const SortChevron = ({ orderType }: { orderType: OrderType }) => {
    if (tableInfo.orderType === orderType) {
      if (tableInfo.direction === 'asc')
        return <ChevronUpIcon className="inline ml-[8px] size-[16px]" />;
      else return <ChevronDownIcon className="inline ml-[8px] size-[16px]" />;
    } else {
      return (
        <ChevronUpDownIcon
          className="inline ml-[8px] size-[16px]"
          color="#AAAAAA"
        />
      );
    }
  };

  const columnDefs: ColumnDef<TfaMemberInfo>[] = [
    {
      id: 'userInfo',
      accessorFn: (info) => ({
        name: info.name,
        userImage: info.thumbnailPath,
      }),
      header: () => (
        <button
          className="flex items-center px-[--Space-Small]"
          onClick={() => handleClickSort('name')}
        >
          {t(
            'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.name',
          )}
          <SortChevron orderType={'name'} />
        </button>
      ),
      cell: (cell) => {
        const value = cell.getValue() as { name: string; userImage?: string };
        return (
          <div className="flex items-center">
            <Avatar size={24} src={value.userImage} alt={value.name} />
            <span className="ml-[8px] text-[#262626] text-[14px] font-[400]">
              {value.name}
            </span>
          </div>
        );
      },
    },
    {
      id: 'loginId',
      accessorKey: 'loginId',
      header: () => (
        <button
          className="flex items-center px-[--Space-Small]"
          onClick={() => {
            handleClickSort('loginId');
          }}
        >
          {t(
            'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.account',
          )}
          <SortChevron orderType={'loginId'} />
        </button>
      ),
    },
    {
      id: 'osType',
      accessorFn: (info) =>
        info.extraAuthToolType === 'MAIL' ? '-' : info.osType,
      header: () => (
        <div className="text-left px-[--Space-Small]">
          {t(
            'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.ostype',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {cell.getValue() as string}
        </div>
      ),
    },
    {
      id: 'deviceModel',
      accessorFn: (info) =>
        info.extraAuthToolType === 'MAIL' ? '-' : info.deviceModel,
      header: () => (
        <div className="text-left px-[--Space-Small]">
          {t(
            'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.modelname',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {cell.getValue() as string}
        </div>
      ),
    },
    {
      id: 'deviceId',
      accessorFn: (info) =>
        info.extraAuthToolType === 'MAIL' ? '-' : info.deviceId,
      header: () => (
        <div className="text-left px-[--Space-Small]">
          {t(
            'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.deviceid',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {cell.getValue() as string}
        </div>
      ),
    },
    {
      id: 'externalEmail',
      accessorFn: (info) =>
        info.extraAuthToolType === 'MOBILE' ? '-' : info.externalEmail,
      header: () => (
        <div className="text-left px-[--Space-Small]">
          {t(
            'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.externalemail',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {cell.getValue() as string}
        </div>
      ),
    },
    {
      id: 'enrollDate',
      accessorFn: (info) => formatDateString(days, info.enrollDate),
      header: () => (
        <div className="text-left px-[--Space-Small]">
          {t(
            'globalconfig.secureManagement.tfaMemberConfig.detail.table.field.enrolldate',
          )}
        </div>
      ),
      cell: (cell) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {cell.getValue() as string}
        </div>
      ),
    },
  ];

  const handleClickSort = (orderType: OrderType) => {
    if (tableInfo.orderType === orderType) {
      setTableInfo({
        ...tableInfo,
        direction: tableInfo.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setTableInfo({
        ...tableInfo,
        orderType: orderType,
        direction: 'desc',
      });
    }
  };

  const handleChangeSearchKeyword = (keyword: string) => {
    setTableInfo({ ...tableInfo, keyword });
  };

  const onClickDelete = async () => {
    if (
      await confirm(
        <div>
          <h2 className="pb-[8px]">
            {t(
              'globalconfig.secureManagement.tfaMemberConfig.detail.delete.button.title',
            )}
          </h2>
          <p>
            {t(
              'globalconfig.secureManagement.tfaMemberConfig.detail.delete.button.description1',
            )}
          </p>
          <p>
            {t(
              'globalconfig.secureManagement.tfaMemberConfig.detail.delete.button.description2',
            )}
          </p>
        </div>,
      )
    ) {
      deleteTfaMemberMutaion.mutate();
    }
  };

  if (error) {
    console.error(
      'Error: GlobalConfig > SecureManagement > TfaMemberConfig : ',
      error,
    );
  }

  return (
    <TablePrimitives.Root<TfaMemberInfo>
      className="mt-[24px]"
      columnDefs={columnDefs}
      contents={tfaMemberList?.elements ?? []}
      selectable
      onSelectContents={setSelectedContents}
    >
      <div className="flex mb-[16px]">
        <div className="flex flex-col">
          <TablePrimitives.SearchBar
            className="inline-block w-[240px] h-[40px]"
            onKeywordChange={handleChangeSearchKeyword}
          >
            <select
              className="w-[50px] border-none mb-[2px] flex-shrink-0"
              onChange={(e) =>
                setTableInfo({
                  ...tableInfo,
                  searchType: e.target.value as SearchType,
                })
              }
            >
              {Array.from(searchOptions).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </TablePrimitives.SearchBar>
          {tableInfo.keyword && (
            <div className="pt-3 text-[#81A9FC]">{`${tfaMemberList.elements.length}${t('globalconfig.common.table.search.result')}`}</div>
          )}
        </div>
        <div className="spacer flex-grow" />
        <div className="flex justify-between items-end">
          <Button
            className={clsx(
              'flex justify-center items-center gap-1 p-[4px] mr-[8px] rounded',
              {
                'text-[#a5a5a5]': !isSelectedContent,
              },
              {
                'hover:bg-[#f2f2f2]': isSelectedContent,
              },
            )}
            disabled={!isSelectedContent}
            onClick={onClickDelete}
          >
            <XMarkIcon size={24} />
            {t(
              'globalconfig.secureManagement.tfaMemberConfig.detail.delete.button.title',
            )}
          </Button>
          <TablePrimitives.SizeSelector
            className="w-[52px]"
            tableSize={size}
            onChangeTableSize={setSize}
          />
        </div>
      </div>
      <TablePrimitives.Contents
        emptyNotice={
          <div className="w-full h-[80px] flex items-center justify-center ">
            <span className="text-[--color-text-level3] text-[14px] font-[400]">
              {t(
                'globalconfig.secureManagement.tfaMemberConfig.detail.table.noList',
              )}
            </span>
          </div>
        }
      ></TablePrimitives.Contents>
      <TablePrimitives.Pagination
        className="mt-[16px]"
        currentPage={page}
        totalPage={tfaMemberList?.page.totalPages ?? 0}
        onNextPage={setPage}
        onPreviousPage={setPage}
        onClickPage={setPage}
        onFirstPage={() => setPage(0)}
        onLastPage={() => setPage(tfaMemberList?.page.totalPages - 1 ?? 0)}
      />
    </TablePrimitives.Root>
  );
}

export default MemberList;
