import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { DEFAULT_OFFSET } from './constants';

export interface IMultiMemberContextValues {
  page: number;
  pageOffset: number;
}

export interface IMultiMemberContextActions {
  setPage: Dispatch<SetStateAction<number>>;
  setPageOffset: Dispatch<SetStateAction<number>>;
}

export const ValuesContext = createContext<IMultiMemberContextValues>({
  page: 0,
  pageOffset: DEFAULT_OFFSET,
});

export const ActionsContext = createContext<IMultiMemberContextActions>({
  setPage: () => undefined,
  setPageOffset: () => undefined,
});

export function Provider({ children }: PropsWithChildren) {
  const [page, setPage] = useState(0);
  const [pageOffset, setPageOffset] = useState(DEFAULT_OFFSET);

  const ctxValues = {
    page,
    pageOffset,
  };

  const ctxActions = {
    setPage,
    setPageOffset,
  };
  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}
export const useValuesContext = () => useContext(ValuesContext);
export const useActionsContext = () => useContext(ActionsContext);

export const useMultiMemberContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();
  return { ...values, ...actions };
};
