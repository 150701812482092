import { IconButton } from '@daouoffice/ui';
import { SearchClearIcon, SearchIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useTranslation } from 'react-i18next';

export function SearchBar() {
  // TODO: 검색 기능 추가 필요. -> Context API로 searchKeyword와 isSearching 관리 필요.
  const { t } = useTranslation();
  const clearButtonClickHandler = () => {};

  return (
    <div className="flex w-[576px] absolute left-1/2 -translate-x-1/2 bg-[#f5f5f5] items-center rounded-lg py-1">
      <SearchIcon className="relative left-[18px]" />
      <input
        className="input_txt bg-transparent w-full pl-[24px]"
        id="test_search"
        placeholder={t('globalconfig.servicemap.search.placeholder')}
        type="search"
      />
      <IconButton
        title="clear_btn"
        className="relative right-[8px]"
        onClick={clearButtonClickHandler}
      >
        <SearchClearIcon />
      </IconButton>
    </div>
  );
}

export default SearchBar;
