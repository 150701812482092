import { fetch } from '../../../../../../lib/fetch/client';

export const MOCK_URL_PATH = '/gw/ad/api';

export const QUERY_KEY = 'permanentDeleteMutation';

export async function permanentDeleteMutation(ids?: number[]) {
  try {
    const FETCH_URL = `${MOCK_URL_PATH}/department/complate/delete`;
    const res = (
      await fetch(FETCH_URL, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: ids ?? [] }),
      })
    ).json();
    return res;
  } catch (error) {
    console.error(error);
  }
}
