import { useDebounce } from '@daouoffice/ui';
import { clsx } from 'clsx';
import { useState } from 'react';
import { FileSizeType } from '../../../../types';
import { formatNumberWithCommas } from '../../../../utils/byteFormatter';

export interface Props {
  title?: string;
  defaultValue?: number;
  onValueChange: (value: number) => void;
  fileSizeType: FileSizeType;
  onFileSizeTypeChange: (value: FileSizeType) => void;
  maxSize?: number;
  withSizeSelector?: boolean;
  className?: string;
}

export function StorageField({
  title,
  defaultValue,
  onValueChange,
  fileSizeType,
  onFileSizeTypeChange,
  maxSize = 122800,
  withSizeSelector = true,
  className,
}: Props) {
  const [value, setValue] = useState<string>(
    defaultValue ? `${defaultValue}` : '',
  );

  const onValueChangeHandler = (value: string) => {
    const num = Number(value);
    const convertedSize = fileSizeType === 'MB' ? maxSize : maxSize / 1000;

    if (isNaN(num)) setValue('');

    if (num < 0) {
      setValue(String(0));
      debouncedHandleValue(0);
    } else if (num > convertedSize) {
      setValue(String(convertedSize));
      debouncedHandleValue(convertedSize);
    } else {
      setValue(value);
      debouncedHandleValue(Number(value));
    }
  };

  const debouncedHandleValue = useDebounce((value: number) => {
    onValueChange(value);
  }, 700);

  return (
    <div className={clsx('flex items-center gap-2', className)}>
      {title && (
        <span className="text-[#1A1A1A] text-[14x] font-medium leading-[150%] -tracking-[0.56px]">
          {title}
        </span>
      )}
      <input
        value={value}
        className="w-[100px] h-[40px] p-3 border border-solid border-[#D8D8D8] rounded-lg appearance-none"
        type="number"
        placeholder={`0~${formatNumberWithCommas(fileSizeType === 'MB' ? maxSize : maxSize / 1000)}`}
        onChange={(e) => onValueChangeHandler(e.target.value)}
      />
      {withSizeSelector ? (
        <select
          defaultValue={fileSizeType}
          className="w-[80px] h-[40px] px-2 border border-solid rounded-lg border-[#d8d8d8]"
          onChange={(e) => {
            onFileSizeTypeChange(e.target.value as FileSizeType);
            onValueChangeHandler('');
          }}
        >
          <option value="MB">MB</option>
          <option value="GB">GB</option>
        </select>
      ) : (
        <span>{fileSizeType ?? 'MB'}</span>
      )}
    </div>
  );
}

export default StorageField;
