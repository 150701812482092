import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * AdminTable Page Indicator 첫번째 페이지로 이동하는 (16x16) 아이콘
 * @param props
 * @returns
 */
export function FirstIndicatorIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62288 2.62288C3.83116 2.4146 4.16885 2.4146 4.37713 2.62288L9.37713 7.62288C9.58541 7.83116 9.58541 8.16884 9.37713 8.37712L4.37713 13.3771C4.16885 13.5854 3.83116 13.5854 3.62288 13.3771C3.4146 13.1688 3.4146 12.8312 3.62288 12.6229L8.24576 8L3.62288 3.37712C3.4146 3.16884 3.4146 2.83116 3.62288 2.62288ZM7.62288 2.62288C7.83116 2.4146 8.16885 2.4146 8.37713 2.62288L13.3771 7.62288C13.5854 7.83116 13.5854 8.16884 13.3771 8.37712L8.37713 13.3771C8.16885 13.5854 7.83116 13.5854 7.62288 13.3771C7.41461 13.1688 7.41461 12.8312 7.62288 12.6229L12.2458 8L7.62288 3.37712C7.41461 3.16884 7.41461 2.83116 7.62288 2.62288Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default FirstIndicatorIcon;
