import Contents from './components/Contents';
import { Root } from './components/Root';

export function AdminManagement() {
  return (
    <Root>
      <Contents />
    </Root>
  );
}

export default AdminManagement;
