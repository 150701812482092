import { Switch } from '../../../foundation/Switch';
import { useContext } from '../Context';

export function ServiceMapTitle() {
  const { isUsedOnly, setUsedOnlyState, t } = useContext();

  const toggleHandler = (state: boolean) => {
    setUsedOnlyState(!state);
  };

  return (
    <div className="wrap_title">
      <i className="icon ic_menu_map"></i>
      <h1 className="title heading_medium bold">{t('serviceMap.title')}</h1>
      <Switch
        id="all_menu"
        label={t('serviceMap.label.showAllApps')}
        defaultChecked={!isUsedOnly}
        onChange={toggleHandler}
      />
    </div>
  );
}
