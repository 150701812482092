import * as React from 'react';
import { SubTabType } from '../types';

export interface IContext {
  selectedType: SubTabType;
  setSelectedType: React.Dispatch<React.SetStateAction<SubTabType>>;
}

const Context = React.createContext<IContext>({
  selectedType: 'perApps',
  setSelectedType: () => undefined,
});

export interface Props {
  selectedTab?: SubTabType;
}

export function Provider({
  selectedTab,
  children,
}: React.PropsWithChildren<Props>) {
  const [selectedType, setSelectedType] = React.useState<SubTabType>('perApps');

  React.useEffect(() => {
    if (selectedTab) {
      setSelectedType(selectedTab);
    }
  }, [selectedTab]);

  const contextValues: IContext = {
    selectedType,
    setSelectedType,
  };

  return <Context.Provider value={contextValues}>{children}</Context.Provider>;
}

export const useContextValues = () => React.useContext(Context);
