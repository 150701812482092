import { IconButton } from '@daouoffice/ui';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24';
import * as Dialog from '@radix-ui/react-dialog';

export function CloseButton() {
  return (
    <Dialog.Close>
      <IconButton
        title="btn_close"
        className="absolute top-[10px] right-[10px] inline-flex size-10 appearance-none items-center"
        aria-label="Close"
      >
        <CloseIcon />
      </IconButton>
    </Dialog.Close>
  );
}

export default CloseButton;
