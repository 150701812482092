import { gql } from 'graphql-request';
import { fetch, fetchGraphQL } from '@dop-ui/react/shared/lib/fetch/client';

import { UPDATE_SECURE_CONFIG_API } from '../../constants';

const tfaUseConfigQuery = gql`
  query {
    authConfig {
      USE_EXTRA_AUTH
    }
  }
`;

interface ConfigQueryResponse<T> {
  data: {
    authConfig: T;
  };
}

interface PlatformTfaUseResponse {
  USE_EXTRA_AUTH: boolean;
}

interface PlatformTfaUseConfig {
  useTfa: boolean;
}

export const QUERY_KEY =
  'GlobalConfig.SecureManagement.TFAConfig.PlatformTfaUseConfig';

export async function getPlatformTfaUseConfig(): Promise<PlatformTfaUseConfig> {
  const response = await fetchGraphQL({ query: tfaUseConfigQuery });
  const responseData =
    (await response.json()) as ConfigQueryResponse<PlatformTfaUseResponse>;
  return {
    useTfa: responseData.data.authConfig.USE_EXTRA_AUTH,
  };
}

async function configPostFetch(key: string, value: unknown) {
  await fetch(UPDATE_SECURE_CONFIG_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Referer-Info': window.location.host,
    },
    body: JSON.stringify({
      configType: 'AUTH',
      configName: key,
      configValue: value,
    }),
  });
}

export async function updateTfaConfig(value: boolean) {
  await configPostFetch('USE_EXTRA_AUTH', value);
}
