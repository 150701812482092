import { SECURE_MANAGEMENT } from '../main/menuPaths';

const menuKeyPath = SECURE_MANAGEMENT;

export const LOGIN_PATH = menuKeyPath + '/login';
export const LOGIN_CONFIG_PATH = LOGIN_PATH + '/config';
export const PASSWORD_CONFIG_PATH = LOGIN_PATH + '/password-config';

export const ADMIN_PATH = menuKeyPath + '/admin';
export const ADMIN_CONFIG_PATH = ADMIN_PATH + '/config';

export const TFA_PATH = menuKeyPath + '/tfa';
export const TFA_CONFIG_PATH = TFA_PATH + '/config';
export const TFA_EXCEPTION_PATH = TFA_PATH + '/exception';
export const TFA_MEMBER_PATH = TFA_PATH + '/member';
export const TFA_ERROR_LIST_PATH = TFA_PATH + '/error-list';

export const MEDIA_PATH = menuKeyPath + '/media';
export const MEDIA_CONFIG_PATH = MEDIA_PATH + '/config';

export const APP_SECURITY_PATH = menuKeyPath + '/app-security';
export const APP_SECURITY_CONFIG_PATH = APP_SECURITY_PATH + '/config';

export const MOBILE_PATH = menuKeyPath + '/mobile';
export const MOBILE_DEVICE_ACCESS_PATH = MOBILE_PATH + '/device-access';
export const MOBILE_ACCESS_BLOCK_PATH = MOBILE_PATH + '/access-block';
export const MOBILE_SECURITY_CONFIG_PATH = MOBILE_PATH + '/security-config';

export const LOG_PATH = menuKeyPath + '/log';
export const LOG_MEMBER_ACCESS_PATH = LOG_PATH + '/member-access';
export const LOG_ADMIN_PATH = LOG_PATH + '/admin';
