import { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, IconButton } from '@daouoffice/ui/lib/foundation/Button';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24/Close';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

export interface Props {
  onClickApply: (blackList: string[]) => void;
}

export function BatchDialog({ onClickApply }: Props) {
  const { t } = useTranslation();
  const [blackList, setBlackList] = useState<Set<string>>(new Set());
  const [blacklistText, setBlacklistText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);

  const onChangeBlackList = (text: string) => {
    if (text.match(/[^,a-zA-Z0-9]/g)) {
      setErrorMessage('영어,숫자,쉼표(,)만 입력 가능합니다.');
    } else {
      setErrorMessage('');
    }
    setBlacklistText(text.replace(/[^,a-zA-Z0-9]/g, ''));
  };

  const onClickAddBlackList = () => {
    if (blacklistText) {
      const newBlackList = new Set(blackList);

      blacklistText
        .split(',')
        .filter((extensionName) => extensionName.trim().length > 0)
        .map((self) => self.toLowerCase())
        .forEach((extensionName) => newBlackList.add(extensionName));

      setBlacklistText('');
      setBlackList(newBlackList);
    }
  };

  const onDeleteBlackList = (extensionName: string) => {
    const newBlackList = new Set(blackList);
    newBlackList.delete(extensionName);
    setBlackList(newBlackList);
  };

  const onClickSave = () => {
    onClickApply(Array.from(blackList));
    setOpen(false);
  };

  const onClickClose = () => {
    setBlacklistText('');
    setBlackList(new Set());
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>일괄 설정</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 z-[1000]" />
        <Dialog.Content
          className="fixed bg-white p-[40px] rounded-[16px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1000]"
          onInteractOutside={(e) => e.preventDefault()}
        >
          <Dialog.Close asChild>
            <IconButton
              className="absolute top-[24px] right-[24px]"
              title={t('dialog.close')}
              size="medium"
              onClick={onClickClose}
            >
              <CloseIcon />
            </IconButton>
          </Dialog.Close>
          <h1 className="mb-[12px]">일괄 설정</h1>
          업로드 불가 확장자를 적용 대상 앱 전체에 추가합니다.
          <div className="flex mt-[24px] items-center h-[40px] gap-[24px]">
            <input
              className="px-[8px] h-full min-w-[400px] flex-grow border border-solid border-[#AAAAAA] rounded-[8px] disabled:opacity-20"
              placeholder="업로드를 제한할 확장자를 ,(쉼표)로 구분하여 입력해주세요. ex) pdf,txt"
              value={blacklistText}
              onChange={(e) => onChangeBlackList(e.target.value)}
            />
            <Button
              title="추가"
              size="medium"
              shape="rect"
              onClick={onClickAddBlackList}
              disabled={blacklistText.length === 0}
            />
          </div>
          {errorMessage && (
            <p className="text-[red] mt-[8px]">{errorMessage}</p>
          )}
          <div className="my-[24px] w-full h-[1px] bg-[#CECECE]" />
          <p className="mt-[12px]">업로드 불가 확장자</p>
          <div className="flex flex-wrap mt-[12px] flex-grow gap-3 min-h-[32px]">
            {Array.from(blackList).map((info) => {
              return (
                <div
                  key={info}
                  className="pl-[8px] border border-solid border-[#AAAAAA] h-[32px] rounded-[8px] flex items-center"
                >
                  {info}
                  <IconButton
                    title={info}
                    size="small"
                    onClick={() => onDeleteBlackList(info)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              );
            })}
          </div>
          <div className="mt-[24px] py-[16px] flex items-center justify-center gap-[8px]">
            <Dialog.Close asChild>
              <Button
                title={t('dialog.cancel')}
                colorset="minor"
                shape="rect"
                styleType="line"
                size="medium"
                onClick={onClickClose}
              />
            </Dialog.Close>
            <Button
              title={t('dialog.save')}
              colorset="major"
              shape="rect"
              styleType="solid"
              size="medium"
              disabled={blackList.size === 0}
              onClick={onClickSave}
            />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
