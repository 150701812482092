import { Suspense } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { DomainInfo } from './domain-info';
import { UsersInfo } from './users-info';
import { DataUsageInfo } from './data-usage-info';
import { RemainUsePeriodInfo } from './remain-use-period-info';
import { InUseAppsInfo } from './components/in-use-apps-info';
import { getBasicServiceInfo, QUERY_KEY } from './apis/basic-service-info';

export function ServiceInfo() {
  const { data: serviceInfo, error } = useSuspenseQuery({
    queryKey: [QUERY_KEY],
    queryFn: getBasicServiceInfo,
  });

  if (error) {
    return <div>Error</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="px-[72px] py-[40px]">
        <DomainInfo
          siteName={serviceInfo.name}
          siteURL={serviceInfo.siteUrl}
          siteID={serviceInfo.id}
          domain={serviceInfo.domain}
        />
        <div className="py-[32px] max-w-full flex flex-wrap 2xl:flex-nowrap items-center justify-evenly gap-[16px]">
          <RemainUsePeriodInfo
            startDateString={serviceInfo.startedAt}
            expireDateString={serviceInfo.usePeriod}
          />
          <UsersInfo
            totalUserCount={serviceInfo.usedUser}
            currentUsingUsersCount={
              serviceInfo.usedUser - serviceInfo.allocatedUser
            }
          />
          <DataUsageInfo
            totalData={serviceInfo.allocatedStorageCount}
            currentUsingData={serviceInfo.usedStorage}
          />
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <InUseAppsInfo />
        </Suspense>
      </div>
    </Suspense>
  );
}
