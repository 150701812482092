import { Button } from '@dop-ui/react/shared/ui/button';
import { ReactNode } from 'react';
import { useContext } from './Context';

export function Trigger({ children }: { children: ReactNode }) {
  const { setCardOpenState } = useContext();
  return (
    <Button className="btn_profile" onClick={() => setCardOpenState(true)}>
      {children}
    </Button>
  );
}
