import { fetch } from '../../../../../../lib/fetch/client';
import type { MultiMemberResponse } from '../types';

interface Params {
  page: number;
  size: number;
  companyGroupId?: number | string;
}

export const QUERY_KEY =
  'QueryKeys.GlobalConfig.MultiCompanyManagement.GetMultiMemberList';

export async function getMultiMemberList({
  page,
  size,
  companyGroupId,
}: Params): Promise<MultiMemberResponse | null> {
  if (!companyGroupId) return null;
  const query = new URLSearchParams();
  query.append('page', page.toString());
  query.append('size', size.toString());

  const response = await fetch(
    `/api/portal/setting/integration/${companyGroupId}?${query.toString()}`,
  );

  const { data } = (await response.json()) as { data: MultiMemberResponse };

  return data;
}
