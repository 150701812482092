import { useMenusAtom } from '../../store';
import * as AppsDockConfigPrimitives from './primitives';

export function AppsDockConfigClient() {
  const [menusAtom] = useMenusAtom();

  return (
    <AppsDockConfigPrimitives.Root userApps={menusAtom}>
      <AppsDockConfigPrimitives.Header title="앱 목록 편집" />
      <AppsDockConfigPrimitives.Contents />
      <AppsDockConfigPrimitives.Footer />
    </AppsDockConfigPrimitives.Root>
  );
}

export default AppsDockConfigClient;
